/* eslint-disable max-lines */
import { useMutation } from '@apollo/client';
import { Alert, AlertDescription } from '@askable/ui/components/ui/alert';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@askable/ui/components/ui/alert-dialog';
import { Button } from '@askable/ui/components/ui/button';
import { Checkbox } from '@askable/ui/components/ui/checkbox';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@askable/ui/components/ui/dialog';
import { Input } from '@askable/ui/components/ui/input';
import { Label } from '@askable/ui/components/ui/label';
import { Textarea } from '@askable/ui/components/ui/textarea';
import { ToggleGroup, ToggleGroupItem } from '@askable/ui/components/ui/toggle-group';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { GripVertical, Trash, List, Text, Check, X } from 'lucide-react';
import { useState, useEffect } from 'react';

import { LoadingOverlay, BoxMessage } from 'components/common';
import createProjectQuestionMutation from 'data/mutations/askablePlus/createProjectQuestion';
import updateProjectQuestionMutation from 'data/mutations/askablePlus/updateProjectQuestion';
import { utils, update } from 'lib/utils';

import type { QuestionType, ScreeningQuestion } from 'types/screening-question';

type Props = {
  open: boolean;
  onClose: () => void;
  onSave: (data: any) => void;
  itemToBeEdited: ScreeningQuestion;
  questionType: QuestionType;
  projectID: string;
  bookingID: string;
  onChange?: (data: any) => void;
};
function ScreeningQuestionModal(props: Props) {
  const [showAdditionalDescription, setShowAdditionalDescription] = useState(
    props.questionType === 2 || (props.questionType === 1 && _.get(props, 'itemToBeEdited.title')),
  );
  const [isLoading, setIsLoading] = useState(false);
  const [draggedFrom, setDraggedFrom] = useState(null);
  const [draggedTo, setDraggedTo] = useState(null);
  const [error, setError] = useState<any>(null);
  const [, setOnFocusInput] = useState('');
  const [isLongQuestion, setIsLongQuestion] = useState(false);
  const [showEligibilityHelp, setShowEligibilityHelp] = useState(false);
  const [openCloseConfirmation, setOpenCloseConfirmation] = useState(false);
  const [question, setQuestion] = useState({
    title: '',
    description: '',
    config: {
      display_participant: false,
      multiple_selection: false,
      none_of_the_above: false,
      type: 1,
    },
    options: [
      {
        label: '',
        screen_in: true,
      },
    ],
  });

  useEffect(() => {
    // If a client is trying to edit its custom question, it should pre-populate the fields
    if (props.itemToBeEdited) {
      const editedQuestion = {
        _id: props.itemToBeEdited._id,
        title: props.itemToBeEdited.title,
        description: props.itemToBeEdited.description || '',
        config: {
          display_participant:
            (props.itemToBeEdited.config && props.itemToBeEdited.config.display_participant) || false,
          multiple_selection: (props.itemToBeEdited.config && props.itemToBeEdited.config.multiple_selection) || false,
          none_of_the_above: (props.itemToBeEdited.config && props.itemToBeEdited.config.none_of_the_above) || false,
          type: (props.itemToBeEdited.config && props.itemToBeEdited.config.type) || 1,
        },
        options: _.map(props.itemToBeEdited.options, (option: any) => {
          return {
            _id: option._id,
            label: option.label,
            screen_in: option.screen_in,
          };
        }),
      };

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ label: string; screen_in: bool... Remove this comment to see the full error message
      editedQuestion.options.push({
        label: '',
        screen_in: true,
      });
      setQuestion(editedQuestion);
      validateLongQuestions(editedQuestion);
    } else {
      resetState();
    }
  }, [props.open]);

  const [createProjectQuestion] = useMutation(createProjectQuestionMutation, {
    onCompleted: data => {
      setIsLoading(false);

      props.onSave(data.createProjectQuestion);

      resetState();
    },

    onError: e => {
      setIsLoading(false);

      setError(_.get(e, 'graphQLErrors[0].message'));
    },
  });

  const [updateProjectQuestion] = useMutation(updateProjectQuestionMutation, {
    onCompleted: data => {
      setIsLoading(false);
      props.onSave(data.updateProjectQuestion);
      resetState();
    },

    onError: e => {
      setIsLoading(false);
      setError(_.get(e, 'graphQLErrors[0].message'));
    },
  });

  const validateLongQuestions = (questionData: any) => {
    const questionOptions = _.filter(_.get(questionData, 'options'), (item: any) => item.label !== '');
    let longOptions = 0;
    _.map(questionOptions, (item: any) => {
      if (item.label.length > 100) longOptions += 1;
    });

    if (_.size(questionOptions) > 10 || longOptions >= 5) {
      setIsLongQuestion(true);
    } else {
      setIsLongQuestion(false);
    }
  };

  const somethingWasChanged = () => {
    const options = question.options.filter(option => option.label !== '');
    const finalObject = {
      ...question,
      options,
    };

    if (props.itemToBeEdited) {
      return JSON.stringify(finalObject) !== JSON.stringify(utils.removeTypenames(props.itemToBeEdited));
    }
    if (
      props.questionType === 1 &&
      (finalObject.title || finalObject.description || _.size(finalObject.options) >= 1)
    ) {
      return true;
    }
    if (props.questionType === 2 && (finalObject.title || finalObject.description)) {
      return true;
    }
    return false;
  };

  const onClickSave = () => {
    // Reset the un-uset fields and pass back to its parent
    const options = question.options.filter(option => option.label !== '');
    const finalObject: any = {
      ...question,
      options,
    };

    setIsLoading(true);
    if (_.size(props.itemToBeEdited) > 0) {
      updateProjectQuestion({
        variables: {
          project_id: props.projectID,
          question_id: finalObject._id,
          question: finalObject,
        },
      });
    } else {
      // Create the question in the database

      createProjectQuestion({
        variables: {
          project_id: props.projectID,
          question: finalObject,
        },
      });
    }
  };

  const onDragStart = (ev: any) => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    setDraggedFrom(Number(ev.currentTarget.dataset.id));
    ev.dataTransfer.effectAllowed = 'move';

    // Firefox requires calling dataTransfer.setData for the drag to properly work
    ev.dataTransfer.setData('text/html', null);
  };

  const onDragOver = (ev: any) => {
    ev.preventDefault();
    const draggingTo = Number(ev.currentTarget.dataset.id);
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    if (draggedTo !== draggingTo) setDraggedTo(draggingTo);
  };

  const onDragEnd = () => {
    // Check weather the user is dragging to a different position
    // Also checks if the user is not dragging at the end of the array
    const lastArrayPosition = question.options.length - 1;
    if (draggedFrom !== draggedTo && draggedTo < lastArrayPosition) {
      setQuestion({
        ...question,
        options: utils.arrayMove(question.options, draggedFrom, draggedTo),
      });
      setDraggedFrom(null);
      setDraggedTo(null);
    } else {
      setDraggedFrom(null);
      setDraggedTo(null);
    }
  };

  const validateQuestion = () => {
    // It should check for pre-requesites depending on the type of question
    if (
      props.questionType === 1 &&
      (!question.title || question.options.length <= 2 || question.options.filter(item => item.screen_in).length === 1)
    ) {
      return true;
    }
    if (props.questionType === 2 && !question.title) return true;
    return false;
  };

  const resetState = () => {
    setQuestion({
      title: '',
      description: '',
      config: {
        display_participant: false,
        multiple_selection: false,
        none_of_the_above: false,
        type: props.questionType || 1,
      },
      options: [
        {
          label: '',
          screen_in: true,
        },
      ],
    });
    setIsLoading(false);
  };

  const renderErrorContainer = () => {
    if (error) {
      return (
        <BoxMessage type="error mtop20" error={error}>
          <span className="errorMessage">{error}</span>
        </BoxMessage>
      );
    }
  };

  const onUpdateQuestion = (index: any, field: any, value: any, config = question.config) => {
    // Test if it should attach a new empty object at the end of the array
    const newOptionsObject = question.options;
    if (field === 'label') {
      if (question.options.length === index + 1) {
        newOptionsObject.push({
          label: '',
          screen_in: true,
        });
      }
    }
    const questionObj = {
      ...question,
      config,
      options: update(newOptionsObject, {
        $auto: {
          [index]: {
            $auto: {
              [field]: {
                $set: value,
              },
            },
          },
        },
      }),
    };
    setQuestion(questionObj);
    validateLongQuestions(questionObj);
  };

  const renderAnswersContainer = (index: any) => {
    const isDisabled = _.size(question.options[index].label) === 0;
    return (
      <li
        key={`__answerContainer_${index}`}
        className={`answerContainer group -mb-1 -ml-6 -mr-1 -mt-1 flex items-center gap-2 rounded p-1 ${
          draggedTo === index ? 'bg-primary-medium-hover' : ''
        } ${isDisabled ? 'disabled' : ''}`}
        data-id={index}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDragOver={onDragOver}
      >
        <div className={`-mr-1 ${isDisabled ? 'disabled' : ''}`} draggable={!isDisabled}>
          <GripVertical className={`h-4 w-4 ${isDisabled ? 'invisible' : ''}`} />
        </div>
        <Input
          id={`__answerInput_${index}`}
          value={question.options[index].label}
          placeholder={`Answer ${index + 1}...`}
          onChange={(e: any) => onUpdateQuestion(index, 'label', e.target.value, question.config)}
          onFocus={() => setOnFocusInput(`answerInput_${index}`)}
          className="w-full"
        />

        <div className="flex items-center gap-1 text-xs">
          <ToggleGroup
            defaultValue={question.options[index].screen_in ? 'eligible' : 'not-eligible'}
            disabled={isDisabled}
            id={`eligibility_${index}`}
            key={`eligibility_${index}_${question.options[index].label}`}
            onValueChange={value => {
              onUpdateQuestion(index, 'screen_in', value === 'eligible');
            }}
            type="single"
          >
            <ToggleGroupItem value="eligible" aria-label="Mark as eligible">
              <Check className="h-4 w-4" />
            </ToggleGroupItem>
            <ToggleGroupItem value="not-eligible" aria-label="Mark as not Eligible">
              <X className="h-4 w-4" />
            </ToggleGroupItem>
          </ToggleGroup>
        </div>

        <Button
          variant="ghost"
          onClick={() => {
            const questionObj = {
              ...question,
              options: update(question.options, {
                $splice: [[index, 1]],
              }),
            };
            setQuestion(questionObj);
            validateLongQuestions(questionObj);
          }}
          disabled={question.options.length === 1 || question.options.length === index + 1}
        >
          <Trash className="deleteIcon h-4 w-4" />
        </Button>
      </li>
    );
  };

  return (
    <>
      <Dialog
        open={props.open}
        onOpenChange={open => {
          if (!open) {
            if (somethingWasChanged()) {
              setOpenCloseConfirmation(true);
              return;
            }

            props.onClose();
          }
        }}
      >
        <DialogContent className="max-w-2xl">
          <DialogHeader>
            {props.questionType === 1 ? (
              <DialogTitle className="flex items-center gap-2">
                <List className="h-4 w-4" /> Multiple Choice
              </DialogTitle>
            ) : (
              <DialogTitle className="flex items-center gap-2">
                <Text className="h-4 w-4" /> Short answer
              </DialogTitle>
            )}
          </DialogHeader>

          {isLoading ? <LoadingOverlay style={{ opacity: 0.8 }} /> : null}

          {error ? renderErrorContainer() : null}

          <div className="flex flex-col gap-2">
            <Label htmlFor="__questionInput">Screener question</Label>
            <Textarea
              id="__questionInput"
              value={question.title}
              rows={2}
              maxRows={3}
              autoFocus
              onChange={e => {
                setQuestion({
                  ...question,
                  title: e.target.value,
                });
                if (props.onChange) {
                  props.onChange({
                    ...question,
                    title: e.target.value,
                  });
                }
              }}
              onFocus={() => setOnFocusInput('questionInput')}
            />

            {!showAdditionalDescription ? (
              <Button variant="link" onClick={() => setShowAdditionalDescription(true)} className="-ml-2">
                Add a description
              </Button>
            ) : null}
          </div>

          <div className="flex flex-col gap-2">
            {showAdditionalDescription ? (
              <div className="fieldContainer flex flex-col gap-2">
                <Label htmlFor="__questionDescriptionInput">
                  Description <span className="text-muted-foreground">(optional)</span>
                </Label>
                <Textarea
                  id="__questionDescriptionInput"
                  value={question.description}
                  rows={2}
                  maxRows={3}
                  onChange={e => {
                    setQuestion({
                      ...question,
                      description: e.target.value,
                    });
                    if (props.onChange) {
                      props.onChange({
                        ...question,
                        description: e.target.value,
                      });
                    }
                  }}
                  onFocus={() => setOnFocusInput('descriptionInput')}
                />
              </div>
            ) : null}
          </div>

          {props.questionType === 1 ? (
            <>
              <div>
                <div className="text-md font-semibold">Answers</div>

                <div className="-mt-1 text-xs text-muted-foreground">
                  Participants must answer every question on your screener.{' '}
                  <Button
                    variant="link"
                    className="-ml-2 inline-block text-xs"
                    onClick={() => {
                      setShowEligibilityHelp(true);
                    }}
                  >
                    Learn more
                  </Button>
                </div>
              </div>

              <ul className="flex flex-col gap-2">
                {question.options.map((value, index) => renderAnswersContainer(index))}
              </ul>

              <div className="flex items-center gap-2">
                <Checkbox
                  id="multiple-selections"
                  checked={question.config.multiple_selection}
                  onCheckedChange={(checked: boolean) => {
                    setQuestion({
                      ...question,
                      config: {
                        ...question.config,
                        multiple_selection: checked,
                      },
                    });
                  }}
                />
                <Label htmlFor="multiple-selections">Allow multiple selections</Label>
              </div>
            </>
          ) : null}

          {isLongQuestion ? (
            <Alert variant="info">
              <AlertDescription>
                <strong>Your question is getting hard to read.</strong>
                <br /> Participants will do their best to try and answer accurately but complex questions like this can
                make it difficult for them. To ensure you get the right participants, we recommend simplifying this
                question or splitting it up.
              </AlertDescription>
            </Alert>
          ) : null}

          <DialogFooter>
            <Button variant="primary" size="lg" onClick={onClickSave} disabled={validateQuestion()}>
              Save
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <AlertDialog open={openCloseConfirmation}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Leave without saving?</AlertDialogTitle>
            <AlertDialogDescription>Any unsaved changes will be lost.</AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel
              onClick={() => {
                setOpenCloseConfirmation(false);
              }}
            >
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                setOpenCloseConfirmation(false);
                props.onClose();
              }}
            >
              Leave
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog open={showEligibilityHelp}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>How does Eligibility work?</AlertDialogTitle>
            <AlertDialogDescription className="flex items-center gap-2">
              <img
                className="eligibilityHelp"
                src="/booking/illustrations/eligibilityHelpImage.svg"
                alt="eligibilityHelp"
              />
              <div className="flex flex-col gap-2">
                <p>
                  Participants must answer every question on your screener. Once they&apos;ve applied, they will appear
                  on your project dashboard.
                </p>
                <p>Depending on their answers, you invite whichever applicants are the most suitable.</p>
              </div>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogAction
              onClick={() => {
                setShowEligibilityHelp(false);
              }}
            >
              Close
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

export default deprecatedWithRouter(ScreeningQuestionModal);
