/* eslint-disable max-lines */
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@askable/ui/components/ui/alert-dialog';
import { Button } from '@askable/ui/components/ui/button';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@askable/ui/components/ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@askable/ui/components/ui/dropdown-menu';
import { Input } from '@askable/ui/components/ui/input';
import { Label } from '@askable/ui/components/ui/label';
import { toast } from '@askable/ui/components/ui/sonner';
import { Textarea } from '@askable/ui/components/ui/textarea';
import { FileText, Ellipsis } from 'lucide-react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import deleteESignatureTemplateMutation from 'src/data/mutations/esignature_templates/requestESignatureTemplateDelete';
import updateESignatureTemplateMutation from 'src/data/mutations/esignature_templates/updateESignatureTemplate';
import getESignaturePreviewURLQuery from 'src/data/queries/esignature_templates/getESignaturePreviewURL';
import { bookingUtils } from 'src/lib/booking';
import { Badge, FormControl, FormErrorMessage } from 'ui';

import { validations } from 'lib/validations';

import type { Booking } from 'generated/graphql';

const getRecipients = (input: string | null): { emails: string[]; error: string | null } => {
  if (!input) return { emails: [], error: null };
  const emailAddresses = input
    .split(/[,\s]+/)
    .filter(email => email.trim())
    .map(email => ({ email, valid: validations.validateEmail(email) }));

  let error = null;

  if (emailAddresses.length === 0) {
    error = 'Please provide at least one email address';
  }
  const invalidEmail = emailAddresses.find(({ valid }) => !valid);
  if (!error && invalidEmail) {
    error = `"${invalidEmail.email}" is not a valid email address`;
  }

  return {
    emails: emailAddresses.filter(({ valid }) => valid).map(({ email }) => email),
    error,
  };
};

export const TemplateItem = ({ template, onUpdate }: { template: { [k: string]: any }; onUpdate: any }) => {
  const { t } = useTranslation();

  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [recipientsError, setRecipientsError] = useState<string | null>(null);
  const [recipientsInput, setRecipientsInput] = useState<string | null>((template?.recipients || []).join(', '));
  const [recipientsList, setRecipientsList] = useState<{ emails: string[]; error: string | null }>(getRecipients(null));
  const [showMoreRecipients, setShowMoreRecipients] = useState(false);
  const [titleInput, setTitleInput] = useState<string | null>(template.name || '');

  const [updateTemplate, updateTemplateStatus] = useMutation(updateESignatureTemplateMutation);
  const [deleteTemplate, deleteTemplateStatus] = useMutation(deleteESignatureTemplateMutation);
  const [getPreviewURL, getPreviewURLStatus] = useLazyQuery(getESignaturePreviewURLQuery);

  useEffect(() => {
    const list = getRecipients(recipientsInput);
    setRecipientsList(list);
    if (recipientsError && !list.error) {
      setRecipientsError(null);
    }
  }, [recipientsInput]);

  const resetTemplateState = () => {
    setTitleInput(template.name || '');
    setRecipientsInput((template?.recipients || []).join(', '));
  };

  useEffect(resetTemplateState, [template]);

  const formError = !!(!titleInput || recipientsList.error);

  const closeEditModal = () => {
    if (updateTemplateStatus.loading) {
      return null;
    }
    resetTemplateState();
    setIsEditing(false);
  };

  return (
    <>
      <div key={template._id} className="border-bottom flex flex-col gap-1 border-b border-border py-2">
        <div className="flex items-center justify-between gap-2">
          <div className="flex items-center gap-2 text-sm font-medium">
            <FileText className="mt-0.5 h-4 w-4" />
            {template.name}
            <div>
              {!template?.provider?.reference ? (
                <Badge variant="solid" colorScheme="gray" ml="2">
                  {t('sections.settings.resources.inReview')}
                </Badge>
              ) : null}
            </div>

            {template?.provider?.reference ? (
              <Button
                variant="outline"
                disabled={!previewUrl}
                onClick={async () => {
                  if (previewUrl) {
                    window.open(previewUrl);
                    return;
                  }

                  const preview = await getPreviewURL({ variables: { _id: template._id } }).catch(err => {
                    console.error(err);
                    return null;
                  });

                  if (preview?.data?.getESignaturePreviewURL) {
                    window.open(preview.data.getESignaturePreviewURL);
                    setPreviewUrl(preview.data.getESignaturePreviewURL);
                  } else {
                    toast.error(t('sections.settings.resources.noPreviewLink'));
                    setPreviewUrl(null);
                  }
                }}
              >
                {getPreviewURLStatus.loading ? t('global.loading') : t('global.preview')}
              </Button>
            ) : null}
          </div>

          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost">
                <Ellipsis className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem onClick={() => setIsEditing(true)}>{t('global.edit')}</DropdownMenuItem>
              <DropdownMenuItem onClick={() => setIsDeleting(true)} className="text-destructive">
                {t('global.delete')}
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>

        <div className="flex w-full flex-col gap-1 text-sm">
          <div className="text-muted-foreground">
            {template.recipients.length === 0 ? (
              <em>{t('sections.settings.resources.noRecipients')}</em>
            ) : (
              <>
                <span className="font-medium">CC:</span>{' '}
                {template.recipients.length > 6 ? (
                  <>
                    <span>{template.recipients.slice(0, 6).join(', ')}</span>
                    <span className={!showMoreRecipients ? 'hidden' : undefined}>
                      {template.recipients.slice(6).join(', ')}
                    </span>
                    <Button
                      variant="link"
                      size="sm"
                      className="-ml-2"
                      onClick={() => {
                        setShowMoreRecipients(!showMoreRecipients);
                      }}
                    >
                      {showMoreRecipients
                        ? t('sections.settings.resources.showLess')
                        : t('sections.settings.resources.showMore', {
                            count: template.recipients.length - 6,
                          })}
                    </Button>
                  </>
                ) : (
                  template.recipients.join(', ')
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <Dialog
        open={isEditing}
        onOpenChange={open => {
          if (!open) {
            closeEditModal();
          }
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t('sections.settings.resources.templateEditTitle')}</DialogTitle>
          </DialogHeader>
          <form
            className="flex flex-col gap-4"
            onSubmit={async event => {
              event.preventDefault();
              await updateTemplate({
                variables: {
                  _id: template._id,
                  template: { name: titleInput, recipients: recipientsList.emails },
                },
              });
              await onUpdate();
              closeEditModal();
            }}
          >
            <FormControl isInvalid={!titleInput} className="flex flex-col gap-1">
              <Label htmlFor="titleInput">{t('global.title')}</Label>
              <Input
                id="titleInput"
                value={titleInput || ''}
                onChange={({ target }) => {
                  setTitleInput(target.value);
                }}
                autoFocus
                disabled={updateTemplateStatus.loading}
              />
              <FormErrorMessage fontSize="sm">{t('formValidation.required')}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!recipientsError} className="flex flex-col gap-1">
              <Label htmlFor="emailInput">
                CC
                <div className="text-sm font-normal text-muted-foreground">
                  {t('sections.settings.resources.templateEditEmail')}
                </div>
              </Label>
              <Textarea
                id="emailInput"
                value={recipientsInput || ''}
                onChange={({ target }) => {
                  setRecipientsInput(target.value);
                }}
                onBlur={() => {
                  setRecipientsError(recipientsList.error);
                  if (!recipientsList.error) {
                    setRecipientsInput(recipientsList.emails.join(', '));
                  }
                }}
                placeholder="someone@email.com"
                maxRows={4}
                disabled={updateTemplateStatus.loading}
              />

              <FormErrorMessage fontSize="sm">{recipientsError || 'Email address input is invalid'}</FormErrorMessage>
            </FormControl>

            <DialogFooter className="flex gap-2">
              <Button variant="outline" onClick={closeEditModal}>
                {t('global.cancel')}
              </Button>
              <Button type="submit" variant="primary" isLoading={updateTemplateStatus.loading} disabled={formError}>
                {t('global.update')}
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>

      <AlertDialog
        open={isDeleting}
        onOpenChange={open => {
          if (!open) {
            setIsDeleting(false);
          }
        }}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{t('sections.settings.resources.deleteTemplateTitle')}</AlertDialogTitle>
            <AlertDialogDescription className="flex flex-col gap-2">
              {t('sections.settings.resources.deleteTemplateDescriptionLine1')}
              {(() => {
                switch (template.AgreementBookings?.length || 0) {
                  case 0:
                    return null;
                  case 1:
                    return (
                      <div>
                        {t('sections.settings.resources.deleteTemplateDescriptionLine2')}
                        <Link to={bookingUtils.getBookingLinkURL(template.AgreementBookings[0])?.url || '#'}>
                          {template.AgreementBookings[0].name}
                        </Link>
                        .
                      </div>
                    );
                  default:
                    return (
                      <>
                        <div>{t('sections.settings.resources.deleteTemplateDescriptionLine2')}</div>
                        <ul className="normal-list">
                          {template.AgreementBookings.map((booking: Booking) => (
                            <li key={booking._id}>
                              <Link to={bookingUtils.getBookingLinkURL(booking)?.url || '#'}>{booking.name}</Link>
                            </li>
                          ))}
                        </ul>
                      </>
                    );
                }
              })()}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => setIsDeleting(false)}>{t('global.cancel')}</AlertDialogCancel>
            <AlertDialogAction
              onClick={async event => {
                event.preventDefault();
                await deleteTemplate({ variables: { _id: template._id } });
                setIsDeleting(false);
                await onUpdate();
              }}
            >
              {deleteTemplateStatus.loading ? `${t('global.loading')}...` : t('global.delete')}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
