import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const ChevronUpIcon: CreateIconType = createIcon({
  displayName: 'ChevronUpIcon',

  defaultProps: {
    ...defaultOptions,
    strokeWidth: '2px',
    viewBox: '0 0 16 16',
  },
  path: (
    <path
      d="M12.53 10.53a.75.75 0 01-1.06 0L8 7.06l-3.47 3.47a.75.75 0 11-1.06-1.06l4-4a.75.75 0 011.06 0l4 4a.75.75 0 010 1.06z"
      fill="currentColor"
    />
  ),
});
