import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@askable/ui/components/ui/alert-dialog';
import { Button } from '@askable/ui/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@askable/ui/components/ui/dialog';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@askable/ui/components/ui/form';
import { Input } from '@askable/ui/components/ui/input';
import { zodResolver } from '@hookform/resolvers/zod';
import { Trash } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { domainBlacklist } from 'lib/domainBlacklist';
import { validDomain } from 'lib/form/validators';

type AllowedDomainsProps = {
  /* Users emails with these domains join the team */
  allowedDomains: string[];
  /* Domain extracted from the users email address eg. user@test.com -> test.com */
  userDomain?: string;
  /* Handle add and remove domains actions */
  onUpdate: (domains: string[], isRemoved?: boolean) => void;
};

type DomainItemProps = {
  domain: string;
  onRemove: (domain: string) => void;
};

const DomainItem = ({ domain, onRemove }: DomainItemProps) => (
  <li className="flex items-center justify-between rounded-md border px-2 py-2" key={domain}>
    <div className="flex gap-1 text-sm font-medium">
      <div className="text-slate-400">@</div>
      {domain}
    </div>

    <Button
      data-testid="remove-domain"
      onClick={() => onRemove(domain)}
      size="icon_sm"
      title="Remove domain"
      variant="ghost"
    >
      <Trash className="h-4 w-4" />
    </Button>
  </li>
);

export const AllowedDomains = ({ allowedDomains, userDomain, onUpdate }: AllowedDomainsProps) => {
  const { t } = useTranslation();
  const [confirmAllow, setConfirmAllow] = useState(false);
  const [confirmRemove, setConfirmRemove] = useState(false);
  const [domain, setDomain] = useState('');
  const [open, setOpen] = useState(false);

  const errorMessages = {
    invalid: t('sections.settings.team.allowedDomains.invalidDomain'),
    exists: t('sections.settings.team.allowedDomains.invalidDomain'),
    blacklisted: t('sections.settings.team.allowedDomains.domainBlacklisted'),
  };

  /** Validation rules:
   * - Between 3 and 50 characters
   * - Format: [characters].[characters], exclude @ symbol
   * - Not already added
   * - Not in the blacklist
   */
  const formSchema = z.object({
    domain: validDomain
      .refine(value => !allowedDomains.includes(value), {
        message: errorMessages.exists,
      })
      .refine(value => !domainBlacklist.includes(value), {
        message: errorMessages.blacklisted,
      }),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    reValidateMode: 'onSubmit',
    defaultValues: {
      domain: '',
    },
  });

  const domainField = form.watch('domain');

  useEffect(() => {
    if (form.formState.errors.domain) {
      form.clearErrors('domain');
    }
  }, [domainField]);

  const addDomain = () => {
    onUpdate([...allowedDomains, domainField]);
    setConfirmAllow(false);
    form.resetField('domain');
  };

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    setOpen(false);

    // Require confirmation the user's domain is different from the domain being added
    if (userDomain?.split('.')[0] !== values.domain.split('.')[0]) {
      setConfirmAllow(true);
    } else {
      addDomain();
    }
  };

  const removeDomain = () => {
    onUpdate(
      allowedDomains.filter(item => item !== domain),
      true,
    );

    setDomain('');
    setConfirmRemove(false);
  };

  const handleRemove = (value: string) => {
    setDomain(value);
    setConfirmRemove(true);
  };

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="flex justify-between gap-2">
          <header className="flex flex-col gap-1">
            <h3 className="font-semibold">{t('sections.settings.team.allowedDomains.allowedDomainsTitle')}</h3>
            <div className="max-w-lg text-pretty text-sm text-muted-foreground">
              {t('sections.settings.team.allowedDomains.allowedDomainsDescription')}
            </div>
          </header>

          <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
              <Button data-testid="button-add-domain">{t('sections.settings.team.allowedDomains.addDomain')}</Button>
            </DialogTrigger>

            <DialogContent>
              <DialogHeader>
                <DialogTitle>{t('sections.settings.team.allowedDomains.addAllowedDomainTitle')}</DialogTitle>
              </DialogHeader>

              <DialogDescription>
                {t('sections.settings.team.allowedDomains.addAllowedDomainDescription')}
              </DialogDescription>

              <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">
                  <FormField
                    control={form.control}
                    name="domain"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="hidden">
                          {t('sections.settings.team.allowedDomains.domainName')}
                        </FormLabel>
                        <FormControl>
                          <Input autoFocus placeholder={userDomain} {...field} data-testid="input-domain" />
                        </FormControl>
                        <FormMessage className="text-xs" variant="error" />
                      </FormItem>
                    )}
                  />

                  <DialogFooter>
                    <Button type="submit" variant="primary" data-testid="button-submit">
                      {t('global.add')}
                    </Button>
                  </DialogFooter>
                </form>
              </Form>
            </DialogContent>
          </Dialog>
        </div>

        {allowedDomains.length > 0 ? (
          <ul className="space-y-2">
            {allowedDomains.map(item => (
              <DomainItem domain={item} onRemove={handleRemove} key={item} />
            ))}
          </ul>
        ) : (
          <div className="flex items-center gap-1 text-sm text-muted-foreground">
            {t('sections.settings.team.allowedDomains.noAllowedDomains')}
            <Button
              onClick={() => setOpen(true)}
              variant="link"
              className="p-1 text-muted-foreground hover:text-foreground"
            >
              {t('global.addOne')}
            </Button>
          </div>
        )}
      </div>

      <AlertDialog open={confirmAllow}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{t('sections.settings.team.allowedDomains.confirmationTitle')}</AlertDialogTitle>
            <AlertDialogDescription>
              {t('sections.settings.team.allowedDomains.confirmationDescription', {
                domain: domainField,
              })}
            </AlertDialogDescription>
          </AlertDialogHeader>

          <AlertDialogFooter>
            <AlertDialogCancel
              onClick={() => {
                setConfirmAllow(false);
                setOpen(true);
              }}
            >
              {t('global.change')}
            </AlertDialogCancel>
            <AlertDialogAction onClick={() => addDomain()} data-testid="button-allow">
              {t('global.allow')}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog open={confirmRemove}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              {t('sections.settings.team.allowedDomains.removeDomainTitle', { domain })}?
            </AlertDialogTitle>
            <AlertDialogDescription>
              {t('sections.settings.team.allowedDomains.removeDomainDescription')}
            </AlertDialogDescription>
          </AlertDialogHeader>

          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => setConfirmRemove(false)}>{t('global.cancel')}</AlertDialogCancel>
            <AlertDialogAction onClick={() => removeDomain()} data-testid="button-remove">
              {t('global.remove')}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
