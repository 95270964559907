import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const AddIcon: CreateIconType = createIcon({
  displayName: 'AddIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: [
    <path
      d="M7.17236 13.0004C7.17236 13.4575 7.54289 13.828 7.99995 13.828C8.45701 13.828 8.82754 13.4575 8.82754 13.0004V8.82804H12.9999C13.457 8.82804 13.8275 8.45752 13.8275 8.00046C13.8275 7.5434 13.457 7.17287 12.9999 7.17287H8.82754V3.00044C8.82754 2.54337 8.45701 2.17285 7.99995 2.17285C7.54289 2.17285 7.17236 2.54337 7.17236 3.00044V7.17287H2.99995C2.54289 7.17287 2.17236 7.5434 2.17236 8.00046C2.17236 8.45752 2.54289 8.82804 2.99995 8.82804H7.17236V13.0004Z"
      fill="currentColor"
    />,
  ],
});
