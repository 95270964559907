import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const TranscriptsIcon: CreateIconType = createIcon({
  displayName: 'TranscriptsIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: (
    <path
      d="M5.82138 8.01782H10.1785M5.82138 11.3392H10.165M5.82138 4.57139H8.45069M14.1414 12.6672C14.2359 11.1607 14.2857 9.59961 14.2857 7.99997C14.2857 7.3521 14.2775 6.71056 14.2615 6.0764C14.2503 5.6309 14.1058 5.19795 13.8421 4.83871C12.837 3.46943 12.0363 2.61816 10.7166 1.5997C10.354 1.31982 9.90899 1.16797 9.45102 1.15795C8.99179 1.14792 8.51223 1.14282 7.99995 1.14282C6.44915 1.14282 5.19818 1.18953 3.9036 1.27854C2.8023 1.35426 1.92764 2.23098 1.85848 3.33272C1.76394 4.8392 1.71423 6.40032 1.71423 7.99997C1.71423 9.59961 1.76394 11.1607 1.85848 12.6672C1.92764 13.769 2.8023 14.6457 3.9036 14.7213C5.19818 14.8104 6.44915 14.8571 7.99995 14.8571C9.55075 14.8571 10.8017 14.8104 12.0963 14.7213C13.1975 14.6457 14.0723 13.769 14.1414 12.6672Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  ),
});
