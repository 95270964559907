import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const OpenLeftIcon: CreateIconType = createIcon({
  displayName: 'OpenLeftIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: (
    <path
      d="M14.5714 8L4.67855 8M8 11.2813C6.56027 10.1017 5.79196 9.38242 4.66964 7.9998C5.79196 6.61719 6.56027 5.89794 8 4.71836M0.999966 1L0.999964 15"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});
