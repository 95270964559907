import '@fontsource-variable/bricolage-grotesque';

import { differenceInDays } from 'date-fns';
import { X } from 'lucide-react';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { useConnectedClient } from 'context/ConnectedClientContext';
import { analytics } from 'lib/analytics';
import { localStorage } from 'lib/storage';
import { userUtils } from 'lib/user';
import { utils } from 'lib/utils';

const CAMPAIGN_URL =
  'https://www.askable.com/eoy-2024?utm_source=Product&utm_medium=product-banner&utm_campaign=eoy-2024';
const CAMPAIGN_END_DATE = new Date('2024-12-21T00:00:00');

const getBannerDaysLeft = () => {
  const now = new Date();
  const daysRemaining = differenceInDays(CAMPAIGN_END_DATE, now);
  return Math.max(0, daysRemaining);
};

const Banner = () => {
  const daysLeft = getBannerDaysLeft();

  return (
    <div className="flex h-full max-w-2xl items-center justify-center gap-4 px-4">
      <img
        className="hidden h-full sm:block"
        height="46"
        alt="Bulk credits"
        src="/assets/promo-xmas-banner-feature.png"
      />
      <div className="flex w-full items-center gap-4">
        <h3 className="font-bricolage-grotesque text-3xl font-bold">
          {daysLeft} {daysLeft === 1 ? 'day' : 'days'} left to save
        </h3>
        <p className="text-xs leading-3">
          get up to 33% off credits <br /> + sweet bonus packs
        </p>
      </div>
      <img
        className="hidden h-full sm:block"
        height="46"
        alt="Bulk credits button"
        src="/assets/promo-xmas-banner-button.png"
      />
    </div>
  );
};

const HeaderAds = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { details: clientDetails } = useConnectedClient();

  useEffect(() => {
    let showingHeaderAds = utils.parseJSON(localStorage.get('showingHeaderAds'));
    if (showingHeaderAds === null || showingHeaderAds === undefined) {
      showingHeaderAds = true;
    }

    // Date when it shouldn't show anymore. Midnight Brisbane time
    if (Date.now() >= CAMPAIGN_END_DATE.valueOf()) {
      showingHeaderAds = false;
      localStorage.remove('showingHeaderAdsBarClosed');
      return;
    }

    if (!showingHeaderAds) {
      const showingHeaderAdsBarClosed = utils.parseJSON(localStorage.get('showingHeaderAdsBarClosed'));
      // Show again after 1 day
      if ((showingHeaderAdsBarClosed ?? 0) < moment().subtract(1, 'days').valueOf()) {
        showingHeaderAds = true;
        localStorage.remove('showingHeaderAdsBarClosed');
      }
    }

    const countryIsUS = clientDetails?.ConnectedTeam?.settings?.billing?.location?.country === 'US';

    // Don't show in US or if the user is a researcher
    if (userUtils.isResearcher(clientDetails) || localStorage.get('connectedAsClient') === 'true' || countryIsUS) {
      showingHeaderAds = false;
    }

    setIsVisible(showingHeaderAds);
    localStorage.save('showingHeaderAds', showingHeaderAds);
  }, []);

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    setIsVisible(false);
    localStorage.save('showingHeaderAds', false);
    localStorage.save('showingHeaderAdsBarClosed', moment().valueOf());
  };

  return isVisible ? (
    <a
      className="t-0 sticky z-50 flex h-[46px] w-full items-center justify-center bg-[#53070f] text-lightest"
      href={`${CAMPAIGN_URL}${clientDetails?.email ? `&email=${clientDetails.email}` : ''}`}
      target="_blank"
      title="View end of year promotion"
      rel="noreferrer"
      onClick={() => {
        analytics.track('marketing.click_on_header_ad', {
          clientDetails: {
            name: clientDetails?.name,
            email: clientDetails?.email,
            operationalOffice: clientDetails?.ConnectedTeam?.operational_office,
            team: clientDetails?.ConnectedTeam?.name,
          },
        });
      }}
    >
      <Banner />
      <button
        aria-label="Close promotion banner"
        className="absolute right-0 flex h-full items-center justify-center p-2"
        onClick={handleClose}
        type="button"
      >
        <X className="h-5 w-5 text-lightest" />
      </button>
    </a>
  ) : null;
};

export default HeaderAds;
