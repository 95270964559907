import { Button } from '@askable/ui/components/ui/button';
import { Skeleton } from '@askable/ui/components/ui/skeleton';
import { Upload } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'urql';

import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { InputField } from 'containers/Studies/components/Fields/InputField';

import { EsignatureTemplate } from '../data/EsignatureTemplate.query';

import { RadioGroupField } from './Fields/RadioGroupField';

interface Template {
  label: string;
  value: string;
  link?: string;
}

export const CustomTerms = () => {
  const { t } = useTranslation();
  const { study } = useStudyContext();
  const [templates, setTemplates] = useState<Template[]>([]);

  const [{ data, fetching }] = useQuery({
    query: EsignatureTemplate,
    pause: !study?._id,
    variables: {
      id: study?._id,
    },
  });

  useEffect(() => {
    if (!fetching && data?.getBookingESignatureTemplates) {
      setTemplates(
        data.getBookingESignatureTemplates?.map(item => ({
          label: `${item?.name ?? ''}${!item?.provider?.reference ? ` (${t('sections.studies.status.in_review')})` : ''}`,
          value: item?._id ?? '',
          link: item?.provider?.reference ?? '',
        })),
      );
    }
  }, [fetching, data]);

  return (
    <div className="flex flex-col gap-4">
      {fetching ? <Skeleton className="h-8 w-2/3" /> : null}

      {templates.length > 0 ? (
        <RadioGroupField name="terms_custom_id" label="" options={templates} variant="stacked" />
      ) : null}

      <Button variant="secondary" className="h-10 w-full">
        <Upload className="h-4 w-4" />
        {t('sections.studies.recruit.fields.customTermsCta')}
      </Button>

      <InputField
        label={t('sections.studies.recruit.fields.customTermsRecipientsTitle')}
        description={t('sections.studies.recruit.fields.customTermsRecipientsDescription')}
        name="terms_custom_recipients"
        placeholder={t('sections.studies.recruit.fields.customTermsRecipientsPlaceholder')}
      />
    </div>
  );
};
