import { getColorValue } from '@askable/ui/lib/utils';

import type { IconProps } from '.';

export const Click = ({ bgColor = 'foreground', fgColor = 'background', size = 48 }: IconProps) => {
  const bgHsl = getColorValue(bgColor);
  const fgHsl = getColorValue(fgColor);

  return (
    <svg fill="none" viewBox="0 0 64 64" width={size} height={size}>
      <path
        fill={bgHsl}
        d="M20.1 25a82.2 82.2 0 0 1 1.9-7c.2-.2.2-.4.5-.5.3 0 2.2 4.4 2.5 4.5l27.5 8c.3.1 2.8.7 3 1 .2.3 2.5.7 2.5 1a72 72 0 0 1-2.4 6.5c-.2.3-.4.4-.8.5l-13 3a3 3 0 0 0-1.4.8c-.4.4-.7.9-.8 1.4l-3.1 12.7-.5.7a1.5 1.5 0 0 1-2.4-.5L20.1 25Z"
      />
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="M22.5 19.4a102 102 0 0 0-1.4 4.7v.5l13.4 32.1.2.2a.5.5 0 0 0 .6 0l.2-.3L38.6 44a3.9 3.9 0 0 1 3-3l13-3h.2a7.5 7.5 0 0 0 .4-.9 117.6 117.6 0 0 0 1.6-4.6h-.3l-.1-.1-.8-.2a4 4 0 0 1-.6-.3l-.2-.1-.1-.1-1-.3-.8-.2h-.1l-.6-.2-27.5-8a1 1 0 0 1-.4-.3l-.2-.2-.1-.2-.4-.6-.6-1.3-.1-.3-.4-.7ZM57 31.8ZM25.6 21.1l-.2-.3-.6-1.2-.1-.4a43.2 43.2 0 0 0-1.4-2.4l-.3-.2a1 1 0 0 0-.7 0 1.4 1.4 0 0 0-1 .5v.2l-.2.2v.2l-.2.3a20.6 20.6 0 0 0-.9 2.8 187.9 187.9 0 0 0-1 3.2c0 .5 0 1 .2 1.4l13.5 32c.2.6.6 1 1 1.2a2.5 2.5 0 0 0 3-.2c.3-.3.6-.8.7-1.3l3.2-12.7c0-.3.2-.6.5-.9a2 2 0 0 1 1-.5l13-3c.5-.1 1-.4 1.3-.8l.1-.2.1-.1.1-.3a28.8 28.8 0 0 0 1.1-3 141.3 141.3 0 0 0 1.1-3.2l.1-.3a1 1 0 0 0-.2-.8l-.3-.3-.5-.2-.9-.3h-.2a17.8 17.8 0 0 1-.9-.4l-.2-.1-.5-.2a17.4 17.4 0 0 0-1.8-.6h-.2a31 31 0 0 1-.4-.1h-.1l-27.2-8Zm-15.3 9.1a.9.9 0 0 1 .1-1.3 4 4 0 0 1 1.2-.6l.6-.2.5-.2 1.6-.6a.9.9 0 0 1 .5 1.7 25 25 0 0 0-3.2 1.3 1 1 0 0 1-1.3-.1ZM8.1 15.1c0-.4.4-.8.9-.8s1 0 1.3.2l.6.2.4.2 1.7.6a.9.9 0 1 1-.7 1.6 25 25 0 0 0-2-.7l-.5-.2L9 16a.9.9 0 0 1-.9-.9Zm12.1-9.8a8 8 0 0 0 1.3 3.2.9.9 0 1 1-1.3 1.1A9.6 9.6 0 0 1 18.4 5a.9.9 0 0 1 1.8.2v.2Zm10.2 7.3a.9.9 0 0 1 0-1.3l3-2.5a.9.9 0 1 1 1 1.3l-2.8 2.5c-.4.3-1 .3-1.2 0Z"
        clipRule="evenodd"
      />
      <path
        fill={fgHsl}
        d="M22.1 19a1.5 1.5 0 0 1 2-1.9l33 13.5a1.5 1.5 0 0 1-.3 2.9l-13 3a3 3 0 0 0-2.2 2.3l-3.1 13a1.5 1.5 0 0 1-2.9.3L22.1 19Z"
      />
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="M23.7 18a.5.5 0 0 0-.7.7l13.5 33a.5.5 0 0 0 1 0l3.1-13.1a4 4 0 0 1 3-3l13-3.1a.5.5 0 0 0 0-1L23.7 18Zm.8-1.8a2.5 2.5 0 0 0-3.3 3.3l13.5 33a2.5 2.5 0 0 0 4.7-.4l3.2-13a2 2 0 0 1 1.4-1.5l13-3.2a2.5 2.5 0 0 0 .4-4.7l-33-13.5Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
