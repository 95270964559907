import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const HelpQuestionFilledIcon: CreateIconType = createIcon({
  displayName: 'HelpIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16c5.12 0 8-2.88 8-8s-2.88-8-8-8-8 2.88-8 8 2.88 8 8 8zM7.301 3.312A2.89 2.89 0 018.55 3.27l.024.005a2.905 2.905 0 012.241 2.241l.003.013c.111.565.046 1.11-.204 1.6-.24.473-.617.827-1.016 1.095h-.002c-.164.11-.293.199-.399.278-.102.076-.163.13-.2.167A1 1 0 017 8.581c0-.558.249-.98.55-1.294.265-.275.618-.514.933-.725.215-.144.308-.263.348-.34.03-.06.055-.142.025-.299a.905.905 0 00-.685-.689.89.89 0 00-1.04.866 1 1 0 11-2-.025 2.89 2.89 0 012.17-2.763zM9 11.462a1 1 0 10-2 0v.32a1 1 0 102 0v-.32z"
      fill="currentColor"
    />
  ),
});
