import { Input } from '@askable/ui/components/ui/input';
import { Label } from '@askable/ui/components/ui/label';
import { cn } from '@askable/ui/lib/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useStudyContext } from 'containers/Studies/StudiesContainer';

import { useResultsContext } from '../Results';
import { ParticipantItem } from '../components/Navigation/ParticipantItem';
import { TaskItem } from '../components/Navigation/TaskItem';
import { Tabs } from '../components/Tabs';

type ResultsNavigationTabs = 'blocks' | 'participants';

interface ResultsNavigationProps {
  active?: ResultsNavigationTabs;
  showParticipants?: boolean;
}

export const ResultsNavigation = ({ active, showParticipants = false }: ResultsNavigationProps) => {
  const { t } = useTranslation();
  const params = useParams();
  const { results } = useResultsContext();
  const { study } = useStudyContext();
  const { taskId, participantId } = params;
  const [activeTab, setActiveTab] = useState<ResultsNavigationTabs>('blocks');
  const [searchQueryParticipants, setSearchQueryParticipants] = useState('');

  const tabs = [
    { label: t('sections.studies.tasks', { count: results?.tasks.length }), value: 'blocks' },
    {
      label: t('sections.studies.results.participants', { count: results?.participants.length }),
      value: 'participants',
    },
  ];

  useEffect(() => {
    if (active === 'participants') {
      setActiveTab(active);
    }
  }, [active]);

  // Basic filter participants by name
  // @todo we'll paginate participants list so debounce and filter on backend instead
  const filteredParticipants =
    results?.participants.filter(participant =>
      participant.name.toLowerCase().includes(searchQueryParticipants.toLowerCase()),
    ) ?? [];

  return (
    <nav className="page-nav flex h-full flex-col overflow-hidden rounded-xl bg-background pt-3 lg:ml-4 lg:pt-1">
      {showParticipants ? (
        <div className="px-3">
          <Tabs
            value={activeTab}
            tabs={tabs}
            isFullWidth
            onValueChange={value => setActiveTab(value as ResultsNavigationTabs)}
          />
        </div>
      ) : null}

      <div className={cn('flex-1 overflow-auto p-3 lg:p-4', { hidden: activeTab !== 'blocks' })}>
        <ol className="flex flex-col gap-2">
          {study.config?.unmoderated?.task_blocks?.map(taskBlock =>
            taskBlock ? (
              <li key={taskBlock._id}>
                <TaskItem
                  id={taskBlock._id}
                  isActive={taskBlock._id === taskId}
                  title={taskBlock.title}
                  type={taskBlock.type}
                />
              </li>
            ) : null,
          )}
        </ol>
      </div>

      <div className="overflow-auto">
        <div
          className={cn('flex flex-1 flex-col gap-2 overflow-auto p-3 lg:p-4', {
            hidden: activeTab !== 'participants',
          })}
        >
          <Label htmlFor="filter-participants" className="sr-only">
            {t('sections.studies.results.filter')}
          </Label>

          <Input
            className="w-full bg-background-subtle hover:bg-secondary focus:bg-secondary"
            id="filter-participants"
            placeholder={`${t('sections.studies.results.filter')}...`}
            type="search"
            value={searchQueryParticipants}
            variant="borderless"
            onChange={e => setSearchQueryParticipants(e.target.value)}
          />

          <ol className="flex flex-col gap-2">
            {filteredParticipants.map(participant => (
              <li key={participant.id}>
                <ParticipantItem
                  completed={participant.completed}
                  id={participant.id}
                  isActive={participant.id === participantId}
                  name={participant.name}
                />
              </li>
            ))}

            {filteredParticipants.length === 0 ? (
              <div className="py-4 text-sm text-muted-foreground">{t('sections.studies.results.noParticipants')}</div>
            ) : null}
          </ol>
        </div>
      </div>
    </nav>
  );
};
