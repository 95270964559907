import { useMutation } from '@apollo/client';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@askable/ui/components/ui/dropdown-menu';
import { toast } from '@askable/ui/components/ui/sonner';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { useState } from 'react';

import { LoadingOverlay } from 'components/common';
import { useAppContext } from 'components/common/Askable/Providers/appProvider';
import changeOrganiserContactMutation from 'data/mutations/booking/changeOrganiserContact';
import { utils } from 'lib/utils';

import './styles/changeOrganiserStyles.scss';

function ChangeOrganiserContactPopover(props: any) {
  const [loadingUI, setLoadingUI] = useState(false);
  const context = useAppContext();

  const [changeOrganiserContact] = useMutation(changeOrganiserContactMutation, {
    onCompleted: () => {
      setLoadingUI(false);
      toast.success('Organiser changed successfully');
    },
    onError: () => {
      // @ts-expect-error ts-migrate(2349) FIXME: This expression is not callable.
      loadingUI(false);
    },
  });

  const onChangeOrganiserContact = async (user: any) => {
    setLoadingUI(true);

    // Changes the Organiser
    await changeOrganiserContact({
      variables: {
        booking_id: _.get(props, 'booking._id'),
        _organiser_user_id: _.get(user, '_id'),
      },
    }).then(({ data }) => {
      if (data && data.changeOrganiserContact) {
        if (props.onChangeOrganiser) props.onChangeOrganiser(_.get(data, 'changeOrganiserContact.config.contact'));
      }
    });
  };

  const renderInviteTeamMemberLink = () => {
    return (
      <a
        className="inviteTeammate"
        onClick={() => {
          context.onOpenInviteTeamMemberModal(null, true);
        }}
      >
        + Invite a teammate to Askable
      </a>
    );
  };

  const renderUsersInTeam = (user: any) => {
    return (
      <DropdownMenuItem
        id={`__${user._id}`}
        key={user._id}
        className="flex flex-col items-start p-2"
        onClick={() => onChangeOrganiserContact(user)}
      >
        <div>
          {_.get(user, 'User.meta.identity.firstname')} {_.get(user, 'User.meta.identity.lastname')}
        </div>
        <div className="text-xs text-muted-foreground">{_.get(user, 'User.email')}</div>
        <div className="text-xs text-muted-foreground">
          {utils.formatPhoneNumber(_.get(user, 'User.contact.phone.mobile'))}
        </div>
      </DropdownMenuItem>
    );
  };

  const renderNoUsersContainer = () => {
    return (
      <DropdownMenuLabel className="noUsersContainer">
        <p>You have no team members</p>
        {renderInviteTeamMemberLink()}
      </DropdownMenuLabel>
    );
  };

  const renderOrganisers = () => {
    const usersInTeam = _.filter(
      _.get(props.data, 'teamById.users'),
      (users: any) => users.status === 1 && users._id !== _.get(props, 'booking.config.contact._organiser_user_id'),
    );
    if (_.size(usersInTeam) === 0) return renderNoUsersContainer();
    return (
      <>
        {_.map(usersInTeam, (users: any) => renderUsersInTeam(users))}
        <DropdownMenuLabel>{renderInviteTeamMemberLink()}</DropdownMenuLabel>
      </>
    );
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>Change</DropdownMenuTrigger>
      <DropdownMenuContent className="max-h-[25rem] overflow-auto">
        {loadingUI ? (
          <DropdownMenuLabel>
            <LoadingOverlay style={{ opacity: 0.8 }} />
          </DropdownMenuLabel>
        ) : null}
        {renderOrganisers()}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default deprecatedWithRouter(ChangeOrganiserContactPopover);
