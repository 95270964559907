import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const SortIcon: CreateIconType = createIcon({
  displayName: 'SortIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: [
    <g fill="currentColor" clipPath="url(#sortIcon)">
      {}
      <path d="M11.433 1.051a.656.656 0 0 0-1.312 0v10.13l-1.114-1.129a.656.656 0 0 0-.934.923l2.126 2.152a.656.656 0 0 0 1.12.06l2.146-2.273a.656.656 0 1 0-.954-.901l-1.078 1.141V1.051Z" />
      <path
        fillRule="evenodd"
        d="M.676 1.766c0-.363.294-.656.656-.656h6.776a.656.656 0 1 1 0 1.312H1.332a.656.656 0 0 1-.656-.656ZM2.7 4.864c0-.363.294-.657.657-.657h4.751a.656.656 0 0 1 0 1.313H3.357a.656.656 0 0 1-.656-.656ZM5.076 7.961c0-.362.294-.656.657-.656h2.375a.656.656 0 0 1 0 1.312H5.733a.656.656 0 0 1-.657-.656Z"
        clipRule="evenodd"
      />
    </g>,
    <defs>
      <clipPath id="sortIcon">
        <path fill="currentColor" d="M0 0h14v14H0z" />
      </clipPath>
    </defs>,
  ],
});
