import { Loader2, CircleCheckBig } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { DateTime } from './DateTime';

interface Props {
  isSaving?: boolean;
  created?: number | null;
  updated?: number | null;
}

export const StudySavingIndicator = ({ isSaving, created, updated }: Props) => {
  const { t } = useTranslation();
  const date = updated ?? created;

  const icon = isSaving ? (
    <Loader2 className="h-3.5 w-3.5 animate-spin opacity-50" aria-label={t('global.saving')} />
  ) : (
    <CircleCheckBig className="h-3.5 w-3.5 text-success" aria-label={t('sections.studies.saved')} />
  );

  if (date) {
    return <DateTime date={date}>{icon}</DateTime>;
  }

  return icon;
};
