import _ from 'lodash';
import { Check } from 'lucide-react';
import { useState, useEffect } from 'react';

import { update } from 'lib/utils';

import './styles/SelectSearchStyles.scss';

function SelectSearch(props: any) {
  const [openSelectOptions, setOpenSelectOptions] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectOptions, setSelectOptions] = useState(props.selectOptions);
  const [selectedValue, setSelectedValue] = useState();

  useEffect(() => {
    const savedValue = _.find(props.selectOptions, (item: any) => item.value === props.value);
    if (savedValue) {
      setSearchValue(savedValue.label);
      setSelectedValue(savedValue);
    }
  }, [props.value]);

  const handleSelectList = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (!openSelectOptions) {
      setSearchValue('');
      setSelectOptions(props.selectOptions);
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      document.getElementById('selectSearchInput').focus();
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      const elm = document.getElementById(_.get(selectedValue, 'value'));
      if (elm) {
        setTimeout(() => {
          elm.scrollIntoView({ behavior: 'instant', block: 'nearest', inline: 'center' });
          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          document.getElementById('selectSearchOptionsContainer').scrollTop += 160;
        }, 100);
      }
    } else {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      document.getElementById('selectSearchInput').blur();
    }
    setOpenSelectOptions(!openSelectOptions);
  };

  const updateSelectOptions = (search: any) => {
    const elm = document.getElementById('selectSearchOptionsContainer');
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    elm.scrollTop = 0;

    setSearchValue(search);
    let newSelectOptions = [];
    if (search && search !== '') {
      newSelectOptions = update(selectOptions, {
        $set: _.filter(props.selectOptions, (item: any) => item.label.toLowerCase().includes(search.toLowerCase())),
      });
    } else {
      newSelectOptions = update(selectOptions, {
        $set: props.selectOptions,
      });
    }

    setSelectOptions(newSelectOptions);
    setSelectedValue(newSelectOptions[0]);
  };

  const renderCheckIcon = (itemValue: any) => {
    if (itemValue !== props.value) return null;

    return (
      <Check
        className="checkItemSelect"
        style={{
          color: '#FF5065',
          margin: '5px',
          right: '0px',
          height: '18px',
          width: '18px',
        }}
      />
    );
  };

  return (
    <div
      id={props.id}
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; id: any; name: any; c... Remove this comment to see the full error message
      name={props.name}
      className={`selectSearchContainer ${props.className ? props.className : ''} ${openSelectOptions ? 'focused' : ''}`}
      onMouseUp={handleSelectList}
      onBlur={e => {
        e.preventDefault();
        e.stopPropagation();
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
        setSearchValue(_.get(selectedValue, 'label'));
        setOpenSelectOptions(false);
        props.onChange(_.get(selectedValue, 'value'));
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        document.getElementById('selectSearchOptionsContainer').scrollTop = 0;
      }}
    >
      <div className="inputArrow">
        <input
          id="selectSearchInput"
          className="searchInput"
          placeholder={_.get(selectedValue, 'label')}
          value={searchValue}
          onChange={item => updateSelectOptions(item.target.value)}
        />
        <div className="arrowDown">
          <i className={`arrow ${openSelectOptions ? 'up' : 'down'}`} />
        </div>
      </div>
      <div
        id="selectSearchOptionsContainer"
        className={`selectSearchOptions ${openSelectOptions ? 'visible' : 'hidden'}`}
      >
        {_.map(selectOptions, (item: any) => {
          return (
            <div
              id={item.value}
              key={item.value}
              className="selectSearchItems"
              onMouseDown={() => {
                setSearchValue(item.label);
                setSelectedValue(item);
                setOpenSelectOptions(false);
                props.onChange(item.value);
              }}
            >
              <span className={`optionValue ${item.value === props.value ? 'active' : ''}`}>{item.label}</span>
              {renderCheckIcon(item.value)}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SelectSearch;
