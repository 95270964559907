import { drawerAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(drawerAnatomy.keys);

export const drawerTheme = defineMultiStyleConfig({
  variants: {
    clickThrough: definePartsStyle({
      dialogContainer: {
        pointerEvents: 'none',
      },
      dialog: {
        pointerEvents: 'auto',
      },
    }),
  },
}) as any;
