import type { ComponentStyleConfig } from '@chakra-ui/react';

type ColorProps = {
  bg: string;
  titleColor: string;
  bodyColor: string;
};

// You can also use the more specific type for
// a single part component: ComponentSingleStyleConfig
export const Tutorial: ComponentStyleConfig = {
  baseStyle: ({ colorMode }) => {
    const darkMode = colorMode === 'dark';

    // TODO: support color schemes
    // const colors: ColorProps = (() => {
    //   switch (colorScheme) {
    //     default:
    //       return {
    //         bg: darkMode ? 'whiteAlpha.300' : 'gray.200',
    //         titleColor: darkMode ? 'white' : 'gray.800',
    //         bodyColor: darkMode ? 'whiteAlpha.800' : 'gray.700',
    //       };
    //   }
    // })();
    const colors: ColorProps = {
      bg: darkMode ? 'whiteAlpha.200' : 'gray.200',
      titleColor: darkMode ? 'white' : 'gray.800',
      bodyColor: darkMode ? 'whiteAlpha.800' : 'gray.700',
    };

    return {
      container: {
        bgColor: colors.bg,
      },
      title: {
        color: colors.titleColor,
      },
      body: {
        color: colors.bodyColor,
      },
      solidButton: {
        color: darkMode ? 'white' : 'gray.800',
        bg: darkMode ? 'whiteAlpha.200' : 'gray.100',
        textDecoration: 'none',
        _hover: {
          bg: darkMode ? 'whiteAlpha.300' : 'white',
          textDecoration: 'none',
        },
      },
      ghostButton: {
        color: darkMode ? 'white' : 'gray.800',
        _hover: {
          bg: darkMode ? 'whiteAlpha.200' : 'gray.100',
        },
      },
    };
  },
};
