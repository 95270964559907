import { Button } from '@askable/ui/components/ui/button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@askable/ui/components/ui/tooltip';
import { ChevronLeftIcon, ChevronRightIcon, ChevronsLeft, ChevronsRight } from 'lucide-react';

type PaginationProps = {
  currentPage: number;
  pageCount: number;
  disabledGoFirstPage: boolean;
  disabledGoLastPage: boolean;
  disabledGoNextPage: boolean;
  disabledGoPreviousPage: boolean;
  onGoFirstPageClick: () => void;
  onGoLastPageClick: () => void;
  onGoNextPageClick: () => void;
  onGoPreviousPageClick: () => void;
};

export function Pagination({
  currentPage,
  pageCount,
  disabledGoFirstPage,
  disabledGoLastPage,
  disabledGoNextPage,
  disabledGoPreviousPage,
  onGoFirstPageClick,
  onGoLastPageClick,
  onGoNextPageClick,
  onGoPreviousPageClick,
}: PaginationProps) {
  if (!pageCount) {
    return null;
  }

  return (
    <div className="flex items-center justify-between px-2">
      <div className="ml-auto flex items-center space-x-6 lg:space-x-8">
        <div className="flex w-[100px] items-center justify-center text-sm font-medium">
          Page {currentPage} of {pageCount}
        </div>
        <div className="flex items-center space-x-2">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger disabled={disabledGoFirstPage} asChild>
                <Button
                  className="hidden h-8 w-8 p-0 lg:flex"
                  onClick={onGoFirstPageClick}
                  disabled={disabledGoFirstPage}
                >
                  <span className="sr-only">Go to first page</span>
                  <ChevronsLeft className="h-4 w-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Go to first page</TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger disabled={disabledGoPreviousPage} asChild>
                <Button className="h-8 w-8 p-0" onClick={onGoPreviousPageClick} disabled={disabledGoPreviousPage}>
                  <span className="sr-only">Go to previous page</span>
                  <ChevronLeftIcon className="h-4 w-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Go to previous page</TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger disabled={disabledGoNextPage} asChild>
                <Button className="h-8 w-8 p-0" onClick={onGoNextPageClick} disabled={disabledGoNextPage}>
                  <span className="sr-only">Go to next page</span>
                  <ChevronRightIcon className="h-4 w-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Go to next page</TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger disabled={disabledGoLastPage} asChild>
                <Button
                  className="hidden h-8 w-8 p-0 lg:flex"
                  onClick={onGoLastPageClick}
                  disabled={disabledGoLastPage}
                >
                  <span className="sr-only">Go to last page</span>
                  <ChevronsRight className="h-4 w-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Go to last page</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
    </div>
  );
}
