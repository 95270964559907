import { getColorValue } from '@askable/ui/lib/utils';

import type { IconProps } from '.';

export const Survey = ({ bgColor = 'foreground', fgColor = 'background', size = 48 }: IconProps) => {
  const bgHsl = getColorValue(bgColor);
  const fgHsl = getColorValue(fgColor);

  return (
    <svg fill="none" viewBox="0 0 64 64" width={size} height={size}>
      <path
        fill={fgHsl}
        stroke={bgHsl}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.6"
        d="M21.3 30.2S16.6 28 14.5 28c-2-.2-9.6 5-9.1 5v10.6l11.3 5.2 4.6-3.4v-15Zm.1.3c-.1-7.5 0-15.1.5-22.6 4.1-2.5 7.3-4.5 11.8-6.1 3 .8 11.3 3.9 11.3 3.9l-.4 43.5-11 6L21.5 50V30.5Z"
      />
      <path fill={fgHsl} d="m33.8 2.7 9.8 3.5V9l-9 5.3v40L22.3 49l1-40.9 10.6-5.3Z" />
      <path fill={fgHsl} d="M37.5 57V33s6.8-4 11-6c4.7 1.6 6.6 2.4 11 4.8v22.6l-11 7.8-11-5.2Z" />
      <path
        stroke={bgHsl}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.6"
        d="M42.3 35.7c4.8 1.6 9.4 4 9.4 4M37.5 33v24l11 5.2 11-7.8V31.8a56.1 56.1 0 0 0-11-4.8c-4.2 2-11 6-11 6Z"
      />
      <path
        stroke={bgHsl}
        strokeLinecap="round"
        strokeWidth="3.6"
        d="m10.6 35.3 7 3M26.1 10c4.1 1.6 6.3 1.7 10.5 3m-10-2.5 8.2 2.6"
      />
      <path
        fill={bgHsl}
        d="m34.5 13.5 10.4-6c0 7.9.3 14.8 0 21L38.2 32l-.9 18.7-3.2 3.3c-.4-6 .1-29.5.4-40.5Zm25 40.9V31.8l-10.6 7-.5 23.4 11.1-7.8ZM16.7 36.7V47l4.6-3.5v-9l-4.6 2Z"
      />
    </svg>
  );
};
