import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const BoldIcon: CreateIconType = createIcon({
  displayName: 'BoldIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: [
    <path
      d="M5 2V7.5H8.25C9.76878 7.5 11 6.26878 11 4.75C11 3.23122 9.76878 2 8.25 2H5Z"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M5 7.5V14H8.75C10.5449 14 12 12.5449 12 10.75C12 8.95507 10.5449 7.5 8.75 7.5H5Z"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
