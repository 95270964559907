import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const SidebarLayoutIcon: CreateIconType = createIcon({
  displayName: 'SidebarLayoutIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: [
    <rect y="3.5" width="9" height="10" rx="1" fill="currentColor" />,
    <rect x="12" y="3.5" width="4" height="4" rx="1" fill="currentColor" />,
    <rect x="12" y="9.5" width="4" height="4" rx="1" fill="currentColor" />,
  ],
});
