import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const BlurBackgroundIcon: CreateIconType = createIcon({
  displayName: 'BlurBackgroundIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: [
    <path
      d="M14 15C12.283 13.7366 10.2196 13 8 13C5.78041 13 3.71695 13.7366 2 15"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />,
    <path d="M1 6.25L6.25 1" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />,
    <path d="M13 3L15 1" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />,
    <path d="M13 10.875L15 8.875" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />,
    <path d="M1 10.75L2 9.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />,
    <path d="M10 1.75L11 0.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />,
    <path d="M1 1.875L1.875 1" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />,
    <path d="M14.125 6.25L15 5.375" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />,
    <path
      d="M8 10.0665C9.65685 10.0665 11 8.72332 11 7.06647C11 5.40961 9.65685 4.06647 8 4.06647C6.34315 4.06647 5 5.40961 5 7.06647C5 8.72332 6.34315 10.0665 8 10.0665Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
