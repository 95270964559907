import _ from 'lodash';
import { ChevronUp, ChevronDown } from 'lucide-react';
import moment from 'moment-timezone';
import { useState, useEffect } from 'react';

import { utils } from 'lib/utils';

// Styles
import './miniCalendar.scss';

function MiniCalendar(props: any) {
  // Declare a new state variable, which we'll call "count"
  const [month, setMonth] = useState(props.month || moment().month());
  const [year, setYear] = useState(props.year || moment().year());

  useEffect(() => {
    setMonth(props.month);
    setYear(props.year);
  }, [props.month, props.year]);

  // Creates an array for the whole month
  const daysOfMonth = utils.getDaysInMonth(month, year, props.timezone);

  // Split array into weeks
  const weeksArray = _.chunk(daysOfMonth, 7);

  const renderMiniCalendarDay = (day: any) => {
    const { date, outOfMonth, isToday } = day;
    let isSelectedDay = false;
    let dayInPast = false;

    // Check whether the day is selected
    if (props.selectedDay && moment(props.selectedDay).isSame(date, 'day')) {
      isSelectedDay = true;
    }

    // Check if date is in past
    if (props.disablePastDates && moment(date).isBefore()) {
      dayInPast = true;
    }

    return (
      <div
        id={`__day${date.getDate()}`}
        key={date.valueOf()}
        className="day"
        onClick={() => {
          // if (outOfMonth) setMonth(new Date(date).getMonth());
          if (props.onClickDay && !dayInPast) props.onClickDay(date);
        }}
      >
        <div
          className={`dayInternal ${outOfMonth ? 'outMonth' : ''} ${isToday ? 'today' : ''} ${isSelectedDay ? 'selectedDay' : ''} ${
            dayInPast ? 'disabled' : ''
          }`}
        >
          <span>
            {moment(date)
              .tz(props.timezone || moment.tz.guess())
              .date()}
          </span>
        </div>
      </div>
    );
  };

  const renderMiniCalendarRow = (week: any, key: any) => {
    // Function to render each row of the month
    return (
      <div className={`weekRow ${_.get(week, '[0].week') === props.week ? 'weekDisplayed' : ''}`} key={`week-${key}`}>
        {_.map(week, (day: any) => renderMiniCalendarDay(day))}
      </div>
    );
  };

  return (
    <div className="miniCalendar">
      <div className="monthHeader">
        <p>{`${moment().month(month).format('MMMM')} ${year}`}</p>
        <div className="monthControls">
          <ChevronUp
            id="__previousMonthOption"
            aria-label="Previous month"
            style={{ width: 20, height: 20 }}
            color="#666"
            onClick={() => {
              let newMonth = month;
              let newYear = year;
              newMonth -= 1;
              if (month === 0) {
                newMonth = 11;
                newYear = year - 1;
                setYear(newYear);
              }
              setMonth(newMonth);
              if (props.onChangeMiniCalendarDate) props.onChangeMiniCalendarDate({ month: newMonth, year: newYear });
            }}
          />
          <ChevronDown
            id="__nextMonthOption"
            style={{ width: 20, height: 20 }}
            aria-label="Next month"
            color="#666"
            onClick={() => {
              let newMonth = month;
              let newYear = year;
              newMonth += 1;
              if (month === 11) {
                newMonth = 0;
                newYear = year + 1;
                setYear(year + 1);
              }
              setMonth(newMonth);
              if (props.onChangeMiniCalendarDate) props.onChangeMiniCalendarDate({ month: newMonth, year: newYear });
            }}
          />
        </div>
      </div>
      <div className="monthContent">
        <div className="weeks">
          {_.map(utils.getDaysOfWeek(), ({ day, abr }: any) => (
            <span key={day}>{abr}</span>
          ))}
        </div>
        <div className="days">{_.map(weeksArray, (week: any, key: any) => renderMiniCalendarRow(week, key))}</div>
      </div>
    </div>
  );
}

export default MiniCalendar;
