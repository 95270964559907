import { Button } from '@askable/ui/components/ui/button';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';

import './styles/confirmationScreenStyles.scss';

function ConfirmationScreen(props: any) {
  const onClickDone = () => {
    props.history.push({ pathname: '/' });
  };

  const renderContentPage = () => {
    return (
      <div className="askablePlusConfirmationScreenContent">
        <img className="confirmationImage" src="/askablePlus/illustrations/personOk.png" alt="personOk" />
        <span className="confirmationTitle font--title1">Thanks, we&apos;ll be in touch</span>
        <span className="confirmationText">
          Our team will be in touch with you shortly. We&apos;ll give you a simple online form to complete so that we
          can get some more specific info about your research plan.
        </span>
        <div className="buttonContainer">
          <Button variant="primary" size="lg" onClick={onClickDone}>
            Done
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="askablePlusConfirmationScreenContainer">
      <div className="askablePlusConfirmationScreenContentContainer">{renderContentPage()}</div>
    </div>
  );
}

export default deprecatedWithRouter(ConfirmationScreen);
