import { Button } from '@askable/ui/components/ui/button';
import { Input } from '@askable/ui/components/ui/input';
import { Label } from '@askable/ui/components/ui/label';
import { toast } from '@askable/ui/components/ui/sonner';
import { Pencil } from 'lucide-react';
import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, CombinedError } from 'urql';

import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { UpdateUnmoderatedBooking } from 'containers/Studies/data/UpdateUnmoderatedBooking.mutation';

interface StudyNameProps {
  id: string;
  isDisabled?: boolean;
  name: string;
}

export const StudyName = ({ id, isDisabled, name }: StudyNameProps) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(true);
  const [localName, setLocalName] = useState(name);

  const { setIsSaving } = useStudyContext();

  const [{ fetching }, updateUnmoderatedBooking] = useMutation(UpdateUnmoderatedBooking);

  const handleNameChange = useCallback(
    async (e?: React.FormEvent) => {
      e?.preventDefault?.();

      if (fetching || !id) {
        return;
      }

      const newName = localName.trim();

      if (newName === name) {
        setIsEditing(false);
        return;
      }

      try {
        setIsSaving(true);
        const { error } = await updateUnmoderatedBooking({ input: { _id: id, name: newName } });

        if (error) {
          throw error;
        }

        setIsEditing(false);
      } catch (err) {
        setIsEditing(true);
        toast.error(err instanceof CombinedError ? err.message : t('sections.studies.failedToUpdateStudyName'));
      } finally {
        setTimeout(() => setIsSaving(false), 200);
      }
    },
    [fetching, id, localName, name, setIsSaving, updateUnmoderatedBooking, t],
  );

  return (
    <h1 className="group order-1 -my-1 -ml-1 py-1" id="study-name">
      {isDisabled ? <div className="truncate pl-3 text-lg font-medium">{localName}</div> : null}

      {!isDisabled && !isEditing ? (
        <Button
          variant="ghost"
          className="flex w-full items-center justify-start truncate hover:bg-transparent focus:bg-transparent active:bg-transparent"
          onClick={() => {
            if (!isDisabled) {
              setIsEditing(true);
            }
          }}
        >
          {localName === '' ? (
            <div className="truncate text-lg font-semibold text-muted-foreground">
              {t('sections.studies.untitledStudy')}
            </div>
          ) : (
            <div className="truncate text-lg font-semibold">{localName}</div>
          )}
          <Pencil className="h-4 w-4 opacity-0 group-focus-within:opacity-100 group-hover:opacity-100 group-focus:opacity-100" />
        </Button>
      ) : null}

      {!isDisabled && isEditing ? (
        <form onSubmit={handleNameChange}>
          <Label htmlFor="name" className="sr-only">
            {t('sections.studies.studyName')}
          </Label>

          <Input
            autoComplete="off"
            autoFocus
            className="text-md -my-2 w-full truncate px-3 text-lg font-semibold text-foreground"
            data-1p-ignore
            id="name"
            name="name"
            placeholder={t('sections.studies.untitledStudy')}
            value={localName}
            variant="borderless"
            onChange={e => setLocalName(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Escape') {
                setLocalName(name);
                setIsEditing(false);
              }
            }}
            onBlur={() => {
              if (!fetching) {
                handleNameChange();
              }
            }}
          />
        </form>
      ) : null}
    </h1>
  );
};
