import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const GlobalIcon: CreateIconType = createIcon({
  displayName: 'GlobalIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
    color: 'gray.500',
  },
  path: [
    <g fill="currentColor" clipPath="url(#globalIcon)">
      {}
      <path d="M7.333 14.74a.36.36 0 0 0-.226-.14 6.52 6.52 0 0 1-2.087-.667v-.86a1.666 1.666 0 0 1 .593-1.273c.377-.32.668-.727.847-1.187.044-.225.102-.448.173-.666.032-.154.05-.31.054-.467a3.007 3.007 0 0 0-3-3h-2.18a6.667 6.667 0 0 1 10.253-4H9.5a1.833 1.833 0 1 0 0 3.667c.3.003.595.086.853.24a.38.38 0 0 0 .24.04c.354-.069.714-.1 1.074-.094a5.334 5.334 0 0 1 4 1.8A.2.2 0 0 0 16 8 8 8 0 0 0 0 8c0 3.28 2.667 8 8 8a.2.2 0 0 0 .133-.347c-.3-.273-.569-.58-.8-.913Z" />
      <path d="M11.666 7.333A4.333 4.333 0 1 0 16 11.667a4.34 4.34 0 0 0-4.334-4.334Zm0 7.334a3 3 0 1 1 3-3 3.007 3.007 0 0 1-3 3Z" />
      <path d="M13 11h-.5a.167.167 0 0 1-.167-.167V10A.667.667 0 1 0 11 10v1.667a.666.666 0 0 0 .667.666H13A.667.667 0 1 0 13 11Z" />
    </g>,
    <defs>
      <clipPath id="globalIcon">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>,
  ],
});
