import { IconBlock } from '@askable/ui/components/unmod/icon-block';
import { cn } from '@askable/ui/lib/utils';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { TriangleAlert } from 'lucide-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { blockSchema } from 'containers/Studies/data/schemas/blockSchema';

import { BlockActions } from './BlockActions';

import type { ActiveBlock } from '../hooks/useActiveBlockId';
import type { CSSProperties } from 'react';

interface Props {
  data: NonNullable<ActiveBlock>;
  isActive?: boolean;
  isMovable?: boolean;
}

export const Block = ({ data, isActive, isMovable }: Props) => {
  const { t } = useTranslation();
  const { newBlockId, setNewBlockId, isBuildDisabled } = useStudyContext();
  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({
    id: isMovable && '_id' in data ? data?._id : null,
  });

  const isSystemBlock = ['welcome', 'thank_you'].includes(data?.type);
  const id = '_id' in data ? data._id : data?.type;
  const type = data?.type;

  const dragStyle: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const isValid = useMemo(() => {
    if (newBlockId === id) {
      // We don't want to show validation errors while a block is being created
      return true;
    }
    return blockSchema.safeParse(data).success;
  }, [data, id, isSystemBlock, newBlockId]);

  const handleSelectBlock = () => {
    setNewBlockId(null);
  };

  return (
    <div
      className={cn('w-full touch-manipulation md:touch-none', {
        'z-50 cursor-grabbing': isDragging,
      })}
      id={id}
      {...attributes}
      {...listeners}
      ref={setNodeRef}
      style={dragStyle}
    >
      <Link
        to={{ search: `?block_id=${id}`, hash: 'editor' }}
        key={id}
        onClick={handleSelectBlock}
        className={cn(
          `flex w-full select-none items-center justify-between rounded-xl border-0.5 border-border bg-background p-1 text-sm
          text-secondary-foreground @[10rem]:w-full`,
          'group',
          'starting:opacity-70',
          '@[10rem]:w-full @[10rem]:justify-start @[10rem]:p-2',
          'hover:border-input focus:border-input active:border-primary active:ring-1 has-[button:active]:border-input',
          {
            [`border-0.5 lg:border-primary lg:bg-background lg:ring-1 lg:ring-primary lg:hover:border-primary lg:focus:border-primary
            lg:has-[button:active]:border-primary`]: isActive,
            'lg:border-input lg:ring-border lg:active:border-input': isDragging && !isActive,
            'pointer-events-none shadow-md': isDragging,
          },
        )}
        style={{ WebkitTouchCallout: 'none' }}
        title={data.title}
      >
        <div className="flex w-full min-w-0 items-center justify-center gap-2 @[10rem]:justify-start">
          <div className={!isSystemBlock ? 'cursor-grab' : undefined}>
            <IconBlock type={type} />
          </div>

          <div className="hidden flex-col truncate text-left font-medium @[10rem]:flex">
            {data.title ? (
              <div className="truncate">{data.title}</div>
            ) : (
              <div className="truncate text-muted-foreground">
                {t(
                  isSystemBlock
                    ? 'sections.studies.build.blocks.placeholder.title'
                    : 'sections.studies.build.blocks.placeholder.taskTitle',
                )}
                ...
              </div>
            )}
            <div className="truncate text-xs text-muted-foreground">
              {t(`sections.studies.build.blocks.${type}.title`)}
            </div>
          </div>
        </div>

        <div className="hidden items-center justify-center @[10rem]:grid">
          {!isValid ? (
            <div
              className={cn('col-start-1 col-end-2 row-start-1 row-end-2 m-auto p-2 ', {
                'transition-opacity lg:group-hover:opacity-0 lg:group-focus:opacity-0': id && !isSystemBlock,
              })}
            >
              <TriangleAlert className="h-4 w-4 text-destructive" aria-label={t('formValidation.invalid')} />
            </div>
          ) : null}

          {!isSystemBlock && !isBuildDisabled && id ? (
            <div
              className="col-start-2 col-end-2 row-start-1 row-end-2 lg:col-start-1"
              // HACK: prevents the block links onClick to fire (which changes the selected block)
              // TODO: ideally this is abstracted out into the dropdown menu component
              onPointerDown={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <BlockActions blockId={id} isVisible={false} />
            </div>
          ) : null}
        </div>
      </Link>
    </div>
  );
};
