import { Button } from '@askable/ui/components/ui/button';
import { cn } from '@askable/ui/lib/utils';
import { ArrowLeft, ArrowRight } from 'lucide-react';

import { IconBlock } from './icon-block';

import type { StudyBlockType } from './types';
import type { ReactNode } from 'react';

interface TaskCardProps {
  children: ReactNode;
  footerSlot: ReactNode;
  id: string;
  isExiting?: boolean;
  isFloating?: boolean;
  isInPreview?: boolean;
}

interface TaskCardHeaderProps {
  instructions: string;
  subtitle?: string;
  title: string;
  type: StudyBlockType;
}

interface TaskCardNavigationProps {
  isNextDisabled?: boolean;
  isPreviousDisabled?: boolean;
  labels: {
    complete: string;
    getStarted: string;
    next: string;
    previous: string;
  };
  step: number;
  totalSteps: number;
  handleProgress: (direction: 'previous' | 'next') => void;
}

const getNavigationLabel = (
  labels: { complete: string; getStarted: string; next: string; previous: string },
  step: number,
  totalSteps: number,
) => {
  if (step === 0) {
    return labels.getStarted;
  }

  if (step === totalSteps - 1) {
    return labels.complete;
  }

  return labels.next;
};

export const TaskCard = ({ children, footerSlot, id, isExiting, isFloating, isInPreview }: TaskCardProps) => {
  return (
    <div
      className={cn(
        'flex h-full w-full max-w-[45rem] flex-col justify-between gap-4 bg-background shadow md:gap-8 lg:rounded-xl',
        {
          'absolute left-4 top-4 z-10 h-[calc(100%_-_36px)] w-auto lg:left-6 lg:top-6 lg:h-[calc(100%_-_48px)]':
            isFloating,
          'left-0 top-0 h-full lg:left-0 lg:top-0 lg:h-full': isFloating && isInPreview,
          'origin-bottom-left animate-scale-down ease-anticipation': isExiting,
        },
      )}
      data-id={id}
    >
      <div className="flex flex-1 flex-col gap-3 overflow-auto">{children}</div>
      <footer className="flex flex-col gap-2 md:gap-6">{footerSlot}</footer>
    </div>
  );
};

export const TaskCardHeader = ({ instructions, subtitle = 'Task', title, type }: TaskCardHeaderProps) => {
  return (
    <header className="flex flex-col gap-12 p-4 md:px-8 md:pt-8">
      {type === 'welcome' || type === 'thank_you' ? (
        <div className="w-fit">
          <IconBlock type={type} size="xl" hasBackground={false} />
        </div>
      ) : null}

      <div className="flex flex-col gap-3">
        {type !== 'welcome' && type !== 'thank_you' ? (
          <div className="w-fit rounded-lg bg-background-subtle px-3 py-2 text-xs font-medium leading-none">
            {subtitle}
          </div>
        ) : null}
        {title ? <h3 className="text-2xl font-semibold">{title}</h3> : null}
        {instructions ? <p className="max-w-text whitespace-pre-wrap text-lg">{instructions}</p> : null}
      </div>
    </header>
  );
};

export const TaskCardFooter = ({ children }: { children: ReactNode }) => (
  <nav className="flex min-h-24 flex-1 justify-end px-4 py-6 @md:border-t md:px-8">{children}</nav>
);

export const TaskCardNavigation = ({
  isNextDisabled,
  isPreviousDisabled,
  labels,
  step = 0,
  totalSteps = 1,
  handleProgress,
}: TaskCardNavigationProps) => {
  return (
    <div>
      <div
        className="relative -bottom-[2px] h-[3px] rounded-r-xl bg-border transition-all"
        style={{ width: `calc(${(step / totalSteps) * 100}%)` }}
      />

      <nav className="flex items-center justify-end gap-2 px-4 py-6 @md:border-t md:px-8">
        {step > 0 ? (
          <Button
            className="flex-1 @md:flex-none"
            disabled={isPreviousDisabled}
            onClick={() => handleProgress('previous')}
            size="lg"
            variant="secondary"
          >
            <ArrowLeft className="hidden h-4 w-4 @md:block" />
            {labels.previous}
          </Button>
        ) : null}

        {step < totalSteps ? (
          <Button
            className="flex-1 @md:flex-none"
            disabled={isNextDisabled}
            onClick={() => handleProgress('next')}
            size="lg"
            variant="primary"
          >
            {getNavigationLabel(labels, step, totalSteps)} <ArrowRight className="hidden h-4 w-4 @md:block" />
          </Button>
        ) : null}
      </nav>
    </div>
  );
};
