import { cn } from '@askable/ui/lib/utils';

import type { ReactNode } from 'react';

/** Custom tag for the ParticipantTableRow */

interface TagProps {
  variant: 'success' | 'incomplete'; // @todo add more variants
  children: ReactNode;
}

export const Tag = ({ children, variant }: TagProps) => {
  return (
    <div
      className={cn('w-fit rounded-lg px-2 py-1 text-sm font-medium leading-none text-primary', {
        'bg-pastel-green': variant === 'success',
        'bg-pastel-red': variant === 'incomplete',
      })}
    >
      {children}
    </div>
  );
};
