/* eslint-disable max-lines */
import { ApolloError } from '@apollo/client';
import { toast } from '@askable/ui/components/ui/sonner';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import { useEffect, useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Heading,
  HStack,
  Image,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from 'ui';
import { useMutation } from 'urql';

import { AskablePlusProjectContainer } from 'components/askablePlus/AskablePlusProjectContainer';
import { AccessDeniedMessage } from 'components/common';
import PriceCardContainer from 'components/manageAskablePlus/components/priceCardContainer';
import ProjectBrief from 'components/manageAskablePlus/components/projectBrief';
import { useConnectedClient } from 'context/ConnectedClientContext';
import { ApplyDeclineProjectType, ProjectUserStatus } from 'generated/graphql';

import { ResearcherAcceptProjectInviteMutation } from './data/ResearcherAcceptProjectInvite.mutation';
import { ResearcherApplyDeclineProjectMutation } from './data/ResearcherApplyDeclineProject.mutation';

import type { Project } from 'generated/graphql';
import type { History } from 'history';
import type { StackProps } from 'ui';

import 'components/manageAskablePlus/styles/askablePlusStyles.scss';

type Props = {
  project: Project;
  location: Location;
  refetchProject: () => Promise<void>;
  history: History;
  subscribeToUpdateProject: () => void;
};

/**
 * This works out which view to show depending on the viewers application status.
 * The options are:
 * 1. Study offer screen. viewer has not applied and no researcher currently assigned
 * 2. Already applied screen. Viewer has applied for the study and no current researcher assigned.
 * 3. Study offer expired. Viewer has applied and the study already has a researcher assigned.
 * 4. Study invite screen. Viewer has been invited to accept the study offer
 * 5. Study screen. Viewer has accepted the study offer.
 */
function getScreenView({ users }: Project, viewerId: string) {
  const viewersApplication = users?.find(user => user?._id === viewerId);
  const invitedResearchers = users?.filter(user => user?.status === ProjectUserStatus.Invited);
  const acceptedResearchers = users?.filter(user => user?.status === ProjectUserStatus.Accepted);

  if (viewersApplication?.status === ProjectUserStatus.Declined) {
    return 'declined';
  }

  // If a user is invited we still want them to be able to view the invited section
  if (
    viewersApplication?.status === ProjectUserStatus.Expired ||
    ((acceptedResearchers?.length ?? 0) >= 1 && viewersApplication?.status !== ProjectUserStatus.Invited)
  ) {
    return 'expired';
  }

  if (viewersApplication && acceptedResearchers?.some(a => a?._id === viewerId)) {
    return 'accepted';
  }

  if (viewersApplication && invitedResearchers?.some(researcher => researcher?._id === viewersApplication?._id)) {
    return 'invited';
  }

  if (viewersApplication?.status === ProjectUserStatus.Applied) {
    return 'applied';
  }

  return 'open_offer';
}

function ProjectOfferScreen({ project, location, history, ...props }: Props) {
  const connectedUser = useConnectedClient();
  const { isOpen: declineDialogIsOpen, onOpen: openDeclineDialog, onClose: closeDeclineDialog } = useDisclosure();
  const {
    isOpen: acceptOfferDialogIsOpen,
    onOpen: openAcceptOfferDialog,
    onClose: closeOfferAcceptDialog,
  } = useDisclosure();

  const declineOfferCancelButtonRef = useRef<HTMLButtonElement>(null);
  const acceptOfferCancelButtonRef = useRef<HTMLButtonElement>(null);

  const viewerScreenStatus = getScreenView(project, connectedUser.details?.id!);

  const [{ fetching: acceptingProjectInvite }, acceptInvite] = useMutation(ResearcherAcceptProjectInviteMutation);

  const [{ fetching: applyDeclineResearcherLoading }, applyDeclineAskablePlusProject] = useMutation(
    ResearcherApplyDeclineProjectMutation,
  );

  const assignedResearchers = project.users?.filter(user => user?.status === ProjectUserStatus.Accepted);

  useEffect(() => {
    if (assignedResearchers?.some(user => user?._id === connectedUser.details?.id)) {
      history.replace(`/manage-askable-plus-project/${project._id}`);
    }
  }, [assignedResearchers]);

  useEffect(() => {
    props.subscribeToUpdateProject();
  }, []);

  const handleAcceptInviteClick = async () => {
    try {
      const { error } = await acceptInvite({
        projectId: project._id,
      });
      if (error) {
        throw error;
      }

      await props.refetchProject();

      history.push({ pathname: `/manage-askable-plus-project/${project._id}` });
    } catch (e) {
      toast.error(e instanceof ApolloError ? e.message : ((e as Error)?.message ?? 'An unknown error has occured'));
    }
  };

  const onConfirmDeclineProject = async () => {
    try {
      const { error } = await applyDeclineAskablePlusProject({
        projectId: project._id!,
        action: ApplyDeclineProjectType.Decline,
      });
      if (error) {
        throw error.graphQLErrors[0].message;
      }

      props.refetchProject();
    } catch (e) {
      toast.error(e instanceof ApolloError ? e.message : ((e as Error)?.message ?? 'An unknown error has occured'));
    }
  };

  const onApplyProject = async () => {
    try {
      const { error: applyDeclineAskablePlusProjectError } = await applyDeclineAskablePlusProject({
        projectId: project._id!,
        action: ApplyDeclineProjectType.Apply,
      });
      if (applyDeclineAskablePlusProjectError) {
        throw applyDeclineAskablePlusProjectError.graphQLErrors[0].message;
      }

      props.refetchProject();

      // Rerender this screen with the applied user
    } catch (e) {
      toast.error(e instanceof ApolloError ? e.message : ((e as Error)?.message ?? 'An unknown error has occured'));
    }
  };

  if (!connectedUser.details?.type?.researcher) {
    return (
      <AccessDeniedMessage
        message="Oops! You're not allowed to see this study 😕"
        booking={project}
        userId={connectedUser.details?.id}
      />
    );
  }

  if (viewerScreenStatus === 'applied') {
    return (
      <VStack w="full" justifyContent="center" alignItems="center" spacing={8} mt="36">
        <Image src="/assets/researcher-applied.svg" />
        <Box textAlign="center">
          <Heading>Thanks for applying</Heading>
          <Text>We'll notify you if you are selected</Text>
        </Box>
      </VStack>
    );
  }

  if (viewerScreenStatus === 'expired' || viewerScreenStatus === 'declined') {
    return (
      <VStack w="full" justifyContent="center" alignItems="center" spacing={8} mt="36">
        <Image src="/assets/researcher-declined.svg" />
        <Box textAlign="center">
          <Heading>Study {viewerScreenStatus === 'declined' ? 'declined' : 'offer expired'}</Heading>
          <Text>
            {viewerScreenStatus === 'declined' && 'Thanks for your response.'} We'll be in touch with more studies.
          </Text>
        </Box>
      </VStack>
    );
  }

  const buttonContainer = (stackProps?: StackProps) => {
    if (viewerScreenStatus === 'accepted') {
      return null;
    }

    if (viewerScreenStatus === 'invited') {
      return (
        <Box {...stackProps} w="full">
          <Button w="full" flex={1} onClick={openAcceptOfferDialog} colorScheme="brand">
            Accept invite
          </Button>
        </Box>
      );
    }

    return (
      <HStack w="full" {...stackProps}>
        <Button
          flex={0.5}
          colorScheme="gray"
          variant="outline"
          onClick={openDeclineDialog}
          disabled={applyDeclineResearcherLoading}
        >
          Decline
        </Button>
        <Button flex={1} onClick={onApplyProject} isLoading={applyDeclineResearcherLoading} colorScheme="brand">
          Apply
        </Button>
      </HStack>
    );
  };

  return (
    <AskablePlusProjectContainer className="askablePlusProjectContainer" p="0" py="6">
      <Stack
        spacing={[6, 6, 20]}
        direction={['column', 'column', 'row-reverse']}
        justifyContent="space-between"
        h="full"
      >
        <Heading px="6" display={['block', 'block', 'none']}>
          {project.name}
        </Heading>
        <VStack px="6" spacing={[0, 0, 6]} w="full" maxW="sm" minW={['full', 'full', 'sm']}>
          {buttonContainer({ display: ['none', 'none', 'flex'] })}
          <PriceCardContainer project={project} projectOfferPage />
        </VStack>
        <Box overflow="scroll" h="full">
          <Box pb={[20, 20, 0]} px="6">
            <ProjectBrief project={project} />
          </Box>
        </Box>
        {buttonContainer({
          display: ['flex', 'flex', 'none'],
          position: 'absolute',
          background: 'white',
          bottom: 0,
          left: 0,
          right: 0,
          borderTop: '1px',
          borderTopColor: 'gray.200',
          p: '4',
        })}
      </Stack>
      <AlertDialog
        isOpen={declineDialogIsOpen}
        leastDestructiveRef={declineOfferCancelButtonRef}
        onClose={closeDeclineDialog}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Are you sure?
            </AlertDialogHeader>

            <AlertDialogBody>By declining you won't be notified about this opportunity again.</AlertDialogBody>

            <AlertDialogFooter>
              <Button
                colorScheme="gray"
                variant="outline"
                ref={declineOfferCancelButtonRef}
                onClick={closeDeclineDialog}
                disabled={applyDeclineResearcherLoading}
              >
                Cancel
              </Button>
              <Button isLoading={applyDeclineResearcherLoading} onClick={onConfirmDeclineProject} ml={3}>
                Decline
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog
        isOpen={acceptOfferDialogIsOpen}
        leastDestructiveRef={acceptOfferCancelButtonRef}
        onClose={closeOfferAcceptDialog}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Are you on board?
            </AlertDialogHeader>

            <AlertDialogBody>
              By accepting this invitation, you'll be assigned to the study and paired up with the client.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                disabled={acceptingProjectInvite}
                colorScheme="gray"
                variant="outline"
                ref={acceptOfferCancelButtonRef}
                onClick={closeOfferAcceptDialog}
              >
                Cancel
              </Button>
              <Button onClick={handleAcceptInviteClick} ml={3} isLoading={acceptingProjectInvite}>
                Accept
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </AskablePlusProjectContainer>
  );
}

export default deprecatedWithRouter(ProjectOfferScreen);
