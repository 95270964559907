import { Dialog, DialogContent, DialogTitle } from '@askable/ui/components/ui/dialog';
import { VideoPlayer } from '@askable/ui/components/ui/video-player';
import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from 'urql';

import { EmptyState } from '../components/EmptyState';
import { StudyTaskBlockResultQuery } from '../data/studyTaskBlockResult.query';

export const RecordingContentBasic = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);

  const resultId = searchParams.get('result_id');

  const [{ data: resultData }] = useQuery({
    query: StudyTaskBlockResultQuery,
    variables: {
      id: resultId ?? '',
    },
    pause: !resultId,
  });

  // // For video player
  const recordingUrl = resultData?.studyBlockResult?.video?.playback_url;

  useEffect(() => {
    setIsOpen(searchParams.has('result_id') || searchParams.has('path_id') || searchParams.has('screen_id'));
  }, [searchParams]);

  const handleClose = () => {
    setIsOpen(false);

    // Clean up URL params when closing the dialog
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('result_id');
    newSearchParams.delete('path_id');
    newSearchParams.delete('screen_id');
    newSearchParams.delete('tab');
    setSearchParams(newSearchParams);
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={open => {
        if (!open) {
          handleClose();
        }
      }}
    >
      <DialogContent
        onEscapeKeyDown={event => event.preventDefault()}
        className="bottom-[unset] top-6 flex min-h-80 translate-y-0 flex-col gap-0 border-0 p-0 data-[state=open]:slide-in-from-top-1
          sm:max-h-[calc(100%_-3rem)] sm:max-w-[calc(100%_-2rem)]"
      >
        <div className="grid grid-cols-1 items-center justify-between gap-2 border-b border-border p-4 sm:grid-cols-3">
          <header className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <DialogTitle className="text-lg font-semibold leading-tight">
                {resultData?.studyBlockResult?._user_id}
              </DialogTitle>
            </div>
          </header>

          <div />
        </div>

        <div className="flex flex-col gap-4 p-4 md:flex-row">
          <div className="aspect-video w-full">
            <VideoPlayer url={recordingUrl ?? ''} placeholder={!recordingUrl ? <EmptyState /> : null} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
