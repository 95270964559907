import { IconButton, Input, InputGroup, InputRightElement, useBoolean } from '@chakra-ui/react';
import { Eye, EyeOff } from 'lucide-react';
import { forwardRef } from 'react';

import type { InputProps } from '@chakra-ui/react';

export const PasswordInput = forwardRef<HTMLInputElement, InputProps>(({ size, ...props }, ref) => {
  const [show, showControl] = useBoolean(false);

  return (
    <InputGroup size={size}>
      <Input type={show ? 'text' : 'password'} {...props} ref={ref} />
      <InputRightElement>
        <IconButton
          aria-label="Password input"
          size="sm"
          variant="ghost"
          colorScheme="gray"
          onClick={showControl.toggle}
          icon={show ? <Eye className="h-4 w-4" /> : <EyeOff className="h-4 w-4" />}
          _focus={{ outline: 0 }}
        />
      </InputRightElement>
    </InputGroup>
  );
});

PasswordInput.displayName = 'PasswordInput';
