import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const LinkIcon: CreateIconType = createIcon({
  displayName: 'LinkIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5112 1.82876C9.51898 2.05993 8.41531 2.78559 7.16652 3.88575C6.85572 4.15957 6.38179 4.12958 6.10798 3.81878C5.83417 3.50797 5.86415 3.03405 6.17496 2.76023C7.45998 1.62815 8.79789 0.687767 10.1708 0.36789C11.626 0.0288189 13.0301 0.406461 14.312 1.6883C15.5819 2.95831 15.9655 4.34943 15.6406 5.79343C15.3338 7.15668 14.4128 8.48409 13.2992 9.75784C13.0265 10.0697 12.5527 10.1014 12.2409 9.8288C11.929 9.55616 11.8973 9.08235 12.1699 8.77052C13.2498 7.5354 13.9566 6.44431 14.1772 5.46415C14.3795 4.56474 14.1899 3.68753 13.2513 2.74896C12.3054 1.80308 11.4209 1.61678 10.5112 1.82876ZM10.5309 5.46963C10.8238 5.76252 10.8238 6.2374 10.5309 6.53029L6.53069 10.5305C6.2378 10.8234 5.76292 10.8234 5.47003 10.5305C5.17714 10.2376 5.17714 9.76278 5.47003 9.46988L9.47028 5.46963C9.76318 5.17674 10.2381 5.17674 10.5309 5.46963ZM3.75946 6.17111C4.07129 6.44376 4.10306 6.91757 3.83041 7.2294C2.75051 8.46452 2.0437 9.55559 1.82317 10.5357C1.6208 11.4351 1.8105 12.3124 2.74909 13.251C3.69496 14.1969 4.57942 14.3832 5.4892 14.1712C6.48136 13.94 7.58502 13.2143 8.83381 12.1142C9.14461 11.8404 9.61854 11.8704 9.89235 12.1812C10.1662 12.492 10.1362 12.9659 9.82537 13.2397C8.54036 14.3718 7.20246 15.3122 5.82958 15.6321C4.37432 15.9711 2.97026 15.5935 1.68842 14.3117C0.41843 13.0416 0.0348486 11.6505 0.359751 10.2065C0.666484 8.84322 1.58749 7.51583 2.70118 6.24207C2.97382 5.93024 3.44763 5.89847 3.75946 6.17111Z"
      fill="currentColor"
    />
  ),
});
