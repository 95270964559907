import { FormControl, FormItem } from '@askable/ui/components/ui/form';
import { Textarea } from '@askable/ui/components/ui/textarea';
import { cn } from '@askable/ui/lib/utils';
import { memo } from 'react';
import { useController } from 'react-hook-form';

import { FormErrorMessage } from 'components/Form/FormErrorMessage';

import { FieldLabel } from './FieldLabel';

import type { InfoPopoverProps } from 'containers/Studies/components/InfoPopover';

interface TextareaFieldProps {
  description?: string;
  infoPopover?: InfoPopoverProps;
  isDisabled?: boolean;
  isDisabledSelectable?: boolean; // Allow text selection when disabled
  isFocused?: boolean;
  label: string;
  maxLength?: number;
  name: string;
  placeholder?: string;
  rows?: number;
}

const TextareaFieldComponent = ({
  description,
  infoPopover,
  isDisabled,
  isDisabledSelectable,
  isFocused,
  label,
  maxLength,
  name,
  placeholder,
  rows = 4,
}: TextareaFieldProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name });

  const currentLength = field.value?.length || 0;

  return (
    <FormItem>
      <FieldLabel name={name} label={label} description={description} infoPopover={infoPopover} />

      <FormControl>
        <Textarea
          {...field}
          aria-describedby={description ? `description_${name}` : undefined}
          autoComplete="off"
          autoFocus={isFocused}
          className={cn('text-md lg:text-sm', {
            ' bg-secondary text-foreground-subtle disabled:cursor-default disabled:border-border disabled:opacity-100':
              isDisabled && isDisabledSelectable,
          })}
          disabled={isDisabled}
          id={`input_${name}`}
          maxLength={maxLength}
          maxRows={10}
          placeholder={placeholder}
          rows={rows}
        />
      </FormControl>

      {maxLength !== undefined ? (
        <div className="text-sm">
          <div className="text-foreground-subtle">
            {currentLength} / {maxLength}
          </div>
        </div>
      ) : null}

      {error ? <FormErrorMessage message={error.message} prefix="sections.studies.recruit.formValidation" /> : null}
    </FormItem>
  );
};

TextareaFieldComponent.displayName = 'TextareaField';

export const TextareaField = memo(TextareaFieldComponent);
