import { Spinner } from '@askable/ui/components/ui/spinner';

interface PosterOverlayProps {
  image: string;
  label: string;
  handleClick: () => void;
}

interface VideoOverlayProps {
  isLoading: boolean;
  isPlaying: boolean;
  isUserPlayed: boolean | null;
  label: string;
  handleClick: () => void;
}

export const PosterOverlay = ({ image, label, handleClick }: PosterOverlayProps) => {
  return (
    <button aria-label={label} className="group h-full w-full" onClick={handleClick}>
      <div
        className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform rounded-lg bg-darkest/10 px-4 py-2 text-lightest
          transition-all group-hover:scale-105 group-hover:bg-darkest/30 group-focus:scale-105 group-focus:bg-darkest/30"
      >
        <svg fill="currentColor" className=" h-8 w-8 " viewBox="0 0 24 24">
          <path d="M4.5 5.7C4.5 4.2 6 3.3 7.3 4l11.5 6.4c1.3.7 1.3 2.5 0 3.2L7.3 20c-1.3.7-2.8-.2-2.8-1.7V5.7Z" />
        </svg>
      </div>
      <img alt="" className="m-auto h-full object-cover" height="100%" src={image} width="100%" />
    </button>
  );
};

// Show a play/pause icon when video is played/paused. Show loading spinner when buffering
export const VideoOverlay = ({ label, isLoading, isPlaying, isUserPlayed, handleClick }: VideoOverlayProps) => {
  return (
    <button
      aria-label={!isPlaying ? label : undefined}
      className="absolute z-10 flex h-full w-full cursor-default items-center justify-center object-contain text-lightest/60"
      onClick={handleClick}
    >
      {isLoading ? <Spinner className="h-8 w-8 fill-lightest text-darkest/40" /> : null}

      {isUserPlayed !== null && !isPlaying ? (
        <div className="animate-scale-out rounded-full bg-darkest/30 p-2 text-lightest opacity-0 fill-mode-forwards">
          <svg fill="currentColor" className=" h-6 w-6 " viewBox="0 0 24 24">
            <path d="M6.75 5.25a.75.75 0 0 1 .75-.75H9a.75.75 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75H7.5a.75.75 0 0 1-.75-.75V5.25Zm7.5 0A.75.75 0 0 1 15 4.5h1.5a.75.75 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75H15a.75.75 0 0 1-.75-.75V5.25Z" />
          </svg>
        </div>
      ) : null}

      {isUserPlayed !== null && isPlaying ? (
        <div className="animate-scale-out rounded-full bg-darkest/30 p-2 text-lightest opacity-0 fill-mode-forwards">
          <svg fill="currentColor" className=" h-6 w-6 " viewBox="0 0 24 24">
            <path d="M4.5 5.7C4.5 4.2 6 3.3 7.3 4l11.5 6.4c1.3.7 1.3 2.5 0 3.2L7.3 20c-1.3.7-2.8-.2-2.8-1.7V5.7Z" />
          </svg>
        </div>
      ) : null}
    </button>
  );
};
