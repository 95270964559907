import { TableCell, TableRow } from '@askable/ui/components/ui/table';
import { secondsToTime } from '@askable/ui/lib/utils';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { StudyBlockResultStatus } from 'generated/graphql';

import { Tag } from '../Tag';

import type { StudyTaskBlockResultPartial } from '../../data/studyTaskBlockResults.query';

interface ResponseTableRowProps {
  result: StudyTaskBlockResultPartial;
}

export const ResponseTableRow = ({ result }: ResponseTableRowProps) => {
  const { t } = useTranslation();
  const [, setSearchParams] = useSearchParams();
  const duration =
    result.task_started && result.task_ended ? Math.abs(result.task_started - result.task_ended) / 1000 : null;

  return (
    <TableRow
      onClick={() => {
        setSearchParams({ result_id: result._id, hash: 'recording' });
      }}
      key={result._id}
      className="h-auto cursor-pointer hover:bg-secondary"
      id={`result-${result._id}`}
    >
      <TableCell>{result._user_id}</TableCell>
      <TableCell>
        <Tag variant={result.status === StudyBlockResultStatus.Completed ? 'success' : 'incomplete'}>
          {result.status === 'completed'
            ? t(`sections.studies.results.success`)
            : t('sections.studies.results.incomplete')}
        </Tag>
      </TableCell>
      <TableCell className="text-end">{result.task_ended && duration ? secondsToTime(duration) : <>N/A</>}</TableCell>
      <TableCell>{result.task_ended ? format(result.task_ended, 'd MMM yyyy, h:mmaaa') : <>-</>}</TableCell>
    </TableRow>
  );
};
