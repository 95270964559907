import { Button } from '@askable/ui/components/ui/button';
import { Textarea } from '@askable/ui/components/ui/textarea';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { useEffect, useState, Fragment } from 'react';

import PriceCardContainer from 'components/askablePlus/components/priceCardContainer';

import './styles/additionalNotesStyles.scss';

function AdditionalNotes(props: any) {
  const askablePlusBrief = _.get(props, 'askablePlusBrief');
  const [askablePlusBriefState, setAskablePlusBriefState] = useState(askablePlusBrief);
  const [showAddAdditionalNotes, setShowAddAdditionalNotes] = useState(
    !_.get(askablePlusBrief, 'askable_plus.additional_info.notes'),
  );
  const [additionalNotes, setAdditionalNotes] = useState(_.get(askablePlusBrief, 'askable_plus.additional_info.notes'));

  useEffect(() => {
    if (!_.get(props, 'askablePlusBrief._id')) {
      props.history.push('/askable-plus/create');
    } else {
      props.updateLastStep({
        step: 'Additional Info',
        subStep: `/askable-plus/${askablePlusBrief._id}/additional-info/additional-notes`,
        stepId: 'additional_info_additional_notes',
      });
      props.renderRightContent(rightContent());
    }
  }, []);

  const rightContent = () => {
    return (
      <Fragment>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          askablePlusBrief={askablePlusBriefState}
          team={_.get(props, 'team')}
          context={props.context}
        />
      </Fragment>
    );
  };

  const onChangeValue = (value: any) => {
    setAdditionalNotes(value);
    const askablePlusBriefStateObj = {
      ...askablePlusBriefState,
      askable_plus: {
        ...askablePlusBriefState.askable_plus,
        additional_info: {
          ...askablePlusBriefState.askable_plus.additional_info,
          notes: value,
        },
      },
    };
    setAskablePlusBriefState(askablePlusBriefStateObj);
    props.updateAskablePlusBriefState(askablePlusBriefStateObj);
  };

  const onClickNext = () => {
    const redirectTo = `/askable-plus/${askablePlusBrief._id}/review-submit/confirm-brief`;
    props.history.push({ pathname: redirectTo });
  };

  return (
    <div className="createAskablePlusBriefContent">
      <h1 className="title">Additional Notes</h1>
      <p className="info">Are there any other requirements the researcher or our team should know about?</p>
      {showAddAdditionalNotes && (
        <div
          id="buttonAddAdditionalNotes"
          className="largeAddButton additionalNotes"
          onClick={() => {
            setShowAddAdditionalNotes(false);
          }}
        >
          <img className="addFiltersIcon" src="/icons/blackPlusIcon.svg" alt="addFiltersIcon" />
          <div className="addFiltersContent">
            <p className="addFiltersTitle">Add other requirements</p>
          </div>
        </div>
      )}
      {!showAddAdditionalNotes && (
        <Textarea
          className="textAreaField"
          value={additionalNotes || ''}
          onChange={(e: any) => onChangeValue(e.target.value)}
          rows={6}
          maxRows={10}
        />
      )}
      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default deprecatedWithRouter(AdditionalNotes);
