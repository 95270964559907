import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const HighlightIcon: CreateIconType = createIcon({
  displayName: 'HighlightIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: (
    <path
      d="M8.12102 12.0272C7.49722 12.0791 6.03558 12.5535 5.17931 14.0366M5.17931 14.0366L4.45144 15.2982L0.816406 15.2945L2.50688 12.4936M5.17931 14.0366L2.50688 12.4936M2.50688 12.4936C3.36314 11.0106 3.04316 9.50754 2.77615 8.94141M0.816406 15.298H15.1854M10.8796 1.07699C14.1017 2.93731 11.0803 8.60237 9.09212 11.5534C8.7663 12.0371 8.12144 12.171 7.61641 11.8795L3.15592 9.3042C2.65089 9.01262 2.44448 8.38718 2.70041 7.86318C4.26202 4.66583 7.65744 -0.783322 10.8796 1.07699Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});
