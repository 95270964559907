import { Button } from '@askable/ui/components/ui/button';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useIntercom } from 'react-use-intercom';

import { Card } from 'components/common';
import CardContainer from 'components/createBooking/components/cardContainer';
import PriceCardContainer from 'components/createBooking/components/priceCardContainer';
import { bookingUtils } from 'lib/booking';

import './styles/panelStyles.scss';

function Panel(props: any) {
  const intercom = useIntercom();

  const booking = _.get(props, 'booking');
  // const country = _.get(booking, 'config.location.country');
  // const countryData = _.find(countryCodeData, { region: _.get(booking, 'config.location.country') });
  const [bookingState, setBookingState] = useState(booking);
  const [bookingData, setBookingData] = useState(null);
  const [recruitmentType, setRecruitmentType] = useState(_.get(booking, 'config.recruitment.byo'));
  // const [usersByState, setUsersByState] = useState([]);

  useEffect(() => {
    props.updateLastStep({
      step: 'Audience',
      subStep: `/booking-setup/${props.booking._id}/audience/panel`,
      stepId: 'audience_panel',
    });
  }, []);

  useEffect(() => {
    setBookingState(props.booking);
  }, [props.booking]);

  useEffect(() => {
    props.renderRightContent(rightContent());
    props.renderRightAppPreview(null);
    // }, [bookingState, recruitmentType, usersByState]);
  }, [bookingState, recruitmentType]);

  // useEffect(() => {
  //     if (country === 'AU' || country === 'NZ' || country === 'GB') {
  //         getUsersByState();
  //     }
  // }, [country]);

  // const getUsersByState = async () => {
  //     if (country) {
  //         const { data } = await apolloFetch.fetch(fetchUsersByState, { country });
  //         if (data) {
  //             const dataArray = _.get(data, 'usersByState').slice(0, 10) || [];
  //             let maxValue = 5000;
  //             const totals = _.map(dataArray, (item, index) => {
  //                 if ((_.get(item, 'total') || 0) > 0) {
  //                     if (index === 0) {
  //                         if (item.total > 1000) maxValue = item.total + 1000;
  //                         else if (item.total > 100) maxValue = item.total + 100;
  //                         else maxValue = item.total + 10;
  //                     }
  //                     return { label: item._id, value: item.total, displayValue: item.total, maxValue };
  //                 }
  //             });
  //             setUsersByState(totals);
  //         }
  //     }
  // };

  const rightContent = () => {
    // const cardTexts1 = [
    //     {
    //         cardText: <span>These numbers are just an indicator. Don&apos;t worry if it looks like there aren&apos;t enough people in your area, we can easily find and target specific people through existing advertising platforms.</span>,
    //         indicators: usersByState
    //     }];
    const cardTexts2 = [
      { cardText: 'We use a variety of methods depending on the requirements. They include:' },
      { cardText: '• Paid advertising', additionalText: true },
      { cardText: '• Referral program' },
      { cardText: '• Social media' },
      { cardText: 'We will never advertise using your information.', additionalText: true },
    ];
    const cardTexts3 = [
      {
        cardText: (
          <span>
            For most studies it's <strong>1-2 business days</strong>. If you need same-day recruitment talk to our
            friendly support team.
          </span>
        ),
      },
    ];
    const cardTexts4 = [
      {
        cardText:
          'Yes it is! A big chunk of the costs of recruitment goes into messaging and marketing to the panel, so if you choose to do your own recruitment you can save up to 50%',
      },
    ];
    const cardTexts5 = [
      {
        cardText: (
          <span>
            You don&apos;t need to share any data with us to recruit from your own list, we&apos;ll provide you with a
            link you can then send to your own database with your own mailing tool.
          </span>
        ),
      },
      {
        cardText:
          'Participants who apply for that study will not get any other notifications from Askable unless they opt in.',
        additionalText: true,
      },
    ];

    return (
      <>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          booking={bookingState}
          bookingSteps={props.bookingSteps}
          team={_.get(props, 'team')}
          context={props.context}
        />
        <p className="cardContainerTitle additionalTitle">Faqs</p>
        {!bookingUtils.isBYO(bookingState) && (
          <>
            {/* {(country === 'AU' || country === 'NZ' || country === 'GB') &&
                            <CardContainer
                                cardId="__panelCard1"
                                cardClass="slideInAnimationDelay80"
                                cardTitle={`Our panel - ${_.get(countryData, 'name')}`}
                                cardTexts={cardTexts1}
                                onLinkClick={() => {
                                    // Track Event Analytics
                                    segment.trackEvent(
                                        'faq_our_panel_clicked',
                                        {
                                            page: window.location.href,
                                            action: `Faq Our panel - ${_.get(countryData, 'name')} clicked`,
                                            bookingId: _.get(props, 'booking._id'),
                                            bookingName: _.get(props, 'booking.name')
                                        }
                                    );
                                    window.open('https://help.askable.com/en/articles/4254541-who-and-where-can-you-recruit-how-long-does-it-take', '_blank');
                                }}
                            />
                        } */}
            <CardContainer
              additionalCard
              cardId="__panelCard2"
              cardClass="slideInAnimationDelay160"
              cardTitle="How does Askable source participants?"
              cardTexts={cardTexts2}
              onLinkClick={() => {
                window.open(
                  'https://help.askable.com/en/articles/4849168-how-does-askable-source-their-participants',
                  '_blank',
                );
              }}
            />
            <CardContainer
              additionalCard
              cardId="__panelCard3"
              cardClass="slideInAnimationDelay240"
              cardTitle="What's the average turnaround / lead time for recruitment?"
              cardTexts={cardTexts3}
              onLinkClick={() => {
                intercom.showNewMessages();
              }}
              linkText="Chat to us"
              hideArrow
            />
            <CardContainer
              additionalCard
              cardId="__panelCard4"
              cardClass="slideInAnimationDelay320"
              cardTitle="Is recruiting from my own panel cheaper?"
              cardTexts={cardTexts4}
              onLinkClick={() => {
                window.open(
                  'https://help.askable.com/en/articles/3777041-byo-i-want-to-recruit-my-own-participants',
                  '_blank',
                );
              }}
            />
          </>
        )}
        {bookingUtils.isBYO(bookingState) && (
          <CardContainer
            additionalCard
            cardId="__panelCard5"
            cardClass="slideInAnimationDelay80"
            cardTitle="How does recruiting from my own list work? Do I have to share my data?"
            cardTexts={cardTexts5}
            onLinkClick={() => {
              window.open(
                'https://help.askable.com/en/articles/3777041-byo-i-want-to-recruit-my-own-participants',
                '_blank',
              );
            }}
          />
        )}
      </>
    );
  };

  const onClickCard = (type: any) => {
    setRecruitmentType(type);

    const bookingStateObj = {
      ...booking,
      config: {
        ...booking.config,
        participant_category: 1,
        recruitment: {
          ...booking.config.recruitment,
          byo: type,
        },
      },
    };
    setBookingState(bookingStateObj);

    // Save changes on history to update booking when click directly on other step
    if (type !== _.get(booking, 'config.recruitment.byo')) {
      const bookingObj = {
        config: {
          participant_category: 1,
          recruitment: {
            byo: type,
          },
        },
      };
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ config: { participant_category... Remove this comment to see the full error message
      setBookingData(bookingObj);
      props.history.replace({ booking: bookingObj, bookingState: bookingStateObj });
    } else {
      setBookingData(null);
      props.history.replace({ booking: null, bookingState: null });
    }
  };

  const onClickNext = () => {
    let redirectTo = `/booking-setup/${booking._id}/audience/participant-locations`;
    if (bookingUtils.isInPerson(booking)) redirectTo = `/booking-setup/${booking._id}/audience/demographic-filters`;

    props.history.push({ pathname: redirectTo, booking: bookingData, bookingState });
  };

  return (
    <div className="createBookingContent">
      <h1 id="__pageTitle" className="title">
        How would you like to source participants for this study?
      </h1>
      <div className="panelTypes">
        <Card
          title="Askable Panel"
          className="panelCard"
          content={[{ label: 'Recruit from the Askable panel' }]}
          selected={!recruitmentType}
          image="/illustrations/askable_panel_icon.svg"
          onClickCard={() => onClickCard(false)}
        />
        <Card
          title="Send a link to your list"
          className="panelCard"
          content={[{ label: 'Recruit your own participants by sending a link' }]}
          selected={recruitmentType}
          image="/illustrations/your_audience_icon.svg"
          onClickCard={() => onClickCard(true)}
        />
      </div>
      {recruitmentType && (
        <div className="additionalInfoContainer spaceTop">
          <div className="additionalInfoContent">
            <img className="additionalInfoIconImage" src="/icons/lightBulb.svg" alt="illustration" />
            <p className="additionalInfoTitle">Legal & Privacy</p>
            <p className="additionalInfoText">
              Participants signing up via your link will need to opt in to see any other Askable opportunity. By default
              they will not receive any communications from us.
            </p>
          </div>
        </div>
      )}
      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default deprecatedWithRouter(Panel);
