import { useQuery } from '@apollo/client';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

import { LoadingOverlay, AccessDeniedMessage } from 'components/common';
import { useConnectedClient } from 'context/ConnectedClientContext';
import FetchProjectById from 'data/queries/project/fetchProjectById';
import updateProjectSubscription from 'data/subscriptions/project/updateProjectSubscription';

import CreateAskablePlusWithData from './createAskablePlusWithData';

function CreateAskablePlus(props: any) {
  const params = useParams();
  const { details } = useConnectedClient();

  const { loading, subscribeToMore, data } = useQuery(FetchProjectById, {
    skip: !params.projectID,
    variables: {
      id: params.projectID,
    },
  });

  if (params.projectID) {
    if (loading) return <LoadingOverlay style={{ opacity: 0.8 }} />;

    // Validate if user has permission to access the booking
    const projectUsers = _.get(data, 'projectByID.users') || [];
    const isProjectUser = _.find(projectUsers, (item: any) => item._id === details?.id);
    if (_.size(projectUsers) > 0 && !isProjectUser) {
      return (
        <AccessDeniedMessage
          message="Oooops! You're not allowed to see this project 😕"
          project={_.get(data, 'projectByID')}
          userId={details?.id}
        />
      );
    }

    return (
      <CreateAskablePlusWithData
        client={props.client}
        project={_.get(data, 'projectByID')}
        subscribeToUpdateProject={() => {
          subscribeToMore({
            document: updateProjectSubscription,
            variables: {
              id: params.projectID,
            },
            updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData.data) return prev;

              return { ...prev, project: _.get(subscriptionData, 'data.projectByID') };
            },
          });
        }}
      />
    );
  }

  return <CreateAskablePlusWithData project={null} />;
}

export default deprecatedWithRouter(CreateAskablePlus);
