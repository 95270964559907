import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const NumberedListIcon: CreateIconType = createIcon({
  displayName: 'NumberedListIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: [
    <path d="M7 3L15 3" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />,
    <path d="M3 1V4" stroke="currentColor" strokeWidth="1.15" strokeLinecap="round" strokeLinejoin="round" />,
    <path
      d="M2 2H2.25C2.66421 2 3 1.55229 3 1"
      stroke="currentColor"
      strokeWidth="1.15"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path d="M4 4L2 4" stroke="currentColor" strokeWidth="1.15" strokeLinecap="round" strokeLinejoin="round" />,
    <path d="M7 8H15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />,
    <path
      d="M4 9H2V8.57385C2 8.27587 2.17639 8.00616 2.44939 7.88673L3.56227 7.39984C3.82818 7.28351 4 7.02079 4 6.73055C4 6.32708 3.67292 6 3.26945 6H2.75C2.42344 6 2.14564 6.2087 2.04268 6.5"
      stroke="currentColor"
      strokeWidth="1.15"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path d="M7 13H15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />,
    <path
      d="M2 13.5C2.10522 13.7913 2.38907 14 2.72274 14H3.23366C3.65691 14 4 13.6642 4 13.25V13.125C4 12.7108 3.65691 12.375 3.23366 12.375L2.97816 12.375H3.16974C3.55774 12.375 3.87225 12.0672 3.87225 11.6875C3.87225 11.3078 3.55774 11 3.16974 11L2.78657 11C2.46509 11 2.19396 11.2114 2.11058 11.5"
      stroke="currentColor"
      strokeWidth="1.15"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
