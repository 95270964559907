import { graphql } from 'src/graphql';

export const Booking = graphql(`
  query Booking($id: ObjectID!) {
    unmoderatedBooking(_id: $id) {
      _id
      created
      updated
      name
      type
      status
      total_participants
      config {
        options {
          review_submission
        }
        unmoderated {
          welcome_block {
            type
            title
            instructions
          }
          thank_you_block {
            type
            title
            instructions
          }
          task_blocks {
            _id
            type
            title
            instructions
            is_recording_enabled

            ... on TaskBlockFigmaPrototype {
              figma_prototype {
                file_id
                goal_screen_id
                start_screen_id
              }
            }

            ... on TaskBlockMultipleChoiceQuestion {
              choices
            }
          }
        }
      }
    }
  }
`);
