import { Check, Figma } from '@askable/ui/components/unmod/icons';
import { PermissionsList } from '@askable/ui/components/unmod/permissions-list';
import { TaskCard, TaskCardHeader, TaskCardNavigation } from '@askable/ui/components/unmod/task-card';
import { TaskCardDraggable, TaskCardDraggableContent } from '@askable/ui/components/unmod/task-card-draggable';
import { cn } from '@askable/ui/lib/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useTaskNavigation } from 'containers/Studies/Preview/hooks/useTaskNavigation';
import { FigmaPreview } from 'containers/Studies/components/FigmaPreview';

import type {
  SystemBlock,
  TaskBlockFigmaPrototype,
  TaskBlockMultipleChoiceQuestion,
  UnmoderatedBookingConfig,
} from 'generated/graphql';

const welcomeBlock = {
  _id: 'welcome',
  instructions: '',
  title: '',
  type: 'welcome',
} as SystemBlock;

interface TaskContainerProps {
  studyConfig: UnmoderatedBookingConfig;
}

const getActiveTask = (config: UnmoderatedBookingConfig, activeBlockId: string | null) => {
  if (activeBlockId === 'welcome') {
    return config.welcome_block as SystemBlock;
  }

  if (activeBlockId === 'thank_you') {
    return config.thank_you_block as SystemBlock;
  }

  return (
    (config.task_blocks.find(task => task?._id === activeBlockId) as
      | TaskBlockFigmaPrototype
      | TaskBlockMultipleChoiceQuestion) ?? welcomeBlock
  );
};

export const TaskContainer = ({ studyConfig }: TaskContainerProps) => {
  const { t } = useTranslation();
  const { activeBlockId, currentStep, totalSteps, isFirstScreen, isLastScreen, handleProgress } =
    useTaskNavigation(studyConfig);
  const activeBlock = getActiveTask(studyConfig, activeBlockId);
  const [isCardCollapsed, setIsCardCollapsed] = useState(true);
  const [isGoalScreen, setIsGoalScreen] = useState(false);

  const isRecordingEnabled =
    (activeBlock.type === 'welcome' && studyConfig.task_blocks.some(task => task?.is_recording_enabled)) ?? false;

  const handleSelectedNode = (nodeId: string) => {
    const goalScreenReached =
      'figma_prototype' in activeBlock && nodeId === activeBlock.figma_prototype?.goal_screen_id;
    setIsGoalScreen(goalScreenReached);
    if (goalScreenReached) {
      setIsCardCollapsed(false);
    }
  };

  const id = '_id' in activeBlock ? activeBlock._id : activeBlock.type;

  return (
    <div
      className={cn('h-full w-full p-4 lg:p-6', {
        'p-0 lg:p-0': activeBlock.type === 'figma_prototype',
      })}
    >
      {activeBlock.type === 'figma_prototype' ? (
        <>
          {'figma_prototype' in activeBlock && !activeBlock.figma_prototype?.file_id ? (
            <div className="drag-container relative h-full w-full @container">
              <div className="h-full w-full bg-prototype p-6">
                <div className="flex h-full w-full items-center justify-center">
                  <div className="flex flex-col items-center gap-3 text-center font-semibold opacity-50">
                    <Figma size={48} />
                    {t('sections.studies.previewNoPrototype')}
                  </div>
                </div>
                <TaskCardDraggable
                  buttonLabel={t('sections.studies.endTask')}
                  collapseLabel={t('sections.studies.hideInstructions')}
                  id={activeBlockId}
                  isCollapsed={isCardCollapsed}
                  isDisabled
                  onCollapse={setIsCardCollapsed}
                  subtitle={t('sections.studies.tasks', { count: 1 })}
                >
                  <TaskCardDraggableContent instructions={activeBlock.instructions} title={activeBlock.title} />
                </TaskCardDraggable>
              </div>
            </div>
          ) : null}

          {'figma_prototype' in activeBlock && activeBlock.figma_prototype?.file_id ? (
            <FigmaPreview
              key={activeBlockId}
              previewMode
              activeBlockId={activeBlockId}
              fileId={activeBlock.figma_prototype.file_id}
              startScreenId={activeBlock.figma_prototype.start_screen_id ?? undefined}
              onSelectedNode={handleSelectedNode}
            >
              <TaskCardDraggable
                buttonLabel={isGoalScreen ? t('global.continue') : t('sections.studies.endTask')}
                collapseLabel={!isGoalScreen ? t('sections.studies.hideInstructions') : undefined}
                id={activeBlockId}
                isCollapsed={isCardCollapsed}
                isDisabled
                isDraggable={!isGoalScreen}
                onCollapse={setIsCardCollapsed}
                subtitle={t('sections.studies.tasks', { count: 1 })}
              >
                {isGoalScreen ? (
                  <div className="flex flex-col gap-2 pt-4">
                    <Check bgColor="success" />
                    <TaskCardDraggableContent
                      instructions={t('sections.studies.taskSuccessDescription')}
                      title={t('sections.studies.taskSuccessTitle')}
                    />
                  </div>
                ) : (
                  <TaskCardDraggableContent instructions={activeBlock.instructions} title={activeBlock.title} />
                )}
              </TaskCardDraggable>
            </FigmaPreview>
          ) : null}
        </>
      ) : (
        <TaskCard
          id={id}
          footerSlot={
            <>
              {isRecordingEnabled ? (
                <div className="px-4 md:px-8">
                  <PermissionsList
                    description={t('sections.studies.permissions.description')}
                    permissions={[
                      { key: 'camera', description: t('sections.studies.permissions.camera') },
                      {
                        key: 'microphone',
                        description: t('sections.studies.permissions.microphone'),
                      },
                    ]}
                  />
                </div>
              ) : null}

              <TaskCardNavigation
                isNextDisabled={isLastScreen}
                isPreviousDisabled={isFirstScreen}
                totalSteps={totalSteps}
                step={currentStep}
                labels={{
                  complete: t('sections.studies.complete'),
                  getStarted: t('sections.studies.getStarted'),
                  next: t('sections.studies.next'),
                  previous: t('sections.studies.previous'),
                }}
                handleProgress={handleProgress}
              />
            </>
          }
        >
          <TaskCardHeader instructions={activeBlock.instructions} title={activeBlock.title} type={activeBlock.type} />
        </TaskCard>
      )}
    </div>
  );
};
