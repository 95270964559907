import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const ColumnsIcon: CreateIconType = createIcon({
  displayName: 'ColumnsIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: [
    <path
      d="M0.857147 11.4236C0.857147 13.2066 2.22159 14.7143 3.99533 14.896C5.29886 15.0295 6.63656 15.1428 8 15.1428C9.36344 15.1428 10.7011 15.0295 12.0047 14.896C13.7784 14.7143 15.1429 13.2066 15.1429 11.4236V4.57637C15.1429 2.79336 13.7784 1.28567 12.0047 1.10401C10.7011 0.970504 9.36344 0.857117 8 0.857117C6.63656 0.857117 5.29886 0.970504 3.99533 1.10401C2.22159 1.28567 0.857147 2.79336 0.857147 4.57637V11.4236Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M5.71429 0.964294V15.0358"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M10.2857 0.964294V15.0358"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
