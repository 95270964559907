import { createContext, useContext, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { useStudyContext } from '../StudiesContainer';
import { getPageTitle } from '../utils/getPageTitle';

import { ResultsHeader } from './components/ResultsHeader';
import { RecordingContentBasic } from './containers/RecordingContentBasic';
import { ResultsNavigation } from './containers/ResultsNavigation';
// @todo replace with real data
import { dummyResults } from './dummyResults';

// @todo get types from API
export type BlockType = 'figma_prototype' | 'multiple_choice_question' | 'open_answer' | 'opinion_scale';
export type StatType = 'successful' | 'incomplete' | 'responses' | 'misclickRate' | 'averageDuration';
export type TaskSectionType = 'success' | 'incomplete';

export interface StatProps {
  subtitle?: string;
  type: StatType;
  value: number | string;
}

export interface ClicksProps {
  x: number;
  y: number;
}

export interface ScreenProps {
  clicks: ClicksProps[];
  duration?: number;
  height: number;
  id: string;
  image: string;
  misclickRate?: number;
  responses?: number;
  thumbnail: string;
  title: string;
  width: number;
}

export interface TaskSectionProps {
  duration: number;
  id: string;
  misclickRate: number;
  responses: number;
  screens: ScreenProps[];
  title: string;
  type: TaskSectionType;
}

interface TaskProps {
  id: string;
  responses: number;
  sections: TaskSectionProps[];
  stats: StatProps[];
  title: string;
  type: BlockType;
}

export interface ParticipantResponse {
  id: string;
  type: BlockType;
  question: string;
  answer: {
    id: string;
    value: string;
  };
}

export interface Recording {
  url: string;
  duration: number;
  screens: ScreenProps[];
}

export interface ParticipantProps {
  completed: number;
  duration: number;
  id: string;
  misclickRate: number;
  name: string;
  outcome: TaskSectionType;
  screensViewed: number;
  status: string;
  thumbnail: string;
  recording: Recording | null;
  responses: ParticipantResponse[];
  details: {
    age: number;
    location: string;
    gender: string;
  };
  screenerQuestions: {
    answer: string;
    eligible: boolean;
    id: string;
    question: string;
  }[];
  studyDetails: {
    completionDate: number;
    duration: number;
    deviceType: string;
    os: string;
  };
}

export interface ResultsProps {
  id: string;
  name: string;
  status: string;
  participants: ParticipantProps[];
  tasks: TaskProps[];
  completed: number;
  created: number;
}

type ResultsContextProps = {
  results: ResultsProps | null;
};

const ResultsContext = createContext<ResultsContextProps>({
  results: null,
});

export const useResultsContext = () => {
  return useContext(ResultsContext);
};

export const Results = () => {
  const params = useParams();
  const { study } = useStudyContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { participantId, taskId } = params;

  const resultsContextValue = useMemo<ResultsContextProps>(
    () => ({
      results: dummyResults,
    }),
    [],
  );

  useEffect(() => {
    if ((!taskId && study?.config?.unmoderated?.task_blocks?.length) || 0 > 0) {
      navigate(`/studies/${study._id}/results/task/${study.config?.unmoderated?.task_blocks?.[0]?._id}`, {
        replace: true,
      });
    }
  }, [taskId, study?.config?.unmoderated?.task_blocks, navigate, study?._id]);

  return (
    <>
      <Helmet>
        <title>{getPageTitle(t('sections.studies.tabs.results'), resultsContextValue?.results?.name ?? '')}</title>
      </Helmet>

      <ResultsContext.Provider value={resultsContextValue}>
        <ResultsHeader />
        <ResultsNavigation active={participantId ? 'participants' : 'blocks'} showParticipants={false} />
        <main className="main">
          <Outlet />
        </main>
        <RecordingContentBasic />
      </ResultsContext.Provider>
    </>
  );
};
