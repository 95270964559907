import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const PeopleIcon: CreateIconType = createIcon({
  displayName: 'PeopleIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: [
    <path
      d="M1.52568 10.141C1.29481 9.45579 1.16968 8.72192 1.16968 7.95883C1.16968 4.1865 4.22775 1.12842 8.00009 1.12842C11.7724 1.12842 14.8305 4.1865 14.8305 7.95883C14.8305 8.72192 14.7054 9.45579 14.4746 10.141"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />,
    <path
      d="M8.00166 9.25553C9.39725 9.25553 10.5286 8.12419 10.5286 6.7286C10.5286 5.33301 9.39725 4.20166 8.00166 4.20166C6.60607 4.20166 5.47473 5.33301 5.47473 6.7286C5.47473 8.12419 6.60607 9.25553 8.00166 9.25553Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M11.4903 12.2235C12.9509 13.0663 12.0253 14.8716 10.3389 14.8716H5.6609C3.97455 14.8716 3.04888 13.0663 4.50955 12.2235C5.53678 11.6309 6.72875 11.2917 7.99991 11.2917C9.27107 11.2917 10.463 11.6309 11.4903 12.2235Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />,
  ],
});
