import { getColorValue } from '@askable/ui/lib/utils';

import type { IconProps } from '.';

export const Hand = ({ bgColor = 'foreground', fgColor = 'background', size = 48 }: IconProps) => {
  const bgHsl = getColorValue(bgColor);
  const fgHsl = getColorValue(fgColor);

  return (
    <svg fill="none" viewBox="0 0 64 64" width={size} height={size}>
      <g fillRule="evenodd" clipPath="url(#a)" clipRule="evenodd">
        <path
          fill={bgHsl}
          d="M41.3 11.4c-1.3-.9 1.2-1.6-.4-1.1-1.5.5-1-.6-2 .8l-.3.6c-.3-1-1-1.8-1.8-2.4a4.6 4.6 0 0 0-4-.4 7 7 0 0 0-3.6 2.8L18.5 28l-.2-1.2c-.2-1.5-3-.6-4.2-1.3-1.2-.8-.8-3.2-2.3-2.8a7 7 0 0 0-3.6 2.7A7 7 0 0 0 6.9 30l1.4 12.5c.3 2.7 1 5.1 2.2 7.3a18 18 0 0 0 6 6l3.6 2.5c4.3 2.8 9.6 3.5 15 1.9 5.2-1.6 10-5.3 13.2-10.2l9-13.4a7 7 0 0 0 1-4.6c0-1.6-.9-3-2.1-3.8a5 5 0 0 0-3.8-.7l.2-.3c.9-1.3-13.1 4.2-13.3 2.7-.2-1.5 3-5 1.7-6-1.2-.7 7.8-5.8 6.4-5.5l.7-1.1c1-1.4-14.5 3-14.6 1.3-.2-1.6 9-6.3 7.8-7.1Z"
        />
        <path
          fill={fgHsl}
          d="M43 5.5c-1.3-.9-2.5-.4-4 0-1.6.5-2.5 1.8-3.4 3.2L34 8.5c-.3-1 0-1-.9-1.6a4.6 4.6 0 0 0-4-.4 7 7 0 0 0-3.5 2.8L15 25.6l-.7-2.6c-.9-1.1-1-1.6-2.2-2.3-1.2-.8-2.7-1-4.2-.5a7 7 0 0 0-3.7 2.7c-.9 1.3-.3 1-.2 2.5-.1 1 1.2 3.3-.3 2.5L5 39l.4 1c.3 2.7 1 4 2.3 6.3 1.3 2.2 2.7 3.8 5.4 5.6l3 2.4c4.2 2.8 9.6 3.5 14.9 1.9 5.2-1.6 10-5.3 13.3-10.2l8.9-13.4a7 7 0 0 0 1.1-4.6c-.1-1.6-1.8-1.7-3-2.6-1.2-.7-3.2-.7-4.5-.5l1.8-1.7a6.8 6.8 0 0 0 1-4.4c-.2-1.5-1.6-2.1-2.9-3-1.1-.7-3-.2-4.5 0l1.9-2.5c.9-1.4 1.3-3 1.2-4.6-.2-1.6-1.2-2.5-2.4-3.3Z"
        />
        <path
          fill={bgHsl}
          d="M14 4.6c.5.4.5 1 0 1.4a13.7 13.7 0 0 0-3.7 6.4 1 1 0 0 1-2-.4c.6-2.5 2-5 4.4-7.4a1 1 0 0 1 1.4 0Zm25 .3c1.6-.5 3.2-.3 4.5.5 1.2.8 2 2.3 2.2 3.8.1 1.6-.3 3.3-1.2 4.7l-.8 1c1.5-.3 2.9 0 4 .7 1.3.9 2.1 2.2 2.3 3.8a7 7 0 0 1-1 4.4l-.2.3a5 5 0 0 1 3.7.7 5 5 0 0 1 2.2 3.8 7 7 0 0 1-1.1 4.5l-9 13.5c-3.2 5-8 8.6-13.2 10.2-5.3 1.6-10.7 1-14.9-1.9l-3.7-2.5a18 18 0 0 1-6-6 20 20 0 0 1-2.2-7.3L3.2 26.6c0-1.6.4-3.2 1.3-4.5a7 7 0 0 1 3.7-2.7c1.4-.4 3-.3 4.2.5a5 5 0 0 1 2.3 3.6l.1 1.2L25.6 8.4A7 7 0 0 1 29 5.6c1.4-.5 2.9-.4 4 .4 1 .6 1.5 1.4 1.8 2.4l.4-.6c1-1.4 2.3-2.5 3.8-3ZM32.2 13l-6.3 9.5a1 1 0 1 0 1.7 1l6.4-9.7c.4-.5.7-1.1.9-1.7L37 8.9a5 5 0 0 1 2.7-2.1c1-.3 2-.2 2.7.3.7.4 1.2 1.3 1.3 2.3 0 1.1-.2 2.3-.9 3.4l-9.1 13.7a1 1 0 1 0 1.7 1.1l5.5-8.4c.2-.4.5-.5.6-.7.6-1 1.5-1.3 2.5-1.6 1-.2 2 0 2.7.4.7.5 1.2 1.3 1.3 2.3a4 4 0 0 1-.7 3.1l-7.1 10.7a1 1 0 1 0 1.7 1.1l4-6 .2-.3a5 5 0 0 1 2.7-2c1-.3 2-.2 2.6.3.7.4 1.2 1.3 1.4 2.3a5 5 0 0 1-.9 3.2L43 45.5c-3 4.6-7.4 8-12.2 9.4-4.8 1.4-9.5.8-13.2-1.7L14 50.8a16 16 0 0 1-5.3-5.4 17 17 0 0 1-2-6.5L5.2 26.4a5 5 0 0 1 1-3.1 5 5 0 0 1 2.5-2c1-.3 2-.1 2.6.3a3 3 0 0 1 1.4 2.2l.7 6a1 1 0 0 0 2-.2l-.3-2.3c.3 0 .5-.2.7-.5L27.3 9.5a5 5 0 0 1 2.4-2c1-.3 1.8-.2 2.4.2.6.3 1 1 1 2l-.1 1.6-.5 1.2-.4.6ZM17.8 8c.3.4.3 1-.2 1.4-1.5 1.2-2.3 3.4-3.2 5.6v.1a1 1 0 1 1-2-.7l.2-.3c.8-2 1.8-4.7 3.8-6.3a1 1 0 0 1 1.4.2Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill={fgHsl} d="M0 0h64v64H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
