import { format } from 'date-fns';
import { alphabetical } from 'radash';

import { ProjectUserStatus } from 'generated/graphql';
import { PROJECT_STATUS_KEY_VALUE, PROJECT_STATUS_VALUE_KEY } from 'lib/constants';

import { CellCreated } from '../components/CellCreated';
import { CellCreatedBy } from '../components/CellCreatedBy';
import { CellName } from '../components/CellName';
import { ColumnHeader } from '../components/ColumnHeader';

import { ProjectsCellActions } from './ProjectsCellActions';
import { ProjectsCellResearcher } from './ProjectsCellResearcher';
import { ProjectsCellStatus } from './ProjectsCellStatus';

import type { ProjectsTableSchemaType } from './ProjectsSchema';
import type { AccessorColumnDef } from '@tanstack/react-table';
import type { ProjectStatus } from 'generated/graphql';

export const columns: AccessorColumnDef<ProjectsTableSchemaType>[] = [
  {
    accessorKey: 'name',
    header: ({ column }) => <ColumnHeader column={column} title="Title" />,
    cell: ({ row }) => {
      const url =
        row.original.status === PROJECT_STATUS_KEY_VALUE.draft
          ? `/askable-plus/${row.original._id}/project-setup/project-title`
          : `/manage-askable-plus-project/${row.original._id}`;

      return <CellName url={url}>{row.getValue('name')}</CellName>;
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorFn: ({ status }) => {
      return PROJECT_STATUS_VALUE_KEY[status as number];
    },
    id: 'status',
    enableResizing: false,
    maxSize: 144,
    minSize: 144,
    size: 144,
    header: ({ column, table }) => (
      <ColumnHeader disableSortByPageCount={table.getPageCount()} column={column} title="Status" />
    ),
    cell: ({ getValue }) => {
      const status = getValue();
      if (!status) {
        return null;
      }

      return <ProjectsCellStatus status={status as ProjectStatus} />;
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorFn: ({ askable_plus }) => askable_plus?.due_date || undefined,
    id: 'closing date',
    enableResizing: false,
    maxSize: 176,
    minSize: 176,
    size: 176,
    sortUndefined: 1,
    header: ({ column, table }) => (
      <ColumnHeader disableSortByPageCount={table.getPageCount()} column={column} title="Closing date" />
    ),
    cell: ({ getValue }) => {
      const dueDate = getValue();

      if (!dueDate) {
        return null;
      }

      return <div className="text-muted-foreground">{format(dueDate as number, 'dd MMM yy')}</div>;
    },
  },
  {
    accessorKey: 'created',
    enableResizing: false,
    maxSize: 160,
    minSize: 160,
    size: 160,
    header: ({ column, table }) => (
      <ColumnHeader disableSortByPageCount={table.getPageCount()} column={column} title="Created date" />
    ),
    cell: ({ getValue }) => {
      const created = getValue();
      if (!created) {
        return null;
      }
      return <CellCreated created={created as number} />;
    },
  },
  {
    accessorFn: ({ users }) =>
      alphabetical(
        users
          ?.filter(user => user?.status === ProjectUserStatus.Accepted)
          .map(
            user => `${user?.User?.meta?.identity?.firstname || ''} ${user?.User?.meta?.identity?.lastname || ''}`,
          ) || [],
        n => n,
      ).join(', ') || undefined,
    sortUndefined: 1,
    id: 'researcher',
    enableResizing: false,
    maxSize: 160,
    minSize: 160,
    size: 160,
    header: ({ column, table }) => (
      <ColumnHeader disableSortByPageCount={table.getPageCount()} column={column} title="Researcher" />
    ),
    cell: ({ getValue }) => {
      const value = getValue() as string;
      if (!value) {
        return null;
      }
      return <ProjectsCellResearcher>{value}</ProjectsCellResearcher>;
    },
  },
  {
    id: 'created by',
    enableResizing: false,
    maxSize: 160,
    minSize: 160,
    size: 160,
    accessorFn: ({ owner }) => {
      return owner?.displayName;
    },
    header: ({ column, table }) => (
      <ColumnHeader disableSortByPageCount={table.getPageCount()} column={column} title="Created by" />
    ),
    cell: ({ getValue }) => {
      const displayName = getValue();

      if (!displayName) {
        return null;
      }
      return <CellCreatedBy displayName={displayName as string} />;
    },
  },
  {
    accessorKey: 'actions',
    enableResizing: false,
    maxSize: 56,
    minSize: 56,
    size: 56,
    cell: ({ row }) => (
      <ProjectsCellActions id={row.original._id} name={row.original.name} status={row.original.status} />
    ),
    header: '',
    enableHiding: false,
  },
];
