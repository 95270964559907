/* eslint-disable max-lines */
import { useMutation } from '@apollo/client';
import { toast } from '@askable/ui/components/ui/sonner';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import { useFeatureFlags } from 'feature-flags';
import _ from 'lodash';
import momentTimezone from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import { LoadingOverlay } from 'components/common';
import { useAppContext } from 'components/common/Askable/Providers/appProvider';
import { useConnectedClient } from 'context/ConnectedClientContext';
import updateAskablePlusDetailsMutation from 'data/mutations/project/updateAskablePlusDetails';
import fetchProjectsByTeam from 'data/queries/project/fetchProjectsByTeam';
import { bookingUtils } from 'lib/booking';
import { BOOKING_TYPE, DEFAULT_QUOTA } from 'lib/constants';
import { localStorage } from 'lib/storage';
import { update, utils } from 'lib/utils';

import AdditionalNotsScreen from './additionalInfo/additionalNotes';
import LegalPrivacyScreen from './additionalInfo/legalPrivacy';
import LinkToAssetsScreen from './additionalInfo/linkToAssets';
import ModerationGuideScreen from './additionalInfo/moderationGuide';
import YourTimezoneScreen from './additionalInfo/yourTimezone';
import CustomScreenerScreen from './audience/customScreener';
import DemographicFiltersScreen from './audience/demographicFilters';
import IncentivesScreen from './audience/incentives';
import ParticipantCriteriaScreen from './audience/participantCriteria';
import ParticipantLocationsScreen from './audience/participantLocations';
import ParticipantsLocationsMultiRegion from './audience/participantLocationsMultiRegion';
import CompetitiveAnalysisQuotaScreen from './competitiveAnalysis/quota';
import { CreateProjectMutation } from './data/CreateProject.mutation';
import DiscoveryInterviewMeetingFormatScreen from './discoveryInterview/meetingFormat';
import DiscoveryInterviewQuotaScreen from './discoveryInterview/quota';
import DiscoveryInterviewSessionDuration from './discoveryInterview/sessionDuration';
import DiscoveryInterviewSessionFormatScreen from './discoveryInterview/sessionFormat';
import LongitudinalStudyQuotaScreen from './longitudinalStudy/quota';
import LongitudinalStudyDurationScreen from './longitudinalStudy/studyDuration';
import DeliverablesScreen from './projectSetup/deliverables';
import DueDateScreen from './projectSetup/dueDate';
import FrequencyDurationScreen from './projectSetup/frequencyDuration';
import MeetingFormatScreen from './projectSetup/meetingFormat';
import ProjectBriefScreen from './projectSetup/projectBrief';
import ProjectTitleScreen from './projectSetup/projectTitle';
import ProjectQuotaScreen from './projectSetup/quota';
import ResearchTypeScreen from './projectSetup/researchType';
import SessionDurationScreen from './projectSetup/sessionDuration';
import SessionFormatScreen from './projectSetup/sessionFormat';
import ConfirmAskablePlusBriefScreen from './reviewSubmit/confirmBrief';
import CreateAskablePlusBriefStepperMenu from './stepperMenu/stepperMenu';
import { stepperMenuUtils } from './stepperMenu/stepperMenuUtils';
import SurveyQuotaScreen from './survey/quota';
import SurveyDurationScreen from './survey/surveyDuration';
import UsabilityTestingMeetingFormatScreen from './usabilityTesting/meetingFormat';
import UsabilityTestingQuotaScreen from './usabilityTesting/quota';
import UsabilityTestingSessionDurationScreen from './usabilityTesting/sessionDuration';

import './styles/createAskablePlusBriefStyles.scss';

function CreateAskablePlusWithData(props: any) {
  const { MULTIREGION_COUNTRIES } = useFeatureFlags(['MULTIREGION_COUNTRIES']);
  const screenPages = {
    'project-setup': {
      'project-title': ProjectTitleScreen,
      'project-brief': ProjectBriefScreen,
      'research-type': ResearchTypeScreen,
      deliverables: DeliverablesScreen,
      'due-date': DueDateScreen,
      quota: ProjectQuotaScreen,
      'session-duration': SessionDurationScreen,
      'meeting-format': MeetingFormatScreen,
      'session-format': SessionFormatScreen,
      'frequency-duration': FrequencyDurationScreen,
    },
    'discovery-interview': {
      quota: DiscoveryInterviewQuotaScreen,
      'session-duration': DiscoveryInterviewSessionDuration,
      'meeting-format': DiscoveryInterviewMeetingFormatScreen,
      'session-format': DiscoveryInterviewSessionFormatScreen,
    },
    'usability-testing': {
      quota: UsabilityTestingQuotaScreen,
      'session-duration': UsabilityTestingSessionDurationScreen,
      'meeting-format': UsabilityTestingMeetingFormatScreen,
    },
    'competitive-analysis': {
      quota: CompetitiveAnalysisQuotaScreen,
    },
    survey: {
      quota: SurveyQuotaScreen,
      'survey-duration': SurveyDurationScreen,
    },
    'longitudinal-study': {
      quota: LongitudinalStudyQuotaScreen,
      'study-duration': LongitudinalStudyDurationScreen,
    },
    audience: {
      'participant-criteria': ParticipantCriteriaScreen,
      'demographic-filters': DemographicFiltersScreen,
      'custom-screener': CustomScreenerScreen,
      incentives: IncentivesScreen,
      'participant-locations': MULTIREGION_COUNTRIES ? ParticipantsLocationsMultiRegion : ParticipantLocationsScreen,
    },
    'additional-info': {
      'link-to-assets': LinkToAssetsScreen,
      'moderation-guide': ModerationGuideScreen,
      'your-timezone': YourTimezoneScreen,
      'legal-privacy': LegalPrivacyScreen,
      'additional-notes': AdditionalNotsScreen,
    },
    'review-submit': {
      'confirm-brief': ConfirmAskablePlusBriefScreen,
    },
  };
  const intercom = useIntercom();
  const { details } = useConnectedClient();

  const [createProject] = useMutation(CreateProjectMutation);
  const [updateAskablePlusDetails] = useMutation(updateAskablePlusDetailsMutation);
  const { CONTINUOUS_DISCOVERY } = useFeatureFlags(['CONTINUOUS_DISCOVERY'], {
    application: 'clients',
  });

  const params = useParams();
  const screen = params.screen;
  const subScreen = params?.subScreen;
  const predefinedCompletedBy = momentTimezone(momentTimezone().add(20, 'days')).endOf('day');

  const context = useAppContext();
  const [askablePlusBrief, setAskablePlusBrief] = useState(props.project);
  const [askablePlusBriefSteps, setAskablePlusBriefSteps] = useState({
    project_setup_project_title: 'seen',
  });
  const [loading, setLoading] = useState(false);
  const [rightContent, setRightContent] = useState(null);
  const [lastStep, setLastStep] = useState({
    lastStepOpened: '',
    lastStepGroup: [],
  });

  useEffect(() => {
    if (props.subscribeToUpdateProject) props.subscribeToUpdateProject();
    if (_.get(props, 'project') && _.get(props.project, 'status') !== 0) {
      props.history.push({ path: '/' });
    }

    if (!props.project) {
      setAskablePlusBrief({
        _user_id: details?._id,
        _team_id: details?.team?.id,
        name: `Askable research project - created at ${momentTimezone().format('DD/MM/YYYY hh:mm')}`,
        type: 1,
        status: 0,
        askable_plus: {
          due_date: predefinedCompletedBy.valueOf(),
          continuous_duration: 12,
          meetings: {
            briefing: {},
            debriefing: {},
          },
          researcher_deliverables: {},
          additional_info: {
            timezone: bookingUtils.getCurrentTimezone(),
            moderation_guide_type: 1,
            nda_guide_type: 1,
          },
          research_type: {
            discovery: {
              ...(!CONTINUOUS_DISCOVERY
                ? {
                    quota: DEFAULT_QUOTA.REMOTE,
                    booking_type: BOOKING_TYPE.REMOTE,
                    session: {
                      slot_min: 1,
                      slot_max: 1,
                      duration: 45,
                      type: 1,
                      time_limit: 45,
                    },
                  }
                : { session: {} }),
            },
            competitive_analysis: {},
            longitudinal: {
              longitudinal_study: {
                participant_workload: {},
                period: {},
              },
            },
            survey: {
              session: {},
            },
            usability: {
              session: {},
            },
            ...(CONTINUOUS_DISCOVERY
              ? {
                  continuous_ai_moderated: {
                    quota: 20,
                    continuous: true,
                    booking_type: BOOKING_TYPE.ONLINE,
                    session: {
                      slot_min: 1,
                      slot_max: 1,
                      duration: 20,
                      type: 1,
                      time_limit: 20,
                    },
                  },
                  continuous_researcher_moderated: {
                    quota: 5,
                    continuous: true,
                    booking_type: BOOKING_TYPE.REMOTE,
                    session: {
                      slot_min: 1,
                      slot_max: 1,
                      duration: 45,
                      type: 1,
                      time_limit: 45,
                    },
                  },
                }
              : {
                  continuous_ai_moderated: { session: {} },
                  continuous_researcher_moderated: { session: {} },
                }),
          },
          deliverables: {
            research_report: true,
            ...(CONTINUOUS_DISCOVERY ? { transcriptions: true } : {}),
          },
          audience: {
            booking_config: {
              participant_category: 1,
              location: {
                country:
                  details?.ConnectedTeam?.settings?.billing?.location?.country ?? localStorage.get('countryByIp'),
              },
              criteria: {
                custom: [],
                locations: {
                  states: [],
                  bounds: [],
                  countries: MULTIREGION_COUNTRIES ? [] : undefined,
                },
              },
              incentive: {},
              question: [],
            },
          },
          steps: {
            project_setup_project_title: 'seen',
          },
        },
      });
    } else {
      setAskablePlusBriefSteps(_.get(props.project, 'askable_plus.steps'));
    }
  }, []);

  useEffect(() => {
    // Set intercom bubble position
    if (rightContent) {
      intercom.update({ horizontalPadding: 295 });
    } else {
      intercom.update({ horizontalPadding: 20 });
    }
  }, [screen, rightContent]);

  const saveAskablePlusProject = async () => {
    if (!_.get(askablePlusBrief, '_id')) return null;
    setLoading(true);
    const briefObj = {
      ..._.omit(askablePlusBrief, ['steps', 'team', 'users', 'owner', 'bookings', 'pricing', 'rating', 'admin']),
      askable_plus: {
        ...askablePlusBrief.askable_plus,
        research_type: {
          ...utils.omitValueRecursively(askablePlusBrief.askable_plus?.research_type, 'Booking'),
        },
        steps: {
          ...askablePlusBrief.steps,
        },
      },
    };
    await updateAskablePlusDetails({
      variables: {
        _project_id: askablePlusBrief._id,
        project: utils.removeTypenames(briefObj),
      },
    }).then(() => {
      setLoading(false);
    });
  };

  const createAskablePlusProject = async (redirectTo = null) => {
    if (_.get(askablePlusBrief, '_id')) return null;
    setLoading(true);

    updateAskablePlusBriefStepsState({ project_setup_project_title: 'seen' });
    const refetchQuery = {
      query: fetchProjectsByTeam,
      variables: {
        _team_id: details?.team?.id,
        type: [1],
        status: [0, 1, 2, 3, 4, 5, 6],
        sortBy: 'created',
        order: -1,
      },
    };
    try {
      const projectCreated = await createProject({
        variables: {
          project: askablePlusBrief,
        },
        refetchQueries: [refetchQuery],
      });

      const updatedBriefObj = {
        ...askablePlusBrief,
        _id: projectCreated?.data?.createProject?._id,
      };

      updateAskablePlusBriefState(updatedBriefObj);
      setLoading(false);

      if (redirectTo) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'replace' does not exist on type 'never'.
        const redirectToLink = redirectTo.replace(':projectID', projectCreated?.data?.createProject?._id);
        props.history.push({ pathname: redirectToLink });
      }
    } catch (e: any) {
      setLoading(false);
      toast.error(_.get(e, 'graphQLErrors[0].message') ? e.graphQLErrors[0].message : 'Error creating project');
    }
  };

  const updateAskablePlusBriefState = (askablePlusBriefState: any) => {
    setAskablePlusBrief(askablePlusBriefState);
  };

  const updateAskablePlusBriefStepsState = (askablePlusBriefStepsState: any) => {
    setAskablePlusBriefSteps(askablePlusBriefStepsState);
  };

  const updateLastStep = async ({ step, subStep, stepId }: any) => {
    setLoading(true);

    let stepsToSave = _.clone(lastStep.lastStepGroup);
    const savedStepIndex = _.findIndex(stepsToSave, (item: any) => item.step === step);
    if (savedStepIndex >= 0) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'subStep' does not exist on type 'never'.
      stepsToSave[savedStepIndex].subStep = subStep;
    } else {
      stepsToSave = update(stepsToSave, {
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ step: any; subStep: any; }[]' is not assig... Remove this comment to see the full error message
        $push: [{ step, subStep }],
      });
    }
    setLastStep({
      lastStepOpened: subStep,
      lastStepGroup: stepsToSave,
    });

    const askablePlusBriefStateObj = {
      ...askablePlusBrief,
      steps: askablePlusBriefSteps,
    };

    let validatedSteps = askablePlusBriefSteps;
    validatedSteps = stepperMenuUtils.validateAskablePlusBriefSteps(
      askablePlusBriefStateObj,
      askablePlusBriefSteps,
      subStep,
      MULTIREGION_COUNTRIES,
    );
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (stepId) validatedSteps[stepId] = _.get(askablePlusBriefSteps, `[${stepId}]`) === 'error' ? 'error' : 'seen';

    const updatedAskablePlusBriefObj = {
      ...askablePlusBriefStateObj,
      steps: validatedSteps,
    };

    updateAskablePlusBriefState(updatedAskablePlusBriefObj);
    updateAskablePlusBriefStepsState(validatedSteps);
    // Do not save project if you just open the page
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
    if (_.get(lastStep, 'lastStepOpened')) saveAskablePlusProject(updatedAskablePlusBriefObj);
    setLoading(false);
  };

  const saveAskablePlusBriefValidation = (askablePlusBrieData: any, link: any) => {
    if (!link) return null;
    const validatedSteps = stepperMenuUtils.validateAskablePlusBriefSteps(
      askablePlusBrieData,
      askablePlusBriefSteps,
      link,
      MULTIREGION_COUNTRIES,
    );
    if (validatedSteps) {
      const askablePlusBriefState = {
        ...askablePlusBrieData,
        steps: validatedSteps,
      };
      updateAskablePlusBriefStepsState(validatedSteps);
      updateAskablePlusBriefState(askablePlusBriefState);
    }
  };

  const renderLeftSidebar = () => {
    return (
      <div className="createAskablePlusBriefLeftSidebar">
        <CreateAskablePlusBriefStepperMenu
          askablePlusBrief={askablePlusBrief}
          askablePlusBriefSteps={askablePlusBriefSteps}
          createAskablePlusBrief={createAskablePlusProject}
          updateAskablePlusBrief={saveAskablePlusProject}
          validateAskablePlusBrief={saveAskablePlusBriefValidation}
          client={_.get(props, 'client')}
          context={context}
          updateLastStep={updateLastStep}
          lastStep={_.get(lastStep, 'lastStepGroup') || []}
          updateAskablePlusBriefState={updateAskablePlusBriefState}
          updateAskablePlusBriefStepsState={updateAskablePlusBriefStepsState}
        />
      </div>
    );
  };

  const renderContentPage = (page: string, subPage: string) => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return React.createElement(screenPages[page][subPage], {
      renderRightContent: (content: any) => setRightContent(content),
      askablePlusBrief,
      project: props.project,
      askablePlusBriefSteps,
      createAskablePlusBrief: createAskablePlusProject,
      updateAskablePlusBrief: saveAskablePlusProject,
      validateAskablePlusBrief: saveAskablePlusBriefValidation,
      client: _.get(props, 'client'),
      context,
      updateLastStep,
      lastStep: _.get(lastStep, 'lastStepGroup') || [],
      updateAskablePlusBriefState,
      updateAskablePlusBriefStepsState,
    });
  };

  const renderContent = () => {
    switch (screen) {
      case 'create':
        return (
          <ProjectTitleScreen
            updateAskablePlusBriefState={updateAskablePlusBriefState}
            renderRightContent={setRightContent}
            askablePlusBrief={askablePlusBrief}
            askablePlusBriefSteps={askablePlusBriefSteps}
            createAskablePlusBrief={createAskablePlusProject}
            updateLastStep={updateLastStep}
          />
        );
      default:
        return renderContentPage(screen!, subScreen!);
    }
  };

  const renderRightSidebar = () => {
    return <div className="createAskablePlusBriefRightSidebar">{rightContent}</div>;
  };

  return (
    <div className="createAskablePlusBriefContainer">
      {loading && <LoadingOverlay style={{ opacity: 0.8 }} />}
      {renderLeftSidebar()}
      <div className="createAskablePlusBriefContentContainer">{renderContent()}</div>
      {rightContent && renderRightSidebar()}
    </div>
  );
}

export default deprecatedWithRouter(CreateAskablePlusWithData);
