/* eslint-disable max-lines */
import { useQuery } from '@apollo/client';
import { Badge } from '@askable/ui/components/ui/badge';
import { Button } from '@askable/ui/components/ui/button';
import { Checkbox } from '@askable/ui/components/ui/checkbox';
import { Command, CommandEmpty, CommandInput, CommandItem, CommandList } from '@askable/ui/components/ui/command';
import { Label } from '@askable/ui/components/ui/label';
import { Popover, PopoverContent, PopoverTrigger } from '@askable/ui/components/ui/popover';
import { RadioGroup, RadioGroupItem } from '@askable/ui/components/ui/radio-group';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@askable/ui/components/ui/select';
import { cn } from '@askable/ui/lib/utils';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { CirclePlus, ChevronDown } from 'lucide-react';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { AgeRange } from 'components/Form/AgeRange';
import CardContainer from 'components/createBooking/components/cardContainer';
import IndustryDropdown from 'components/createBooking/components/industryDropdown';
import PriceCardContainer from 'components/createBooking/components/priceCardContainer';
import fetchIndustryList from 'data/queries/industry_data/fetchIndustryList';
import { bookingUtils } from 'lib/booking';
import { utils, update } from 'lib/utils';
import { getDemographicAgeDefaultValue } from 'utils/booking-utils';

import type { Booking } from 'generated/graphql';

import './styles/demographicFiltersStyles.scss';

function DemographicFilters(props: any) {
  const { data } = useQuery(fetchIndustryList);
  const industries = _.get(data, 'industryList');
  const currentYear = utils.getCurrentYear();
  const booking = _.get(props, 'booking');

  const [, setExcludeParticipantsFromTime] = useState();
  const [allDemographicFiltersSaved, setAllDemographicFiltersSaved] = useState([]);
  const [bookingData, setBookingData] = useState<Partial<Booking> | null>(null);
  const [bookingState, setBookingState] = useState(booking);
  const [deleteFilter, setDeleteFilter] = useState(null);
  const [demographicFilterOptions, setDemographicFilterOptions] = useState(bookingUtils.demographicFilterOptions());
  const [demographicFiltersSaved, setDemographicFiltersSaved] = useState([]);
  const [industriesSaved, setIndustriesSaved] = useState([]);
  const [newFilterAdded, setNewFilterAdded] = useState(null);
  const [openFilterOptions, setOpenFilterOptions] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);

  useEffect(() => {
    props.updateLastStep({
      step: 'Audience',
      subStep: `/booking-setup/${props.booking._id}/audience/demographic-filters`,
      stepId: 'audience_demographic_filters',
    });
  }, []);

  useEffect(() => {
    setBookingState(_.get(props, 'booking'));
  }, [props.booking]);

  useEffect(() => {
    props.renderRightContent(rightContent());
    props.renderRightAppPreview(null);
    loadDemographicFiltersSaved();
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ _industry_id: any; industry_na... Remove this comment to see the full error message
    setIndustriesSaved(normaliseWorkIndustryArray(_.get(bookingState, 'config.criteria.meta_work_industry')));
    const exclusionType = _.filter(
      bookingUtils.exclusionTypes(),
      (item: any) => item.value === _.get(bookingState, 'config.criteria.exclude_participants_from_time'),
    );
    setExcludeParticipantsFromTime(_.get(exclusionType, '[0].label'));
  }, [booking, bookingState]);

  const onClickNext = () => {
    const redirectTo = `/booking-setup/${booking._id}/audience/incentives`;
    props.history.push({ pathname: redirectTo, booking: bookingData, bookingState });
  };

  const loadDemographicFiltersSaved = async () => {
    const filtersOrder = _.get(bookingState, 'config.criteria.filters_order') || [];
    const allFilters = bookingUtils.demographicFilterOptions();
    setDemographicFilterOptions(allFilters);

    // Load all saved filters
    const allFiltersSaved = await _.filter(allFilters, (item: any) => {
      if (
        _.has(bookingState, `config.criteria[${item.id}]`) &&
        _.get(bookingState, `config.criteria[${item.id}]`) !== null
      ) {
        return item;
      }
    });
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(number | { value: number; label... Remove this comment to see the full error message
    setAllDemographicFiltersSaved(allFiltersSaved);

    let bookingTypeFilters = allFilters;
    if (!bookingUtils.isBookingForProfessionals(bookingState)) {
      bookingTypeFilters = _.filter(allFilters, (item: any) => !_.has(item, 'type'));
    }
    // Load saved filters
    const filtersSaved = await _.filter(bookingTypeFilters, (item: any) => {
      if (
        _.has(bookingState, `config.criteria[${item.id}]`) &&
        _.get(bookingState, `config.criteria[${item.id}]`) !== null
      ) {
        return item;
      }
    });

    // @ts-expect-error ts-migrate(7034) FIXME: Variable 'sortedFilters' implicitly has type 'any[... Remove this comment to see the full error message
    let sortedFilters = [];

    if (_.size(filtersOrder) >= _.size(filtersSaved)) {
      // Save filters on the right order
      const savedFiltersOrder = _.filter(filtersOrder, (order: any) => {
        if (_.find(filtersSaved, (item: any) => item.id === order)) return order;
      });
      sortedFilters = _.map(savedFiltersOrder, (filter: any) => {
        return _.find(filtersSaved, (item: any) => item.id === filter);
      });
    } else if (_.size(filtersOrder) < _.size(filtersSaved)) {
      sortedFilters = filtersSaved;
      const currentFiltersOrder = _.map(filtersSaved, (item: any) => item.id);
      const bookingStateObj = {
        ...bookingState,
        config: {
          ...bookingState.config,
          criteria: {
            ...bookingState.config.criteria,
            filters_order: currentFiltersOrder,
          },
        },
      };
      setBookingState(bookingStateObj);
      const bookingObj = {
        config: {
          ..._.get(bookingData, 'config'),
          criteria: {
            ..._.get(bookingData, 'config.criteria'),
            filters_order: currentFiltersOrder,
          },
        },
      };
      setBookingData(bookingObj);
      props.history.replace({ booking: bookingObj, bookingState: bookingStateObj });
    }

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
    setDemographicFiltersSaved(sortedFilters);
    // setDemographicFilterOptions(_.differenceBy(allFilters, sortedFilters, 'id'));

    if (newFilterAdded) {
      const newFilterIndex = _.findIndex(filtersOrder, (item: any) => item === newFilterAdded);
      // @ts-expect-error ts-migrate(7005) FIXME: Variable 'sortedFilters' implicitly has an 'any[]'... Remove this comment to see the full error message
      handleClickFilter(newFilterIndex >= 0 ? newFilterIndex : _.size(sortedFilters) - 1);
      setNewFilterAdded(null);
    }
  };

  const rightContent = () => {
    const cardTexts0 = [
      {
        cardText: (
          <span>
            Premium demographic filters allow you to reach highly specific groups of participants with the following
            filters: Specifying their level of employment (business owner, freelancer, employee etc…), and filtering
            them by their industry and job title.
          </span>
        ),
      },
    ];
    const cardTexts1 = [
      {
        cardText: (
          <span>
            If you need a specific split (e.g. 80% Female and 20% Male), you&apos;ll need to switch over to{' '}
            <strong>hand picking mode</strong>. This will let you have more control over who you want to invite to
            participant in your research.
          </span>
        ),
      },
    ];
    const cardTexts2 = [
      {
        cardText: (
          <span>
            If you need a specific split (e.g. 80% Female and 20% Male), don&apos;t worry! You get full control over who
            you select to participate in your research so you can manage your quotas at that point
          </span>
        ),
      },
    ];
    return (
      <>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          booking={bookingState}
          bookingSteps={props.bookingSteps}
          team={_.get(props, 'team')}
          context={props.context}
          condensedCard
        />
        <p className="cardContainerTitle additionalTitle">Faqs</p>
        <CardContainer
          cardId="__demographicFiltersCard0"
          cardClass="slideInAnimationDelay80"
          cardTitle="What are the benefits of premium demographic filters?"
          cardTexts={cardTexts0}
          onLinkClick={() => {
            window.open('https://help.askable.com/en/articles/4275559-participant-incentives', '_blank');
          }}
        />
        {!_.get(bookingState, 'config.options.review_submission') && (
          <CardContainer
            additionalCard
            cardId="__demographicFiltersCard1"
            cardClass="slideInAnimationDelay240"
            cardTitle="Managing quotas"
            cardTexts={cardTexts1}
            onLinkClick={() => {
              props.history.push({
                pathname: `/booking-setup/${booking._id}/project-setup/participant-selection`,
              });
            }}
            linkText="Go to participant selection"
          />
        )}
        {_.get(bookingState, 'config.options.review_submission') && (
          <CardContainer
            additionalCard
            cardId="__demographicFiltersCard1"
            cardClass="slideInAnimationDelay240"
            cardTitle="Managing quotas"
            cardTexts={cardTexts2}
            onLinkClick={() => {
              window.open(
                'https://help.askable.com/en/articles/4849442-what-if-i-have-my-own-criteria-quotas',
                '_blank',
              );
            }}
          />
        )}
      </>
    );
  };

  const normaliseCriteriaArray = (originArray: any) => {
    return originArray.map((item: any) => {
      return {
        field: item.field,
        value: item.value,
        operator: item.operator,
      };
    });
  };

  const normaliseWorkIndustryArray = (workIndustryArray: any) => {
    return _.map(workIndustryArray, (industry: any) => {
      return {
        _industry_id: industry._industry_id,
        industry_name: industry.industry_name,
        subcategories: _.map(industry.subcategories, (subcategory: any) => _.omit(subcategory, ['__typename'])),
      };
    });
  };

  const hasDemographicFilterChanges = (bookingObj: any) => {
    const savedFilters = JSON.stringify(utils.removeTypenames(_.get(booking, 'config.criteria')));
    const newFilters = JSON.stringify(utils.removeTypenames(_.get(bookingObj, 'config.criteria')));
    return savedFilters !== newFilters;
  };

  const onChangeFilter = (key: any, value: any) => {
    const bookingObj = {
      config: {
        ..._.get(bookingData, 'config'),
        criteria: {
          ..._.get(bookingData, 'config.criteria'),
          [key]: value,
        },
      },
    };

    const bookingStateObj = {
      ...bookingState,
      config: {
        ...bookingState.config,
        criteria: {
          ...bookingState.config.criteria,
          [key]: value,
        },
      },
    };
    setBookingState(bookingStateObj);

    if (hasDemographicFilterChanges(bookingObj)) {
      setBookingData(bookingObj);
      props.history.replace({ booking: bookingObj, bookingState: bookingStateObj });
    } else {
      setBookingData(null);
      props.history.replace({ booking: null, bookingState: null });
    }
  };

  const addNewDemographicFilter = (criteriaParent: any) => {
    let incentiveType = _.get(bookingState, 'config.participant_category');
    const filterAdded = _.find(demographicFilterOptions, (item: any) => item.id === criteriaParent);
    if (_.has(filterAdded, 'type') && !bookingUtils.isBookingForProfessionals(bookingState)) {
      incentiveType = 2;
    }

    let defaultValue: any = [];
    if (criteriaParent === 'meta_identity_birthday_year') {
      defaultValue = Object.values(getDemographicAgeDefaultValue());
    } else if (criteriaParent === 'meta_identity_languages_english_speak') {
      defaultValue = [
        {
          field: 'user.meta.identity.languages.english.speak.native',
          value: 'true',
          operator: '==',
        },
        {
          field: 'user.meta.identity.languages.english.speak.fluent',
          value: 'true',
          operator: '==',
        },
        {
          field: 'user.meta.identity.languages.english.speak.conversational',
          value: 'true',
          operator: '==',
        },
      ];
    }

    setNewFilterAdded(criteriaParent);

    let filtersOrder = _.clone(_.get(bookingState, 'config.criteria.filters_order')) || [];
    const hasFilterOrder = _.find(filtersOrder, (item: any) => item === criteriaParent);
    if (!hasFilterOrder) {
      filtersOrder = update(filtersOrder, {
        $push: [criteriaParent],
      });
    }

    const filterAlreadyExists = _.find(allDemographicFiltersSaved, (item: any) => item.id === criteriaParent);
    let newCriteria = {
      ..._.get(bookingData, 'config.criteria'),
      [criteriaParent]: defaultValue,
      filters_order: filtersOrder,
    };
    let newCriteriaState = {
      ..._.get(bookingState, 'config.criteria'),
      [criteriaParent]: defaultValue,
      filters_order: filtersOrder,
    };
    if (filterAlreadyExists) {
      newCriteria = {
        ..._.get(bookingData, 'config.criteria'),
        filters_order: filtersOrder,
      };
      newCriteriaState = {
        ..._.get(bookingState, 'config.criteria'),
        filters_order: filtersOrder,
      };
    }

    const bookingObj = {
      config: {
        ..._.get(bookingData, 'config'),
        criteria: newCriteria,
        participant_category: incentiveType,
      },
    };
    const bookingStateObj = {
      ...bookingState,
      config: {
        ...bookingState.config,
        criteria: newCriteriaState,
        participant_category: incentiveType,
      },
    };
    setBookingState(bookingStateObj);

    if (hasDemographicFilterChanges(bookingObj)) {
      setBookingData(bookingObj);
      props.history.replace({ booking: bookingObj, bookingState: bookingStateObj });
    } else {
      setBookingData(null);
      props.history.replace({ booking: null, bookingState: null });
    }
    setNewFilterAdded(criteriaParent);
  };

  const resetCriteriaArray = (criteriaParent: any) => {
    const bookingObj = {
      config: {
        ..._.get(bookingData, 'config'),
        criteria: {
          ..._.get(bookingData, 'config.criteria'),
          [criteriaParent]: [],
        },
      },
    };

    const bookingStateObj = {
      ...bookingState,
      config: {
        ...bookingState.config,
        criteria: {
          ...bookingState.config.criteria,
          [criteriaParent]: [],
        },
      },
    };
    setBookingState(bookingStateObj);

    if (hasDemographicFilterChanges(bookingObj)) {
      setBookingData(bookingObj);
      props.history.replace({ booking: bookingObj, bookingState: bookingStateObj });
    } else {
      setBookingData(null);
      props.history.replace({ booking: null, bookingState: null });
    }
  };

  const updateBulkCriterias = (criteriaParent: any, field: any, values: any, operator: any) => {
    let newObject = {};
    if (typeof values === 'object') {
      newObject = values.map((item: any) => {
        return {
          field,
          operator,
          value: item,
        };
      });
    }

    if (typeof operator === 'object') {
      newObject = operator.map((item: any) => {
        return {
          field,
          operator: item,
          value: values,
        };
      });
    }

    const bookingObj = {
      config: {
        ..._.get(bookingData, 'config'),
        criteria: {
          ..._.get(bookingData, 'config.criteria'),
          [criteriaParent]: newObject,
        },
      },
    };

    const bookingStateObj = {
      ...bookingState,
      config: {
        ...bookingState.config,
        criteria: {
          ...bookingState.config.criteria,
          [criteriaParent]: newObject,
        },
      },
    };
    setBookingState(bookingStateObj);

    if (hasDemographicFilterChanges(bookingObj)) {
      setBookingData(bookingObj);
      props.history.replace({ booking: bookingObj, bookingState: bookingStateObj });
    } else {
      setBookingData(null);
      props.history.replace({ booking: null, bookingState: null });
    }
  };

  const updateCriteria = (criteriaParent: any, field: any, value: any, operator: any, keepOtherValues = true) => {
    // Check where is the object inside the criteria array
    const arrayOrigin = _.get(bookingState, `config.criteria[${criteriaParent}]`);
    let index = arrayOrigin.indexOf(
      arrayOrigin.find((item: any) => {
        if (operator) return item.field === field && item.operator === operator;
        if (value) return item.field === field && item.value === value;
        return item.field === field;
      }),
    );
    // If the object doesn't exist, it should push a new object
    if (index === -1) index = arrayOrigin.length;
    // If the array should be recreated the index should be 0
    if (!keepOtherValues) index = 0;

    const bookingObj = {
      config: {
        ..._.get(bookingData, 'config'),
        criteria: {
          ..._.get(bookingData, 'config.criteria'),
          [criteriaParent]: update(
            keepOtherValues ? normaliseCriteriaArray(_.get(bookingState, `config.criteria[${criteriaParent}]`)) : [],
            {
              $auto: {
                [index]: {
                  $auto: {
                    $set: {
                      field,
                      value,
                      operator: operator || '==',
                    },
                  },
                },
              },
            },
          ),
        },
      },
    };

    const bookingStateObj = {
      ...bookingState,
      config: {
        ...bookingState.config,
        criteria: {
          ...bookingState.config.criteria,
          [criteriaParent]: update(
            keepOtherValues ? normaliseCriteriaArray(_.get(bookingState, `config.criteria[${criteriaParent}]`)) : [],
            {
              $auto: {
                [index]: {
                  $auto: {
                    $set: {
                      field,
                      operator: operator || '==',
                      value,
                    },
                  },
                },
              },
            },
          ),
        },
      },
    };
    setBookingState(bookingStateObj);

    if (hasDemographicFilterChanges(bookingObj)) {
      setBookingData(bookingObj);
      props.history.replace({ booking: bookingObj, bookingState: bookingStateObj });
    } else {
      setBookingData(null);
      props.history.replace({ booking: null, bookingState: null });
    }
  };

  const removeCriteria = (criteriaParent: any, field: any, value?: any, fieldName = 'field') => {
    let newObject;
    if (!value) {
      newObject = _.filter(
        _.get(bookingState, `config.criteria[${criteriaParent}]`),
        (item: any) => item[fieldName] !== field,
      );
    } else {
      newObject = _.filter(
        _.get(bookingState, `config.criteria[${criteriaParent}]`),
        (item: any) => item.value !== value,
      );
    }

    const bookingObj = {
      config: {
        ..._.get(bookingData, 'config'),
        criteria: {
          ..._.get(bookingData, 'config.criteria'),
          [criteriaParent]: normaliseCriteriaArray(newObject),
        },
      },
    };

    const bookingStateObj = {
      ...bookingState,
      config: {
        ...bookingState.config,
        criteria: {
          ...bookingState.config.criteria,
          [criteriaParent]: normaliseCriteriaArray(newObject),
        },
      },
    };
    setBookingState(bookingStateObj);

    if (hasDemographicFilterChanges(bookingObj)) {
      setBookingData(bookingObj);
      props.history.replace({ booking: bookingObj, bookingState: bookingStateObj });
    } else {
      setBookingData(null);
      props.history.replace({ booking: null, bookingState: null });
    }
  };

  const onUpdateIndustry = (industry: any, roles: any, industryIndex: any) => {
    const industryObject = {
      ...industry,
      subcategories: roles,
    };
    // It should check whether or not the index exists in the booking object already
    if (_.get(bookingState, `config.criteria.meta_work_industry[${industryIndex}]`)) {
      const bookingObj = {
        config: {
          ..._.get(bookingData, 'config'),
          criteria: {
            ..._.get(bookingData, 'config.criteria'),
            meta_work_industry: update(_.get(bookingState, 'config.criteria.meta_work_industry'), {
              $auto: {
                [industryIndex]: {
                  $set: industryObject,
                },
              },
            }),
          },
        },
      };

      const bookingStateObj = {
        ...bookingState,
        config: {
          ...bookingState.config,
          criteria: {
            ...bookingState.config.criteria,
            meta_work_industry: update(_.get(bookingState, 'config.criteria.meta_work_industry'), {
              $auto: {
                [industryIndex]: {
                  $set: industryObject,
                },
              },
            }),
          },
        },
      };
      setBookingState(bookingStateObj);

      if (hasDemographicFilterChanges(bookingObj)) {
        setBookingData(bookingObj);
        props.history.replace({ booking: bookingObj, bookingState: bookingStateObj });
      } else {
        setBookingData(null);
        props.history.replace({ booking: null, bookingState: null });
      }
    } else {
      const bookingObj = {
        config: {
          ..._.get(bookingData, 'config'),
          criteria: {
            ..._.get(bookingData, 'config.criteria'),
            meta_work_industry: update(_.get(bookingState, 'config.criteria.meta_work_industry'), {
              $push: [industryObject],
            }),
          },
        },
      };

      const bookingStateObj = {
        ...bookingState,
        config: {
          ...bookingState.config,
          criteria: {
            ...bookingState.config.criteria,
            meta_work_industry: update(_.get(bookingState, 'config.criteria.meta_work_industry'), {
              $push: [industryObject],
            }),
          },
        },
      };
      setBookingState(bookingStateObj);

      if (hasDemographicFilterChanges(bookingObj)) {
        setBookingData(bookingObj);
        props.history.replace({ booking: bookingObj, bookingState: bookingStateObj });
      } else {
        setBookingData(null);
        props.history.replace({ booking: null, bookingState: null });
      }
    }
  };

  const onRemoveIndustry = (industryIndex: any) => {
    // It should check whether or not the index exists in the booking object already
    if (_.get(bookingState, `config.criteria.meta_work_industry[${industryIndex}]`)) {
      const bookingObj = {
        config: {
          ..._.get(bookingData, 'config'),
          criteria: {
            ..._.get(bookingData, 'config.criteria'),
            meta_work_industry: update(_.get(bookingState, 'config.criteria.meta_work_industry'), {
              $splice: [[industryIndex, 1]],
            }),
          },
        },
      };
      const bookingStateObj = {
        ...bookingState,
        config: {
          ...bookingState.config,
          criteria: {
            ...bookingState.config.criteria,
            meta_work_industry: update(_.get(bookingState, 'config.criteria.meta_work_industry'), {
              $splice: [[industryIndex, 1]],
            }),
          },
        },
      };
      setBookingState(bookingStateObj);
      if (hasDemographicFilterChanges(bookingObj)) {
        setBookingData(bookingObj);
        props.history.replace({ booking: bookingObj, bookingState: bookingStateObj });
      } else {
        setBookingData(null);
        props.history.replace({ booking: null, bookingState: null });
      }
    }
  };

  const onClickNoSearchResults = () => {
    props.history.push({
      pathname: `/booking-setup/${props.booking._id}/audience/custom-screener`,
    });
  };

  const renderCheckboxSelectionTitle = (parentCriteria: any, choicesArray: any) => {
    const parsedSelection = _.map(_.get(bookingState, `config.criteria[${parentCriteria}]`), (item: any) => {
      if (item.value === 'true') {
        return _.get(
          _.find(choicesArray, (status: any) => status.id === item.field),
          'label',
        );
      }
      if (item.value) {
        return _.get(
          _.find(choicesArray, (status: any) => status.id === item.field && status.value === item.value),
          'label',
        );
      }
      return null;
    });
    return (
      <div className="demographicFilterName">
        {parsedSelection.length > 1 && (
          <p className="formField">
            <strong>Any combination of: </strong>
            {parsedSelection.join(', ')}
          </p>
        )}
        {parsedSelection.length === 1 && (
          <p className="formField">
            {parsedSelection.toString()}
            <strong> only</strong>
          </p>
        )}
        {parsedSelection.length === 0 && (
          <p className="formField">{choicesArray.filter((item: any) => item.id === 'any')[0].label}</p>
        )}
      </div>
    );
  };

  const renderCheckboxStatusContainer = (parentCriteria: any, choicesArray: any, minValues = 0) => {
    const status = {};
    let qtdValues = 0;
    if (_.get(bookingState, `config.criteria[${parentCriteria}]`)) {
      qtdValues = _.size(_.get(bookingState, `config.criteria[${parentCriteria}]`));
      _.get(bookingState, `config.criteria[${parentCriteria}]`).forEach((item: any) => {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        if (item.value === 'true') status[item.field] = item.value;
      });
    }
    return (
      <div key={`render${parentCriteria}Container`} className={`render${parentCriteria}Container bookingFormAccordion`}>
        {choicesArray.map((item: any) => {
          return (
            <div className="flex items-center gap-2 py-1" key={item.id}>
              <Checkbox
                id={item.id}
                // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                checked={status[item.id] === 'true' || (item.id === 'any' && !_.size(status))}
                onCheckedChange={(checked: boolean) => {
                  if (checked) {
                    updateCriteria(parentCriteria, item.id, 'true', '==');
                  } else if (qtdValues > minValues) {
                    removeCriteria(parentCriteria, item.id);
                  }

                  if (item.id === 'any' && checked) {
                    resetCriteriaArray(parentCriteria);
                  }
                }}
              />
              <Label htmlFor={item.id}>{item.label}</Label>
            </div>
          );
        })}
      </div>
    );
  };

  const renderAgesContainer = () => {
    const defaultValue = getDemographicAgeDefaultValue();
    const bookingMinAge = (() => {
      const maybeCurrentBookingMinAge =
        bookingUtils.getCriteriaValue(
          {
            parent: 'meta_identity_birthday_year',
            field: 'user.meta.identity.birthday.timestamp',
            operator: 'lt',
          },
          bookingState,
        ) || defaultValue.minAge.value;

      return utils.getYearFromTimestamp(maybeCurrentBookingMinAge);
    })();

    const bookingMaxAge = (() => {
      const maybeCurrentBookingMaxAge =
        bookingUtils.getCriteriaValue(
          {
            parent: 'meta_identity_birthday_year',
            field: 'user.meta.identity.birthday.timestamp',
            operator: 'gt',
          },
          bookingState,
        ) || defaultValue.maxAge.value;

      return utils.getYearFromTimestamp(maybeCurrentBookingMaxAge);
    })();

    const minYear = currentYear - 199;
    let minAges = [currentYear - 100];
    let maxAges = [currentYear - 100, minYear];
    for (let i = currentYear - 99; i < currentYear - 17; i += 1) {
      minAges.unshift(i);
      maxAges.unshift(i);
    }

    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ value: any; label: string | number; }[]' i... Remove this comment to see the full error message
    minAges = _.map(minAges, (item: any) => {
      const ageValue = currentYear - item;
      return {
        value: item,
        label: ageValue,
      };
    });
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ value: any; label: string | number; }[]' i... Remove this comment to see the full error message
    maxAges = _.map(maxAges, (item: any) => {
      const ageValue = currentYear - item;
      return {
        value: item,
        label: ageValue,
      };
    });

    return (
      <div key="renderAgesContainer" className="renderAgesContainer bookingFormAccordion">
        <span className="optionText pright15">Ages</span>

        <AgeRange
          minAge={bookingMinAge}
          maxAge={bookingMaxAge}
          onMinChange={onChangeMinAge}
          onMaxChange={onChangeMaxAge}
        />

        {/* <Select defaultValue={bookingMinAge as any} onValueChange={(value: any) => onChangeMinAge(value)}>
          <SelectTrigger className="w-[6rem]">
            <SelectValue placeholder="Min age" />
          </SelectTrigger>
          <SelectContent>
            {minAges.map((item: any) => (
              <SelectItem key={item.value} value={item.value}>
                {item.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <span className="optionText font--18 pleft15 pright15">-</span>
        <Select defaultValue={bookingMaxAge || (minYear as any)} onValueChange={(value: any) => onChangeMaxAge(value)}>
          <SelectTrigger className="w-[6rem]">
            <SelectValue placeholder="Max age" />
          </SelectTrigger>
          <SelectContent>
            {maxAges.map((item: any) => (
              <SelectItem key={item.value} value={item.value}>
                {item.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select> */}
      </div>
    );
  };

  const onChangeMinAge = (value: number) => {
    // 1 - Transform the year argument received into age
    // 2 - Get the timestamp from the current server datetime in x years ago
    // 3 - Convert to a string

    console.log(value);

    // Validation:
    //   1 - Min Age year shouldn't be smaller than maxAge.
    const maxAge = bookingUtils.getCriteriaValue(
      {
        parent: 'meta_identity_birthday_year',
        field: 'user.meta.identity.birthday.timestamp',
        operator: 'gt',
      },
      bookingState,
    );
    const setValue = moment()
      .subtract(currentYear - value, 'y')
      .valueOf()
      .toString();
    if (utils.getYearFromTimestamp(setValue) < utils.getYearFromTimestamp(maxAge)) {
      // It should change the Max age to be the same as Min Age
      updateBulkCriterias('meta_identity_birthday_year', 'user.meta.identity.birthday.timestamp', setValue, [
        'lt',
        'gt',
      ]);
    } else {
      updateCriteria('meta_identity_birthday_year', 'user.meta.identity.birthday.timestamp', setValue, 'lt'); // Min Age
    }
  };

  const onChangeMaxAge = (value: any) => {
    // Validation:
    //   1 - Max Age year shouldn't be bigger than min Age.
    const minAge = bookingUtils.getCriteriaValue(
      {
        parent: 'meta_identity_birthday_year',
        field: 'user.meta.identity.birthday.timestamp',
        operator: 'lt',
      },
      bookingState,
    );
    const setValue = moment()
      .subtract(currentYear - value, 'y')
      .valueOf()
      .toString();
    if (utils.getYearFromTimestamp(setValue) > utils.getYearFromTimestamp(minAge)) {
      // It should change the Min age to be the same as Max Age
      updateBulkCriterias('meta_identity_birthday_year', 'user.meta.identity.birthday.timestamp', setValue, [
        'lt',
        'gt',
      ]);
    } else {
      updateCriteria('meta_identity_birthday_year', 'user.meta.identity.birthday.timestamp', setValue, 'gt'); // Max Age
    }
  };

  const renderGenderContainer = () => {
    const onChangeGender = (value: any) => {
      switch (parseInt(value, 10)) {
        case 1: // All genders
          resetCriteriaArray('meta_identity_gender');
          break;
        case 2: // Males only
          updateCriteria('meta_identity_gender', 'user.meta.identity.gender', 'male', null, false);
          break;
        case 3: // Females only
          updateCriteria('meta_identity_gender', 'user.meta.identity.gender', 'female', null, false);
          break;
        case 0: // Non-binary only
          updateCriteria('meta_identity_gender', 'user.meta.identity.gender', 'non-binary', null, false);
          break;
        default:
          return null;
      }
    };
    return (
      <div key="genderTypesContainer" className="genderTypesContainer bookingFormAccordion">
        <RadioGroup
          defaultValue={bookingUtils.getCurrentGenderValue(bookingState) as any}
          onValueChange={(value: string) => onChangeGender(value)}
        >
          {bookingUtils.genderTypes().map(option => (
            <div className="flex items-center space-x-2" key={option.value}>
              <RadioGroupItem value={String(option.value)} id={`option_${option.value}`} />
              <Label htmlFor={`option_${option.value}`}>{option.label}</Label>
            </div>
          ))}
        </RadioGroup>
      </div>
    );
  };

  const renderCheckboxEmploymentTypeContainer = (choicesArray: any) => {
    return (
      <div
        key="rendermeta_work_employment_typeContainer"
        className="rendermeta_work_employment_typeContainer bookingFormAccordion"
      >
        {choicesArray.map((item: any) => {
          if (item.id === 'any') {
            return (
              <div className="flex items-center gap-2 py-1" key={item.id}>
                <Checkbox
                  id={item.id}
                  checked={_.size(_.get(bookingState, 'config.criteria.meta_work_employment_type')) === 0}
                  onCheckedChange={(checked: boolean) => checked && resetCriteriaArray('meta_work_employment_type')}
                />
                <Label htmlFor={item.id}>{item.label}</Label>
              </div>
            );
          }
          const isChecked =
            _.indexOf(
              _.map(
                _.get(bookingState, 'config.criteria.meta_work_employment_type'),
                (employment_type: any) => employment_type.value,
              ),
              item.value,
            ) >= 0;
          return (
            <div className="flex items-center gap-2 py-1" key={item.id}>
              <Checkbox
                id={item.id}
                checked={isChecked}
                onCheckedChange={(checked: boolean) => {
                  if (checked) {
                    // @ts-expect-error ts-migrate(2554) FIXME: Expected 4-5 arguments, but got 3.
                    updateCriteria('meta_work_employment_type', item.id, item.value);
                  } else {
                    removeCriteria('meta_work_employment_type', item.id, item.value);
                  }
                }}
              />
              <Label htmlFor={item.id}>{item.label}</Label>
            </div>
          );
        })}
      </div>
    );
  };

  const renderIndustryTitle = () => {
    const { meta_work_industry } = bookingState.config.criteria;
    const numberOfIndustries = _.size(meta_work_industry);
    let title;
    switch (numberOfIndustries) {
      case 0:
        title = 'Any Industry';
        break;
      case 1:
        title = `${meta_work_industry[0].industry_name} only`;
        break;
      default:
        title = _.map(meta_work_industry, (industry: any) => industry.industry_name).join(', ');
        break;
    }
    return (
      <div className="demographicFilterName">
        <p className="formField">{title}</p>
      </div>
    );
  };

  const renderIndustryContainer = () => {
    const numberOfIndustriesSaved = _.size(industriesSaved);
    return (
      <div key="industryContainer" className="industryContainer">
        {numberOfIndustriesSaved === 0 ? (
          <IndustryDropdown
            industries={industries}
            industrySaved={[]}
            industriesSaved={[]}
            onFinishIndustrySelection={({ industry, roles }: any) => onUpdateIndustry(industry, roles, 0)}
          />
        ) : (
          _.map(industriesSaved, (industrySaved: any, index: any) => {
            return (
              <IndustryDropdown
                key={`${industrySaved}-${index}`}
                industries={industries}
                industriesSaved={industriesSaved}
                industrySaved={industrySaved}
                onFinishIndustrySelection={({ industry, roles }: any) => onUpdateIndustry(industry, roles, index)}
                onRemoveIndustry={() => {
                  if (_.get(industrySaved, '_industry_id')) {
                    onRemoveIndustry(index);
                  } else {
                    const newIndustriesSaved = _.clone(industriesSaved);
                    _.pullAt(newIndustriesSaved, index);
                    setIndustriesSaved(newIndustriesSaved);
                  }
                }}
              />
            );
          })
        )}
        <Button
          variant="link"
          onClick={() => {
            const industriesAlreadySaved: any = _.clone(industriesSaved);
            industriesAlreadySaved.push({
              _industry_id: '',
              industry_name: '',
              subcategories: [],
            });
            setIndustriesSaved(industriesAlreadySaved);
          }}
        >
          Add more industry filters
        </Button>
      </div>
    );
  };

  const renderEnglishProficiencyCriteriaTitle = () => {
    const parsedSelection = _.map(
      _.get(bookingState, 'config.criteria.meta_identity_languages_english_speak'),
      (item: any) => {
        if (item.value === 'true') {
          return bookingUtils.englishProficiencyTypes().find((status: any) => status.id === item.field);
        }
        return null;
      },
    );
    let prefix = '';
    let values = '';
    // It should test if the bigger index (which represents the least level of enflish) has all the parents selected. If so, it should change the way the component renders
    if (parsedSelection.length === 1) {
      prefix = 'English speaking level should be ';
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'string'.
      values = <strong>{parsedSelection.map((item: any) => item.label).toString()} only</strong>;
    } else if (
      parsedSelection.length > 1 &&
      parsedSelection.find((item: any) => item.index === 0) &&
      utils.isPerfectSequence(parsedSelection.map((item: any) => item.index))
    ) {
      prefix = 'English speaking level must be at least ';
      // Get the last item in the ordered array
      const orderedArray = utils.orderArrayByField(parsedSelection, 'index');
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'string'.
      values = <strong>{orderedArray[orderedArray.length - 1].label}</strong>;
    } else {
      prefix = 'English speaking level must be any combination of ';
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'string'.
      values = (
        <span>
          <strong>{parsedSelection.map((item: any) => item.label).join(', ')}</strong>
        </span>
      );
    }
    return (
      <div className="demographicFilterName">
        <p className="formField">
          {prefix}
          {values}
        </p>
      </div>
    );
  };

  const renderFilters = () => {
    const filtersToRender: any = [];
    _.map(demographicFiltersSaved, (item: any) => {
      switch (item.id) {
        case 'meta_identity_birthday_year':
          filtersToRender.push({
            key: item.id,
            name: <div className="demographicFilterName">{bookingUtils.getAgesTitle(bookingState)}</div>,
            component: renderAgesContainer(),
          });
          break;
        case 'meta_identity_gender':
          filtersToRender.push({
            key: item.id,
            name: <div className="demographicFilterName">{bookingUtils.getGenderTitle(bookingState)}</div>,
            component: renderGenderContainer(),
          });
          break;
        case 'meta_work_status':
          filtersToRender.push({
            key: item.id,
            name: renderCheckboxSelectionTitle('meta_work_status', bookingUtils.workStatusTypes()),
            component: renderCheckboxStatusContainer('meta_work_status', bookingUtils.workStatusTypes()),
          });
          break;
        case 'meta_work_employment_type':
          filtersToRender.push({
            key: item.id,
            name: renderCheckboxSelectionTitle('meta_work_employment_type', bookingUtils.workEmploymentType()),
            component: renderCheckboxEmploymentTypeContainer(bookingUtils.workEmploymentType()),
          });
          break;
        case 'meta_work_industry':
          filtersToRender.push({
            key: item.id,
            name: renderIndustryTitle(),
            component: renderIndustryContainer(),
          });
          break;
        case 'meta_identity_languages_english_speak':
          filtersToRender.push({
            key: item.id,
            name: renderEnglishProficiencyCriteriaTitle(),
            component: renderCheckboxStatusContainer(
              'meta_identity_languages_english_speak',
              bookingUtils.englishProficiencyTypes(),
              1,
            ),
          });
          break;
        case 'meta_identity_languages':
          filtersToRender.push({
            key: item.id,
            name: (
              <div className="demographicFilterName">
                <p className="formField">Languages</p>
              </div>
            ),
            component: renderGenderContainer(),
          });
          break;
        case 'meta_family_status':
          filtersToRender.push({
            key: item.id,
            name: renderCheckboxSelectionTitle('meta_family_status', bookingUtils.maritalStatusTypes()),
            component: renderCheckboxStatusContainer('meta_family_status', bookingUtils.maritalStatusTypes()),
          });
          break;
        case 'meta_education':
          filtersToRender.push({
            key: item.id,
            name: renderCheckboxSelectionTitle('meta_education', bookingUtils.educationLevels()),
            component: renderCheckboxStatusContainer('meta_education', bookingUtils.educationLevels()),
          });
          break;
        case 'meta_education_field':
          filtersToRender.push({
            key: item.id,
            name: (
              <div className="demographicFilterName">
                <p className="formField">Education field</p>
              </div>
            ),
            component: renderGenderContainer(),
          });
          break;
        case 'meta_individual_income':
          filtersToRender.push({
            key: item.id,
            name: (
              <div className="demographicFilterName">
                <p className="formField">Individual income</p>
              </div>
            ),
            component: renderGenderContainer(),
          });
          break;
        case 'meta_family_income':
          filtersToRender.push({
            key: item.id,
            name: (
              <div className="demographicFilterName">
                <p className="formField">Family income</p>
              </div>
            ),
            component: renderGenderContainer(),
          });
          break;
        case 'meta_tech_savviness':
          filtersToRender.push({
            key: item.id,
            name: (
              <div className="demographicFilterName">
                <p className="formField">Tech savviness</p>
              </div>
            ),
            component: renderGenderContainer(),
          });
          break;
        case 'meta_home_ownership':
          filtersToRender.push({
            key: item.id,
            name: (
              <div className="demographicFilterName">
                <p className="formField">Home ownership</p>
              </div>
            ),
            component: renderGenderContainer(),
          });
          break;
        case 'meta_purchasing_behaviour':
          filtersToRender.push({
            key: item.id,
            name: (
              <div className="demographicFilterName">
                <p className="formField">Purchasing behavior</p>
              </div>
            ),
            component: renderGenderContainer(),
          });
          break;
        case 'meta_children':
          filtersToRender.push({
            key: item.id,
            name: (
              <div className="demographicFilterName">
                <p className="formField">Childrem</p>
              </div>
            ),
            component: renderGenderContainer(),
          });
          break;
        default:
          break;
      }
    });
    return filtersToRender;
  };

  const renderDeleteFilter = (index: any) => {
    return (
      <a
        className="deleteFilter"
        key={`_containerEdit_${index}`}
        onClick={event => {
          event.stopPropagation();
          setDeleteFilter(index);
        }}
      >
        Delete
      </a>
    );
  };

  const onDeleteFilter = (key: any) => {
    setDeleteFilter(null);

    let filtersOrder = _.clone(_.get(bookingState, 'config.criteria.filters_order')) || [];
    const filterIndex = _.findIndex(filtersOrder, (item: any) => item === key);
    if (filterIndex >= 0) {
      filtersOrder = update(filtersOrder, {
        $splice: [[filterIndex, 1]],
      });
    }

    const bookingObj = {
      config: {
        criteria: {
          ..._.get(bookingData, 'config.criteria'),
          [key]: null,
          filters_order: filtersOrder,
        },
      },
    };
    setBookingData(bookingObj);

    const bookingStateObj = {
      ...bookingState,
      config: {
        ...bookingState.config,
        criteria: {
          ...bookingState.config.criteria,
          [key]: null,
          filters_order: filtersOrder,
        },
      },
    };
    setBookingState(bookingStateObj);

    if (hasDemographicFilterChanges(bookingObj)) {
      props.history.replace({ booking: bookingObj, bookingState: bookingStateObj });
    } else {
      props.history.replace({ booking: null, bookingState: null });
    }
  };

  const renderConfirmDeleteFilter = (index: any, key: any) => {
    let hasFilterChanged = true;
    const filterValue = _.get(bookingState, `config.criteria.[${key}]`) || [];
    if (key === 'meta_identity_birthday_year') {
      const bookingMaxAge = bookingUtils.getCriteriaValue(
        {
          parent: 'meta_identity_birthday_year',
          field: 'user.meta.identity.birthday.timestamp',
          operator: 'gt',
        },
        bookingState,
      );
      const bookingMinAge = bookingUtils.getCriteriaValue(
        {
          parent: 'meta_identity_birthday_year',
          field: 'user.meta.identity.birthday.timestamp',
          operator: 'lt',
        },
        bookingState,
      );
      const minAge = bookingMinAge ? currentYear - utils.getYearFromTimestamp(bookingMinAge) : '18';
      const maxAge = bookingMaxAge ? currentYear - utils.getYearFromTimestamp(bookingMaxAge) : '100+';
      if (minAge === 18 && (maxAge === 199 || maxAge === '100+')) {
        hasFilterChanged = false;
      }
    } else if (key !== 'meta_identity_birthday_year' && _.size(filterValue) === 0) {
      hasFilterChanged = false;
    }

    if (hasFilterChanged) {
      return (
        <div
          className="confirmDeleteFilter"
          key={`confirmDeleteFilter_${index}`}
          onClick={event => event.stopPropagation()}
        >
          <p className="confirmDeleteQuestion">Are you sure?</p>
          <a className="cancelDelete" onClick={() => setDeleteFilter(null)}>
            Cancel
          </a>
          <a
            className="confirmDelete"
            onClick={() => {
              onDeleteFilter(key);
            }}
          >
            Yes
          </a>
        </div>
      );
    }
    onDeleteFilter(key);
  };

  const handleClickFilter = (index: any) => {
    setDeleteFilter(null);
    if (selectedFilter === index) setSelectedFilter(null);
    if (selectedFilter !== index) setSelectedFilter(index);
  };

  const renderDemographicFilters = () => {
    const filtersToRender = renderFilters();

    return filtersToRender.length > 0 ? (
      <div className="demographicFiltersContainer">
        <div className="demographicFilters divide-y">
          {_.map(filtersToRender, (item: any, index: any) => (
            <div
              id={`__filter_${item.key}`}
              key={index}
              className={`demographicFilterItem ${selectedFilter === index ? 'selectedFilter' : ''}`}
              onClick={() => handleClickFilter(index)}
            >
              <div className="demographicFilterTitle">
                <div className="h-4 w-8 px-2">
                  <ChevronDown
                    className="h-4 w-4"
                    style={selectedFilter !== index ? { transform: 'rotate(-90deg)' } : undefined}
                  />
                </div>
                {item.name}
                {deleteFilter !== index && renderDeleteFilter(index)}
                {deleteFilter === index && renderConfirmDeleteFilter(index, item.key)}
              </div>
              {selectedFilter === index && (
                <div className="demographicFilterOptions" onClick={event => event.stopPropagation()}>
                  {item.component}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    ) : null;
  };

  return (
    <div className="createBookingContent">
      <h1 id="__pageTitle" className="title">
        Demographic filters
      </h1>

      <p className="label">
        Add some basic filters to narrow down your audience. If you need something specific, you can always add that as
        a custom screening question in the next step.
      </p>

      {renderDemographicFilters()}

      <div>
        <Popover open={openFilterOptions} onOpenChange={setOpenFilterOptions}>
          <PopoverTrigger asChild>
            {_.size(demographicFiltersSaved) > 0 ? (
              <Button variant="link" className="mt-4">
                + Add another filter
              </Button>
            ) : (
              <button
                type="button"
                className="flex items-center justify-center gap-4 rounded-sm border p-4 text-left shadow hover:shadow-md
                  focus-visible:ring-offset-2"
              >
                <CirclePlus className="h-5 w-5" />
                <div>
                  <div className="text-sm font-bold">Add demographic filter</div>
                  <div className="text-xs text-muted-foreground">{`Age, Gender and ${_.size(demographicFilterOptions) - 2}+ more filters`}</div>
                </div>
              </button>
            )}
          </PopoverTrigger>
          <PopoverContent className="w-[18rem] p-0" align="start">
            <Command label="Demographic filter">
              <CommandInput placeholder="Filter items" />
              <CommandList>
                {demographicFilterOptions.map((item: any) => {
                  const filterInUse = !!_.find(demographicFiltersSaved, (filter: any) => filter.id === item.id);
                  return (
                    <CommandItem
                      key={item.id}
                      disabled={filterInUse}
                      className={cn('flex justify-between gap-2', {
                        'text-muted-foreground': filterInUse,
                      })}
                      onSelect={() => {
                        addNewDemographicFilter(item.id);
                        setOpenFilterOptions(false);
                      }}
                    >
                      <div className="flex gap-2">
                        <img src={item.image} className="h-4 w-4" alt="" /> <div>{item.label}</div>
                      </div>
                      {_.get(item, 'type') === 'professionals' ? <Badge variant="warning">Premium</Badge> : null}
                    </CommandItem>
                  );
                })}
                <CommandEmpty>
                  <div>No results.</div>
                  <Button variant="link" className="text-xs" onClick={onClickNoSearchResults}>
                    Add a custom screening
                    <br /> question instead?
                  </Button>
                </CommandEmpty>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      </div>

      <div className="mt-6 flex max-w-sm flex-col gap-2">
        <Label htmlFor="__excludepeople">Exclude people you've already spoken to</Label>

        <Select
          defaultValue={_.get(bookingState, 'config.criteria.exclude_participants_from_time')}
          onValueChange={(value: any) => onChangeFilter('exclude_participants_from_time', value)}
        >
          <SelectTrigger id="__excludepeople">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            {bookingUtils.exclusionTypes().map((item: any) => (
              <SelectItem key={item.value} value={item.value}>
                {item.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default deprecatedWithRouter(DemographicFilters);
