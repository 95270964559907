import { graphql } from 'src/graphql';

export const UpdateUnmoderatedBookingConfig = graphql(`
  mutation UpdateUnmoderatedBookingConfig($input: UpdateUnmoderatedBookingConfigInput!) {
    updateUnmoderatedBookingConfig(input: $input) {
      _id
      config {
        unmoderated {
          welcome_block {
            type
            title
            instructions
          }
          thank_you_block {
            type
            title
            instructions
          }
        }
      }
    }
  }
`);
