import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const CopyIcon: CreateIconType = createIcon({
  displayName: 'LinkIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: [
    <g clipPath="url(#clip0_311_1349)">
      <path
        d="M11.7234 4.12692C11.6972 3.52531 11.6609 2.91884 11.6142 2.30371C11.5738 1.77231 11.152 1.35081 10.6206 1.31066C7.80526 1.09795 5.18617 1.09795 2.37085 1.31066C1.83945 1.35081 1.41757 1.77241 1.37726 2.3038C1.16494 5.10303 1.16494 7.72279 1.37726 10.522C1.41757 11.0534 1.83934 11.475 2.37074 11.5151C3.01035 11.5635 3.63984 11.6008 4.2638 11.6271M4.39047 13.4216C4.43077 13.9529 4.85264 14.3745 5.38405 14.4147C8.19937 14.6274 10.8185 14.6274 13.6338 14.4147C14.1652 14.3745 14.5871 13.9529 14.6274 13.4216C14.8397 10.6223 14.8397 8.00255 14.6274 5.20332C14.5871 4.67193 14.1652 4.25033 13.6338 4.21017C10.8185 3.99747 8.19937 3.99747 5.38405 4.21017C4.85264 4.25033 4.43077 4.67192 4.39047 5.20332C4.17814 8.00255 4.17814 10.6223 4.39047 13.4216Z"
        stroke="currentColor"
        strokeWidth="1.65517"
      />
    </g>,
    <defs>
      <clipPath id="clip0_311_1349">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>,
  ],
});
