import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isExternalURL } from 'shared-utils/validation';
import { useAddUTMToAppState } from 'src/hooks/useAddUTMToAppState';

import { useIsKinde } from 'containers/Auth/AuthWrapper';
import { useConnectedClient } from 'context/ConnectedClientContext';
import { globalVariables } from 'lib/globalVariables';
import { localStorage } from 'lib/storage';
import { userUtils } from 'lib/user';
import { utils } from 'lib/utils';
import { decodeToken } from 'utils/token-utils';

import type { UserRoles } from 'generated/graphql';

import './styles/loginStyles.scss';

type LocationState = {
  email?: string;
  googleAccountError?: string;
  loginToJoinTeam?: boolean;
  from?: {
    pathname?: string;
  };
};

export const LoginContainer = () => {
  const clientContext = useConnectedClient();
  const { isAuthenticated, login } = useKindeAuth();
  const isKinde = useIsKinde();

  const location = useLocation();
  const navigate = useNavigate();
  const locationState = location.state as LocationState | null;
  const extraArguments = useRef<URLSearchParams | null>();
  const { addUTMToAppState } = useAddUTMToAppState();
  useEffect(() => {
    // Get extra arguments coming from the URL
    const args = utils.parseQueryParams(location?.search);
    extraArguments.current = args;
    if (isAuthenticated) {
      try {
        const url = new URL(args?.get('redirectPath') || '');
        return navigate(url.pathname);
      } catch (e) {
        console.error('Error redirecting to the path', e);
      }
      return navigate('/');
    }
    // For admin, we get token from the URL, and for the client, we get it from the localStorage
    const token = args?.get('token') || localStorage.get('access_token');
    const userType = args?.get('user_type') || 'client';
    const { redirectPath, redirectSearch = undefined } = (() => {
      const paramRedirectPath = args?.get('redirectPath');
      if (paramRedirectPath) {
        return { redirectPath: paramRedirectPath };
      }
      if (userType === 'researcher') {
        const redirectUri = new URL(window.location.origin);
        redirectUri.searchParams.set('user_type', 'researcher');
        return { redirectPath: redirectUri.pathname, redirectSearch: redirectUri.search };
      }
      return { redirectPath: null };
    })();

    (async () => {
      if (!token && isKinde) {
        const orgCode = args?.get('org_code');
        await login({
          ...(orgCode ? { org_code: orgCode } : {}),
          app_state: addUTMToAppState({
            user_type: userType,
            ...(redirectPath ? { redirect_uri: redirectPath } : {}),
          }),
        });

        return;
      }

      const decodedToken = decodeToken(token);

      const userId = decodedToken?.user_id ?? args?.get('user_id');

      const roles = decodedToken?.roles ?? (args?.get('roles') as unknown as UserRoles[]);

      // If we have everything necessary to log an user in, it should log him in
      if (token && userId) {
        // Before processing everything, it should first test whether a connection to a demo_client is trying to get made
        // If that's the case, then it should check whether the client isn't connected already.
        //  Connected -> Don't disconnect the user
        //  Disconnected -> Connect to the demo client account
        if (!(userUtils.isDemoClient(roles) && clientContext.authState === 'authenticated')) {
          // Resets all the localStorage to make sure its clean
          localStorage.reset();

          clientContext.actions.setAccessToken(token);
          const teamId = decodedToken?._default_team_id ?? extraArguments.current?.get('team_id');
          const teamName = extraArguments.current?.get('team_name');
          if (teamId) localStorage.save('teamId', teamId);
          if (teamName) localStorage.save('teamName', teamName);

          localStorage.save('access_token', token);
          // Set a variable to show that you're connected as a client if ignoreWarning has not been defined
          localStorage.save('connectedAsClient', true);
          if (globalVariables.getEnvironmentVariables().environment === 'DEV') {
            localStorage.save('ignoreWarning', true);
          }
        }

        if (extraArguments.current?.get('ignoreWarning') || extraArguments.current?.get('ignoreWarning') === 'true') {
          localStorage.save('ignoreWarning', true);
        }

        const isExternal = isExternalURL(redirectPath);

        if (redirectPath && !isExternal) {
          // Redirects the user to the specific route
          navigate(
            { pathname: redirectPath, search: redirectSearch },
            { state: { team_id: extraArguments.current?.get('team_id') } },
          );
        } else {
          // Redirects the user to the dashboard
          navigate({ pathname: '/' }, { state: { team_id: extraArguments.current?.get('team_id') } });
        }
        return;
      }

      if (!extraArguments.current && clientContext.authState === 'authenticated') {
        // Redirects the user to the dashboard
        navigate({ pathname: '/' }, { state: { team_id: localStorage.get('teamId') } });
        localStorage.save('teamId', clientContext?.details?.ConnectedTeam?._id);
        localStorage.save('teamName', clientContext?.details?.ConnectedTeam?.name);
        localStorage.save(
          'teamIgnoreTracking',
          clientContext?.details?.ConnectedTeam?.settings?.ignore_tracking || false,
        );
        return;
      }

      navigate({ pathname: '/logout' }, { replace: true });
      console.error('Login flow is not being handled correctly.');
    })();
  }, [
    isAuthenticated,
    clientContext.details,
    locationState?.email,
    locationState?.googleAccountError,
    location?.search,
    location.pathname,
    clientContext.authState,
    clientContext.actions,
    navigate,
  ]);

  return null;
};
