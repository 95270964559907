import { SplitFactory, useTreatments, SplitClient } from '@splitsoftware/splitio-react';
import { createContext, useContext, useMemo } from 'react';

import type { ISplitFactoryChildProps } from '@splitsoftware/splitio-react/types/types';
import type { FC, ReactElement, ReactNode } from 'react';

export type Feature =
  | 'INSIGHTS'
  | 'INSIGHTS_REALTIME'
  | 'MANAGE_TAGS'
  | 'COLLECTIONS'
  | 'KINDE_AUTH'
  | 'ASK_AI'
  | 'SESSIONS_STUDIES'
  | 'AI_MODERATED'
  | 'AI_MODERATED_V2'
  | 'TRANSLATIONS'
  | 'CONTINUOUS_DISCOVERY'
  | 'MULTIREGION_COUNTRIES'
  | 'DISCOVER_PAGE'
  | 'SESSIONS_MOBILE'
  | 'UNMODERATED_STUDY'
  | 'ENTERPRISE_SSO';

type Props = {
  app: 'clients' | 'sessions';
  attributes?: { demo?: boolean };
  apiKey?: string;
  children: ((a: ISplitFactoryChildProps) => ReactNode) | ReactNode;
  config?: Partial<SplitIO.IBrowserSettings>;
};

function getLocalFlag(feature: Feature) {
  if (typeof window === 'undefined') return undefined;
  if (!window.localStorage.getItem('feature_flags')) return undefined;
  try {
    const flags = JSON.parse(window.localStorage.getItem('feature_flags') || '');
    return flags?.[feature];
  } catch {
    window.localStorage.removeItem('feature_flags');
    return undefined;
  }
}

export function setLocalFlag(feature: Feature, on: boolean) {
  const treatment = on ? 'on' : 'off';
  if (typeof window === 'undefined') return;
  if (!window.localStorage.getItem('feature_flags')) {
    window.localStorage.setItem('feature_flags', JSON.stringify({ [feature]: treatment }));
    return;
  }
  try {
    const flags = JSON.parse(window.localStorage.getItem('feature_flags') || '');
    flags[feature] = treatment;
    window.localStorage.setItem('feature_flags', JSON.stringify(flags));
  } catch {
    window.localStorage.setItem('feature_flags', JSON.stringify({ [feature]: treatment }));
  }
}

export function clearLocalFlag(feature: Feature) {
  if (typeof window === 'undefined') return;
  if (!window.localStorage.getItem('feature_flags')) return;
  try {
    const flags = JSON.parse(window.localStorage.getItem('feature_flags') || '');
    delete flags[feature];
    window.localStorage.setItem('feature_flags', JSON.stringify(flags));
  } catch {
    window.localStorage.removeItem('feature_flags');
  }
}

type UseFeatureFlagsArgs = Parameters<typeof useTreatments>;

const Ctx = createContext<{ userId?: string; attributes?: UseFeatureFlagsArgs[1] } | undefined>(undefined);

function useUserFeatureFlags() {
  return useContext(Ctx);
}

type UserFeatureFlagProviderProps = {
  children: (args: { isReady: boolean }) => ReactElement;
  app: 'clients' | 'sessions';
  userId?: string;
  userAttributes?: {
    team?: string;
    type?: string;
  };
  anonymousAttributes?: Props['attributes'];
};

export const UserFeatureFlagProvider: FC<UserFeatureFlagProviderProps> = ({
  children,
  userAttributes,
  anonymousAttributes,
  app,
  userId,
}) => {
  const value = useMemo(() => {
    return {
      userId,
      attributes: {
        app,
        pathname: (typeof window !== 'undefined' && window.location.pathname) || '',
        ...userAttributes,
        ...anonymousAttributes,
      },
    };
  }, [userAttributes, userId]);

  return (
    <Ctx.Provider value={value}>
      {value.userId ? (
        <SplitClient
          splitKey={value.userId}
          attributes={value.attributes}
          updateOnSdkReadyFromCache={false}
          updateOnSdkUpdate={false}
          updateOnSdkTimedout={false}
        >
          {({ isReady }) => {
            return children({ isReady });
          }}
        </SplitClient>
      ) : (
        <>{children({ isReady: true })}</>
      )}
    </Ctx.Provider>
  );
};

function useFeatureFlags<T extends Feature = Feature>(
  flags: T[],
  att?: { application: 'clients' | 'sessions' },
  key?: UseFeatureFlagsArgs['2'],
): Record<T, boolean> {
  const userDetails = useUserFeatureFlags();

  const treatments = useTreatments(flags, att ?? userDetails?.attributes, key ?? userDetails?.userId ?? 'anonymous');
  // Turn on flags by local storage

  const mappedFlags = flags.reduce<Record<T, boolean>>(
    (acc, curr) => {
      acc[curr] = (getLocalFlag(curr) || treatments?.[curr]?.treatment) === 'on';

      return acc;
    },
    {} as Record<T, boolean>,
  );

  return mappedFlags;
}

const FeatureFlagsProvider = ({ children, apiKey, app, attributes, config }: Props) => {
  return (
    <SplitFactory
      attributes={{
        pathname: (typeof window !== 'undefined' && window.location.pathname) || '',
        ...attributes,
        app,
      }}
      config={{
        ...config,
        core: {
          authorizationKey: apiKey || 'localhost',
          key: 'anonymous',
        },
      }}
    >
      {props => {
        return <>{typeof children === 'function' ? children(props) : children}</>;
      }}
    </SplitFactory>
  );
};

export { FeatureFlagsProvider, useFeatureFlags };
