import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useResultsContext } from 'containers/Studies/Results/Results';
import { EmptyState } from 'containers/Studies/Results/components/EmptyState';
import { ParticipantHeader } from 'containers/Studies/Results/components/Participant/ParticipantHeader';
import { ParticipantResponseItem } from 'containers/Studies/Results/components/Participant/ParticipantResponseItem';

export const ParticipantContent = () => {
  const params = useParams();
  const { t } = useTranslation();
  const { results } = useResultsContext();
  const { participantId } = params;
  const participant = results?.participants?.find(p => p.id === participantId);

  return (
    <div
      id={`participant-${participant?.id}`}
      className="flex h-full flex-col gap-10 rounded-xl bg-background p-3 lg:p-8"
    >
      {participant ? (
        <>
          <ParticipantHeader name={participant.name} />

          <section className="flex flex-col gap-6" id="participant-responses">
            <h3 className="text-lg font-semibold">{t('sections.studies.results.responses', { count: 2 })}</h3>

            {participant?.responses.length > 0 ? (
              <ol className="flex max-w-[70ch] flex-col gap-6 text-pretty">
                {participant?.responses.map(response => <ParticipantResponseItem key={response.id} {...response} />)}
              </ol>
            ) : (
              <EmptyState />
            )}
          </section>
        </>
      ) : (
        <EmptyState />
      )}
    </div>
  );
};
