import { Button } from '@askable/ui/components/ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@askable/ui/components/ui/select';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { useEffect, useState, Fragment } from 'react';

import PriceCardContainer from 'components/askablePlus/components/priceCardContainer';
import { askablePlusUtils } from 'lib/askablePlus';
import { bookingUtils } from 'lib/booking';

function DiscoveryInterviewSessionDuration(props: any) {
  const askablePlusBrief = _.get(props, 'askablePlusBrief');
  const [askablePlusBriefState, setAskablePlusBriefState] = useState(askablePlusBrief);
  const [sessionDurationValue, setSessionDurationValue] = useState(
    _.get(askablePlusBrief, 'askable_plus.research_type.discovery.session.duration'),
  );

  const totalResearchTypesSelected = askablePlusUtils.totalResearchTypesSelected(askablePlusBrief);
  const hasDiscoveryInterviewType = askablePlusUtils.hasDiscoveryInterviewType(askablePlusBrief);

  useEffect(() => {
    if (!_.get(props, 'askablePlusBrief._id')) {
      props.history.push('/askable-plus/create');
    } else if (totalResearchTypesSelected <= 1) {
      props.history.push(`/askable-plus/${askablePlusBrief._id}/audience/participant-criteria`);
    } else if (!hasDiscoveryInterviewType) {
      props.history.push(`/askable-plus/${askablePlusBrief._id}/usability-testing/quota`);
    } else {
      props.updateLastStep({
        step: 'Discovery Interview',
        subStep: `/askable-plus/${askablePlusBrief._id}/discovery-interview/session-duration`,
        stepId: 'discovery_interview_session_duration',
      });
    }
  }, []);

  useEffect(() => {
    parseSessionDuration(sessionDurationValue);
    props.renderRightContent(rightContent());
  }, [askablePlusBrief, askablePlusBriefState]);

  const rightContent = () => {
    return (
      <Fragment>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          askablePlusBrief={askablePlusBriefState}
          team={_.get(props, 'team')}
          context={props.context}
        />
      </Fragment>
    );
  };

  const parseSessionDuration = (duration: number) => {
    setSessionDurationValue(duration);
  };

  const onChangeValue = (value: number) => {
    parseSessionDuration(value);
    const askablePlusBriefStateObj = {
      ...askablePlusBriefState,
      askable_plus: {
        ...askablePlusBriefState.askable_plus,
        research_type: {
          ...askablePlusBriefState.askable_plus.research_type,
          discovery: {
            ...askablePlusBriefState.askable_plus.research_type.discovery,
            session: {
              ...askablePlusBriefState.askable_plus.research_type.discovery.session,
              duration: value,
              time_limit: value,
            },
          },
        },
      },
    };
    setAskablePlusBriefState(askablePlusBriefStateObj);
    props.updateAskablePlusBriefState(askablePlusBriefStateObj);
  };

  const onClickNext = () => {
    const redirectTo = `/askable-plus/${askablePlusBrief._id}/discovery-interview/meeting-format`;
    props.history.push({ pathname: redirectTo });
  };

  return (
    <div className="createAskablePlusBriefContent">
      <h1 className="title">Session duration</h1>
      <p className="label">How long should the sessions go for?</p>

      <Select onValueChange={v => onChangeValue(Number(v))} value={`${sessionDurationValue}`}>
        <SelectTrigger>
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          {bookingUtils?.sessionDurations(2, null)?.map(value => {
            return (
              <SelectItem key={value.value} value={`${value.value}`}>
                {value.label}
              </SelectItem>
            );
          })}
        </SelectContent>
      </Select>
      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default deprecatedWithRouter(DiscoveryInterviewSessionDuration);
