import {
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from '@askable/ui/components/ui/dropdown-menu';

import type { FC } from 'react';

type Props = {
  activeOrgId: string | undefined;
  teams: { _id: string; name?: string | null }[];
  handleSwitchTeams: (selectedTeamId: string) => void;
  onSignIntoAnotherTeam?: () => void;
  hideSignIntoAnotherTeam: boolean;
};

export const TeamSwitcher: FC<Props> = ({
  activeOrgId,
  teams,
  handleSwitchTeams,
  onSignIntoAnotherTeam,
  hideSignIntoAnotherTeam,
}) => {
  return (
    <DropdownMenuSub>
      <DropdownMenuSubTrigger>Switch</DropdownMenuSubTrigger>
      <DropdownMenuPortal>
        <DropdownMenuSubContent>
          <DropdownMenuLabel>Teams</DropdownMenuLabel>
          <DropdownMenuRadioGroup
            className="max-h-[70vh] overflow-y-auto"
            value={activeOrgId}
            onValueChange={handleSwitchTeams}
          >
            {teams?.map(team => {
              return (
                <DropdownMenuRadioItem value={team._id} key={team?._id}>
                  {team?.name}
                </DropdownMenuRadioItem>
              );
            })}
          </DropdownMenuRadioGroup>
          {!hideSignIntoAnotherTeam && (
            <>
              <DropdownMenuSeparator />
              <DropdownMenuItem onClick={onSignIntoAnotherTeam}>Sign into another team</DropdownMenuItem>
            </>
          )}
        </DropdownMenuSubContent>
      </DropdownMenuPortal>
    </DropdownMenuSub>
  );
};
