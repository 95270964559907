import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const HeartFilledIcon: CreateIconType = createIcon({
  displayName: 'HeartFilledIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: (
    <path
      d="M5.39665 1.18735C6.16283 1.43055 6.92031 1.90993 7.64654 2.64107C8.37277 1.90993 9.13025 1.43055 9.89643 1.18735C10.8797 0.875226 11.8272 0.969224 12.6438 1.34974C14.243 2.09495 15.2662 3.88962 15.293 5.7L15.2931 5.7111C15.2931 8.33463 13.7358 10.6384 12.1162 12.2373C11.2959 13.0471 10.4293 13.7071 9.67061 14.1693C9.29157 14.4003 8.92936 14.588 8.60481 14.7205C8.29922 14.8452 7.95904 14.9496 7.64654 14.9496C7.33404 14.9496 6.99386 14.8452 6.68827 14.7205C6.36372 14.588 6.00151 14.4003 5.62247 14.1693C4.86377 13.7071 3.99717 13.0471 3.17691 12.2373C1.55729 10.6384 0 8.33463 0 5.7111L8.19564e-05 5.70002C0.0268432 3.88963 1.05009 2.09496 2.64931 1.34974C3.46589 0.969224 4.41335 0.875227 5.39665 1.18735Z"
      fill="currentColor"
    />
  ),
});
