import { AskableIconLoading } from '@askable/ui/components/ui/askable-icon';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { useQuery, useSubscription } from 'urql';

import { Booking } from 'containers/Studies/data/Booking.query';
import { Booking as BookingSubscription } from 'containers/Studies/data/Booking.subscription';

import { EmptyState } from '../components/EmptyState';
import { getPageTitle } from '../utils/getPageTitle';

import { PreviewContainer } from './components/PreviewContainer';
import { TaskContainer } from './components/TaskContainer';

import type { UnmoderatedBookingConfig } from 'generated/graphql';

export const Preview = () => {
  const { t } = useTranslation();
  const params = useParams();
  const { studyId } = params;
  invariant(studyId, 'Study ID is required');

  const [{ data, fetching }] = useQuery({
    query: Booking,
    variables: { id: studyId },
  });

  // TODO: re-hook up subscriptions with bookings to get live updates in the preview
  useSubscription({ query: BookingSubscription, variables: { id: studyId } }, (_, response) => response);

  return (
    <>
      <Helmet>
        <title>
          {getPageTitle(
            t('sections.studies.preview'),
            data?.unmoderatedBooking?.name ?? t('sections.studies.untitledStudy'),
          )}
        </title>
      </Helmet>

      {fetching && !data?.unmoderatedBooking ? (
        <div className="flex h-full w-full items-center justify-center">
          <AskableIconLoading />
        </div>
      ) : null}

      {!data?.unmoderatedBooking ? <EmptyState title={t('sections.studies.studyNotFound')} /> : null}

      {!fetching && data?.unmoderatedBooking ? (
        <PreviewContainer
          studyName={data?.unmoderatedBooking.name || t('sections.studies.untitledStudy')}
          studyConfig={data?.unmoderatedBooking.config?.unmoderated as UnmoderatedBookingConfig}
        >
          <TaskContainer studyConfig={data?.unmoderatedBooking.config?.unmoderated as UnmoderatedBookingConfig} />
        </PreviewContainer>
      ) : null}
    </>
  );
};
