import { useToken } from '@chakra-ui/react';
import { useMemo } from 'react';
import ReactSelectElement from 'react-select';
import Creatable from 'react-select/creatable';

import type { GroupBase, Props, StylesConfig, MultiValue } from 'react-select';
import type { CreatableProps } from 'react-select/creatable';

export function useSelectStyles<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>() {
  const [gray500, gray600, blue500] = useToken('colors', ['gray.500', 'gray.600', 'blue.500']);
  const [borderRadius] = useToken('radii', ['md']);

  const styleConfig = useMemo<StylesConfig<Option, IsMulti, Group>>(() => {
    return {
      container: p => {
        return {
          ...p,
          // Cannot set height 100% as default because this component doesn't recognize a parent element height when parent is display grid
          height: 'auto',
          width: '100%',
        };
      },
      control: (baseStyle, state) => {
        return {
          ...baseStyle,
          height: '100%',
          borderRadius,
          borderColor: state.isDisabled ? baseStyle.borderColor : gray500,
          boxShadow: state.isFocused ? `0 0 0 1px ${blue500}` : baseStyle.boxShadow,
          ':hover': {
            borderColor: gray600,
          },
        };
      },
    };
  }, []);

  return styleConfig;
}

export function ReactSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: Props<Option, IsMulti, Group>) {
  const styleConfig = useSelectStyles<Option, IsMulti, Group>();
  return <ReactSelectElement {...props} styles={styleConfig} />;
}

export function CreatableSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: CreatableProps<Option, IsMulti, Group>) {
  const styleConfig = useSelectStyles<Option, IsMulti, Group>();
  return <Creatable autoFocus {...props} styles={styleConfig} />;
}

export type { MultiValue };
