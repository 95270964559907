import { graphql } from 'src/graphql';

export const UpdateUnmoderatedBooking = graphql(`
  mutation UpdateUnmoderatedBooking($input: UpdateUnmoderatedBookingInput!) {
    updateUnmoderatedBooking(input: $input) {
      _id
      name
      type
      status
    }
  }
`);
