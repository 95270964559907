/* eslint-disable max-lines */
import { useMutation, useQuery } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { Button, AnchorButton } from '@askable/ui/components/ui/button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@askable/ui/components/ui/dropdown-menu';
import { toast } from '@askable/ui/components/ui/sonner';
import { cn } from '@askable/ui/lib/utils';
import find from 'lodash/find';
import get from 'lodash/get';
import size from 'lodash/size';
import { CircleAlert, ChevronDown, UserCircle } from 'lucide-react';
import momentTimezone from 'moment-timezone';
import { useEffect, useState } from 'react';
import { graphql } from 'src/graphql';

import { Dialog, LoadingOverlay } from 'components/common';
import { useConnectedClient } from 'context/ConnectedClientContext';
import cancelSessionMutation from 'data/mutations/booking/cancelSession';
import { SearchAskableMeeting } from 'data/queries/askable_video/SearchAskableMeeting.query';
import fetchParticipantSessionsSearch from 'data/queries/bookingSubmission/fetchParticipantSessionsSearch';
import { bookingUtils } from 'lib/booking';
import { BOOKING_CONFIG_PARTICIPANT_AGREEMENT_TYPE, SUBMISSION_AGREEMENT_STATUS } from 'lib/constants';
import { utils } from 'lib/utils';

import ActionsContainer from './actionsContainer';
import AssignSessionTo from './assignSessionTo';

import './sessionDetailsPopover.scss';

const SessionMeetingLinkQuery = graphql(`
  query FetchSessionMeetingLink($id: ID!) {
    bookingSessionByID(id: $id) {
      _id
      MeetingLink
    }
  }
`);

function SessionDetailsPopover(props: any) {
  const [sessionStatusSelected, setSessionStatusSelected] = useState();
  const { details } = useConnectedClient();
  const { data: askableVideoData } = useQuery(SearchAskableMeeting, {
    client: props.client,
    variables: {
      input: {
        _booking_id: props.booking?._id || '',
        _session_id: props.sessionSelected?._id || '',
      },
    },
    skip: !props.sessionSelected?._id || !props.booking?._id || !bookingUtils.isAskableLive(props.booking),
  });

  const { data: meetingLinkData } = useQuery(SessionMeetingLinkQuery, {
    client: props.client,
    variables: {
      id: props.sessionSelected?._id,
    },
    skip: !props.sessionSelected?._id || !props.booking?._id || !bookingUtils.isAskableLive(props.booking),
  });

  const [displayCancelSessionDialog, setDisplayCancelSessionDialog] = useState(false);
  const [participantSelected, setParticipantSelected] = useState();
  const [openAssignSessionTo, setOpenAssignSessionTo] = useState(false);
  const [researcher, setResearcher] = useState({});

  useEffect(() => {
    const sessionResearchers = bookingUtils.getBookingResearchers(
      props.sessionTimes,
      details?._id!,
      `${details?.name?.firstname} ${details?.name?.lastname}`,
    );
    let researcherData;
    if (get(props, 'sessionSelected._researcher_user_id')) {
      researcherData = find(
        sessionResearchers,
        (item: any) => item._id.toString() === props.sessionSelected._researcher_user_id.toString(),
      );
    } else {
      researcherData = find(sessionResearchers, (item: any) => item._id.toString() === details?._id);
    }

    setResearcher(researcherData);
  }, [props]);

  const [cancelSession] = useMutation(cancelSessionMutation, {
    client: props.client,
  });

  if (!props.sessionSelected) {
    return null;
  }

  const { start, end, CurrentStatus, participants } = props.sessionSelected;
  const isHappeningOrInPast = momentTimezone().tz(props.timezone).isAfter(start);

  const sessionStatus = bookingUtils.getSessionStatus(
    CurrentStatus,
    props.sessionSelected,
    participants,
    props.timezone,
  );

  // Last time a participant took action to change his status
  const updatedTimestamp = get(CurrentStatus, `history[${get(sessionStatus, 'confirmation_field')}]`);
  const onDeleteSession = () => {
    // If should double check whether the client really wants to delete a session when a session is either Confirmed or Waitlisted
    if (get(sessionStatus, 'label') === 'Confirmed' || get(sessionStatus, 'label') === 'Waitlisted') {
      setDisplayCancelSessionDialog(true);
    } else {
      onConfirmDelete();
    }
  };

  const onConfirmDelete = () => {
    if (bookingUtils.isInDraft(props.booking)) {
      props.onDeleteSession();
    } else {
      cancelSession({
        variables: {
          booking_id: props.booking._id,
          session_id: get(props, 'sessionSelected._id'),
        },
      }).catch(e => {
        toast.error(get(e, 'graphQLErrors[0].message') ? e.graphQLErrors[0].message : 'Error saving study');
      });
    }
    props.onClose();
  };

  const renderParticipantsTime = (user: any) => {
    if (!start || !end || !user) return null;

    let userTimeZone = props.timezone;
    if (get(user, 'location.timezone')) {
      userTimeZone = get(user, 'location.timezone');
    }
    const clientStartSession = momentTimezone(start).tz(props.timezone).format('h:mm');
    const participantStartSession = momentTimezone(start).tz(userTimeZone).format('h:mm');
    const participantEndSession = momentTimezone(end).tz(userTimeZone).format('h:mma');
    const participantDateSession = momentTimezone(start).tz(userTimeZone).format('ddd DD');
    const participantName = get(user, 'meta.identity.firstname');

    if (clientStartSession !== participantStartSession) {
      return (
        <p className="participantTime">{`${participantDateSession}, ${participantStartSession} - ${participantEndSession} for ${participantName}`}</p>
      );
    }
    return null;
  };

  const renderWaitlistedParticipantsForSession = () => {
    // It should only render a specific area for waitlisted people if the session is confirmed
    if (
      get(sessionStatus, 'label') !== 'Confirmed' &&
      get(sessionStatus, 'label') !== 'Cancelled' &&
      get(sessionStatus, 'class') !== 'invited'
    ) {
      return null;
    }

    return (
      <Query
        query={fetchParticipantSessionsSearch}
        variables={{
          _booking_id: props.booking._id,
          _session_id: [get(props, 'sessionSelected._id')],
          status: [2],
          cancel: [0],
        }}
        client={props.client}
      >
        {({ loading, data }: any) => {
          if (loading) return <LoadingOverlay style={{ opacity: 0.8 }} />;
          if (size(data.participantSessionsSearch) === 0) return null;

          return (
            <div className="waitlistedSessionStatus">
              {data?.participantSessionsSearch.map(renderWaitlistedParticipantsContent)}
            </div>
          );
        }}
      </Query>
    );
  };

  const renderWaitlistedParticipantsContent = (participant: any) => {
    return (
      <div>
        <div key={participant._id} className="waitlistedSession flex-between flex gap-2">
          <div className="flex w-full gap-2">
            <UserCircle className="mt-1 h-4 w-4" height="20" width="20" />
            <div className="font-semibold">
              (Waitlisted) {get(participant, 'user.meta.identity.firstname')}{' '}
              {get(participant, 'user.meta.identity.lastname')}
            </div>
          </div>
          <div>{renderActionsContainer(participant)}</div>
        </div>
        {bookingUtils.isFocusGroup(props.booking) && renderParticipantsTime(get(participant, 'user'))}
      </div>
    );
  };

  const renderSessionStatus = () => {
    // Available Session
    if (get(sessionStatus, 'label') === '') return null;

    // Checks whether or not is a focus group
    const isFocusGroup = bookingUtils.isFocusGroup(props.booking);
    if (!isFocusGroup) {
      return <div className="sessionStatus">{renderSessionStatusContent(CurrentStatus)}</div>;
    }
    // Run a new query to get everyone that is important to be listed in a focus group with all their information
    return (
      <Query
        query={fetchParticipantSessionsSearch}
        variables={{
          _booking_id: props.booking._id,
          _session_id: [get(props, 'sessionSelected._id')],
          status: [1, 4],
        }}
        client={props.client}
      >
        {({ loading, data }: any) => {
          if (loading) return <LoadingOverlay style={{ opacity: 0.8 }} />;
          return (
            <div className="sessionStatus">{data?.participantSessionsSearch?.map(renderSessionStatusContent)}</div>
          );
        }}
      </Query>
    );
  };

  const renderActionsContainer = (participant: any) => {
    const participantSessionStatus = bookingUtils.getSessionStatus(
      participant,
      get(props, 'sessionSelected'),
      participants,
      props.timezone,
    );
    return (
      <DropdownMenu
        onOpenChange={(open: boolean) => {
          if (open) {
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ label: string; labelAction: st... Remove this comment to see the full error message
            setSessionStatusSelected(participantSessionStatus);
            setParticipantSelected(participant);
          }
        }}
      >
        <DropdownMenuTrigger asChild>
          <Button variant="ghost">
            Actions <ChevronDown className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="z-[1000]">
          <ActionsContainer
            client={props.client}
            booking={props.booking}
            sessionStatus={sessionStatusSelected}
            sessionSelected={get(props, 'sessionSelected')}
            participantSelected={participantSelected}
            timezone={props.timezone}
            context={props.context}
            onSendMessage={props.onSendMessage}
            confirmedParticipants={props.confirmedParticipants}
            onClose={() => {
              // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
              setSessionStatusSelected();
              props.onClose();
            }}
            viewParticipantsScreen={props.viewParticipantsScreen}
          />
        </DropdownMenuContent>
      </DropdownMenu>

      //   <div
      //     className="actionsContainer"
      //     onClick={(event) => {
      //       event.preventDefault();
      //       setOpenActions(true);
      //       // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ label: string; labelAction: st... Remove this comment to see the full error message
      //       setSessionStatusSelected(participantSessionStatus);
      //       setParticipantSelected(participant);
      //       setAnchorElement(event.currentTarget);
      //     }}
      //   >
      //     <a>Actions</a>
      //     <ChevronDown style={{ width: 14, height: 14 }} color="#3b99f0" />
      //   </div>
    );
  };

  const renderRecordingLink = (sessionSelected: any) => {
    // If session is not confirmed, return null
    if (get(sessionSelected, 'CurrentStatus.status') !== 1 || get(sessionSelected, 'CurrentStatus.cancel') !== 0) {
      return null;
    }

    // Checks if it's a past session or not
    // If session has passed already it should display the recording link
    // Otherwise it should display the link to the meeting room
    if (!utils.isPast(sessionSelected.end)) {
      const meetingLink = meetingLinkData?.bookingSessionByID?.MeetingLink || '#';
      return (
        <div className="flex gap-2">
          <AnchorButton href={meetingLink} target="_blank" variant="primary">
            Meeting link
          </AnchorButton>
        </div>
      );
    }

    const meetingCode = askableVideoData?.searchAskableMeeting?.[0]?.code;
    const roomId = askableVideoData?.searchAskableMeeting?.[0]?.rooms?.[0]?._id;
    if (meetingCode && roomId) {
      return (
        <Button
          onClick={() => {
            window.open(
              `/askable-sessions/${meetingCode}/playback/${roomId}?teamId=${props.booking?._team_id}`,
              '_blank',
            );
          }}
        >
          <img src="/icons/SessionsIcon.svg" alt="View recording" className="h-4 w-4" />
          View session
        </Button>
      );
    }
    return null;
  };

  const renderSessionStatusContent = (participant: any) => {
    const participantSessionStatus = bookingUtils.getSessionStatus(
      participant,
      get(props, 'sessionSelected'),
      participants,
      props.timezone,
    );
    const participantFeedback = get(participant, 'feedback.participant', '');
    const hasRating = get(participant, 'rating.engagement') || get(participant, 'rating.overall');

    let avgRating = 0;
    if (hasRating) {
      const { rating } = participant;
      avgRating = get(rating, 'overall');
      if (get(participant, 'rating.engagement')) {
        avgRating = (get(rating, 'engagement') + get(rating, 'punctuality')) / 2;
      }
    }

    return (
      <>
        <div
          key={participant._id}
          className={`sessionStatusInternal flex-between flex gap-2 status-${get(participantSessionStatus, 'class')}`}
        >
          <div className="flex w-full gap-2">
            <UserCircle className="mt-1 h-4 w-4" />

            <div className="personContextInternalContainer flex flex-col gap-1">
              <div className="font-semibold">
                {get(participant, 'user.meta.identity.firstname')} {get(participant, 'user.meta.identity.lastname')}
              </div>
              <div className="flex flex-col gap-1 text-sm text-muted-foreground">
                {updatedTimestamp && (
                  <p className="updatedTime">
                    {get(participantSessionStatus, 'label')}{' '}
                    {momentTimezone(updatedTimestamp).tz(props.timezone).fromNow()}
                  </p>
                )}
                {bookingUtils.isFocusGroup(props.booking) && renderParticipantsTime(get(participant, 'user'))}

                <div
                  className={cn('font-semibold', {
                    'text-warning': get(participantSessionStatus, 'class') === 'invited',
                    'text-success': get(participantSessionStatus, 'class') === 'confirmed',
                    'text-foreground': get(participantSessionStatus, 'class') === 'completed',
                    'text-destructive': get(participantSessionStatus, 'class') === 'cancelled',
                  })}
                >
                  {get(participantSessionStatus, 'labelAction')}
                </div>

                {renderSessionStatusWarnings(participant)}

                {bookingUtils.isRemote(props.booking) && bookingUtils.isAskableLive(props.booking) && (
                  <div className="pt-2">{renderRecordingLink(get(props, 'sessionSelected'))}</div>
                )}
              </div>
            </div>
          </div>
          <div>{renderActionsContainer(participant)}</div>
        </div>
        {hasRating && (
          <div className="clientFeedback">
            <img alt="clientFeedbackStar" src="/icons/fullStartIcon.svg" className="clientFeedbackStar" />
            <span className="clientFeedbackRating">{`You rated ${avgRating} ${avgRating > 1 ? 'stars' : 'star'}`}</span>
          </div>
        )}
        {participantFeedback && (
          <>
            <div className="participantFeedbackContainer">
              <img
                alt="participantFeedbackIcon"
                src="/icons/participantFeedbackIcon.svg"
                className="participantFeedbackIcon"
              />
              <span className="participantFeedbackTitle">Participant feedback:</span>
            </div>
            <div
              className={`participantFeedback ${get(props, 'booking.config.session.type') === 2 ? 'focusGroup' : ''}`}
            >{`"${participantFeedback}"`}</div>
          </>
        )}
      </>
    );
  };

  const renderSessionStatusWarnings = (participant: any) => {
    if (
      get(props, 'booking.config.participant_agreement.type') ===
        BOOKING_CONFIG_PARTICIPANT_AGREEMENT_TYPE.CUSTOM_AGREEMENT &&
      get(participant, 'status') === 1 &&
      get(participant, 'cancel') === 0 &&
      get(participant, 'Submission.agreement.status') !== SUBMISSION_AGREEMENT_STATUS.COMPLETED
    ) {
      return (
        <div className="status flex items-center gap-1" style={{ color: '#89601F' }}>
          <CircleAlert className="h-4 w-4" /> Agreement not signed
        </div>
      );
    }
  };

  const renderActionContainer = (
    itemId: string,
    iconPath: string,
    label: string,
    action: () => void,
    rightText?: string,
  ) => {
    return (
      <Button className="flex gap-2" onClick={action} id={itemId}>
        <img alt={`icon-${label}`} src={iconPath} className="h-4 w-4" />
        <span>{label}</span>

        {rightText && (
          <div className="rightText">
            <span>{rightText}</span>
          </div>
        )}
      </Button>
    );
  };

  const sessionStatusVisible = get(sessionStatus, 'label') !== '';

  return (
    <div>
      <div className="sessionDetailsComponentInternal w-full">
        <div className="flex-between align-center mb-4 flex gap-2">
          <div className="w-full">
            <div className="day">{momentTimezone(start).tz(props.timezone).format('dddd DD MMMM')}</div>
            <time className="font-semibold">
              {momentTimezone(start).tz(props.timezone).format('h:mm')} -{' '}
              {momentTimezone(end).tz(props.timezone).format('h:mma')}
            </time>
            {!bookingUtils.isFocusGroup(props.booking) &&
              renderParticipantsTime(get(props, 'sessionSelected.CurrentStatus.user'))}
          </div>
          {researcher && (
            <div
              // @ts-expect-error ts-migrate(2339) FIXME: Property '_id' does not exist on type '{}'.
              key={researcher._id}
              className="flex h-10 w-10 shrink-0 items-center justify-center rounded-full font-bold text-background"
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'color' does not exist on type '{}'.
              style={{ backgroundColor: researcher.color }}
            >
              {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'name' does not exist on type '{}'. */}
              <div className="researcherInitials">{researcher.name}</div>
            </div>
          )}
        </div>
        {sessionStatusVisible && renderSessionStatus()}
        {(get(sessionStatus, 'label') === 'Confirmed' ||
          get(sessionStatus, 'label') === 'Cancelled' ||
          get(sessionStatus, 'class') === 'invited') &&
          renderWaitlistedParticipantsForSession()}
        {!isHappeningOrInPast && (
          <div className={cn('flex gap-2', sessionStatusVisible && 'ml-6 mt-2')}>
            <AssignSessionTo
              client={props.client}
              session={props.sessionSelected}
              open={openAssignSessionTo}
              booking={props.booking}
              context={props.context}
              onClose={() => setOpenAssignSessionTo(false)}
              onAssignSessionToResearcher={props.onAssignSessionToResearcher}
              onCloseDetails={props.onClose}
              onFinishAssignTo={(newSessionsData: any, newResearcherId: any) => {
                setOpenAssignSessionTo(false);
                const sessionResearchers = bookingUtils.getBookingResearchers(
                  newSessionsData.session,
                  details?.id!,
                  `${details?.name?.firstname} ${details?.name?.lastname}`,
                );
                const researcherData = find(
                  sessionResearchers,
                  (item: any) => item._id.toString() === newResearcherId.toString(),
                );

                setResearcher(researcherData);
              }}
            />
            {/* If the session is not available, we shouldnt give the option to delete the session */}
            {get(sessionStatus, 'label') === '' &&
              renderActionContainer(
                'deleteSessionAction',
                '/icons/deleteSession.svg',
                'Delete this session',
                onDeleteSession,
              )}
          </div>
        )}
      </div>

      <Dialog
        title="Are you sure you want to delete this session?"
        modal={false}
        open={displayCancelSessionDialog}
        onRequestClose={() => setDisplayCancelSessionDialog(false)}
        styles={{ width: '30%' }}
        bodyStyle={{ zIndex: 2200 }}
        customActions={[
          {
            label: 'No',
            primary: true,
            customFunction: () => setDisplayCancelSessionDialog(false),
          },
          {
            label: 'Yes',
            buttonType: 'normal',
            containerButtonStyle: { marginLeft: '20px' },
            customFunction: () => {
              setDisplayCancelSessionDialog(false);
              onConfirmDelete();
            },
          },
        ]}
      >
        Note: {get(CurrentStatus, 'user.meta.identity.firstname')} will get notified
      </Dialog>
    </div>
  );
}

export default SessionDetailsPopover;
