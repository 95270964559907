import { Button } from '../button';
import { Tooltip, TooltipTrigger, TooltipContent } from '../tooltip';

import type { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  isDisabled: boolean;
  label: string;
  onClick: () => void;
}

export const ControlButton = ({ children, isDisabled, label, onClick }: Props) => {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          className="[drop-shadow-[0_0_2px_rgba(0,0,0,0.4)]] cursor-pointer text-lightest [text-shadow:_0_0_2px_rgba(0,0,0,0.4)]
            hover:bg-lightest/10 focus:bg-lightest/10 active:bg-lightest/20"
          disabled={isDisabled}
          size="icon"
          variant="ghost"
          onClick={onClick}
        >
          {children}
        </Button>
      </TooltipTrigger>
      <TooltipContent className="bg-darkest text-lightest">{label}</TooltipContent>
    </Tooltip>
  );
};
