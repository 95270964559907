import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const LightModeIcon: CreateIconType = createIcon({
  displayName: 'LightModeIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: [
    <path
      d="M14.8579 7.99997L13.7598 7.99054"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M12.8555 3.15759L12.0723 3.92742"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M3.14453 3.15759L3.9277 3.92742"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M8.0293 1.14252L8.03874 2.24066"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M2.24072 7.99997L1.14258 7.99054"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M3.92771 12.0725L3.14453 12.8423"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M12.0723 12.0725L12.8554 12.8423"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M8.03809 13.7593L8.04752 14.8574"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M8.03783 11.2516C9.8337 11.2516 11.2895 9.7958 11.2895 7.99994C11.2895 6.20406 9.8337 4.74823 8.03783 4.74823C6.24197 4.74823 4.78613 6.20406 4.78613 7.99994C4.78613 9.7958 6.24197 11.2516 8.03783 11.2516Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
