import omit from 'omit-deep';

export const fullscreen = {
  open: (elem: any) => {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  },
  close: () => {
    if (typeof document === 'undefined') {
      throw new Error('document is undefined');
    }
    document.exitFullscreen();
  },
};

export const downloadTextFile = (() => {
  if (typeof document === 'undefined') {
    return () => undefined;
  }
  const a: HTMLAnchorElement = document.createElement('a');
  document.body.appendChild(a);
  a.setAttribute('style', 'display: none');
  return (data: string, fileName: string) => {
    const blob = new Blob([data], { type: 'octet/stream' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    // remove anchor element after 2 seconds
    setTimeout(() => {
      a.remove();
    }, 2000);
  };
})();

// Deeply removes typenames
export function removeTypeNames<T extends object>(obj: T): T {
  return omit(obj, ['__typename']) as T;
}

export function checkIsValidColor(color: string): boolean {
  const reg = /^#([0-9a-f]{3}){1,2}$/i;
  return reg.test(color);
}

export function sanitizeHexColor(color: string) {
  return `#${color.replace(/[^a-fA-F0-9]/gi, '').substring(0, 6)}`;
}
