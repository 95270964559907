import { useSearchParams } from 'react-router-dom';

import type { UnmoderatedBookingConfig } from 'generated/graphql';

export const useTaskNavigation = (studyConfig: UnmoderatedBookingConfig) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeBlockId = searchParams.get('task_id') ?? 'welcome';

  const taskSequence = ['welcome', ...studyConfig.task_blocks.map(task => task?._id), 'thank_you'];
  const currentIndex = taskSequence.indexOf(activeBlockId);
  const totalSteps = taskSequence.length - 1;

  const handleProgress = (direction: 'previous' | 'next') => {
    const newIndex = direction === 'next' ? currentIndex + 1 : currentIndex - 1;

    const newBlockId = taskSequence[newIndex];
    if (newBlockId) {
      const newParams = new URLSearchParams(searchParams);
      newParams.set('task_id', newBlockId);
      setSearchParams(newParams);
    }
  };

  const setActiveBlock = (newBlockId: string) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set('task_id', newBlockId);
    setSearchParams(newParams);
  };

  return {
    activeBlockId,
    currentStep: currentIndex,
    totalSteps,
    isFirstScreen: currentIndex === 0,
    isLastScreen: currentIndex === totalSteps,
    handleProgress,
    setActiveBlock,
  };
};
