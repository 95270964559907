import { Box, createMultiStyleConfigHelpers, HStack, useMultiStyleConfig, forwardRef, Text } from '@chakra-ui/react';
import ReactLinkify from 'react-linkify';

import { LogoIcon } from '../Icons';

import type { HTMLChakraProps, ThemingProps } from '@chakra-ui/react';
import type { ReactNode } from 'react';

const helpers: ReturnType<typeof createMultiStyleConfigHelpers<'message' | 'link'>> = createMultiStyleConfigHelpers([
  'message',
  'link',
]);

export const MessageComponent: ReturnType<typeof helpers.defineMultiStyleConfig> = helpers.defineMultiStyleConfig({
  baseStyle: {
    link: {
      _hover: {
        textDecoration: 'underline',
      },
    },
    message: {
      display: 'inline-block',
      py: 2,
      px: 3,
      borderRadius: 'xl',
      whiteSpace: 'pre-wrap',
    },
  },
  variants: {
    system: ({ colorMode }) => {
      const isLight = colorMode === 'light';

      return {
        link: {
          color: isLight ? 'blue.600' : 'blue.200',
        },
        message: {
          py: 4,
          borderRadius: 'md',
          border: '1px',
          borderColor: 'gray.300',
          w: 'full',
        },
      };
    },
    sent: ({ colorMode }) => {
      const isLight = colorMode === 'light';

      return {
        link: {
          color: isLight ? 'blue.200' : 'blue.600',
        },
        message: {
          color: isLight ? 'white' : 'gray.800',
          bg: isLight ? 'blue.800' : 'blue.200',
        },
      };
    },
    received: ({ colorMode }) => {
      const isLight = colorMode === 'light';

      return {
        message: {
          color: isLight ? 'gray.800' : 'white',
          bg: isLight ? 'gray.100' : 'gray.700',
        },
        link: {
          color: isLight ? 'blue.600' : 'blue.200',
        },
      };
    },
  },
  defaultProps: {
    variant: 'sent',
  },
});

export interface MessageComponentProps extends HTMLChakraProps<'div'>, ThemingProps<'MessageComponent'> {}

export const Message: ReturnType<typeof forwardRef<MessageComponentProps, 'div'>> = forwardRef<
  MessageComponentProps,
  'div'
>((props, ref) => {
  const { size, variant, children, ...rest } = props;
  const styles = useMultiStyleConfig('MessageComponent', { size, variant });

  const decorator = (href: string, text: string): ReactNode => (
    <Box href={href} as="a" __css={styles.link} target="_blank">
      {text}
    </Box>
  );

  return (
    <Box ref={ref} __css={styles.message} {...rest}>
      {variant === 'system' && (
        <HStack mb="2">
          <LogoIcon height={5} width={5} />
          <Text fontWeight="semibold">Askable team</Text>
        </HStack>
      )}
      <ReactLinkify componentDecorator={decorator}>{children}</ReactLinkify>
    </Box>
  );
});
