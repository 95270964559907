import { getColorValue } from '@askable/ui/lib/utils';

import type { IconProps } from '.';

export const Plus = ({ bgColor = 'foreground', size = 48 }: IconProps) => {
  const bgHsl = getColorValue(bgColor);

  return (
    <svg fill="none" viewBox="0 0 64 64" width={size} height={size}>
      <path fill={bgHsl} d="M32 3.6c2 0 3.6 1.5 3.6 3.5V57a3.6 3.6 0 1 1-7.2 0V7c0-2 1.6-3.5 3.6-3.5Z" />
      <path fill={bgHsl} d="M3.6 32c0-2 1.5-3.6 3.5-3.6H57a3.6 3.6 0 0 1 0 7.2H7c-2 0-3.5-1.6-3.5-3.6Z" />
    </svg>
  );
};
