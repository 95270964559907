import { ApolloClient, from, HttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import fetch from 'cross-fetch';
import { createClient } from 'graphql-ws';

import { globalVariables } from 'lib/globalVariables';
import { graphqlErrorHandler } from 'lib/graphqlErrorHandler';
import { localStorage } from 'lib/storage';
import { utils } from 'lib/utils';
import { AUTH_CONFIG } from 'network/auth';

// Set it up the HTTP Link
const httpLink = new HttpLink({
  uri: globalVariables.getEnvironmentVariables().graphqlURI,
  fetch,
});

function createWebSoketClient(): GraphQLWsLink {
  return new GraphQLWsLink(
    createClient({
      url: globalVariables.getEnvironmentVariables().subscriptionURI,
      connectionParams: async () => {
        const token = (await AUTH_CONFIG.getToken()) ?? null;
        if (!token) {
          return {};
        }

        return {
          Authorization: token,
        };
      },
    }),
  );
}

const wsLink = createWebSoketClient();

const socketLink = split(
  // split based on operation type
  ({ query }) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'operation' does not exist on type 'Opera... Remove this comment to see the full error message
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
);

const authLink = setContext(async (_arg1, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = (await AUTH_CONFIG.getToken()) ?? '';
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ?? '',
      'x-graphql-client-name': 'clients',
      'x-graphql-client-version': import.meta.env.VITE_APP_VERSION ?? 'N/A',
      Accept: 'application/json',
      ...(localStorage.get('connectedAsClient') ? { 'x-askable-admin': true } : {}),
    },
  };
});

// Set it up the Retry Link for an offline strategy
const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: error => !!error,
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  graphqlErrorHandler({
    graphqlErrors: graphQLErrors,
    networkError,
  });
});

// Create the Apollo Client with the caching system and links
export const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      User: {
        keyFields: false,
      },
      Query: {
        fields: {
          findParticipantsSubmission: {
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ keyFields: string[]; keyArgs: never[]; mer... Remove this comment to see the full error message
            keyFields: ['_booking_id'],
            // Don't cache separate results based on
            // any of this field's arguments.
            keyArgs: [],
            // Concatenate the incoming list items with
            // the existing list items.
            // @ts-expect-error FIXME - offset does not exist?
            merge(existing = [], incoming, { args: { offset = 0 } }) {
              if (offset === 0) return [...incoming];
              return [...existing, ...incoming];
            },
          },
        },
      },
    },
  }),
  link: from([errorLink, authLink, socketLink, retryLink, httpLink]),
  // assumeImmutableResults: true,
  name: 'Clients app',
  version: utils.getBuildInfo().version,
});
