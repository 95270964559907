import { useMutation, useQuery } from '@apollo/client';
import { Button } from '@askable/ui/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuLabel,
} from '@askable/ui/components/ui/dropdown-menu';
import _ from 'lodash';
import { User } from 'lucide-react';
import { useState } from 'react';

import { LoadingOverlay } from 'components/common';
import { useConnectedClient } from 'context/ConnectedClientContext';
import updateSessionMutation from 'data/mutations/booking/updateSession';
import fetchProjectById from 'data/queries/project/fetchProjectById';
import fetchUsersByTeamId from 'data/queries/teams/fetchUsersByTeamId';
import { utils } from 'lib/utils';

import type { AppContextType } from 'components/common/Askable/Providers/appProvider';

import './assignSessionToStyles.scss';

// TODO: Fix these any types
interface Props {
  client: any;
  booking: any;
  session: any;
  open: boolean;
  onClose: () => void;
  onFinishAssignTo: (session: any, userId: string) => void;
  onCloseDetails: () => void;
  onAssignSessionToResearcher?: (sessionId: string, userId: string) => void;
  context: AppContextType;
}

function ChangeFacilitatorContactPopover(props: Props) {
  const { details } = useConnectedClient();
  const [loadingUI, setLoadingUI] = useState(false);

  const [updateSession] = useMutation(updateSessionMutation, {
    client: props.client,
  });

  const { loading: loadingUsersByTeam, data: usersByTeamData } = useQuery(fetchUsersByTeamId, {
    variables: {
      _id: _.get(props, 'booking._team_id'),
    },
    client: props.client,
  });

  const { loading: loadingProjectById, data: projectByIdData } = useQuery(fetchProjectById, {
    variables: {
      id: _.get(props, 'booking._project_id'),
    },

    client: props.client,
  });

  const onAssignSessionTo = async (user: any) => {
    setLoadingUI(true);

    if (props.onAssignSessionToResearcher) {
      props.onAssignSessionToResearcher(_.get(props.session, '_id'), user._id);
      props.onCloseDetails();
      setLoadingUI(false);
    } else {
      const newResearcher = { _researcher_user_id: user._id };

      // Changes the facilitator
      await updateSession({
        variables: {
          booking_id: props.booking._id,
          session_id: _.get(props.session, '_id'),
          session: newResearcher,
        },
      }).then(sessionUpdated => {
        setLoadingUI(false);
        props.onFinishAssignTo(sessionUpdated.data.updateSession, user._id);
      });
    }
  };

  const renderInviteTeamMemberLink = () => {
    return (
      <DropdownMenuItem asChild>
        <a
          className="inviteTeammate"
          onClick={() => {
            props.context.onOpenInviteTeamMemberModal(null, true);
          }}
        >
          + Invite a teammate to Askable
        </a>
      </DropdownMenuItem>
    );
  };

  const renderUsers = (user: any) => {
    return (
      <DropdownMenuItem id={`__${user._id}`} key={user._id} className="userRow" onClick={() => onAssignSessionTo(user)}>
        <p className="formField">
          {_.get(user, 'User.meta.identity.firstname')} {_.get(user, 'User.meta.identity.lastname')}
        </p>
        <p className="userField">{_.get(user, 'User.email')}</p>
        <p className="userField">{utils.formatPhoneNumber(_.get(user, 'User.contact.phone.mobile'))}</p>
      </DropdownMenuItem>
    );
  };

  const renderNoUsersContainer = () => {
    return (
      <div className="noUsersContainer">
        <p>You have no team members</p>
        {renderInviteTeamMemberLink()}
      </div>
    );
  };

  const renderFacilitators = () => {
    const sessionUser = _.get(props, 'session._researcher_user_id') || details?._id;
    const usersInTeam = _.get(usersByTeamData, 'teamById.users');
    const projectUsers = _.get(projectByIdData, 'projectByID.users') || [];
    const allUsersToRender = Array.from(new Set(usersInTeam.concat(projectUsers)));
    const usersToRender = _.filter(
      allUsersToRender,
      (users: any) => users.status === 1 && users._id.toString() !== sessionUser.toString(),
    );

    if (_.size(usersInTeam) === 0) return renderNoUsersContainer();

    return (
      <>
        {_.map(usersToRender, user => renderUsers(user))}
        {renderInviteTeamMemberLink()}
      </>
    );
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button>
          <User className="h-4 w-4" />
          Assign to...
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="z-[2101]">
        {loadingUI || loadingUsersByTeam || loadingProjectById ? (
          <DropdownMenuLabel>
            <LoadingOverlay style={{ opacity: 0.8 }} />
          </DropdownMenuLabel>
        ) : null}
        {!loadingUsersByTeam && !loadingProjectById ? renderFacilitators() : null}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default ChangeFacilitatorContactPopover;
