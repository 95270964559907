import { z } from 'zod';

const baseBlockSchema = z.object({
  title: z.string().min(1, { message: 'title.required' }),
  instructions: z.string().optional(),
});

const welcomeBlockSchema = baseBlockSchema.extend({
  type: z.literal('welcome'),
});

const thankYouBlockSchema = baseBlockSchema.extend({
  type: z.literal('thank_you'),
});

const figmaPrototypeBlockSchema = baseBlockSchema.extend({
  type: z.literal('figma_prototype'),
  figma_prototype: z.object({
    file_id: z.string().min(1, { message: 'figmaPrototypeFileId.required' }),
    start_screen_id: z.string().min(1, { message: 'figmaPrototypeStartScreenId.required' }),
    goal_screen_id: z.string().min(1, { message: 'figmaPrototypeGoalScreenId.required' }),
  }),
  is_recording_enabled: z.boolean(),
});

const multipleChoiceBlockSchema = baseBlockSchema.extend({
  type: z.literal('multiple_choice_question'),
  choices: z.array(z.string()),
});

export const blockSchema = z.discriminatedUnion('type', [
  welcomeBlockSchema,
  thankYouBlockSchema,
  figmaPrototypeBlockSchema,
  multipleChoiceBlockSchema,
]);

export const taskBlockSchema = z.discriminatedUnion('type', [figmaPrototypeBlockSchema, multipleChoiceBlockSchema]);

export const blockFormSchema = z.object({
  title: z.string().min(1, { message: 'title.required' }),
  instructions: z.string().optional(),
  type: z.string(),
  is_recording_enabled: z.boolean(),
  figma_prototype: z.object({
    file_id: z.string().min(1, { message: 'figmaPrototypeFileId.required' }),
    start_screen_id: z.string().min(1, { message: 'figmaPrototypeStartScreenId.required' }),
    goal_screen_id: z.string().min(1, { message: 'figmaPrototypeGoalScreenId.required' }),
  }),
  choices: z.array(z.string()),
});
