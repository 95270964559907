import { Button } from '@askable/ui/components/ui/button';
import { ChevronDown, CircleHelp, Monitor, MonitorSmartphone, Smartphone } from 'lucide-react';
import { useState } from 'react';
import { useWatch, useFormState, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'urql';

import { AGE_LIMIT } from 'containers/Studies/data/schemas/recruitSchema';

import { SummaryItem } from '../components/SummaryItem';
import { IndustryList } from '../data/IndustryList.query';

import type { RecruitFormFields } from '../Recruit';
import type { IndustryAndOccupationType } from '../components/IndustryAndOccupation';
import type { DEVICE_OPTIONS } from 'containers/Studies/data/schemas/recruitSchema';
import type { LucideIcon } from 'lucide-react';

type DeviceType = (typeof DEVICE_OPTIONS)[number];

// For translation keys
type OptionValue = NonNullable<
  | RecruitFormFields['gender']
  | (RecruitFormFields['language_fluency'] extends (infer T)[] ? T : never)
  | (RecruitFormFields['marital_status'] extends (infer T)[] ? T : never)
  | (RecruitFormFields['occupational_status'] extends (infer T)[] ? T : never)
  | (RecruitFormFields['business_ownership'] extends (infer T)[] ? T : never)
>;

const deviceIconByType: Record<DeviceType, LucideIcon> = {
  any_device: MonitorSmartphone,
  desktop: Monitor,
  mobile: Smartphone,
};

const FORM_FIELDS = [
  'participants',
  'device',
  'locations',
  'age_range',
  'gender',
  'industry_and_occupation',
  'language_fluency',
  'education_level',
  'marital_status',
  'occupational_status',
  'business_ownership',
  'screener_questions',
] as const;

export const RecruitSummary = () => {
  const { t } = useTranslation();
  const [isOpenInfo, setIsOpenInfo] = useState(false);
  const { handleSubmit, control } = useFormContext<RecruitFormFields>();
  const { isSubmitting } = useFormState();
  const formValues = useWatch({
    control,
    name: FORM_FIELDS,
  });

  const [{ data }] = useQuery({
    query: IndustryList,
  });

  const [
    participants,
    device,
    locations,
    age_range,
    gender,
    industry_and_occupation,
    language_fluency,
    education_level,
    marital_status,
    occupational_status,
    business_ownership,
    screener_questions,
  ] = formValues;

  const Icon = deviceIconByType[device as DeviceType];

  const hasAnyFieldValues = () => {
    return (
      locations?.length > 0 ||
      age_range?.[0] !== AGE_LIMIT.min ||
      age_range?.[1] !== AGE_LIMIT.max ||
      !!gender ||
      (industry_and_occupation?.length ?? 0) > 0 ||
      (language_fluency?.length ?? 0) > 0 ||
      (education_level?.length ?? 0) > 0 ||
      (marital_status?.length ?? 0) > 0 ||
      (occupational_status?.length ?? 0) > 0 ||
      (business_ownership?.length ?? 0) > 0 ||
      (screener_questions?.length ?? 0) > 0
    );
  };

  const showFields = hasAnyFieldValues();

  // @todo hook these up
  const onSubmit = (data: any) => console.log('Form submitted with values', data);
  const onError = (errors: any) => {
    console.log('Form has errors', errors);
  };

  const getTranslatedOptions = <T extends string>(options: T[]): string[] => {
    return options.map(option => {
      return t(`sections.studies.recruit.options.${option as OptionValue}`);
    });
  };

  const getIndustryList = (options: IndustryAndOccupationType[]): string[] => {
    // Flatten the options to a list of industry - occupation names
    const optionsByIndustry = options
      .map(({ industry, occupations }) => {
        if (occupations.length === 0) {
          return [{ industry, occupation: null }];
        }
        return occupations.map(occupation => ({ industry, occupation }));
      })
      .flat();

    return optionsByIndustry.map(item => {
      const industryItem = data?.industryList?.find(industry => industry?._id === item.industry);
      const occupation =
        industryItem?.subcategories?.find(option => option?._id === item.occupation)?.name ??
        t(`sections.studies.recruit.fields.occupationsPlaceholderEmpty`);

      if (!industryItem) {
        return '';
      }

      return `${industryItem?.name} - ${occupation}`;
    });
  };

  return (
    <aside className="recruit-summary lg:pr-4 lg:pt-4">
      <div className="sticky top-4 flex max-h-[calc(100vh_-_5rem)] flex-col gap-5 rounded-xl bg-background p-4 lg:p-6">
        <div className="grid grid-cols-2 divide-x-0.5 rounded-md border-0.5 border-border p-4 text-center">
          <div className="flex flex-col items-center gap-1 truncate text-sm">
            <div className="text-3xl font-semibold">{participants}</div>
            {t('sections.studies.recruit.participants', { count: participants })}
          </div>
          {device ? (
            <div className="flex flex-col items-center gap-1 text-sm">
              <Icon className="h-9 w-9" />
              {t(`sections.studies.recruit.options.${device as DeviceType}`)}
            </div>
          ) : (
            <div className="flex flex-col items-center gap-1 text-sm text-input">
              <MonitorSmartphone className="h-9 w-9" />
              {t('sections.studies.recruit.selectDevice')}
            </div>
          )}
        </div>

        {showFields ? (
          <>
            <div className="font-semibold">{t('sections.studies.recruit.audience')}</div>

            <ul className="flex flex-col gap-2 overflow-auto text-balance text-sm">
              {locations.length > 0 ? (
                <SummaryItem
                  label={t('sections.studies.recruit.fields.locationsTitle')}
                  type="locations"
                  options={locations}
                />
              ) : null}

              {(age_range && age_range[0] !== AGE_LIMIT.min) || age_range[1] !== AGE_LIMIT.max ? (
                <SummaryItem
                  label={t('sections.studies.recruit.fields.ageRangeTitle')}
                  type="age_range"
                  options={age_range}
                  variant="dash-between"
                />
              ) : null}

              {gender ? (
                <SummaryItem
                  label={t('sections.studies.recruit.fields.genderTitle')}
                  type="gender"
                  options={getTranslatedOptions([gender])}
                />
              ) : null}

              {industry_and_occupation &&
              industry_and_occupation.length > 0 &&
              industry_and_occupation[0]?.industry !== '' ? (
                <SummaryItem
                  label={t('sections.studies.recruit.fields.industryAndOccupationTitle')}
                  type="industry_and_occupation"
                  options={getIndustryList(industry_and_occupation)}
                  variant="stacked"
                />
              ) : null}

              {language_fluency && language_fluency.length > 0 ? (
                <SummaryItem
                  label={t('sections.studies.recruit.fields.languageFluencyTitle')}
                  type="language_fluency"
                  options={getTranslatedOptions(language_fluency)}
                />
              ) : null}

              {education_level && education_level.length > 0 ? (
                <SummaryItem
                  label={t('sections.studies.recruit.fields.educationLevelTitle')}
                  type="education_level"
                  options={getTranslatedOptions(education_level)}
                />
              ) : null}

              {marital_status && marital_status.length > 0 ? (
                <SummaryItem
                  label={t('sections.studies.recruit.fields.maritalStatusTitle')}
                  type="marital_status"
                  options={getTranslatedOptions(marital_status)}
                />
              ) : null}

              {occupational_status && occupational_status.length > 0 ? (
                <SummaryItem
                  label={t('sections.studies.recruit.fields.occupationalStatusTitle')}
                  type="occupational_status"
                  options={getTranslatedOptions(occupational_status)}
                />
              ) : null}

              {business_ownership && business_ownership.length > 0 ? (
                <SummaryItem
                  label={t('sections.studies.recruit.fields.businessOwnershipTitle')}
                  type="business_ownership"
                  options={getTranslatedOptions(business_ownership)}
                />
              ) : null}

              {screener_questions && screener_questions.length > 0 ? (
                <li className="flex gap-2">
                  <CircleHelp className="h-4 w-4 text-foreground-subtle" />
                  <div className="flex flex-col gap-1">
                    <div className="text-xs text-foreground-subtle">
                      {t('sections.studies.recruit.fields.screenerQuestionsTitle')}
                    </div>
                    {screener_questions.length}{' '}
                    {t('sections.studies.recruit.screenerQuestions', {
                      count: screener_questions.length,
                    }).toLocaleLowerCase()}
                  </div>
                </li>
              ) : null}
            </ul>
          </>
        ) : null}

        <Button
          className="w-full"
          disabled={isSubmitting}
          onClick={handleSubmit(onSubmit, onError)}
          size="lg"
          type="submit"
          variant="primary"
        >
          {t('sections.studies.recruit.submitStudy')}
        </Button>

        <div
          className="flex flex-col gap-1 text-pretty rounded-md border-0.5 border-border text-sm text-foreground-subtle transition-all
            focus-within:border-input hover:border-input"
        >
          <button
            aria-expanded={isOpenInfo}
            className="flex items-center justify-between gap-2 px-4 py-2 text-start font-medium"
            onClick={() => setIsOpenInfo(!isOpenInfo)}
            type="button"
          >
            {t('sections.studies.recruit.summaryCalloutTitle')}

            <ChevronDown
              className={`h-4 w-4 transform transition-transform duration-200 ${isOpenInfo ? 'rotate-180' : ''}`}
            />
          </button>
          {isOpenInfo ? (
            <div className="px-4 py-2">{t('sections.studies.recruit.summaryCalloutDescription')}</div>
          ) : null}
        </div>
      </div>
    </aside>
  );
};
