import { useLocation, useNavigate, useParams } from 'react-router-dom';
/**
 * Use hooks over this HOC
 */

export function deprecatedWithRouter<TProps = any>(Component: any) {
  return (props: TProps) => {
    const location = useLocation();
    const params = useParams();

    const navigate = useNavigate();

    const push = (args: any) => {
      if (typeof args === 'string') {
        navigate(args);
        return;
      }

      const { pathname = location.pathname, search, booking, bookingState, bookingSession, state } = args;

      navigate({ pathname, search }, { state: { booking, bookingState, bookingSession, ...state } });
    };

    const replace = (args: any) => {
      if (typeof args === 'string') {
        navigate(args, { replace: true });
        return;
      }

      const { pathname = location.pathname, booking, bookingState, bookingSession, state } = args;

      navigate(pathname, {
        replace: true,
        state: { booking, bookingState, bookingSession, ...state },
      });
    };

    return <Component {...props} location={location} history={{ push, replace }} params={params} />;
  };
}
