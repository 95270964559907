import { AnchorButton } from '@askable/ui/components/ui/button';
import { cn } from '@askable/ui/lib/utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { z } from 'zod';

import { StudyActions } from 'containers/Studies/components/StudyActions';

import { StudyName } from '../BuildStudy/components/StudyName';
import { useStudyContext } from '../StudiesContainer';
import { blockSchema, taskBlockSchema } from '../data/schemas/blockSchema';

import { InvalidIndicator } from './InvalidIndicator';
import { PreviewLinkButton } from './PreviewLink';
import { StudySavingIndicator } from './StudySavingIndicator';
import { StudyStatusIndicator } from './StudyStatusIndicator';

export const StudiesHeader = () => {
  const { t } = useTranslation();
  const { study, isSaving } = useStudyContext();
  const location = useLocation();
  const section = location.pathname.split('/')[3];

  const navItems = [
    { label: t('sections.studies.tabs.build'), to: 'build' },
    { label: t('sections.studies.tabs.recruit'), to: 'recruit' },
    { label: t('sections.studies.tabs.results'), to: 'results' },
    { label: t('sections.studies.tabs.report'), to: 'report' },
  ];

  const isValid = useMemo(() => {
    const welcomeBlock = study.config?.unmoderated?.welcome_block;
    const thankYouBlock = study.config?.unmoderated?.thank_you_block;

    const blocks = study.config?.unmoderated?.task_blocks || [];

    const buildParsed =
      !!blocks.length &&
      z.array(blockSchema).safeParse([welcomeBlock, thankYouBlock]).success &&
      z.array(taskBlockSchema).safeParse(blocks).success;

    return { build: buildParsed };
  }, [study]);

  return (
    <header className="studies-header">
      <div className="left-0 grid grid-cols-2 items-center gap-2 border-b-0.5 border-border bg-background px-2 py-3 lg:grid-cols-3">
        <StudyName id={study._id} name={study.name || ''} />

        <nav
          className="order-4 col-start-1 col-end-3 flex w-full gap-1 justify-self-center overflow-auto lg:order-2 lg:col-start-2 lg:col-end-3
            lg:w-fit"
        >
          {navItems.map(item => (
            <AnchorButton
              key={item.to}
              variant="ghost"
              className={cn(
                'relative flex-1 rounded-lg px-4 text-foreground-subtle hover:text-foreground focus:text-foreground lg:flex-none',
                {
                  'bg-secondary text-foreground': section === item.to,
                },
              )}
              asChild
            >
              <Link to={item.to} replace>
                {item.label}
                {item.to === 'build' && section !== 'build' ? <InvalidIndicator isValid={isValid.build} /> : null}
              </Link>
            </AnchorButton>
          ))}
        </nav>

        <div className="order-3 flex items-center gap-3 justify-self-end pr-2">
          <StudyStatusIndicator study={study} />
          <StudySavingIndicator isSaving={isSaving} created={study.created} updated={study.updated} />

          <PreviewLinkButton />
          <StudyActions study={study} disableSubmit={!isValid.build} />
        </div>
      </div>
    </header>
  );
};
