import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const PauseIcon: CreateIconType = createIcon({
  displayName: 'PauseIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: (
    <path
      d="M6.056 2H3.833A.826.826 0 003 2.818v10.364c0 .452.373.818.833.818h2.223c.46 0 .833-.366.833-.818V2.818A.826.826 0 006.056 2zM12.167 2H9.944a.826.826 0 00-.833.818v10.364c0 .452.373.818.833.818h2.223c.46 0 .833-.366.833-.818V2.818A.826.826 0 0012.167 2z"
      fill="currentColor"
    />
  ),
});
