import _ from 'lodash';

import { globalVariables } from 'lib/globalVariables';
import { utils } from 'lib/utils';

import type { ClientDetails } from 'context/ConnectedClientContext';

const userUtils = {
  validateAccountInfo(user: any) {
    // Function to check if all user account info details have been filled
    let valid = false;
    const { firstname, lastname } = user.meta.identity;
    if (firstname !== '' && lastname !== '') {
      valid = true;
    }
    return valid;
  },
  employmentStatus() {
    return [
      {
        id: 'fulltime',
        label: 'Full time',
      },
      {
        id: 'parttime',
        label: 'Part time',
      },
      {
        id: 'fulltime_student',
        label: 'Full time student',
      },
      {
        id: 'unemployed',
        label: 'Unemployed',
      },
      {
        id: 'homeduties',
        label: 'Homeduties',
      },
      {
        id: 'retired',
        label: 'Retired',
      },
    ];
  },
  workEmploymentType() {
    return [
      {
        id: 'employment_type',
        value: '1',
        label: 'Employee',
      },
      {
        id: 'employment_type',
        value: '2',
        label: 'Sole trader / freelancer',
      },
      {
        id: 'employment_type',
        value: '3',
        label: 'Business Owner / Founder',
      },
    ];
  },
  maritalStatus() {
    return [
      {
        id: 'single',
        label: 'Single',
      },
      {
        id: 'relationship',
        label: 'Relationship',
      },
      {
        id: 'married',
        label: 'Married / Defacto',
      },
      {
        id: 'divorced',
        label: 'Divorced',
      },
      {
        id: 'widowed',
        label: 'Widowed',
      },
    ];
  },
  educationLevels() {
    return [
      {
        id: 'highschool',
        label: 'Finished high school',
      },
      {
        id: 'some_university',
        label: 'Some university',
      },
      {
        id: 'undergraduate',
        label: 'Undergraduate',
      },
      {
        id: 'postgraduate',
        label: 'Postgraduate',
      },
      {
        id: 'apprenticeship',
        label: 'Apprenticeship',
      },
    ];
  },
  getAge(user: any) {
    let age = '';
    if (_.get(user.meta, 'identity.birthday.timestamp')) {
      age = Math.abs(utils.getDifferenceFromNow(_.get(user.meta, 'identity.birthday.timestamp'), 'years')).toString();
    }
    return age;
  },
  getEmploymentStatus(user: any) {
    const statusObject = _.get(user.meta, 'work.status');

    if (statusObject && statusObject.fulltime) {
      return userUtils.employmentStatus().find(item => item.id === 'fulltime')?.label;
    }

    if (statusObject && statusObject.parttime) {
      return userUtils.employmentStatus().find(item => item.id === 'parttime')?.label;
    }

    if (statusObject && statusObject.fulltime_student) {
      return userUtils.employmentStatus().find(item => item.id === 'fulltime_student')?.label;
    }

    if (statusObject && statusObject.unemployed) {
      return userUtils.employmentStatus().find(item => item.id === 'unemployed')?.label;
    }

    if (statusObject && statusObject.homeduties) {
      return userUtils.employmentStatus().find(item => item.id === 'homeduties')?.label;
    }

    if (statusObject && statusObject.retired) {
      return userUtils.employmentStatus().find(item => item.id === 'retired')?.label;
    }

    return '';
  },
  getMaritalStatus(user: any) {
    const statusObject = _.get(user.meta, 'family.status');

    if (statusObject && statusObject.single) {
      return userUtils.maritalStatus().find(item => item.id === 'single')?.label;
    }

    if (statusObject && statusObject.relationship) {
      return userUtils.maritalStatus().find(item => item.id === 'relationship')?.label;
    }

    if (statusObject && statusObject.married) {
      return userUtils.maritalStatus().find(item => item.id === 'married')?.label;
    }

    if (statusObject && statusObject.divorced) {
      return userUtils.maritalStatus().find(item => item.id === 'divorced')?.label;
    }

    if (statusObject && statusObject.widowed) {
      return userUtils.maritalStatus().find(item => item.id === 'widowed')?.label;
    }

    return '';
  },
  getEducationLevels(user: any) {
    const statusObject = _.get(user.meta, 'education');

    if (statusObject && statusObject.highschool) {
      return userUtils.educationLevels().find(item => item.id === 'highschool')?.label;
    }

    if (statusObject && statusObject.some_university) {
      return userUtils.educationLevels().find(item => item.id === 'some_university')?.label;
    }

    if (statusObject && statusObject.undergraduate) {
      return userUtils.educationLevels().find(item => item.id === 'undergraduate')?.label;
    }

    if (statusObject && statusObject.postgraduate) {
      return userUtils.educationLevels().find(item => item.id === 'postgraduate')?.label;
    }

    if (statusObject && statusObject.apprenticeship) {
      return userUtils.educationLevels().find(item => item.id === 'apprenticeship')?.label;
    }

    return '';
  },
  getAgreementId() {
    if (globalVariables.getEnvironmentVariables().environment === 'DEV') return '5b20915e6575c0be9ee44a90';
    return '5b20915e6575c0be9ee44a90';
  },
  getEnglishLevel(user: any) {
    const statusObject = _.get(user.meta, 'identity.languages.english.speak');
    if (statusObject && statusObject.native) return 'Native';
    if (statusObject && statusObject.fluent) return 'Fluent';
    if (statusObject && statusObject.conversational) return 'Conversational';
    if (statusObject && statusObject.beginner) return 'Beginner';
    return '';
  },
  getFullName(user: any) {
    return `${_.get(user, 'meta.identity.firstname')} ${_.get(user, 'meta.identity.lastname')}`;
  },
  isValidLinkedInURL(url: any) {
    if (url) {
      return /(ftp|http|https):\/\/?(?:www\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/.test(
        url,
      );
    }
    return false;
  },
  isDemoClient(roles: any) {
    return _.includes(roles, 'demo_client');
  },
  isClientUser(roles: any) {
    return _.includes(roles, 'client');
  },
  getUserRole(user: any) {
    const usersOnTeam = _.get(user, 'ConnectedTeam.users');
    const userTeam = _.filter(usersOnTeam, (item: any) => item._id === user._id);
    return _.get(userTeam, '[0].role');
  },
  isGuestUser(user: any) {
    const userRole = userUtils.getUserRole(user);
    return userRole === 3;
  },
  // @ts-expect-error FIXME - "type" doesn't satisfy never
  isResearcher(viewer: Pick<ClientDetails, 'type'> | null) {
    return _.get(viewer, 'type.researcher');
  },
};

export { userUtils };
