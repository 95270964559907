import { Button } from '@askable/ui/components/ui/button';
import { FormControl, FormItem, FormLabel } from '@askable/ui/components/ui/form';
import { Input } from '@askable/ui/components/ui/input';
import { Label } from '@askable/ui/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@askable/ui/components/ui/select';
import { Switch } from '@askable/ui/components/ui/switch';
import { cn } from '@askable/ui/lib/utils';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Copy, GripVertical, Plus, Trash2 } from 'lucide-react';
import { memo, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

import { FormErrorMessage } from 'components/Form/FormErrorMessage';
import { SortableList } from 'containers/Studies/components/SortableList';

import { ScreenerQuestionItemAnswer } from './ScreenerQuestionItemAnswer';

import type { ScreenerQuestionAnswer } from './SectionScreenerQuestions';
import type { RecruitFormFields } from '../Recruit';
import type { CSSProperties } from 'react';

interface ScreenerQuestionItemProps {
  index: number;
  isDisabled?: boolean;
  isMovable?: boolean;
  onCopy: (id: string) => void;
  onRemove: (id: string) => void;
}

const ScreenerQuestionItemComponent = ({
  index,
  isDisabled,
  isMovable,
  onCopy,
  onRemove,
}: ScreenerQuestionItemProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext<RecruitFormFields>();
  const {
    field,
    fieldState: { error },
  } = useController({ name: `screener_questions.${index}`, control });

  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({
    id: isMovable && !isDisabled ? field.value._id : '',
  });

  const [showDescription, setShowDescription] = useState(field.value.description ? true : false);

  const dragStyle: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const addAnswer = () => {
    const newAnswer = {
      _id: v4(),
      value: '',
      isQualified: false,
    };

    field.onChange({ ...field.value, type: 'multiple_choice', answers: [...(field.value.answers || []), newAnswer] });
  };

  const handleChangeAnswer = (answerIndex: number, value: string, isQualified: boolean) => {
    const updatedList = (field.value.answers ?? []).map((item: ScreenerQuestionAnswer, i: number) => {
      if (i === answerIndex) {
        return {
          ...item,
          value,
          isQualified,
        };
      }

      return item;
    });

    field.onChange({ ...field.value, answers: updatedList });
  };

  const handleRemoveAnswer = (answerIndex: number) => {
    const updatedList = (field.value.answers ?? []).filter(
      (item: ScreenerQuestionAnswer, i: number) => i !== answerIndex,
    );
    field.onChange({ ...field.value, answers: updatedList });
  };

  const handleAnswerSortChange = (updatedList: ScreenerQuestionAnswer[]) => {
    field.onChange({ ...field.value, answers: updatedList });
  };

  const updateAnswerList = (type: string) => {
    if (type === 'multiple_choice') {
      addAnswer();
    } else {
      // Remove all answers when switching to short answer
      field.onChange({ ...field.value, type, answers: [] });
    }
  };

  return (
    <li
      ref={setNodeRef}
      style={dragStyle}
      className={cn(
        `flex flex-col gap-4 overflow-hidden rounded-lg border border-border bg-background opacity-100 transition-opacity
        starting:opacity-0`,
        {
          'shadow-lg': isDragging,
        },
      )}
      data-testid={field.value._id}
    >
      <div
        className="flex !cursor-grab touch-none items-center justify-between gap-2 px-4 pt-2 active:!cursor-grabbing
          aria-[disabled=true]:!cursor-not-allowed"
        {...attributes}
        {...listeners}
        aria-disabled={isDisabled}
      >
        <div>{isMovable ? <GripVertical className="h-4 w-4" /> : null}</div>

        <div className="flex items-center gap-2">
          <Button
            variant="ghost"
            size="icon"
            title={t('global.copy')}
            onClick={() => onCopy(field.value._id)}
            disabled={isDisabled}
          >
            <Copy className="h-4 w-4" />
          </Button>

          <Button
            variant="ghost"
            size="icon"
            title={t('global.remove')}
            onClick={() => onRemove(field.value._id)}
            disabled={isDisabled}
          >
            <Trash2 className="h-4 w-4" />
          </Button>
        </div>
      </div>

      <FormItem className="flex flex-col gap-4 p-4 pt-0">
        <FormControl>
          <div className="flex flex-col gap-2 md:flex-row">
            <div className="flex flex-1 flex-col gap-1">
              <FormLabel htmlFor={`input_screener_question_${field.value._id}_question`}>
                {t('sections.studies.recruit.fields.questionTitle')}
              </FormLabel>

              <Input
                value={field.value?.question}
                name={`input_screener_question_${field.value._id}_question`}
                disabled={isDisabled}
                onChange={e => field.onChange({ ...field.value, question: e.target.value })}
              />
            </div>

            <div className="flex w-[180px] flex-col gap-1">
              <FormLabel htmlFor={`input_screener_question_${field.value._id}_type`}>&nbsp;</FormLabel>

              <Select
                value={field.value?.type ?? 'short_answer'}
                onValueChange={value => {
                  field.onChange({ ...field.value, type: value });
                  updateAnswerList(value);
                }}
                disabled={isDisabled}
              >
                <SelectTrigger id={`input_screener_question_${field.value._id}_type`}>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent className="z-20">
                  <SelectItem value="short_answer">{t('sections.studies.recruit.options.short_answer')}</SelectItem>
                  <SelectItem value="multiple_choice">
                    {t('sections.studies.recruit.options.multiple_choice')}
                  </SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
        </FormControl>

        {showDescription ? (
          <FormControl>
            <div className="flex flex-col gap-1">
              <FormLabel htmlFor={`input_screener_question_${field.value._id}_description`}>
                {t('sections.studies.recruit.fields.descriptionTitle')}
              </FormLabel>

              <Input
                value={field.value?.description ?? ''}
                name={`input_screener_question_${field.value._id}_description`}
                disabled={isDisabled}
                onChange={e => field.onChange({ ...field.value, description: e.target.value })}
              />
            </div>
          </FormControl>
        ) : (
          <Button variant="link" className="px-0" onClick={() => setShowDescription(true)} disabled={isDisabled}>
            <Plus className="h-4 w-4" />
            {t('sections.studies.recruit.fields.descriptionCta')}
          </Button>
        )}

        {field.value?.type === 'multiple_choice' ? (
          <div className="flex flex-col gap-2">
            <FormLabel
              htmlFor={`input_screener_question_${field.value._id}_answers`}
              className="flex w-full items-center justify-between gap-2"
            >
              {t('sections.studies.recruit.fields.answersTitle')}
              <div className="flex items-center gap-2">
                <Label
                  htmlFor={`input_screener_question_${field.value._id}_allowMultipleSelections`}
                  className="font-normal"
                >
                  {t('sections.studies.recruit.fields.allowMultipleSelectionsTitle')}
                </Label>
                <Switch
                  id={`input_screener_question_${field.value._id}_allowMultipleSelections`}
                  checked={!!field.value?.isMultipleSelection}
                  disabled={isDisabled}
                  onCheckedChange={value => field.onChange({ ...field.value, allowMultipleSelections: value })}
                  size="small"
                />
              </div>
            </FormLabel>

            {field.value?.answers?.length ? (
              <ul className="flex flex-col">
                <SortableList
                  onChange={handleAnswerSortChange}
                  items={field.value?.answers}
                  renderItem={(answer, index) => (
                    <ScreenerQuestionItemAnswer
                      key={index}
                      id={answer._id}
                      index={index}
                      isDisabled={isDisabled}
                      isMovable={(field.value?.answers ?? []).length > 1}
                      isQualified={answer.isQualified}
                      value={answer.value}
                      onChangeValue={handleChangeAnswer}
                      onRemove={handleRemoveAnswer}
                    />
                  )}
                />
              </ul>
            ) : null}

            <Button variant="outline" onClick={addAnswer}>
              <Plus className="h-4 w-4" />
              {t('sections.studies.recruit.fields.answersCta')}
            </Button>
          </div>
        ) : null}
      </FormItem>
      {error ? <FormErrorMessage message={error.message} prefix="sections.studies.recruit.formValidation" /> : null}
    </li>
  );
};

ScreenerQuestionItemComponent.displayName = 'ScreenerQuestionItem';

export const ScreenerQuestionItem = memo(ScreenerQuestionItemComponent);
