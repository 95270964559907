import { Button } from '@askable/ui/components/ui/button';
import { Checkbox } from '@askable/ui/components/ui/checkbox';
import { Label } from '@askable/ui/components/ui/label';
import { RadioGroup, RadioGroupItem } from '@askable/ui/components/ui/radio-group';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { useEffect, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import CardContainer from 'components/createBooking/components/cardContainer';
import PriceCardContainer from 'components/createBooking/components/priceCardContainer';
import { bookingUtils } from 'lib/booking';
import { BOOKING_TYPE, REQUIRED_DEVICE_TYPE } from 'lib/constants';

function Devices(props: any) {
  const booking = _.get(props, 'booking');
  const [bookingState, setBookingState] = useState(booking);
  const [bookingData, setBookingData] = useState(null);
  const [requiredDevice, setRequiredDevice] = useState(
    _.get(booking, 'config.online_task.required_device[0]') || REQUIRED_DEVICE_TYPE.ANY,
  );
  const [requiredFeatures, setRequiredFeatures] = useState(
    _.get(booking, 'config.online_task.required_features') || [],
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (bookingUtils.isInPerson(booking)) {
      props.history.push({ pathname: `/booking-setup/${booking._id}/project-setup/research-type` });
    }
    props.updateLastStep({
      step: 'Project setup',
      subStep: `/booking-setup/${props.booking._id}/project-setup/devices`,
      stepId: 'project_setup_devices',
    });
  }, []);

  useEffect(() => {
    if (bookingUtils.isInPerson(booking)) {
      props.history.push({ pathname: `/booking-setup/${booking._id}/project-setup/quota` });
    }
    setBookingState(_.get(props, 'booking'));
  }, [props.booking]);

  useEffect(() => {
    props.renderRightContent(rightContent());
    props.renderRightAppPreview(null);
    setRequiredFeatures(_.get(bookingState, 'config.online_task.required_features') || []);
  }, [booking, bookingState]);

  const rightContent = () => {
    const cardTexts = [
      {
        cardText:
          'Yes. If you select desktop for example, the participant app will check their device and will not allow the participant to proceed on to your online task until they have switched to the appropriate device.',
      },
    ];
    return (
      <Fragment>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          booking={bookingState}
          bookingSteps={props.bookingSteps}
          team={_.get(props, 'team')}
          context={props.context}
          condensedCard
        />
        <p className="cardContainerTitle additionalTitle">Faqs</p>
        <CardContainer
          cardId="__devicesCard1"
          cardClass="slideInAnimationDelay80"
          cardTitle="Do you check for specific devices?"
          cardTexts={cardTexts}
          onLinkClick={() => {
            window.open(
              'https://help.askable.com/en/articles/4849436-what-devices-can-be-used-for-online-tasks',
              '_blank',
            );
          }}
        />
      </Fragment>
    );
  };

  const onClickNext = () => {
    const redirectTo = bookingUtils.includesBookingType(booking, [
      BOOKING_TYPE.IN_PERSON,
      BOOKING_TYPE.LONGITUDINAL_STUDY,
    ])
      ? `/booking-setup/${booking._id}/project-setup/quota`
      : `/booking-setup/${booking._id}/project-setup/tool`;

    props.history.push({ pathname: redirectTo, booking: bookingData, bookingState });
  };

  const onChangeRequirements = (type: any, value: any) => {
    let valueToBeSet = value;
    if (_.size(value) > 0 && typeof value === 'string') {
      valueToBeSet = parseInt(value, 10);
    } else if (value === '') {
      valueToBeSet = null;
    }

    if (type === 'required_device') {
      setRequiredDevice(valueToBeSet);
    } else {
      setRequiredFeatures(valueToBeSet);
    }

    const updatedOnlineTaskObj = {};
    if (type === 'required_device') {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'required_device' does not exist on type ... Remove this comment to see the full error message
      updatedOnlineTaskObj.required_device = valueToBeSet ? [valueToBeSet] : [];
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'required_features' does not exist on typ... Remove this comment to see the full error message
      updatedOnlineTaskObj.required_features = _.get(bookingState, 'config.online_task.required_features') || [];
    } else if (type === 'required_features') {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'required_features' does not exist on typ... Remove this comment to see the full error message
      updatedOnlineTaskObj.required_features = valueToBeSet;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'required_device' does not exist on type ... Remove this comment to see the full error message
      updatedOnlineTaskObj.required_device = _.get(bookingState, 'config.online_task.required_device') || [];
    }

    const bookingObj = {
      config: {
        online_task: {
          ...updatedOnlineTaskObj,
        },
      },
    };

    const bookingStateObj = {
      ...bookingState,
      config: {
        ...bookingState.config,
        online_task: {
          ...bookingState.config.online_task,
          ...updatedOnlineTaskObj,
        },
      },
    };
    setBookingState(bookingStateObj);

    if (
      JSON.stringify(_.get(bookingObj, 'config.online_task.required_device[0]')) !==
        JSON.stringify(_.get(booking, 'config.online_task.required_device[0]')) ||
      JSON.stringify(_.get(bookingObj, 'config.online_task.required_features')) !==
        JSON.stringify(_.get(booking, 'config.online_task.required_features'))
    ) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ config: { online_task: {}; }; ... Remove this comment to see the full error message
      setBookingData(bookingObj);
      props.history.replace({ booking: bookingObj, bookingState: bookingStateObj });
    } else {
      setBookingData(null);
      props.history.replace({ booking: null, bookingState: null });
    }
  };

  let studyLabel = 'online task';
  if (bookingUtils.isSurvey(booking)) studyLabel = 'survey';
  if (bookingUtils.isLongitudinal(booking)) studyLabel = 'study';

  return (
    <div className="createBookingContent">
      <h1 id="__pageTitle" className="title">
        {t('sections.booking.setup.device.title')}
      </h1>
      <p className="label">{t('sections.booking.setup.device.description', { studyType: studyLabel })}</p>
      <div className="bookingFormAccordion" style={{ width: '150px' }}>
        <RadioGroup
          defaultValue={String(requiredDevice)}
          onValueChange={(value: string) => onChangeRequirements('required_device', value)}
        >
          {bookingUtils.bookingOnlineTaskRequiredDeviceTypes().map(option => (
            <div className="flex items-center space-x-2" key={option.value}>
              <RadioGroupItem value={String(option.value)} id={`option_${option.value}`} />
              <Label htmlFor={`option_${option.value}`}>{option.label}</Label>
            </div>
          ))}
        </RadioGroup>
      </div>

      {!bookingUtils.isSurvey(booking) && !bookingUtils.isRemote(booking) && (
        <Fragment>
          <p className="label additionalLabel">{t('sections.booking.setup.device.peripherals')}</p>
          <div className="featureContainer" style={{ width: '200px' }}>
            {bookingUtils.bookingOnlineTaskRequiredFeatureTypes().map((item: any) => {
              return (
                <div className="flex items-center gap-2 whitespace-nowrap py-1" key={item.value}>
                  <Checkbox
                    id={item.value}
                    name={`__require${item.value === 1 ? 'Camera' : 'Microphone'}`}
                    checked={requiredFeatures.includes(item.value)}
                    onCheckedChange={() => {
                      let newRequiredFeatures = _.clone(requiredFeatures);
                      if (_.includes(requiredFeatures, item.id)) {
                        newRequiredFeatures = _.filter(requiredFeatures, (feature: any) => feature !== item.id);
                      } else {
                        newRequiredFeatures.push(item.id);
                      }
                      onChangeRequirements('required_features', newRequiredFeatures);
                    }}
                  />
                  <Label htmlFor={item.id}>{item.label}</Label>
                </div>
              );
            })}
          </div>
        </Fragment>
      )}

      <div className="mt-4">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          {t('global.next')}
        </Button>
      </div>
    </div>
  );
}

export default deprecatedWithRouter(Devices);
