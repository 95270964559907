/* eslint-disable max-lines */
import { useMutation } from '@apollo/client';
import { Button } from '@askable/ui/components/ui/button';
import { Command, CommandItem, CommandList } from '@askable/ui/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@askable/ui/components/ui/popover';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { CircleCheck, CirclePlus, CircleX, Copy, List, Text } from 'lucide-react';
import { useEffect, useState } from 'react';

import { LoadingOverlay, BoxMessage, Dialog } from 'components/common';
import CardContainer from 'components/createBooking/components/cardContainer';
import ModalBookingsList from 'components/createBooking/components/modalBookingsList';
import PriceCardContainer from 'components/createBooking/components/priceCardContainer';
import ScreeningQuestionModal from 'components/createBooking/components/screeningQuestionModal';
import deleteQuestionMutation from 'data/mutations/booking/deleteQuestion';
import duplicateQuestionMutation from 'data/mutations/booking/duplicateQuestion';
import reorderQuestionsMutations from 'data/mutations/booking/reorderQuestions';
import fetchDemoBooking from 'data/queries/booking/fetchDemoBooking';
import { bookingUtils } from 'lib/booking';
import { globalVariables } from 'lib/globalVariables';
import { apolloFetch } from 'lib/http';
import { utils } from 'lib/utils';

import type { AppContextType } from 'components/common/Askable/Providers/appProvider';

import './styles/customScreenerStyles.scss';

type Props = {
  booking: any;
  bookingSteps: any;
  context: AppContextType;
  history: any;
  renderRightAppPreview: (_: any) => void;
  renderRightContent: (_: any) => void;
  updateBookingState: (_: any) => void;
  updateLastStep: (_: any) => void;
};

function CustomScreener(props: Props) {
  const [booking, setBooking] = useState(_.get(props, 'booking'));
  const [customQuestionType, setCustomQuestionType] = useState(1);
  const [draggedFrom, setDraggedFrom] = useState(null);
  const [draggedTo, setDraggedTo] = useState(null);
  const [error, setError] = useState(null);
  const [indexCustomQuestionToBeEdited, setIndexCustomQuestionToBeEdited] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [openBookingListModal, setOpenBookingListModal] = useState(false);
  const [openCustomQuestionsModal, setOpenCustomQuestionsModal] = useState(false);
  const [openDemoBookingError, setOpenDemoBookingError] = useState(false);
  const [openFilterOptions, setOpenFilterOptions] = useState(false);
  const [question, setQuestion] = useState({});
  const [savedScreeningQuestions, setSavedScreeningQuestions] = useState(_.get(props.booking, 'config.question') || []);

  const [deleteQuestion] = useMutation(deleteQuestionMutation, {
    onCompleted: data => {
      setIsLoading(false);
      onChangeQuestions(_.get(data, 'deleteQuestion.config.question'));
    },
    onError: e => {
      setIsLoading(false);
      setError(_.get(e, 'graphQLErrors[0]'));
    },
  });

  const [reorderQuestions] = useMutation(reorderQuestionsMutations, {
    onCompleted: data => {
      setIsLoading(false);
      onChangeQuestions(_.get(data, 'reorderQuestions.config.question'));
    },
    onError: e => {
      setIsLoading(false);
      setError(_.get(e, 'graphQLErrors[0]'));
    },
  });

  const [duplicateQuestion] = useMutation(duplicateQuestionMutation, {
    onCompleted: data => {
      setIsLoading(false);
      onChangeQuestions(_.get(data, 'duplicateQuestion.config.question'));
    },
    onError: e => {
      setIsLoading(false);
      setError(_.get(e, 'graphQLErrors[0]'));
    },
  });

  useEffect(() => {
    props.updateLastStep({
      step: 'Audience',
      subStep: `/booking-setup/${props.booking._id}/audience/custom-screener`,
      stepId: 'audience_custom_screener',
    });
  }, []);

  useEffect(() => {
    setSavedScreeningQuestions(_.get(props.booking, 'config.question') || []);
    setBooking(_.get(props, 'booking'));
  }, [props.booking]);

  useEffect(() => {
    props.renderRightContent(rightContent());
    props.renderRightAppPreview(null);
  }, [props.booking, booking, question, savedScreeningQuestions]);

  const onClickDemoBooking = async () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'data' does not exist on type 'unknown'.
    const { data } = await apolloFetch.fetch(fetchDemoBooking, {
      _team_id: _.get(props, 'team._id'),
    });

    if (data && _.get(data, 'demoBooking._id')) {
      const baseURL = globalVariables.getEnvironmentVariables().clientsAppURI;
      const demoBookingUrl = `${baseURL}/bookings/${data.demoBooking._id}/participants/all`;
      window.open(demoBookingUrl, '_blank');
    } else {
      setOpenDemoBookingError(true);
    }
  };

  const rightContent = () => {
    const cardTexts1 = [
      {
        cardText: (
          <span>
            Check out our awesome beginner&apos;s guide on how to write the most effective screening questions to get
            the perfect participants.
          </span>
        ),
      },
      {
        middleLink: true,
        middleLinkAction: () => {
          window.open(
            'https://help.askable.com/en/articles/4849468-how-to-get-started-with-screener-question-writing',
            '_blank',
          );
        },
      },
    ];
    const cardTexts2 = [
      {
        cardText: (
          <span>
            You&apos;ll be able to see the profiles of anyone who has applied, even if they choose an ineligible answer.
          </span>
        ),
      },
      {
        cardText: 'This makes it easy for you if you need to broaden your criteria later.',
        additionalText: true,
      },
    ];
    const cardTexts3 = [
      {
        cardText: <span>You&apos;ll be able to see the profiles of anyone who has confirmed.</span>,
      },
      {
        cardText: 'This makes it easy for you if you need to broaden your selection criteria later.',
        additionalText: true,
      },
    ];

    return (
      <>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          booking={booking}
          bookingSteps={props.bookingSteps}
          team={_.get(props, 'team')}
          context={props.context}
          condensedCard
        />
        <p className="cardContainerTitle additionalTitle">Faqs</p>
        <CardContainer
          cardId="__customScreenerCard2"
          cardClass="slideInAnimationDelay80"
          cardTitle="Can I get some help with writing my screener questions?"
          cardTexts={cardTexts1}
        />
        {_.get(booking, 'config.options.review_submission') && (
          <CardContainer
            additionalCard
            cardId="__customScreenerCard3"
            cardClass="slideInAnimationDelay160"
            cardTexts={cardTexts2}
            onLinkClick={onClickDemoBooking}
            linkText="Try a demo study"
            onAdditionalLinkClick={() => {
              window.open(
                'https://help.askable.com/en/articles/4849410-how-do-custom-screeners-work-exactly',
                '_blank',
              );
            }}
          />
        )}
        {!_.get(booking, 'config.options.review_submission') && (
          <CardContainer
            additionalCard
            cardId="__customScreenerCard4"
            cardClass="slideInAnimationDelay160"
            cardTexts={cardTexts3}
            onLinkClick={onClickDemoBooking}
            linkText="Try a demo study"
            onAdditionalLinkClick={() => {
              window.open(
                'https://help.askable.com/en/articles/4849410-how-do-custom-screeners-work-exactly',
                '_blank',
              );
            }}
          />
        )}
      </>
    );
  };

  const onClickNext = () => {
    let redirectTo = `/booking-setup/${booking._id}/additional-info/your-timezone`;
    if (bookingUtils.isInPerson(booking)) {
      redirectTo = `/booking-setup/${booking._id}/additional-info/session-location`;
    } else if (bookingUtils.isSurvey(booking)) {
      redirectTo = `/booking-setup/${booking._id}/link-to-task/survey`;
    } else if (bookingUtils.isAiModeration(booking)) {
      redirectTo = `/booking-setup/${booking._id}/ai-moderated/research-objective`;
    } else if (bookingUtils.isOnlineTask(booking)) {
      redirectTo = `/booking-setup/${booking._id}/link-to-task/online-task`;
    } else if (bookingUtils.isLongitudinal(booking)) {
      redirectTo = `/booking-setup/${booking._id}/additional-info/closing-date`;
    }
    props.history.push({ pathname: redirectTo, booking: null, bookingState: null });
  };

  const onCopyBookingQuestions = (bookingObj: any) => {
    const bookingStateObj = {
      ...booking,
      config: {
        ...booking.config,
        question: _.get(bookingObj, 'config.question'),
      },
    };
    setBooking(bookingStateObj);
    props.updateBookingState(bookingStateObj);
  };

  const onChangeQuestions = (questions: any) => {
    const bookingStateObj = {
      ...booking,
      config: {
        ...booking.config,
        question: questions,
      },
    };
    setBooking(bookingStateObj);
    props.updateBookingState(bookingStateObj);
    setSavedScreeningQuestions(questions);
  };

  const onDragStart = (ev: any) => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    setDraggedFrom(Number(ev.currentTarget.dataset.id));
    ev.dataTransfer.effectAllowed = 'move';

    // Firefox requires calling dataTransfer.setData for the drag to properly work
    ev.dataTransfer.setData('text/html', null);
  };

  const onDragOver = (ev: any) => {
    ev.preventDefault();
    const newDraggedTo = Number(ev.currentTarget.dataset.id);
    if (draggedTo !== newDraggedTo) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
      setDraggedTo(newDraggedTo);
    }
  };

  const onDragEnd = () => {
    if (draggedFrom !== draggedTo) {
      setIsLoading(true);
      const bookingQuestions = _.map(savedScreeningQuestions, (item: any) => {
        return {
          _id: item._id,
          title: item.title,
          description: item.description || '',
          config: {
            display_participant: (item.config && item.config.display_participant) || false,
            multiple_selection: (item.config && item.config.multiple_selection) || false,
            none_of_the_above: (item.config && item.config.none_of_the_above) || false,
            type: (item.config && item.config.type) || 1,
          },
          options: _.map(item.options, (option: any) => {
            return {
              _id: option._id,
              label: option.label,
              screen_in: option.screen_in,
            };
          }),
        };
      });

      reorderQuestions({
        variables: {
          booking_id: booking._id,
          questions: utils.arrayMove(bookingQuestions, draggedFrom, draggedTo),
        },
      });

      setDraggedTo(null);
      setDraggedFrom(null);
    }
  };

  const renderAnswerOptions = (answer: any, index: any, answerIndex: any) => {
    return (
      <div key={`answer_${answer.label}_${index}`} className="answer">
        {answer.screen_in ? (
          <CircleCheck color="#219653" className="h-4 w-4" />
        ) : (
          <CircleX color="#EB5757" className="h-4 w-4" />
        )}
        <span id={`__question_${answerIndex}_option_${index}`} className="text">
          {answer.label}
        </span>
      </div>
    );
  };

  const renderQuestion = (value: any, index: any) => {
    return (
      <li
        key={`customQuestion_${index}`}
        className={`customQuestion ${draggedTo === index ? 'draggedTo' : ''}`}
        data-id={index}
        draggable
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDragOver={onDragOver}
      >
        <div className="typesContainer">
          {value.config.type === 1 ? (
            <div className="typeContainer">
              <List color="#888" className="h-4 w-4" />
              <p className="type">Multi Choice</p>
            </div>
          ) : (
            <div className="typeContainer">
              <Text color="#888" className="h-4 w-4" />
              <p className="type">Short Answer</p>
            </div>
          )}
        </div>
        <div className="questionContainer">
          <p id={`__questionTitle_${index}`} className="question">
            {value.title}
          </p>
          {value.description && (
            <p id={`__questionDescription_${index}`} className="description">
              {value.description}
            </p>
          )}
          {value.config.multiple_selection && (
            <p id={`__questionMultipleSelection_${index}`} className="multipleSelection">
              Select as many as you like
            </p>
          )}
          {value.options.length > 0 && (
            <div className="answersContainer">
              {_.map(value.options, (optVal: any, optIndex: any) => renderAnswerOptions(optVal, optIndex, index))}
            </div>
          )}
        </div>
        <div className="actionsContainer">
          <div className="mainActions">
            <a
              id={value._id ? value._id : index}
              onClick={() => {
                setCustomQuestionType(
                  booking.config.question.find((item: any, indexOrigin: any) => indexOrigin === index).config.type,
                );
                setIndexCustomQuestionToBeEdited(index);
                setOpenCustomQuestionsModal(true);
              }}
            >
              Edit
            </a>
            <a
              id={value._id ? value._id : index}
              onClick={() => {
                setIsLoading(true);

                // Duplicates the question
                duplicateQuestion({
                  variables: {
                    booking_id: booking._id,
                    question_id: value._id,
                  },
                });
              }}
            >
              Duplicate
            </a>
          </div>
          <div className="secondaryActions">
            <a
              id={value._id ? value._id : index}
              onClick={() => {
                setIsLoading(true);

                deleteQuestion({
                  variables: {
                    booking_id: booking._id,
                    question_id: value._id,
                  },
                });
              }}
            >
              Delete
            </a>
          </div>
        </div>
      </li>
    );
  };

  const renderSavedQuestions = () => {
    return (
      _.size(savedScreeningQuestions) >= 1 && (
        <div className="questionsContainer">
          <ul className="customQuestions">{_.map(savedScreeningQuestions, renderQuestion)}</ul>
        </div>
      )
    );
  };

  return (
    <>
      {(isLoading || !booking) && <LoadingOverlay style={{ opacity: 0.8 }} />}
      <div className="createBookingContent">
        <h1 id="__pageTitle" className="title">
          Create a custom screener
        </h1>
        <p className="label">
          Adding your own customer screener is a great way to get even more specific about what kind of participants you
          want.
        </p>

        {error && (
          <BoxMessage type="error" error={error}>
            {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'message' does not exist on type 'never'. */}
            <p className="error">{error.message}</p>
          </BoxMessage>
        )}

        {renderSavedQuestions()}

        {openCustomQuestionsModal && (
          <ScreeningQuestionModal
            booking={booking}
            onClose={() => setOpenCustomQuestionsModal(false)}
            onSave={(bookingQuestions: any) => {
              setOpenCustomQuestionsModal(false);
              setIndexCustomQuestionToBeEdited(-1);
              if (bookingQuestions) onChangeQuestions(bookingQuestions.config.question);
            }}
            open={openCustomQuestionsModal || false}
            itemToBeEdited={
              _.get(booking, 'config.question') &&
              _.find(
                _.get(booking, 'config.question'),
                (item: any, indexOrigin: any) => indexOrigin === indexCustomQuestionToBeEdited,
              )
            }
            bookingID={_.get(props, 'booking._id')}
            questionType={customQuestionType}
            onChange={(value: any) => {
              setQuestion(value);
              // setAppPreviewParams({ screening_question: value });
            }}
          />
        )}

        <div>
          <Popover open={openFilterOptions} onOpenChange={setOpenFilterOptions}>
            <PopoverTrigger asChild>
              {_.size(savedScreeningQuestions) > 0 ? (
                <Button
                  variant="link"
                  onClick={() => {
                    setIndexCustomQuestionToBeEdited(-1);
                  }}
                >
                  + Add another custom question
                </Button>
              ) : (
                <button
                  type="button"
                  className="flex items-center justify-center gap-4 rounded-sm border p-4 text-left shadow hover:shadow-md
                    focus-visible:ring-offset-2"
                >
                  <CirclePlus className="h-5 w-5" />
                  <div>
                    <div className="text-sm font-bold">Add a custom screening question</div>
                    <div className="text-xs text-muted-foreground">Multiple choice or short answer</div>
                  </div>
                </button>
              )}
            </PopoverTrigger>
            <PopoverContent className="w-[18rem] p-0" align="start">
              <Command label="Customer screener filter">
                <CommandList>
                  <CommandItem
                    className="flex gap-2"
                    onSelect={() => {
                      setOpenFilterOptions(false);
                      setCustomQuestionType(1);
                      setOpenCustomQuestionsModal(true);
                    }}
                  >
                    <List className="h-4 w-4" /> Multiple choice
                  </CommandItem>
                  <CommandItem
                    className="flex gap-2"
                    onSelect={() => {
                      setOpenFilterOptions(false);
                      setCustomQuestionType(2);
                      setOpenCustomQuestionsModal(true);
                    }}
                  >
                    <Text className="h-4 w-4" /> Short answer
                  </CommandItem>
                  <CommandItem
                    className="flex gap-2"
                    onSelect={() => {
                      setOpenFilterOptions(false);
                      setOpenBookingListModal(true);
                    }}
                  >
                    <Copy className="h-4 w-4" /> Copy from other study
                  </CommandItem>
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>
        </div>

        <div className="buttonNextContainer">
          <Button variant="primary" size="lg" onClick={onClickNext}>
            Next
          </Button>
        </div>
      </div>

      <ModalBookingsList
        onClose={() => setOpenBookingListModal(false)}
        open={openBookingListModal}
        team_id={_.get(booking, '_team_id')}
        booking_id={_.get(booking, '_id')}
        client={_.get(props, 'client')}
        onCopyQuestions={onCopyBookingQuestions}
      />

      <Dialog
        title="Something went wrong!"
        modal={false}
        open={openDemoBookingError}
        onRequestClose={() => setOpenDemoBookingError(false)}
        styles={{ width: '380px' }}
        bodyStyle={{ zIndex: 2200 }}
        customActions={[
          {
            label: 'Okay',
            buttonType: 'normal',
            containerButtonStyle: { marginLeft: '20px' },
            customFunction: () => {
              setOpenDemoBookingError(false);
            },
          },
        ]}
      >
        <h3 style={{ margin: '0px' }}>
          Sorry, it looks like you no longer have the demo study in your account, please contact support to resolve
          this.
        </h3>
      </Dialog>
    </>
  );
}

export default deprecatedWithRouter(CustomScreener);
