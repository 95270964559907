/* eslint-disable react/prop-types */
import { cn } from '@askable/ui/lib/utils';
import { Slot } from '@radix-ui/react-slot';
import React from 'react';

import { link } from '../styles/shared-styles';

type LinkProps = {
  asChild?: boolean;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(({ asChild, className, ...props }, ref) => {
  const Comp = asChild ? Slot : 'a';

  return <Comp className={cn(link, className)} ref={ref} {...props} />;
});

Link.displayName = 'Link';

export { Link };
