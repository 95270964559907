import { useMutation } from '@apollo/client';
import { toast } from '@askable/ui/components/ui/sonner';
import _ from 'lodash';
import { useState } from 'react';

import { withAppContext } from 'components/HOCS';
import cancelParticipantSessionMutation from 'data/mutations/bookingSubmission/cancelParticipantSession';
import inviteParticipantSessionMutation from 'data/mutations/bookingSubmission/inviteParticipantSession';
import reInviteParticipantSessionMutation from 'data/mutations/bookingSubmission/reInviteParticipantSession';
import fetchBookingSessionsWithParticipants from 'data/queries/booking/fetchBookingSessionsWithParticipants';

import InviteParticipant from './inviteParticipant';
import ReInviteParticipant from './reInviteParticipant';

import type React from 'react';

import './invitationDialogStyles.scss';

interface IProps {
  booking: any;
  singleSession: { participant: any; participant_session_id: string; session: any };
  onFinishInvitationProcess: any;
  client: any;
  link_id: any;
  onCloseInvitationDialog: any;
  onRefreshTable: any;
}

function InvitationDialog(props: IProps): React.ReactElement<IProps> {
  const { booking, singleSession, onFinishInvitationProcess } = props;

  const [loading, setLoading] = useState(false);
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);

  const [reInviteParticipantSession] = useMutation(reInviteParticipantSessionMutation, {
    refetchQueries: [
      {
        query: fetchBookingSessionsWithParticipants,
        variables: { booking_id: booking._id },
      },
    ],
    client: props.client,
  });
  const [cancelParticipantSession] = useMutation(cancelParticipantSessionMutation, {
    // refetchQueries: [{
    //     query: fetchBookingSessionsWithParticipants,
    //     variables: { booking_id: booking._id },
    // }],
    client: props.client,
  });
  const [inviteParticipantSession] = useMutation(inviteParticipantSessionMutation, {
    client: props.client,
  });

  const onConfirmInvitation = async () => {
    const participantSessions = _.get(singleSession, 'participant.booking_participant');
    const participantWaitlistSessions = _.filter(participantSessions, (item: any) => item.status === 2);
    await Promise.all(
      participantWaitlistSessions.map((session: any) => {
        return cancelParticipantSession({
          variables: {
            booking_id: booking._id,
            participant_session_id: _.get(session, '_id'),
          },
        });
      }),
    );

    setLoading(true);
    inviteParticipantSession({
      variables: {
        booking_id: booking._id,
        participant_session_id: props.singleSession.participant_session_id,
        online_task_link_id: props.link_id,
      },
    }).catch(e => {
      toast.error(_.get(e, 'graphQLErrors[0].message') ? e.graphQLErrors[0].message : 'Error saving study');
    });

    // Stops the loading state and start the confirmation message animation
    setLoading(false);
    setShowConfirmationMessage(true);
  };

  const onConfirmReInvitation = async () => {
    const { participant } = props.singleSession;
    setLoading(true);

    await reInviteParticipantSession({
      variables: {
        booking_id: booking._id,
        participant_session_id: _.get(participant, 'invitedSession._id'),
      },
    }).catch(e => {
      toast.error(_.get(e, 'graphQLErrors[0].message') ? e.graphQLErrors[0].message : 'Error saving study');
    });

    // Stops the loading state and start the confirmation message animation
    setLoading(false);
    setShowConfirmationMessage(true);
  };

  // Parent component to deal with all different scenarios to invite participants
  //  It accepts either single invitation and bulk invitation
  //  If it gets a 'single_session' object as a prop then it means the client is trying to invite just one participant
  //  If it gets a 'bulk_session' object as a prop then it means the client is trying to inbite multiple participants for sessions

  const hasBeenInvited = _.has(props, 'singleSession.participant.invitedSession');
  if (hasBeenInvited) {
    return (
      <ReInviteParticipant
        {...props}
        loading={loading}
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
        onConfirm={(callback: any) => onConfirmReInvitation(callback)}
        onFinishInvitation={() => {
          // Close the confirmation message and after closes the sessions container
          setShowConfirmationMessage(false);
          // Custom callback after finishing everything
          if (onFinishInvitationProcess) onFinishInvitationProcess();

          if (props.onRefreshTable) props.onRefreshTable();
        }}
        showConfirmationMessage={showConfirmationMessage}
      />
    );
  }
  return (
    <InviteParticipant
      {...props}
      loading={loading}
      onConfirm={onConfirmInvitation}
      onFinishInvitation={() => {
        // Close the invitation dialog
        if (props.onCloseInvitationDialog) props.onCloseInvitationDialog();
        // Custom callback after finishing everything
        if (props.onFinishInvitationProcess) props.onFinishInvitationProcess();
        // Close the confirmation message and after closes the sessions container
        // setState({ showConfirmationMessage: false }, () => {
        //     if (props.onCloseSessionsContainer) props.onCloseSessionsContainer();
        // });
        setShowConfirmationMessage(false);

        if (props.onRefreshTable) props.onRefreshTable();
      }}
      showConfirmationMessage={showConfirmationMessage}
    />
  );
}

export default withAppContext(InvitationDialog);
