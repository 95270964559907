import { useState, useEffect } from 'react';

function Clock(props: any) {
  const [time, setTime] = useState(0);
  let timerInterval: any;
  let timer: any;

  useEffect(() => {
    if (props.time) setTime(props.time);
  }, [props.time]);

  useEffect(() => {
    if (!props.time) {
      setTime(props.startTime);
      timer = props.startTime;
      timerInterval = setInterval(() => {
        setTime(timer + 1);
        timer += 1;
      }, 1000);

      return () => {
        // Clear the timer interval
        clearInterval(timerInterval);
      };
    }
  }, [props.startTime]);

  const format = (timeArgs: any) => {
    const seconds = (time % 60).toFixed(0);
    const minutes = Math.floor(timeArgs / 60);
    if (minutes >= 1) return `${minutes}m ${seconds}s`;
    return `${seconds}s`;
  };

  return <span className="displayedTime">{format(time)}</span>;
}

export default Clock;
