/**
 * This context should include all relevant information about the connected client.
 * It stands as a single entry point and single source of truth for all connected client information.
 */
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import deepmerge from 'deepmerge';
import { createContext, useContext, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IdentifyUser } from 'src/IdentifyUser';
import { useSwitchTeam } from 'src/hooks/useSwitchTeam';
import { useMutation } from 'urql';

import { useIsKinde } from 'containers/Auth/AuthWrapper';
import { OnboardingStatus, useViewerQuery } from 'generated/graphql';
import { analytics } from 'lib/analytics';
import { localStorage } from 'lib/storage';
import { userUtils } from 'lib/user';
import { invariant } from 'utils/error_utils';

import { AddAcquisitionSourceMutation } from './data/AddAcquisitionSource.mutation';

import type { Maybe, User, UserMetaSocialType, UserResearcher, UserRoles, ViewerQuery } from 'generated/graphql';
import type { ComponentType, Dispatch, FC, ReactNode, SetStateAction } from 'react';

export type AuthState = 'unknown' | 'authenticated' | 'unauthenticated';

export type ClientDetails = Maybe<{
  id?: Maybe<string>;
  email?: Maybe<string>;
  researcher?: UserResearcher;
  name?: Maybe<{
    firstname?: Maybe<string>;
    lastname?: Maybe<string>;
  }>;
  team?: Maybe<{
    id?: Maybe<string>;
    name?: Maybe<string>;
    kinde_org_id?: Maybe<string>;
  }>;
  social?: Maybe<UserMetaSocialType>;
  phone?: Maybe<string>;
  roles?: (UserRoles | null)[] | null;
  teamRole?: number | null;
  type: Maybe<{
    researcher?: Maybe<boolean>;
    client?: Maybe<boolean>;
  }>;
  Teams?: User['Teams'];
}> &
  ViewerQuery['viewer'];

export type ConnectedClientContext = {
  authState: AuthState;
  actions: {
    setAccessToken: Dispatch<SetStateAction<Maybe<string>>>;
    clear: () => void;

    updateClientDetails: (details: Partial<ClientDetails>) => void;
    refetch: () => void;
    maybeNavigateToCompleteProfile: (viewer: ViewerQuery['viewer'], redirectPath: string) => boolean;
  };
  details: ClientDetails;
};

export const ConnectedClientDetailsContext = createContext<ConnectedClientContext | undefined>(undefined);

export function useConnectedClient() {
  const ctx = useContext(ConnectedClientDetailsContext);

  if (ctx === undefined) {
    throw new Error('useClient cannot be used outside of ClientProvider');
  }

  return ctx;
}

type Props = {
  children: ((a: { isReady: boolean; details: ClientDetails | null }) => ReactNode) | ReactNode;
  defaultClientDetails?: ClientDetails;
};

export const ConnectedClientProvider: FC<Props> = ({ children, defaultClientDetails }) => {
  // Save in state for now. So in the future when we do changes to the client details we can update here optimistically.
  const [clientDetails, setClientDetails] = useState<ClientDetails | null>(defaultClientDetails ?? null);
  const [accessToken, setAccessToken] = useState<string | null>(localStorage.get('access_token') ?? null);
  const [, addAcquisitionSource] = useMutation(AddAcquisitionSourceMutation);
  const { isAuthenticated, getOrganization } = useKindeAuth();
  const isKinde = useIsKinde();
  const navigate = useNavigate();
  const location = useLocation();

  const updateClientDetails = (newDetails: Partial<ClientDetails>) => {
    setClientDetails(state => deepmerge(state!, newDetails, { arrayMerge: (a, b) => b }));
  };
  const { switchTeam } = useSwitchTeam({ updateClientDetails });

  const maybeNavigateToCompleteProfile = (viewer: ViewerQuery['viewer'], redirectPath: string) => {
    if (
      !isKinde &&
      (!viewer?.meta?.identity?.firstname || !viewer?.meta?.identity?.lastname) &&
      location.pathname !== '/complete-profile'
    ) {
      navigate(`/complete-profile?redirect_path=${redirectPath}`);
      return true;
    }
    return false;
  };

  const { loading, refetch } = useViewerQuery({
    returnPartialData: false,
    skip: !isAuthenticated && accessToken === null,
    onError: err => {
      if (err.message === 'jwt expired') {
        navigate('/logout');
      }
    },
    onCompleted: ({ viewer }) => {
      if (viewer?.type?.participant && !window.location.pathname.startsWith('/participant-redirect')) {
        window.location.href = '/participant-redirect';
        return;
      }

      maybeNavigateToCompleteProfile(viewer, '/');

      const viewerType = viewer?.type?.researcher ? 'researcher' : 'client';

      const utmSource = sessionStorage.getItem('utm_source');
      const utmCampaign = sessionStorage.getItem('utm_campaign');
      if (utmSource || utmCampaign) {
        addAcquisitionSource({
          user: {
            meta: {
              acquisition: {
                ...(utmSource && { source: utmSource }),
                ...(utmCampaign && { campaign: utmCampaign }),
              },
            },
          },
        }).then(() => {
          sessionStorage.removeItem('utm_source');
          sessionStorage.removeItem('utm_campaign');
        });
      }

      const team = isKinde
        ? viewer?.Teams?.find(a => a?.kinde_org_id === getOrganization?.()?.orgCode)
        : viewer?.ConnectedTeam;

      if (viewerType === 'client' && team?._id === undefined && (viewer?.Teams?.length ?? 0) >= 1) {
        invariant(viewer?.ConnectedTeam?._id, 'Client must have a connected team');
        switchTeam(viewer.ConnectedTeam._id);
        return;
      }

      const teamUser = viewer?.ConnectedTeam?.users?.find(a => a?._id === viewer._id);
      const teamRole = teamUser?.role;

      // If researcher has no Kinde organisation set but has Teams, then switch to the first available team
      if (viewerType === 'researcher' && isKinde && !getOrganization?.()?.orgCode && viewer?.Teams && viewer.Teams[0]) {
        invariant(viewer.Teams[0]._id, 'Researcher must have a team');
        switchTeam(viewer.Teams[0]._id);
        return;
      }

      // If user is currently logged into a team they no longer have access to
      // This can happen e.g. when they get disabled/banned during their session
      if (viewer?.ConnectedTeam && (!teamUser || teamUser.status !== 1)) {
        invariant(viewer?.Teams?.[0]?._id, 'Client must have a team');
        switchTeam(viewer?.Teams?.[0]?._id);
        return;
      }

      updateClientDetails({
        id: viewer?._id,
        ...viewer,
        Teams: (viewer?.Teams ? [...viewer.Teams] : []).sort((a, b) => (a?.name ?? '').localeCompare(b?.name ?? '')),
        teamRole,
        name: {
          firstname: viewer?.meta?.identity?.firstname,
          lastname: viewer?.meta?.identity?.lastname,
        },
        email: viewer?.email,
        team: {
          id: team?._id,
          name: team?.name,
        },
        type: viewer?.type,
        social: {
          linkedin: {
            profile_url: viewer?.meta?.social?.linkedin?.profile_url ?? '',
          },
        },
        researcher: {
          ux_length: viewer?.researcher?.ux_length,
          certification: {
            status: viewer?.researcher?.certification?.status!,
          },
          onboarding: {
            linkedIn: viewer?.researcher?.onboarding?.linkedIn ?? OnboardingStatus.Incomplete,
            ux_length: viewer?.researcher?.onboarding?.ux_length ?? OnboardingStatus.Incomplete,
            methods: viewer?.researcher?.onboarding?.methods ?? OnboardingStatus.Incomplete,
          },
        },
      });
    },
  });

  const authState = useMemo<AuthState>(() => {
    // First check whether its a normal client trying to connect
    if (clientDetails?.id && clientDetails?.team?.id) {
      return 'authenticated';
    }

    if (clientDetails?.id || userUtils.isDemoClient(clientDetails?.roles)) {
      return 'authenticated';
    }

    return 'unauthenticated';
  }, [clientDetails]);

  const clear = () => {
    setClientDetails(null);
    localStorage.reset();
    analytics.reset();
  };

  return (
    <ConnectedClientDetailsContext.Provider
      value={{
        authState,
        details: clientDetails!,
        actions: {
          updateClientDetails,
          clear,
          setAccessToken,
          refetch,
          maybeNavigateToCompleteProfile,
        },
      }}
    >
      <>
        {typeof children === 'function'
          ? children({
              isReady: !loading || import.meta.env.MODE === 'test',
              details: clientDetails,
            })
          : children}
        <IdentifyUser />
      </>
    </ConnectedClientDetailsContext.Provider>
  );
};

export function withClientContext<T>(WrappedComponent: ComponentType<T>) {
  return function WithClientContext(props: T) {
    return (
      <ConnectedClientDetailsContext.Consumer>
        {clientContext => {
          return <WrappedComponent {...props} clientContext={clientContext} />;
        }}
      </ConnectedClientDetailsContext.Consumer>
    );
  };
}
