import { Button } from '@askable/ui/components/ui/button';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';

import PriceCardContainer from 'components/askablePlus/components/priceCardContainer';
import { Card } from 'components/common';
import { askablePlusUtils } from 'lib/askablePlus';

import '../styles/meetingFormatStyles.scss';

function DiscoveryInterviewMeetingFormat(props: any) {
  const askablePlusBrief = _.get(props, 'askablePlusBrief');
  const totalResearchTypesSelected = askablePlusUtils.totalResearchTypesSelected(askablePlusBrief);
  const hasDiscoveryInterviewType = askablePlusUtils.hasDiscoveryInterviewType(askablePlusBrief);

  const [askablePlusBriefState, setAskablePlusBriefState] = useState(askablePlusBrief);
  const [meetingFormat, setMeetingFormat] = useState(
    _.get(askablePlusBrief, 'askable_plus.research_type.discovery.booking_type'),
  );

  useEffect(() => {
    if (!_.get(props, 'askablePlusBrief._id')) {
      props.history.push('/askable-plus/create');
    } else if (totalResearchTypesSelected <= 1) {
      props.history.push(`/askable-plus/${askablePlusBrief._id}/audience/participant-criteria`);
    } else if (!hasDiscoveryInterviewType) {
      props.history.push(`/askable-plus/${askablePlusBrief._id}/usability-testing/quota`);
    } else {
      props.updateLastStep({
        step: 'Discovery Interview',
        subStep: `/askable-plus/${askablePlusBrief._id}/discovery-interview/meeting-format`,
        stepId: 'discovery_interview_meeting_format',
      });
    }
  }, []);

  useEffect(() => {
    props.renderRightContent(rightContent());
  }, [askablePlusBriefState]);

  const rightContent = () => {
    return (
      <Fragment>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          askablePlusBrief={askablePlusBriefState}
          team={_.get(props, 'team')}
          context={props.context}
        />
      </Fragment>
    );
  };

  const onClickCard = (value: any) => {
    setMeetingFormat(value);
    const askablePlusBriefStateObj = {
      ...askablePlusBriefState,
      askable_plus: {
        ...askablePlusBriefState.askable_plus,
        research_type: {
          ...askablePlusBriefState.askable_plus.research_type,
          discovery: {
            ...askablePlusBriefState.askable_plus.research_type.discovery,
            booking_type: value,
          },
        },
      },
    };

    setAskablePlusBriefState(askablePlusBriefStateObj);
    props.updateAskablePlusBriefState(askablePlusBriefStateObj);
  };

  const onClickNext = () => {
    const redirectTo = `/askable-plus/${askablePlusBrief._id}/discovery-interview/session-format`;
    props.history.push({ pathname: redirectTo });
  };

  return (
    <div className="createAskablePlusBriefContent">
      <h1 className="title">Meeting format</h1>
      <p className="label">How would you like the researcher to meet with participants?</p>
      <div className="researchTypeGroup">
        <div className="researchTypeTasks">
          <Card
            id="__remoteCard"
            title="Remote"
            className="researchTypeCard"
            vertical
            content={[{ label: 'Askable Sessions, Zoom, Google Meet' }, { label: 'Microsoft Teams, etc' }]}
            image="/askablePlus/illustrations/remoteFormat.svg"
            selected={meetingFormat === 2}
            onClickCard={() => onClickCard(2)}
          />
          <Card
            id="__inPersonCard"
            title="In-person"
            className="researchTypeCard"
            vertical
            content={[{ label: '1 on 1 interviews, Focus groups' }, { label: 'In home visits' }]}
            image="/askablePlus/illustrations/inPersonFormat.svg"
            selected={meetingFormat === 1}
            onClickCard={() => onClickCard(1)}
          />
        </div>
      </div>
      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default deprecatedWithRouter(DiscoveryInterviewMeetingFormat);
