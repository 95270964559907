import { Button } from '@askable/ui/components/ui/button';
import { Form, FormItem, FormLabel, FormControl, FormField, FormMessage } from '@askable/ui/components/ui/form';
import { Input } from '@askable/ui/components/ui/input';
import { Label } from '@askable/ui/components/ui/label';
import { Link } from '@askable/ui/components/ui/link';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@askable/ui/components/ui/select';
import { toast } from '@askable/ui/components/ui/sonner';
import { Switch } from '@askable/ui/components/ui/switch';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ASKABLE_TERMS_URL, ASKABLE_PRIVACY_URL } from 'shared-utils';
import { useSwitchTeam } from 'src/hooks/useSwitchTeam';
import { useMutation, useQuery } from 'urql';
import { z } from 'zod';

import { MinimalPage } from 'components/MinimalPage/MinimalPage';
import { useConnectedClient } from 'context/ConnectedClientContext';
import fetchCountries from 'data/queries/country/fetchCountries';
import { domainBlacklist } from 'lib/domainBlacklist';

import NewTeamMutation from '../data/NewTeam.mutation';

import type { SubmitHandler } from 'react-hook-form';

const formSchema = z.object({
  addDomain: z.boolean(),
  name: z.string().min(1, { message: 'Team name is required' }).max(30, {
    message: 'Team name is too long',
  }),
  billingCountry: z.string().min(1, { message: 'Billing country is required' }),
});

export const CreateTeam = () => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: { name: '', addDomain: false },
  });
  const { formState } = form;
  const navigate = useNavigate();
  const { details, actions } = useConnectedClient();

  const [{ fetching: creatingTeam }, createTeam] = useMutation(NewTeamMutation);
  const [{ fetching: countryListLoading, data: countryList }] = useQuery({ query: fetchCountries });

  const { switchTeam, switchingTeams } = useSwitchTeam({
    updateClientDetails: actions.updateClientDetails,
  });
  const domain = details?.email?.split('@')[1];

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = async fv => {
    try {
      const result = await createTeam({
        name: fv.name,
        allowedDomains: fv.addDomain ? [domain!] : [],
        billingCountry: fv.billingCountry,
      });
      if (result.error?.graphQLErrors[0].extensions.type === 'team_already_exists') {
        throw new Error('Team name is already in use.');
      }
      if (!result.data?.newTeam?._id) {
        throw new Error('Failed to create team');
      }

      const switchResult = await switchTeam(result.data?.newTeam?._id);

      if (switchResult === 'kinde_switch') {
        return;
      }

      navigate('/');
    } catch (e) {
      toast.error(`Error creating team. ${(e as Error)?.message ?? 'Unknown error, we are looking into this.'}`);
    }
  };

  // put the form in a loading state while the submit handler is processing, and while onSwitchToDifferentTeam is being called
  const isLoading =
    formState.isLoading || formState.isSubmitting || switchingTeams || creatingTeam || countryListLoading;

  return (
    <MinimalPage
      bodyClasses="mt-6 md:mt-auto mx-auto h-auto w-full max-w-sm py-4 space-y-4"
      footer={
        <p className="text-sm text-muted-foreground">
          By clicking Create team, you accept our{' '}
          <Link href={ASKABLE_TERMS_URL} target="_blank">
            Terms of Use
          </Link>{' '}
          and{' '}
          <Link href={ASKABLE_PRIVACY_URL} target="_blank">
            Privacy Policy
          </Link>
        </p>
      }
    >
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <h1 className="mb-4 text-lg font-medium">Set up your team</h1>
          <div className="space-y-6">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Team name</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      inputSize="lg"
                      className="w-full"
                      placeholder="ACME Inc."
                      {...field}
                      disabled={isLoading}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="billingCountry"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Billing Country</FormLabel>
                  <FormControl>
                    <Select {...field} onValueChange={field.onChange} disabled={isLoading}>
                      <SelectTrigger size="lg">
                        <SelectValue placeholder="Select a country" />
                      </SelectTrigger>
                      <SelectContent>
                        {countryList?.countryList?.map(country => (
                          <SelectItem key={country?._id} value={country?.country_code || 'AU'}>
                            {country?.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {!domainBlacklist.includes(domain!) ? (
              <FormField
                control={form.control}
                name="addDomain"
                render={({ field }) => (
                  <FormItem className="space-y-4">
                    <div className="space-y-2">
                      <FormLabel>Allow domain access</FormLabel>
                      <FormMessage className="text-sm text-muted-foreground">
                        Enabling access allows users with email address with with this domain to join the team without
                        invitation.
                      </FormMessage>
                    </div>
                    <FormControl>
                      <div className="flex w-full items-center justify-between space-x-2">
                        <Label htmlFor="domain-join">
                          Enable access for <strong>{domain}</strong>
                        </Label>
                        <Switch checked={field.value} onCheckedChange={field.onChange} id="domain-join" />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            ) : null}
          </div>
          <Button
            type="submit"
            variant="primary"
            size="lg"
            className="mt-6 w-full"
            disabled={!!formState.errors.name}
            isLoading={isLoading}
          >
            Create team
          </Button>
        </form>
      </Form>
    </MinimalPage>
  );
};
