import { useCallback, useEffect, useRef, useState } from 'react';
import { useClient, useQuery } from 'urql';

import { globalVariables } from 'lib/globalVariables';

import { FetchFigmaAuthStatus } from '../data/queries/user/FetchFigmaAuthStatus.query';

export const FIGMA_AUTH_CALLBACK_PATH = 'oauth/figma';
export type FigmaAuthStatus = 'unknown' | 'connecting' | 'connected' | 'not-connected' | 'failed';

export const useFigmaAuth = () => {
  const [status, setStatus] = useState<FigmaAuthStatus>('unknown');
  const statusRef = useRef(status);
  const pollingTimeoutRef = useRef<NodeJS.Timeout>();

  const [{ data }, executeQuery] = useQuery({
    query: FetchFigmaAuthStatus,
    requestPolicy: 'cache-and-network',
  });
  const client = useClient();

  const checkStatus = async () => {
    const { data: figmaAuthStatusData, error } = await client
      .query(
        FetchFigmaAuthStatus,
        {},
        {
          requestPolicy: 'network-only',
        },
      )
      .toPromise();

    if (!error && figmaAuthStatusData?.viewerFigmaAuthStatus === true) {
      setStatus('connected');
      statusRef.current = 'connected';
      return true;
    }
    setStatus('not-connected');
    statusRef.current = 'not-connected';
    return false;
  };

  useEffect(() => {
    return () => {
      if (pollingTimeoutRef.current) {
        clearTimeout(pollingTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (data?.viewerFigmaAuthStatus === true) {
      setStatus('connected');
    } else if (data?.viewerFigmaAuthStatus === false) {
      setStatus('not-connected');
    }
  }, [status, data?.viewerFigmaAuthStatus]);

  useEffect(() => {
    statusRef.current = status;
  }, [status]);

  type PollingOptions = {
    intervalMs: number; // time between each poll
    timeoutMs: number; // max time to poll for
    onTimeout?: () => void; // callback when polling times out
    onAuthenticated?: () => void; // callback when authenticated
  };
  const pollUntilConnected = useCallback(
    (pollingOptions: PollingOptions, attempt = 1) => {
      if (pollingTimeoutRef.current) {
        clearTimeout(pollingTimeoutRef.current);
      }
      if (attempt * pollingOptions.intervalMs >= pollingOptions.timeoutMs) {
        if (statusRef.current !== 'connected' && pollingOptions.onTimeout) {
          pollingOptions.onTimeout();
        }
        setStatus('failed');
        return;
      }
      if (statusRef.current === 'connected') {
        pollingOptions.onAuthenticated?.();
        return;
      }
      pollingTimeoutRef.current = setTimeout(() => {
        executeQuery();
        pollUntilConnected(pollingOptions, attempt + 1);
      }, pollingOptions.intervalMs);
    },
    [executeQuery],
  );

  const openAuthPopup = useCallback(
    ({ polling: pollingOptions }: { polling?: Partial<PollingOptions> } = {}) => {
      openFigmaAuthPopup();
      setStatus('connecting');
      pollUntilConnected({
        intervalMs: 2000, // 2 seconds default
        timeoutMs: 300000, // 5 minutes default
        ...pollingOptions,
      });
    },
    [pollUntilConnected, status],
  );

  return { status, openAuthPopup, checkStatus };
};

export const figmaRedirectUri = () => {
  const { environment, clientsAppURI } = globalVariables.getEnvironmentVariables();
  // use generic dev URI for preview to handle allowed redirect URIs
  if (environment === 'PREVIEW' || environment === 'DEV') {
    return `https://app-dev.askable.com/${FIGMA_AUTH_CALLBACK_PATH}`;
  }
  return `${clientsAppURI}/${FIGMA_AUTH_CALLBACK_PATH}`;
};

const openFigmaAuthPopup = () => {
  const scope = 'files:read';

  const params = new URLSearchParams({
    client_id: import.meta.env.VITE_FIGMA_CLIENT_ID!,
    redirect_uri: figmaRedirectUri(),
    scope,
    state: 'default',
    response_type: 'code',
  });

  const popup = window.open(`https://www.figma.com/oauth?${params.toString()}`, '_blank', 'width=567,height=775');
  if (popup) {
    popup.focus();
  }

  return popup;
};
