import { Popover, PopoverContent, PopoverTrigger } from '@askable/ui/components/ui/popover';
import { useTranslation } from 'react-i18next';

import { FacilitatorAvatar } from './FacilitatorAvatar';
import { FacilitatorItem } from './FacilitatorItem';

import type { FacilitatorItemProps } from './FacilitatorItem';
import type { AvatarProps } from '@askable/ui/components/ui/avatar';

type FacilitatorListProps = {
  popoverTriggerAvatarSize?: AvatarProps['size'];
  facilitators: FacilitatorItemProps[];
};

export const FacilitatorList = ({ facilitators, popoverTriggerAvatarSize }: FacilitatorListProps) => {
  const { t } = useTranslation();

  return facilitators.length > 0 ? (
    <Popover openOnHover>
      <PopoverTrigger className="flex cursor-default" data-testid="facilitator_list">
        {facilitators.map(facilitator => (
          <FacilitatorAvatar
            firstName={facilitator.firstName || t('global.unknown')}
            key={facilitator.id}
            lastName={facilitator.lastName}
            pictureLink={facilitator.pictureLink ?? ''}
            size={popoverTriggerAvatarSize}
          />
        ))}
      </PopoverTrigger>

      <PopoverContent
        onOpenAutoFocus={e => e.preventDefault()}
        className="flex w-72 flex-col gap-3 bg-background dark:bg-accent"
        align="start"
      >
        <h4 className="text-md font-semibold leading-tight">
          {t('sections.discover.contributingFacilitators', { count: facilitators.length })}
        </h4>

        <ul className="flex flex-col gap-2 text-sm">
          {facilitators.map(({ counts, ...facilitator }) => (
            <li key={facilitator.id} id={facilitator.id} className="grid grid-cols-3 justify-between gap-2">
              <FacilitatorItem {...facilitator} counts={counts} />
            </li>
          ))}
        </ul>
      </PopoverContent>
    </Popover>
  ) : null;
};
