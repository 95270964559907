import { Box, createStylesContext, forwardRef, useMultiStyleConfig } from '@chakra-ui/react';

import type { BoxProps, HTMLChakraProps, ThemingProps } from '@chakra-ui/react';
import type { FC, PropsWithChildren } from 'react';

interface BadgeProps extends HTMLChakraProps<'div'>, ThemingProps<'ButtonBadgeGroup'> {
  isRound?: boolean;
  shouldShow?: boolean;
  placement?: 'top-left' | 'rop-right';
}

const [ButtonBadgeGroupProvider, useBadgeStyleConfig] = createStylesContext('ButtonBadgeGroup');

export const ButtonBadgeGroup: ReturnType<typeof forwardRef<BadgeProps, 'div'>> = forwardRef(
  ({ children, size, isRound, placement, variant, shouldShow, ...props }, ref) => {
    const styles = useMultiStyleConfig('ButtonBadgeGroup', {
      size,
      variant,
      isRound,
      placement,
      shouldShow,
    });

    return (
      <Box __css={styles.container} ref={ref} {...props}>
        <ButtonBadgeGroupProvider value={styles}>{children}</ButtonBadgeGroupProvider>
      </Box>
    );
  },
);

export const ButtonBadge: FC<PropsWithChildren<BoxProps>> = ({ children, ...props }) => {
  const styles = useBadgeStyleConfig();

  return (
    <Box __css={styles.badge} {...props}>
      {children}
    </Box>
  );
};
