import { Button } from '@askable/ui/components/ui/button';
import { Label } from '@askable/ui/components/ui/label';
import { RadioGroup, RadioGroupItem } from '@askable/ui/components/ui/radio-group';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import PriceCardContainer from 'components/askablePlus/components/priceCardContainer';

function ModerationGuide(props: any) {
  const askablePlusBrief = _.get(props, 'askablePlusBrief');
  const [askablePlusBriefState, setAskablePlusBriefState] = useState(askablePlusBrief);
  const [moderationGuide, setModerationGuide] = useState(
    _.get(askablePlusBrief, 'askable_plus.additional_info.moderation_guide_type') ?? 1,
  );

  useEffect(() => {
    if (!_.get(props, 'askablePlusBrief._id')) {
      props.history.push('/askable-plus/create');
    } else {
      props.updateLastStep({
        step: 'Additional Info',
        subStep: `/askable-plus/${askablePlusBrief._id}/additional-info/moderation-guide`,
        stepId: 'additional_info_moderation_guide',
      });
      props.renderRightContent(rightContent());
    }
  }, []);

  const rightContent = () => {
    return (
      <>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          askablePlusBrief={askablePlusBriefState}
          team={_.get(props, 'team')}
          context={props.context}
        />
      </>
    );
  };

  const onChangeModerationGuide = (value: any) => {
    if (!askablePlusBriefState) {
      return;
    }

    const valueToBeSet = parseInt(value, 10);
    const askablePlusBriefStateObj = {
      ...askablePlusBriefState,
      askable_plus: {
        ...askablePlusBriefState.askable_plus,
        additional_info: {
          ...askablePlusBriefState.askable_plus.additional_info,
          moderation_guide_type: valueToBeSet,
        },
      },
    };
    setModerationGuide(valueToBeSet);
    setAskablePlusBriefState(askablePlusBriefStateObj);
    props.updateAskablePlusBriefState(askablePlusBriefStateObj);
  };

  const onClickNext = () => {
    const redirectTo = `/askable-plus/${askablePlusBrief._id}/additional-info/your-timezone`;
    props.history.push({ pathname: redirectTo });
  };

  const options = [
    {
      label: "I'd like the researcher to create one",
      value: 1,
    },
    {
      label: "I'll provide it myself",
      value: 2,
    },
  ];

  return (
    <div className="createAskablePlusBriefContent">
      <h1 className="title">Do you require a moderation guide to be set up?</h1>
      <p className="label">A moderation guide outlines the questions and format of the user interview.</p>

      <RadioGroup defaultValue={moderationGuide} onValueChange={(value: string) => onChangeModerationGuide(value)}>
        {options.map(option => (
          <div className="flex items-center space-x-2" key={option.value}>
            <RadioGroupItem value={String(option.value)} id={`option_${option.value}`} />
            <Label htmlFor={`option_${option.value}`}>{option.label}</Label>
          </div>
        ))}
      </RadioGroup>

      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default deprecatedWithRouter(ModerationGuide);
