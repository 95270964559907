import { cn, secondsToTime } from '@askable/ui/lib/utils';
import { Maximize, Minimize } from 'lucide-react';
import { fullscreen } from 'shared-utils';

import { ControlButton } from './control-button';
import { ControlProgress } from './control-progress';
import { ControlVolume } from './control-volume';

import type { Labels, Chapter } from './';
import type ReactPlayer from 'react-player/file';

interface ControlsBarProps {
  chapters?: Chapter[];
  containerRef: React.RefObject<HTMLDivElement>;
  duration: number;
  isDisabled: boolean;
  isFullscreen: boolean;
  isMobile: boolean;
  isPlaying: boolean;
  isVisible: boolean;
  labels: Omit<Labels, 'error'>;
  loadingProgress: number;
  playbackRate: number;
  playerRef: React.RefObject<ReactPlayer>;
  progress: number;
  volume: number;
  playVideo: () => void;
  setPlaybackRate: (playbackRate: number) => void;
  setVolume: (volume: number) => void;
}

export const ControlsBar = ({
  containerRef,
  chapters,
  duration,
  isDisabled,
  isFullscreen,
  isMobile,
  isPlaying,
  isVisible,
  labels,
  loadingProgress,
  playbackRate,
  playerRef,
  progress,
  volume,
  playVideo,
  setPlaybackRate,
  setVolume,
}: ControlsBarProps) => {
  // Toggle playback speed between 0.5x, 1x, 1.5x, 2x
  const updatePlaybackRate = () => {
    const speeds = [0.5, 1, 1.5, 2];
    const currentIndex = speeds.indexOf(playbackRate);
    const nextIndex = (currentIndex + 1) % speeds.length;
    setPlaybackRate(speeds[nextIndex]);
  };

  return (
    <div
      className={cn('absolute bottom-0 z-20 flex w-full flex-col p-2 text-lightest transition-all sm:p-4', {
        'translate-y-1 opacity-0': !isVisible,
        'sm:p-2': isMobile,
      })}
    >
      <ControlProgress
        chapters={chapters || []}
        duration={duration}
        isDisabled={isDisabled}
        labels={{ progress: labels.progress }}
        loadingProgress={loadingProgress}
        playbackRate={playbackRate}
        progress={progress}
        onSeek={time => {
          if (playerRef?.current) {
            playerRef.current.seekTo(time, 'seconds');
          }
        }}
      />

      <div className="grid w-full grid-cols-3 grid-rows-1 items-center justify-between">
        <div className="flex gap-1">
          <ControlButton isDisabled={isDisabled} label={isPlaying ? labels.pause : labels.play} onClick={playVideo}>
            {isPlaying ? (
              <svg fill="currentColor" className="h-5 w-5" viewBox="0 0 24 24">
                <title>{labels.pause}</title>
                <path d="M6.75 5.25a.75.75 0 0 1 .75-.75H9a.75.75 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75H7.5a.75.75 0 0 1-.75-.75V5.25Zm7.5 0A.75.75 0 0 1 15 4.5h1.5a.75.75 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75H15a.75.75 0 0 1-.75-.75V5.25Z" />
              </svg>
            ) : (
              <svg fill="currentColor" className="h-5 w-5" viewBox="0 0 24 24">
                <title>{labels.play}</title>
                <path d="M4.5 5.7C4.5 4.2 6 3.3 7.3 4l11.5 6.4c1.3.7 1.3 2.5 0 3.2L7.3 20c-1.3.7-2.8-.2-2.8-1.7V5.7Z" />
              </svg>
            )}
          </ControlButton>

          <ControlButton isDisabled={isDisabled} label={labels.playbackRate} onClick={updatePlaybackRate}>
            {playbackRate}x
          </ControlButton>

          <ControlVolume
            isDisabled={isDisabled}
            isMobile={isMobile}
            labels={{ mute: labels.mute, unmute: labels.unmute, volume: labels.volume }}
            volume={volume}
            setVolume={setVolume}
          />
        </div>

        <div className="flex justify-center gap-1 text-sm tabular-nums leading-none [text-shadow:_0_0_2px_rgba(0,0,0,0.4)]">
          {secondsToTime(progress * duration * playbackRate)}
          {!isMobile ? <span className="hidden text-lightest/70 sm:block">/ {secondsToTime(duration)}</span> : null}
        </div>

        <div className="flex justify-end">
          <ControlButton
            isDisabled={isDisabled}
            label={labels.fullscreen}
            onClick={() => {
              if (isFullscreen) {
                fullscreen.close();
              } else {
                fullscreen.open(containerRef.current);
              }
            }}
          >
            {isFullscreen ? <Minimize className="h-5 w-5" /> : <Maximize className="h-5 w-5" />}
          </ControlButton>
        </div>
      </div>
    </div>
  );
};
