import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const SpotlightIcon: CreateIconType = createIcon({
  displayName: 'SpotlightIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 3.5C0.447715 3.5 0 3.94772 0 4.5V12.5C0 13.0523 0.447715 13.5 1 13.5H15C15.5523 13.5 16 13.0523 16 12.5V4.5C16 3.94772 15.5523 3.5 15 3.5H1ZM10 7.5C9.44771 7.5 9 7.94772 9 8.5V10.5C9 11.0523 9.44771 11.5 10 11.5H13C13.5523 11.5 14 11.0523 14 10.5V8.5C14 7.94772 13.5523 7.5 13 7.5H10Z"
      fill="currentColor"
    />
  ),
});
