import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const DeleteIcon: CreateIconType = createIcon({
  displayName: 'DeleteIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: [
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4.4 3.53a3.603 3.603 0 0 1 7.196 0H15.142a.75.75 0 0 1 0 1.5h-1.221c.124 2.76.087 5.528-.33 8.31a2.996 2.996 0 0 1-2.964 2.553h-5.26a2.996 2.996 0 0 1-2.962-2.552c-.418-2.783-.455-5.55-.33-8.31H.856a.75.75 0 0 1 0-1.5h1.996l.01-.001H4.4ZM6.51 2.223a2.103 2.103 0 0 1 3.582 1.307h-4.19c.042-.492.256-.955.608-1.307ZM3.888 13.118c-.401-2.676-.437-5.36-.312-8.088h8.843c.125 2.728.09 5.412-.312 8.088a1.496 1.496 0 0 1-1.48 1.275h-5.26c-.74 0-1.369-.542-1.479-1.275Z"
        clipRule="evenodd"
      />
    </g>,
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>,
  ],
});
