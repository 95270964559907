import { Button } from '@askable/ui/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@askable/ui/components/ui/dropdown-menu';
import { Popover, PopoverContent, PopoverTrigger } from '@askable/ui/components/ui/popover';
import _ from 'lodash';
import { ChevronDown } from 'lucide-react';
import momentTimezone from 'moment-timezone';
import { useState } from 'react';

import { MiniCalendar, LoadingOverlay } from 'components/common';
import { useConnectedClient } from 'context/ConnectedClientContext';
import { bookingUtils } from 'lib/booking';
import { utils } from 'lib/utils';

import type { ApolloClient } from '@apollo/client';
import type { Booking } from 'generated/graphql';
import type { Moment } from 'moment-timezone';

import './sessionToAddPopover.scss';

interface SessionProps {
  _id?: string;
  start: number;
  end: number;
  _researcher_user_id: string | null;
}
interface Props {
  sessionToAdd?: SessionProps;
  timezone: string;
  booking_id?: string;
  booking?: Booking;
  sessionDuration?: number;
  client?: ApolloClient<object>;
  onClose: () => void;
  onAddSession: (session: SessionProps) => void;
}

type HoursMinutesDay = { value: string; hour: number; minutes: number };

function SessionToAddPopover(props: Props) {
  const { details } = useConnectedClient();
  const [openMiniCalendarPopover, setOpenMiniCalendarPopover] = useState(false);
  const [dateToAdd, setDateToAdd] = useState<Moment | null>(
    props?.sessionToAdd?.start ? momentTimezone(props?.sessionToAdd?.start).tz(props.timezone) : null,
  );
  const [isLoading, setIsLoading] = useState(false);
  const hoursMinutesDay = utils.getHoursMinutesDay();
  const isBookingLive = bookingUtils.isActive(props.booking);
  const isBookingCompleted = bookingUtils.isCompleted(props.booking);

  if (!props.sessionToAdd || !props.sessionToAdd.start) {
    return null;
  }

  const onAddSession = async () => {
    if (!dateToAdd) return null;

    setIsLoading(true);
    const sessionToAdd = {
      start: dateToAdd.valueOf(),
      end: momentTimezone(dateToAdd).add(props.sessionDuration, 'minutes').valueOf(),
      _researcher_user_id: details?._id!,
    };

    // Callback to create a session
    await props.onAddSession(sessionToAdd);

    props.onClose();
    setIsLoading(false);
  };

  const handleScrollToSelectedTime = (isOpen: boolean) => {
    if (!isOpen) {
      return;
    }
    const timeSelected = momentTimezone(dateToAdd).tz(props.timezone).format('h:mm a');
    setTimeout(() => {
      const element = document.getElementById(`time-${timeSelected}`);
      if (element) element.scrollIntoView();
    }, 150);
  };

  const handleSelectTime = (item: HoursMinutesDay) => {
    const m = momentTimezone(dateToAdd).tz(props.timezone);
    const newSelectedTime = m.hour(item.hour).minutes(item.minutes).valueOf();
    setDateToAdd(momentTimezone(newSelectedTime).tz(props.timezone));
  };

  return (
    <div className="sessionToAddComponent">
      {isLoading && <LoadingOverlay style={{ opacity: 0.8 }} />}
      <h3>Add session slot</h3>
      <div className="timeToAddContainer">
        <Popover open={openMiniCalendarPopover} onOpenChange={setOpenMiniCalendarPopover}>
          <PopoverTrigger>
            <div
              id="sessionToAddComponent_dayContainer"
              className="dayContainer"
              onClick={() => setOpenMiniCalendarPopover(true)}
            >
              <p>{momentTimezone(dateToAdd).tz(props.timezone).format('D MMM, YYYY')}</p>
              <ChevronDown color="#444" style={{ height: 20, marginTop: 4 }} />
            </div>
          </PopoverTrigger>
          <PopoverContent align="start" side="bottom" className="z-[4001]">
            <MiniCalendar
              timezone={props.timezone}
              month={momentTimezone(dateToAdd).tz(props.timezone).month()}
              year={momentTimezone(dateToAdd).tz(props.timezone).year()}
              selectedDay={dateToAdd}
              onClickDay={(day: any) => {
                const selectedDay = momentTimezone(day).date();
                const selectedMonth = momentTimezone(day).month();
                const selectedYear = momentTimezone(day).year();
                const m = momentTimezone(dateToAdd).date(selectedDay);
                const newDaySelected = m.month(selectedMonth).year(selectedYear).valueOf();
                setDateToAdd(momentTimezone(newDaySelected).tz(props.timezone));
                setOpenMiniCalendarPopover(false);
              }}
              disablePastDates
            />
          </PopoverContent>
        </Popover>
        <DropdownMenu onOpenChange={handleScrollToSelectedTime}>
          <DropdownMenuTrigger asChild>
            <div id="sessionToAddComponent_hourContainer" className="hourContainer">
              <p>{momentTimezone(dateToAdd).tz(props.timezone).format('h:mm a')}</p>
              <ChevronDown color="#444" style={{ height: 20, marginTop: 4 }} />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="z-[4001]">
            {_.map(hoursMinutesDay, item => {
              const isSelected = item.value === momentTimezone(dateToAdd).tz(props.timezone).format('h:mm a');
              return (
                <DropdownMenuItem
                  asChild
                  id={`time-${item.value}`}
                  key={`time-${item.value}`}
                  className="w-full cursor-pointer px-2 py-1 text-sm hover:bg-gray-100"
                  onClick={() => handleSelectTime(item)}
                >
                  <span className={isSelected ? 'selected' : ''}>{item.value}</span>
                </DropdownMenuItem>
              );
            })}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="bottomContainer">
        <div className="alertContainer">
          {(isBookingLive || isBookingCompleted) && (
            <>
              <img alt="icon-calendar" src="/icons/calendarInterrogation.svg" className="calendarIcon" />
              <p>Existing applicant availability unknown</p>
            </>
          )}
        </div>
        <Button variant="primary" onClick={onAddSession}>
          Add
        </Button>
      </div>
    </div>
  );
}

export default SessionToAddPopover;
