import { useMemo } from 'react';
import { useQuery } from 'urql';
import { v5 as uuidv5 } from 'uuid';

import { useConnectedClient } from 'context/ConnectedClientContext';
import getLocationAutocomplete from 'data/queries/location/getLocationAutocomplete';

import type { ResultOf } from 'gql.tada';
import type React from 'react';

type ChildState = {
  data?: Required<ResultOf<typeof getLocationAutocomplete>>;
  fetching: boolean;
};

type Props = {
  children: (result: ChildState) => React.JSX.Element | null;
  input?: any;
  location?: {
    countries?: string[];
    latitude?: number | null;
    longitude?: number | null;
  };
  types?: any;
  biasLocation?: boolean;
};

export const LocationAutocompleteQuery: React.FC<Props> = props => {
  const { details } = useConnectedClient();
  const sessionToken = useMemo(() => generateUUID(Date.now().toString(), details?._id), [details?._id]);

  const shouldPause = !props.input || props.input.length <= 1;
  const [{ fetching, data, error }] = useQuery({
    query: getLocationAutocomplete,
    variables: {
      search: {
        sessionToken,
        input: props.input,
        types: props.types,
        countries: props.location?.countries,
        latitude: props.location?.latitude,
        longitude: props.location?.longitude,
        biasLocation: props.biasLocation ?? false,
      },
    },
    pause: shouldPause,
  });

  function generateUUID(token: string, userId?: string) {
    const namespace = uuidv5('http://app.askable.com', uuidv5.URL);
    return uuidv5(`LocationAutocompleteQuery|${userId || 'anon'}|${token}`, namespace);
  }

  return (
    <div>
      {error ? <div>Error: {error.message}</div> : null}
      {props.children({ data, fetching })}
    </div>
  );
};
