import { getColorValue } from '@askable/ui/lib/utils';

import type { IconProps } from '.';

export const Message = ({ bgColor = 'foreground', fgColor = 'background', size = 48 }: IconProps) => {
  const bgHsl = getColorValue(bgColor);
  const fgHsl = getColorValue(fgColor);

  return (
    <svg fill="none" viewBox="0 0 64 64" width={size} height={size}>
      <path
        fill={bgHsl}
        d="M56.6 25.6C52.3 9 25.2 2.3 12 13.8c-.8.7-5.3 5.7-6 6.5a17 17 0 0 0-3.2 16.1c1.2 5 5.6 8.2 9.9 10a36 36 0 0 0 6.4 1.8c1.4 2.8 1.4 5.4-.6 8.7 1.3.4 2.7.4 4 0a17 17 0 0 0 11.3-8.5 39.3 39.3 0 0 0 17-6.5c1.8-1.3 7.8-6.1 9.2-9.9 1.2-3.3-2.4-3-3.3-6.4Z"
      />
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="M37.9 8.2c9.1 2.4 17.4 8.4 19.7 17.1.3 1.2 1 1.8 1.8 2.5l.3.3c.5.4 1 1 1.3 1.7a4 4 0 0 1-.2 2.6 19 19 0 0 1-4.7 6.2 40.3 40.3 0 0 1-21.8 10.7 18.2 18.2 0 0 1-11.6 8.6 9 9 0 0 1-4.6 0 1 1 0 0 1-.5-1.5c1-1.6 1.3-2.9 1.4-4 0-1.1-.2-2.2-.7-3.3-2-.4-4.1-1-6.1-1.9C7.8 45.4 3 42.1 1.7 36.7c-1.7-7-.1-12.7 3.5-17a182.4 182.4 0 0 1 6-6.6c7-6 17.5-7.2 26.7-4.9Zm-25.4 6.4a76.8 76.8 0 0 0-5.8 6.3 16.3 16.3 0 0 0-3 15.3c1 4.5 5 7.5 9.2 9.2a35 35 0 0 0 6.3 1.9c.3 0 .5.2.7.5.7 1.5 1.2 3 1 4.6 0 1.3-.3 2.5-1 3.8.8 0 1.5 0 2.3-.2m-9.7-41.4C19 9 28.6 7.9 37.4 10c8.8 2.3 16.2 8 18.3 15.7a6.8 6.8 0 0 0 2.4 3.5l.3.3c.4.4.6.6.7.9 0 .2.1.5-.1 1.2-.6 1.6-2.3 3.6-4.2 5.5a52.2 52.2 0 0 1-4.7 4c-4 2.9-9.1 4.5-14 5.7l-2.5.5a1 1 0 0 0-.8.5 16.2 16.2 0 0 1-10.6 8"
        clipRule="evenodd"
      />
      <path
        fill={fgHsl}
        d="M61.2 20.5C55.3-2 0 1.6 7.2 31.4c1.3 5 5.7 8.1 10 9.9a36 36 0 0 0 6.5 2 10 10 0 0 1-2 9c1.7.5 3.5.6 5.2.1 3.3-.9 8.6-4.3 10.3-8.9a44 44 0 0 0 3.8-.7C47.9 41 55.7 38.5 60 32c1.8-3 2.1-8 1.2-11.5Z"
      />
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="M49.6 7.6c6.1 2.5 11 6.6 12.6 12.7.5 1.8.6 4.1.4 6.3-.2 2.1-.8 4.3-1.9 6-4.4 6.8-12.6 9.5-19.5 11.2a44 44 0 0 1-3.3.6 18 18 0 0 1-10.7 9c-2 .5-4 .4-5.8-.1a1 1 0 0 1-.4-1.6 9 9 0 0 0 1.9-7.6c-2.1-.5-4.1-1-6.1-1.9-4.4-1.8-9.1-5.2-10.5-10.6-2-7.7.3-14 4.8-18.3 4.4-4.4 11-7 18-8 7-.8 14.4-.1 20.5 2.3ZM8.2 31.2c-1.7-7.1.2-12.6 4.2-16.5 4-4 10.3-6.5 17-7.4a40 40 0 0 1 19.5 2.2c5.7 2.3 10 6 11.4 11.3.4 1.6.6 3.6.4 5.6-.2 2-.8 3.8-1.6 5.1-4 6-11.4 8.7-18.3 10.3a43 43 0 0 1-3.8.7 1 1 0 0 0-.8.7c-.7 2-2.3 4-4.2 5.4a16 16 0 0 1-5.4 2.8c-1 .3-2.1.4-3.2.3a11 11 0 0 0 1.2-8.7 1 1 0 0 0-.7-.7 35 35 0 0 1-6.4-2c-4.1-1.6-8.2-4.6-9.3-9.1"
        clipRule="evenodd"
      />
    </svg>
  );
};
