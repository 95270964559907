import { Button } from '@askable/ui/components/ui/button';
import { Dialog, DialogContent } from '@askable/ui/components/ui/dialog';
import _ from 'lodash';
import { CalendarFold, CircleHelp } from 'lucide-react';
import momentTimezone from 'moment-timezone';
import { useState } from 'react';

import { bookingUtils } from 'lib/booking';
import { utils } from 'lib/utils';

import inviteApplicantsHelperImage from '../assets/inviteApplicantsHelper.png';

import SessionStatusContainer from './sessionStatusContainer';

function InvitationDialogContentContainer(props: any) {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popupContent, setPopupContent] = useState(null);
  const [popoverCustomClass, setPopoverCustomClass] = useState('');

  const { booking, session, participant, previousAttendance, isAvailableForSession, isBulkInvite, bulkSession } = props;
  const timezone = _.get(booking, 'config.timezone') || utils.getCurrentTimezone();

  const handleClick = (event: any, popupContentArgs: any, popoverCustomClassArgs: string) => {
    event.preventDefault();

    setPopoverOpen(true);
    setPopupContent(popupContentArgs);
    setPopoverCustomClass(popoverCustomClassArgs);
  };

  const renderHelpOnInvitationContainer = () => {
    return (
      <div className="flex-between flex items-center gap-2 p-4 pr-0">
        <div className="w-full">
          <div className="font-bold">What happens when I invite an applicant?</div>
          <p>
            When you send an invite, the applicant will get an SMS and an email asking them to RSVP to the session time
            that you've allocated them to.
          </p>
        </div>
        <img src={inviteApplicantsHelperImage} alt="" className="w-48" />
      </div>
    );
  };

  const renderHelpContainer = () => {
    return (
      <div className={`helpContainer ${bookingUtils.isOnlineTask(booking) ? 'onlineTask' : ''}`}>
        {props.isBulkInvite && _.size(props.bulkSession.participants) > 0 ? (
          <p>All {_.size(props.bulkSession.participants)} participants will get an SMS</p>
        ) : (
          <p>{_.get(participant, 'user.meta.identity.firstname')} will be invited by SMS</p>
        )}
        {!bookingUtils.isOnlineTask(booking) && (
          <Button
            variant="ghost"
            size="sm"
            onClick={event => {
              handleClick(event, renderHelpOnInvitationContainer(), 'bigPopover');
            }}
            aria-label="Help"
          >
            <CircleHelp className="h-4 w-4" />
          </Button>
        )}
      </div>
    );
  };

  return (
    <>
      {(bookingUtils.isRemote(booking) || bookingUtils.isInPerson(booking)) &&
        (!previousAttendance || (isBulkInvite && _.size(bulkSession.participants) > 0)) && (
          <div className="sessionTimeContainer">
            <CalendarFold style={{ width: 32, height: 32 }} className="eventNote" />
            <div className="timeContainer">
              {bookingUtils.isRemote(booking) || bookingUtils.isInPerson(booking) ? (
                <p>{`${momentTimezone(_.get(session, 'start')).tz(timezone).format('hh:mm A')} - ${momentTimezone(_.get(session, 'end')).tz(timezone).format('hh:mm A')}`}</p>
              ) : (
                <p>Complete by:</p>
              )}
              <p>{momentTimezone(_.get(session, 'start')).tz(timezone).format('dddd Do MMMM')}</p>
            </div>
          </div>
        )}
      {!isAvailableForSession && (bookingUtils.isRemote(booking) || bookingUtils.isInPerson(booking)) && (
        <div className="sessionStatusContainer invited">
          <p>
            <b>Heads up:</b> This participant didn&apos;t mark themselves as &apos;available&apos; for this session time
            when they applied.
          </p>
        </div>
      )}
      {(bookingUtils.isRemote(booking) || bookingUtils.isInPerson(booking)) && (
        <SessionStatusContainer timezone={timezone} {...props} />
      )}
      {(!previousAttendance || (isBulkInvite && _.size(bulkSession.participants) > 0)) && renderHelpContainer()}
      <Dialog open={popoverOpen} onOpenChange={setPopoverOpen}>
        <DialogContent className={`tooltipText ${popoverCustomClass || ''} z-[2101]`}>{popupContent}</DialogContent>
      </Dialog>
    </>
  );
}

export default InvitationDialogContentContainer;
