import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const AutoLayoutIcon: CreateIconType = createIcon({
  displayName: 'AutoLayoutIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 3.5C0.447715 3.5 0 3.94772 0 4.5V12.5C0 13.0523 0.447715 13.5 1 13.5H15C15.5523 13.5 16 13.0523 16 12.5V4.5C16 3.94772 15.5523 3.5 15 3.5H1ZM5 11.5H6.41248L6.87496 10.1318H9.12594L9.58752 11.5H11L8.85084 5.5H7.15221L5 11.5ZM8.79187 9.1416L8.02588 6.87109H7.97717L7.20968 9.1416H8.79187Z"
      fill="currentColor"
    />
  ),
});
