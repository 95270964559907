import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const ChevronDownIcon: CreateIconType = createIcon({
  displayName: 'ChevronDownIcon',

  defaultProps: {
    ...defaultOptions,
    strokeWidth: '2px',
    viewBox: '0 0 16 16',
  },
  path: (
    <path
      d="M3.47 5.47a.75.75 0 011.06 0L8 8.94l3.47-3.47a.75.75 0 111.06 1.06l-4 4a.75.75 0 01-1.06 0l-4-4a.75.75 0 010-1.06z"
      fill="currentColor"
    />
  ),
});
