import _ from 'lodash';
import { useEffect, useState, Fragment, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import PriceCardContainer from 'components/askablePlus/components/priceCardContainer';
import { askablePlusUtils } from 'lib/askablePlus';

import { QuotaInput } from '../components/QuotaInput';

import type { QuotaInputRef } from '../components/QuotaInput';

function LongitudinalStudyQuota(props: any) {
  const navigate = useNavigate();
  const quotaInputRef = useRef<QuotaInputRef>(null);
  const askablePlusBrief = _.get(props, 'askablePlusBrief');
  const [askablePlusBriefState, setAskablePlusBriefState] = useState(askablePlusBrief);

  const totalResearchTypesSelected = askablePlusUtils.totalResearchTypesSelected(askablePlusBrief);
  const hasLongitudinalStudyType = askablePlusUtils.hasLongitudinalStudyType(askablePlusBrief);

  useEffect(() => {
    if (!_.get(props, 'askablePlusBrief._id')) {
      navigate({ pathname: '/askable-plus/create' });
    } else if (totalResearchTypesSelected <= 1 || !hasLongitudinalStudyType) {
      navigate({ pathname: `/askable-plus/${askablePlusBrief._id}/audience/participant-criteria` });
    } else {
      props.updateLastStep({
        step: 'Longitudinal Study',
        subStep: `/askable-plus/${askablePlusBrief._id}/longitudinal-study/quota`,
        stepId: 'longitudinal_study_quota',
      });
    }
  }, []);

  useEffect(() => {
    if (!quotaInputRef.current?.form.getValues('quota')) {
      quotaInputRef.current?.form.setError('quota', { message: 'This field is required' });
    }
    props.renderRightContent(rightContent());
  }, [askablePlusBrief, askablePlusBriefState]);

  const rightContent = () => {
    return (
      <Fragment>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          askablePlusBrief={askablePlusBriefState}
          team={_.get(props, 'team')}
          context={props.context}
        />
      </Fragment>
    );
  };

  const onChangeValue = (value: number) => {
    const askablePlusBriefStateObj = {
      ...askablePlusBriefState,
      askable_plus: {
        ...askablePlusBriefState.askable_plus,
        research_type: {
          ...askablePlusBriefState.askable_plus.research_type,
          longitudinal: {
            ...askablePlusBriefState.askable_plus.research_type.longitudinal,
            quota: value,
          },
        },
      },
    };
    setAskablePlusBriefState(askablePlusBriefStateObj);
    props.updateAskablePlusBriefState(askablePlusBriefStateObj);
  };

  const onClickNext = () => {
    const redirectTo = `/askable-plus/${askablePlusBrief._id}/longitudinal-study/study-duration`;
    navigate({ pathname: redirectTo });
  };

  return (
    <QuotaInput
      className="createAskablePlusBriefContent"
      ref={quotaInputRef}
      title="Longitudinal Study - Quota"
      description="How many participants do you need?"
      defaultValues={{
        quota: _.get(askablePlusBrief, 'askable_plus.research_type.longitudinal.quota'),
      }}
      onClickNext={onClickNext}
      onQuotaValueChange={onChangeValue}
    />
  );
}

export default LongitudinalStudyQuota;
