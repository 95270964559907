import { graphql } from 'src/graphql';

import type { ResultOf } from 'gql.tada';

export const IndustryList = graphql(`
  query IndustryList {
    industryList {
      _id
      name
      subcategories {
        _id
        name
      }
    }
  }
`);

export type IndustryListType = NonNullable<NonNullable<ResultOf<typeof IndustryList>>['industryList']>;
export type IndustryListItemType = NonNullable<IndustryListType[number]>;
