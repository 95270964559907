import _ from 'lodash';
import momentTimezone from 'moment-timezone';

import { bookingUtils } from 'lib/booking';
import { globalVariables } from 'lib/globalVariables';

import type { ReactElement } from 'react';

const SessionStatusContainer = (props: any): ReactElement | null => {
  // It should show an alert container with the status of the session
  // It perform some tests to assess which message to display to clients
  //  1) If the session is very close to happen the client should be warned
  //  2) If the session has someone already booked in and confirmed the client should be warned
  //  3) If the client has invited more people for the same session we should inform them
  // It should only show the alert if the session has some status AND is not a focus group
  // Focus Groups will always have more than one person, meaning that the alert don't make sense to be displayed

  // Quant and longitudinal bookings don't have a specific session time assigned, so it doesn't make sense to render the status container
  if (bookingUtils.isOnlineTask(props.booking) || bookingUtils.isLongitudinal(props.booking)) return null;

  const { sessionStatus, participant } = props.singleSession;
  const minutesToStartSession = momentTimezone(_.get(props.session, 'start'))
    .tz(props.timezone)
    .diff(momentTimezone(), 'minutes');
  if (minutesToStartSession <= 60) {
    return (
      <div className="sessionStatusContainer warned">
        <p>
          This session is starting <strong>very soon</strong> ({minutesToStartSession} mins).
        </p>
        <p>
          {_.get(props, 'participant.user.meta.identity.firstname')} may not have enough notice to get there on time.
        </p>
      </div>
    );
  }
  if (props.previousAttendance) {
    return (
      <div className="sessionStatusContainer invited">
        <p>{_.get(participant, 'user.meta.identity.firstname')} has already been invited on another booking.</p>
        <div className="bookingContainer">
          <a
            href={`${globalVariables.getEnvironmentVariables().clientsAppURI}/bookings/${props.previousAttendance.booking._id}/participants/all`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {_.get(props, 'previousAttendance.booking.name')}
          </a>
          {(bookingUtils.isRemote(props.previousAttendance.booking) ||
            bookingUtils.isInPerson(props.previousAttendance.booking)) && (
            <p>
              {momentTimezone(_.get(props, 'previousAttendance.CurrentStatus.session.start'))
                .tz(props.previousAttendance.booking.config.timezone)
                .format('h:mm A')}{' '}
              -{' '}
              {momentTimezone(_.get(props, 'previousAttendance.CurrentStatus.session.end'))
                .tz(props.previousAttendance.booking.config.timezone)
                .format('h:mm A')}
            </p>
          )}
          <p>
            {momentTimezone(_.get(props, 'previousAttendance.CurrentStatus.session.start'))
              .tz(props.previousAttendance.booking.config.timezone)
              .format('dddd Do MMM')}
          </p>
        </div>
        <p>
          Your study exclusion rules don't allow participants to attend more than one opportunity for{' '}
          {props.booking.team.name}.
        </p>
      </div>
    );
  }
  if (!_.has(sessionStatus, 'status') || _.get(props.booking, 'config.session.type') === 2) return null;
  if (_.has(participant, 'invitedSession')) {
    return (
      <div className={`sessionStatusContainer ${sessionStatus.status}`}>
        <p>
          You sent {_.get(participant, 'user.meta.identity.firstname')} an invitation{' '}
          {momentTimezone(_.get(participant, 'invitedSession.history.session_invitation_notification')).fromNow()}.
        </p>
      </div>
    );
  }
  return (
    <div className={`sessionStatusContainer ${sessionStatus.status}`}>
      {sessionStatus.status === 'invited' ? (
        <p>
          You’ve already invited {sessionStatus.qtd === 1 ? '1 person' : `${sessionStatus.qtd} people`} to this session.
          The first person who responds gets the spot, all other invitees will get added to the waitlist.
        </p>
      ) : (
        <p>
          You’ve already confirmed a participant for this session. Any additional invitees will get added to the
          waitlist.
        </p>
      )}
    </div>
  );
};

export default SessionStatusContainer;
