import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const CameraOffIcon: CreateIconType = createIcon({
  displayName: 'CameraOffIcon',
  defaultProps: defaultOptions,
  path: [
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.22949 2.16931C2.35227 2.13634 2.48011 2.11535 2.61171 2.10765C3.53896 2.05339 4.4948 2.02504 5.4715 2.02504C6.4482 2.02504 7.40403 2.05339 8.33128 2.10765C9.29536 2.16406 10.0575 2.93397 10.1099 3.89828C10.127 4.21367 10.1416 4.53223 10.1535 4.85369L13.25 3.47747V10.516L11.7019 9.82793L10.1537 9.13988C10.1418 9.46355 10.1271 9.7843 10.1099 10.1018C10.1051 10.1894 10.0945 10.2754 10.0784 10.3594"
    />,
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M0.818136 4.18854C0.773338 5.10227 0.75 6.04163 0.75 7.00006C0.75 8.0603 0.778559 9.09719 0.833134 10.1019C0.885516 11.0662 1.64764 11.8361 2.61172 11.8925C3.53897 11.9467 4.49481 11.9751 5.47151 11.9751C6.33239 11.9751 7.17706 11.9531 8.00023 11.9107"
    />,
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.25 12.9301C8.05247 8.87256 5.44702 6.23899 1.25 0.930053"
    />,
  ],
});
