import { compact } from '@chakra-ui/object-utils';
import { Box, Center, createStylesContext, useMultiStyleConfig } from '@chakra-ui/react';
import { getValidChildren } from '@chakra-ui/react-children-utils';
import { cloneElement } from 'react';

import type { BoxProps, CenterProps, HTMLChakraProps, ThemingProps } from '@chakra-ui/react';

const [TextareaGroupProvider, useTextareaGroupStyleConfig] = createStylesContext('TextareaGroup');

type TextareaGroupProps = HTMLChakraProps<'div'> & ThemingProps<'Textarea'>;

// TODO: test with combinations of children and sizes

const hPadding = (size: TextareaGroupProps['size']) => {
  if (size === 'xs') {
    return 6;
  }
  if (size === 'sm') {
    return 8;
  }
  if (size === 'md') {
    return 10;
  }
  return 12;
};
const vPadding = (size: TextareaGroupProps['size']) => {
  if (size === 'xs') {
    return 8;
  }
  if (size === 'sm') {
    return 10;
  }
  if (size === 'md') {
    return 12;
  }
  return 12;
};

export const TextareaGroup = ({ children, size, ...props }: TextareaGroupProps) => {
  const styles = useMultiStyleConfig('TextareaGroup', { size });
  const validChildren = getValidChildren(children);
  const groupStyles: BoxProps = {};
  validChildren.forEach((child: any) => {
    if (!styles) return;
    switch (child.type.id) {
      case 'TextareaLeftElement':
        groupStyles.paddingStart = hPadding(size);
        break;
      case 'TextareaRightElement':
        groupStyles.paddingEnd = hPadding(size);
        break;
      case 'TextareaBottomElement':
        groupStyles.paddingBottom = vPadding(size);
        break;
      default:
    }
  });
  const clones = validChildren.map((child: any) => {
    /**
     * Make it possible to override the size and variant from `Textarea`
     */
    const theming = compact({
      size: child.props?.size || size,
      variant: child.props?.variant || props.variant,
    });

    return child.type.id !== 'Textarea'
      ? cloneElement(child, theming)
      : cloneElement(child, Object.assign(theming, groupStyles, child.props));
  });

  return (
    <Box __css={styles.container} {...props}>
      <TextareaGroupProvider value={styles}>{clones}</TextareaGroupProvider>
    </Box>
  );
};

export const TextareaLeftElement = (props: CenterProps) => {
  const styles = useTextareaGroupStyleConfig();
  return <Center id="TextareaLeftElement" __css={styles.leftElement} {...props} />;
};
TextareaLeftElement.id = 'TextareaLeftElement';
TextareaLeftElement.displayName = 'TextareaLeftElement';

export const TextareaRightElement = (props: CenterProps) => {
  const styles = useTextareaGroupStyleConfig();
  return <Center id="TextareaRightElement" __css={styles.rightElement} {...props} />;
};
TextareaRightElement.id = 'TextareaRightElement';
TextareaRightElement.displayName = 'TextareaRightElement';

export const TextareaBottomElement = (props: BoxProps) => {
  const styles = useTextareaGroupStyleConfig();
  return <Box id="TextareaBottomElement" w="full" __css={styles.bottomElement} {...props} />;
};
TextareaBottomElement.id = 'TextareaBottomElement';
TextareaBottomElement.displayName = 'TextareaBottomElement';
