import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  GENDER_OPTIONS,
  LANGUAGE_FLUENCY_OPTIONS,
  EDUCATION_LEVEL_OPTIONS,
  MARITAL_STATUS_OPTIONS,
  OCCUPATIONAL_STATUS_OPTIONS,
  BUSINESS_OWNERSHIP_OPTIONS,
} from 'containers/Studies/data/schemas/recruitSchema';

import { Collapsible } from './Collapsible';
import { CheckboxGroupField } from './Fields/CheckboxGroupField';
import { DualSliderField } from './Fields/DualSliderField';
import { MultiSelectField } from './Fields/MultiSelectField';
import { RadioGroupField } from './Fields/RadioGroupField';
import { SwitchField } from './Fields/SwitchField';
import { IndustryAndOccupation } from './IndustryAndOccupation';

export const SectionAudience = ({ isDisabled }: { isDisabled?: boolean }) => {
  const { t } = useTranslation();
  const { watch, reset } = useFormContext();
  const showAdvancedFilters = watch('advanced_filters');

  // @todo fetch from API
  const countryOptions = [
    { label: 'Australia', value: 'au' },
    { label: 'United States', value: 'us' },
    { label: 'England', value: 'gb' },
  ];

  const genderOptions = GENDER_OPTIONS.map(option => ({
    label: t(`sections.studies.recruit.options.${option}`),
    value: option,
  }));

  const languageFluencyOptions = LANGUAGE_FLUENCY_OPTIONS.map(option => ({
    label: t(`sections.studies.recruit.options.${option}`),
    value: option,
  }));

  const educationLevelOptions = EDUCATION_LEVEL_OPTIONS.map(option => ({
    label: t(`sections.studies.recruit.options.${option}`),
    value: option,
  }));

  const maritalStatusOptions = MARITAL_STATUS_OPTIONS.map(option => ({
    label: t(`sections.studies.recruit.options.${option}`),
    value: option,
  }));

  const occupationStatusOptions = OCCUPATIONAL_STATUS_OPTIONS.map(option => ({
    label: t(`sections.studies.recruit.options.${option}`),
    value: option,
  }));

  const businessOwnershipOptions = BUSINESS_OWNERSHIP_OPTIONS.map(option => ({
    label: t(`sections.studies.recruit.options.${option}`),
    value: option,
  }));

  useEffect(() => {
    if (!showAdvancedFilters) {
      // Reset advanced fields when collapsed
      reset(prev => ({
        ...prev,
        industry_and_occupation: [],
        language_fluency: [],
        education_level: [],
        marital_status: [],
        occupational_status: [],
        business_ownership: [],
      }));
    }
  }, [showAdvancedFilters]);

  return (
    <>
      <MultiSelectField
        name="locations"
        label={t('sections.studies.recruit.fields.locationsTitle')}
        placeholder={`${t('sections.studies.recruit.fields.locationsPlaceholder')}...`}
        options={countryOptions}
        isDisabled={isDisabled}
      />

      <DualSliderField
        name="age_range"
        label={t('sections.studies.recruit.fields.ageRangeTitle')}
        min={18}
        max={99}
        isDisabled={isDisabled}
      />

      <RadioGroupField
        name="gender"
        label={t('sections.studies.recruit.fields.genderTitle')}
        options={genderOptions}
        infoPopover={{
          title: t('sections.studies.recruit.fields.genderCalloutTitle'),
          description: t('sections.studies.recruit.fields.genderCalloutDescription'),
        }}
        isDisabled={isDisabled}
      />

      <hr className="my-1 border-t-0.5 border-secondary p-0" />

      <SwitchField
        name="advanced_filters"
        label={t('sections.studies.recruit.fields.advancedFiltersTitle')}
        size="lg"
        description={t('sections.studies.recruit.fields.advancedFiltersDescription')}
        isDisabled={isDisabled}
      />

      {showAdvancedFilters ? (
        <div className="flex flex-col gap-3">
          <Collapsible
            isDefaultOpen={false}
            title={t('sections.studies.recruit.fields.industryAndOccupationTitle')}
            name="industry_and_occupation"
          >
            <IndustryAndOccupation isDisabled={isDisabled} />
          </Collapsible>

          <Collapsible
            isDefaultOpen={false}
            title={t('sections.studies.recruit.fields.languageFluencyTitle')}
            name="language_fluency"
          >
            <CheckboxGroupField name="language_fluency" options={languageFluencyOptions} isDisabled={isDisabled} />
          </Collapsible>

          <Collapsible
            isDefaultOpen={false}
            title={t('sections.studies.recruit.fields.educationLevelTitle')}
            name="education_level"
          >
            <CheckboxGroupField name="education_level" options={educationLevelOptions} isDisabled={isDisabled} />
          </Collapsible>

          <Collapsible
            isDefaultOpen={false}
            title={t('sections.studies.recruit.fields.maritalStatusTitle')}
            name="marital_status"
          >
            <CheckboxGroupField name="marital_status" options={maritalStatusOptions} isDisabled={isDisabled} />
          </Collapsible>

          <Collapsible
            isDefaultOpen={false}
            title={t('sections.studies.recruit.fields.occupationalStatusTitle')}
            name="occupational_status"
          >
            <CheckboxGroupField name="occupational_status" options={occupationStatusOptions} isDisabled={isDisabled} />
          </Collapsible>

          <Collapsible
            isDefaultOpen={false}
            title={t('sections.studies.recruit.fields.businessOwnershipTitle')}
            name="business_ownership"
          >
            <CheckboxGroupField name="business_ownership" options={businessOwnershipOptions} isDisabled={isDisabled} />
          </Collapsible>
        </div>
      ) : null}
    </>
  );
};
