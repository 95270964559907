/* eslint-disable max-lines */
import { Button } from '@askable/ui/components/ui/button';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import { useFeatureFlags } from 'feature-flags';
import _ from 'lodash';
import { MapPin } from 'lucide-react';
import { useEffect, useState } from 'react';

import { GoogleMaps, LoadingOverlay } from 'components/common';
import LocationAutocomplete from 'components/common/LocationAutocomplete/view';
import PriceCardContainer from 'components/createBooking/components/priceCardContainer';
import getLocationByAddress from 'data/queries/location/getLocationByAddress';
import { bookingUtils } from 'lib/booking';
import countryCodeData from 'lib/data/phoneCountryCodes.json';
import { apolloFetch } from 'lib/http';
import { update, utils } from 'lib/utils';

import type { GoogleViewportType, Location } from 'generated/graphql';

import './styles/participantLocationStyles.scss';

type ParticipantLocation = {
  formatted_address?: string;
  latitude?: number;
  longitude?: number;
  locationViewport?: GoogleViewportType[];
};
function ParticipantLocations(props: any) {
  const booking = _.get(props, 'booking');
  const [bookingState, setBookingState] = useState(booking);
  const [bookingData, setBookingData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<ParticipantLocation | null>(null);
  const [countryLocation, setCountryLocation] = useState<Location | null>(null);
  const [googleMap, setGoogleMap] = useState<google.maps.Map | null>(null);
  const [mapMarkers, setMapMarkers] = useState<google.maps.Marker[] | null>([]);

  const { MULTIREGION_COUNTRIES } = useFeatureFlags(['MULTIREGION_COUNTRIES']);

  useEffect(() => {
    if (bookingUtils.isInPerson(booking)) {
      props.history.push({ pathname: `/booking-setup/${booking._id}/audience/panel` });
    }
    props.updateLastStep({
      step: 'Audience',
      subStep: `/booking-setup/${props.booking._id}/audience/participant-locations`,
      stepId: 'audience_participant_locations',
    });
    createGoogleMap();
  }, []);

  useEffect(() => {
    setBookingState(_.get(props, 'booking'));
  }, [props.booking]);

  useEffect(() => {
    props.renderRightContent(rightContent());
    props.renderRightAppPreview(null);
  }, [bookingState]);

  useEffect(() => {
    if (!selectedLocation && !bookingUtils.isInPerson(booking)) {
      updateSelectedLocation(booking);
    }
  }, [bookingState.config?.location, bookingState.config?.criteria?.location]);

  const createGoogleMap = async () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'google' does not exist on type 'Window &... Remove this comment to see the full error message
    const map = await new window.google.maps.Map(document.getElementById('mapPreviewContainer'), {
      ...props.options,
      controlSize: 24,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      gestureHandling: 'cooperative',
    });
    setGoogleMap(map);
    loadSavedMarkers(map);
  };

  const loadSavedMarkers = async (map: any) => {
    const states = _.map(_.get(props.booking, 'config.criteria.locations.states') || [], (item: any) => ({
      ...item,
      type: 'states',
    }));
    const bounds = _.map(_.get(props.booking, 'config.criteria.locations.bounds') || [], (item: any) => ({
      ...item,
      type: 'bounds',
    }));
    const remoteQuantStates = utils.removeTypenames(states || []);
    const remoteQuantBounds = utils.removeTypenames(bounds || []);
    const savedLocations = remoteQuantStates.concat(remoteQuantBounds);

    if (savedLocations.length > 0) {
      const markers = await Promise.all(
        _.map(savedLocations, (item: any) => {
          return handleMarker({
            savedMarkers: [],
            map,
            action: 'firstLoad',
            item: {
              title: item.formatted_address,
              name: _.get(item, 'name') || item.formatted_address,
              latitude: item.latitude,
              longitude: item.longitude,
              type: item.type,
            },
          });
        }),
      );
      setMapMarkers(markers);
    } else if (_.get(props.booking, 'config.location.country')) {
      const countryLocationData = await getCountryData(_.get(props.booking, 'config.location.country'));
      const marker = await handleMarker({
        savedMarkers: [],
        map,
        action: 'firstLoad',
        item: {
          title: _.get(props.booking, 'config.location.country'),
          name: _.get(props.booking, 'config.location.country'),
          latitude: countryLocationData?.latitude,
          longitude: countryLocationData?.longitude,
          type: 'country',
        },
      });
      setMapMarkers([marker]);
    }
  };

  const handleMarker = async ({ savedMarkers, map, action, item, colloquial_area = false }: any) => {
    const markerIcon = { url: '/booking/icons/mapMarkerIcon.svg' };
    let markersToSave = _.clone(savedMarkers);

    if (action === 'insert' || action === 'firstLoad') {
      const marker = new window.google.maps.Marker({
        title: item.title,
        position: { lat: item.latitude, lng: item.longitude },
        animation: window.google.maps.Animation.DROP,
        icon: markerIcon,
        map,
      });

      let markerCircle = null;
      let mapPolygons = null;
      if (colloquial_area) {
        markerCircle = new window.google.maps.Circle({
          strokeColor: '#56A1EB',
          strokeWeight: 1,
          fillColor: '#066CD2',
          map: googleMap,
          center: { lat: item.latitude, lng: item.longitude },
          radius: 20000,
        });
      } else if (item.type !== 'bounds') {
        mapPolygons = await loadMapPolygon(map, item.name);
      }

      const newMarker = {
        title: item.title,
        marker,
        markerCircle,
        mapPolygons,
      };

      if (action === 'firstLoad') return newMarker;

      markersToSave = update(markersToSave, {
        $push: [newMarker],
      });
    } else if (action === 'delete') {
      const markerData = _.filter(markersToSave, (mark: any) => mark.title === item.title);
      await Promise.all(
        _.map(markerData, (marker: any, index: any) => {
          if (marker) {
            if (_.get(marker, 'marker')) marker.marker.setMap(null);
            if (_.get(marker, 'markerCircle')) marker.markerCircle.setMap(null);
            if (_.get(marker, 'mapPolygons')) {
              _.map(_.get(marker, 'mapPolygons'), (polygon: any) => polygon.setMap(null));
            }
          }
          if (index) {
            markersToSave = update(markersToSave, {
              $splice: [[index, 1]],
            });
          }
        }),
      );
    }

    return markersToSave;
  };

  const removeAllMarkers = async (savedMarkers: any) => {
    await Promise.all(
      _.map(savedMarkers, (markerData: any) => {
        if (_.get(markerData, 'marker')) {
          markerData.marker.setMap(null);
        }
        if (_.get(markerData, 'markerCircle')) {
          markerData.markerCircle.setMap(null);
        }
        if (_.get(markerData, 'mapPolygons')) {
          _.map(_.get(markerData, 'mapPolygons'), (polygon: any) => polygon.setMap(null));
        }
      }),
    );
    return [];
  };

  const loadMapPolygon = async (map: any, locationName: any) => {
    const locationPolygons = await bookingUtils.getLocationPolygons(locationName);
    if (locationPolygons) {
      const polygonData = _.map(locationPolygons, (item: any) => {
        const polygon = new window.google.maps.Polygon({
          paths: item,
        });
        polygon.setOptions({ fillColor: '#56A1EB', strokeColor: '#066CD2', strokeWeight: 1 });
        polygon.setMap(map);
        return polygon;
      });

      return polygonData;
    }
    return null;
  };

  const saveBookingData = (objectToUpdate: any, bookingStateObj: any) => {
    setBookingData(objectToUpdate);
    props.history.replace({ booking: objectToUpdate, bookingState: bookingStateObj });
  };

  const rightContent = () => {
    return (
      <>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          booking={bookingState}
          bookingSteps={props.bookingSteps}
          team={_.get(props, 'team')}
          context={props.context}
          condensedCard
        />
      </>
    );
  };

  const onClickNext = () => {
    const redirectTo = `/booking-setup/${booking._id}/audience/demographic-filters`;
    props.history.push({ pathname: redirectTo, booking: bookingData, bookingState });
  };

  const onLocationClick = (value: any) => {
    // Updates location on the map view
    setSelectedLocation({
      formatted_address: value.formatted_address,
      latitude: value.latitude,
      longitude: value.longitude,
      locationViewport: [_.get(value, 'google_location.viewport')],
    });
  };

  const getCountryData = async (country: any) => {
    const countryData = _.find(countryCodeData, { region: country });
    const result = await apolloFetch.fetch(getLocationByAddress, {
      address: _.get(countryData, 'name'),
      country: _.get(countryData, 'region'),
      types: '(regions)',
    });
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    const countryLocationData = result.data.getLocationByAddress;
    return countryLocationData;
  };

  const getCountryLocation = async (country: any, saveCountryMarker: any) => {
    const countryLocationData = await getCountryData(country);
    setCountryLocation(countryLocationData);
    setSelectedLocation({
      formatted_address: countryLocationData?.formatted_address,
      latitude: countryLocationData?.latitude,
      longitude: countryLocationData?.longitude,
      locationViewport: [
        _.get(countryLocationData, 'google_location.viewport') ||
          _.get(bookingState, 'config.location.google_location.viewport'),
      ],
    });

    const bookingStateObj = {
      ...bookingState,
      config: {
        ...bookingState.config,
        location: {
          ...bookingState.config.location,
          google_location: {
            viewport:
              _.get(countryLocationData, 'google_location.viewport') ||
              _.get(bookingState, 'config.location.google_location.viewport'),
          },
        },
        criteria: {
          ...(bookingState.config?.criteria ?? {}),
          locations: {
            states: [],
            bounds: [],
            countries: MULTIREGION_COUNTRIES ? [] : undefined,
          },
        },
      },
    };
    setBookingState(bookingStateObj);

    const bookingObj = {
      config: {
        location: {
          google_location: {
            viewport: utils.removeTypenames(
              _.get(countryLocationData, 'google_location.viewport') ||
                _.get(bookingState, 'config.location.google_location.viewport'),
            ),
          },
        },
        criteria: {
          locations: {
            states: [],
            bounds: [],
            countries: MULTIREGION_COUNTRIES ? [] : undefined,
          },
        },
      },
    };
    saveBookingData(bookingObj, bookingStateObj);

    if (saveCountryMarker) {
      const markersToSave = await handleMarker({
        savedMarkers: [],
        map: googleMap,
        action: 'insert',
        item: {
          title: country,
          name: country,
          latitude: _.get(countryLocationData, 'latitude'),
          longitude: _.get(countryLocationData, 'longitude'),
          type: 'country',
        },
      });
      setMapMarkers(markersToSave);
    }
  };

  const updateSelectedLocation = (bookingObj: any, saveCountryMarker = false) => {
    // Update selected locations on screen
    const remoteQuantStates = utils.removeTypenames(_.get(bookingObj, 'config.criteria.locations.states') || []);
    const remoteQuantBounds = utils.removeTypenames(_.get(bookingObj, 'config.criteria.locations.bounds') || []);
    const locationsViewport = getLocationsViewport(remoteQuantStates, remoteQuantBounds);
    if (remoteQuantStates.length > 0 || remoteQuantBounds.length > 0) {
      if (remoteQuantStates.length > 0) {
        setSelectedLocation({
          formatted_address: remoteQuantStates[0].formatted_address,
          latitude: remoteQuantStates[0].latitude,
          longitude: remoteQuantStates[0].longitude,
          locationViewport: locationsViewport,
        });
      } else {
        setSelectedLocation({
          formatted_address: remoteQuantBounds[0].formatted_address,
          latitude: remoteQuantBounds[0].latitude,
          longitude: remoteQuantBounds[0].longitude,
          locationViewport: locationsViewport,
        });
      }
    } else if (_.get(bookingObj, 'config.location.country')) {
      getCountryLocation(_.get(bookingObj, 'config.location.country'), saveCountryMarker);
    }
  };

  const getLocationsViewport = (states: any, bounds: any) => {
    const viewportStates = _.map(states, (state: any) => {
      return _.get(state, 'google_location.viewport');
    });
    const viewportBounds = _.map(bounds, (bound: any) => {
      return _.get(bound, 'google_location.viewport');
    });
    return viewportStates.concat(viewportBounds);
  };

  const onLocationRemove = async (index: any, value: any) => {
    let remoteQuantStates = utils.removeTypenames(_.get(bookingState, 'config.criteria.locations.states') || []);
    let remoteQuantBounds = utils.removeTypenames(_.get(bookingState, 'config.criteria.locations.bounds') || []);

    // Deleting a state
    if (!_.has(value, 'google_location.geometry')) {
      remoteQuantStates = update(remoteQuantStates, {
        $splice: [[index, 1]],
      });
    }

    // Deleting bounds
    if (_.has(value, 'google_location.geometry')) {
      remoteQuantBounds = update(remoteQuantBounds, {
        $splice: [[index, 1]],
      });
    }

    const bookingLocation = utils.removeTypenames(bookingState.config.location);
    bookingLocation.google_location = {};

    const bookingStateObj = {
      ...bookingState,
      config: {
        ...bookingState.config,
        location: bookingLocation,
        criteria: {
          ...bookingState.config.criteria,
          locations: {
            states: remoteQuantStates,
            bounds: remoteQuantBounds,
          },
        },
      },
    };
    setBookingState(bookingStateObj);

    const bookingObj = {
      config: {
        location: bookingLocation,
        criteria: {
          locations: {
            states: remoteQuantStates,
            bounds: remoteQuantBounds,
          },
        },
      },
    };
    saveBookingData(bookingObj, bookingStateObj);

    const markersToSave = await handleMarker({
      savedMarkers: mapMarkers,
      map: googleMap,
      action: 'delete',
      item: { title: value.formatted_address },
    });

    setMapMarkers(markersToSave);

    const saveCountryBounds = _.size(remoteQuantStates) === 0 && _.size(remoteQuantBounds) === 0;
    updateSelectedLocation(bookingObj, saveCountryBounds);
  };

  const renderRemoteLocation = (data: any, index: any) => {
    const totalLocations =
      _.size(_.get(bookingState, 'config.criteria.locations.states')) +
      _.size(_.get(bookingState, 'config.criteria.locations.bounds'));
    return (
      <div
        className={`locationsAreaItems ${totalLocations <= 1 ? 'oneLocation' : ''}`}
        onClick={event => {
          if (totalLocations > 1) {
            event.preventDefault();
            event.stopPropagation();
            onLocationClick(data);
          }
        }}
        key={index}
      >
        <MapPin className="mx-2 h-4 w-4" />
        <p className="locationName">{_.get(data, 'formatted_address')}</p>
        {totalLocations >= 1 && (
          <a
            className="locationRemove"
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              onLocationRemove(index, data);
            }}
          >
            Delete
          </a>
        )}
      </div>
    );
  };

  const renderParticipantsLocation = () => {
    const hasStates = _.size(_.get(bookingState, 'config.criteria.locations.states')) > 0;
    const hasBounds = _.size(_.get(bookingState, 'config.criteria.locations.bounds')) > 0;

    return (
      <>
        <div className="locationsContainer">
          <div className="locationsArea">
            {_.get(bookingState, 'config.location.country') &&
              !hasStates &&
              !hasBounds &&
              renderRemoteLocation(countryLocation, 0)}
            {hasStates &&
              _.map(_.get(bookingState, 'config.criteria.locations.states'), (data: any, index: any) => {
                return renderRemoteLocation(data, index);
              })}
            {hasBounds &&
              _.map(_.get(bookingState, 'config.criteria.locations.bounds'), (data: any, index: any) => {
                return renderRemoteLocation(data, index);
              })}
          </div>
        </div>

        <LocationAutocomplete
          placeholder="Type to add more participant locations"
          onNewPlace={onLocationAdded}
          type="(regions)"
          countryCodes={[_.get(bookingState, 'config.location.country')]}
          styleWidth={548}
          clearValue
          setIsLoading={setIsLoading}
          canChangeCountry
        />
      </>
    );
  };

  const onLocationAdded = async (value: Location) => {
    let markersToSave = _.clone(mapMarkers);
    let remoteQuantStates = utils.removeTypenames(_.get(bookingState, 'config.criteria.locations.states') || []);
    let remoteQuantBounds = utils.removeTypenames(_.get(bookingState, 'config.criteria.locations.bounds') || []);
    const locationInfo = {
      country: value.country,
      name: '',
      street1: '',
      city: '',
      postal_code: '',
      state: '',
      latitude: null,
      longitude: null,
      region: '',
      google_location:
        value.country !== _.get(bookingState, 'config.location.country')
          ? {}
          : _.get(bookingState, 'config.location.google_location'),
    };

    // Tests if location has already been added
    const locationAdded = _.filter([...remoteQuantStates, ...remoteQuantBounds], (data: any) => {
      return data.formatted_address === value.formatted_address;
    });
    if (locationAdded.length > 0) return null;

    // If select an entire country or change country location
    if (
      _.get(value, 'google_location_types')?.includes('country') ||
      _.get(value, 'country') !== _.get(bookingState, 'config.location.country')
    ) {
      // Clears list of selected locations
      remoteQuantStates = [];
      remoteQuantBounds = [];
      locationInfo.google_location = { viewport: _.get(value, 'google_location.viewport') || {} };
      setCountryLocation(value);

      // Remove all markers
      markersToSave = await removeAllMarkers(markersToSave);

      // Add country marker and polygon
      if (_.get(value, 'google_location_types')?.includes('country')) {
        markersToSave = await handleMarker({
          savedMarkers: markersToSave,
          map: googleMap,
          action: 'insert',
          item: {
            title: value.country,
            name: value.country,
            latitude: _.get(value, 'latitude'),
            longitude: _.get(value, 'longitude'),
            type: 'country',
          },
        });
      }
    }

    // If you didnt select an entire country
    if (!_.get(value, 'google_location_types')?.includes('country')) {
      // If was selected a state
      if (_.includes(_.get(value, 'google_location_types'), 'administrative_area_level_1')) {
        const stateSelected = value.google_location_types?.includes('administrative_area_level_1');

        // Remove state bounds markers and circles
        const boundsOnState = _.filter(remoteQuantBounds, data => data.state === _.get(value, 'state'));
        if (_.size(boundsOnState) > 0) {
          markersToSave = await Promise.all(
            _.map(boundsOnState, item => {
              return handleMarker({
                savedMarkers: markersToSave,
                map: googleMap,
                action: 'delete',
                item: { title: _.get(item, 'formatted_address') },
              });
            }),
          );
        }

        // Remove country marker and polygon
        markersToSave = await handleMarker({
          savedMarkers: markersToSave,
          map: googleMap,
          action: 'delete',
          item: { title: value.country },
        });

        // Add state marker and polygon
        markersToSave = await handleMarker({
          savedMarkers: markersToSave,
          map: googleMap,
          action: 'insert',
          item: {
            title: value.formatted_address,
            name: _.get(stateSelected, '[0].short_name'),
            latitude: _.get(value, 'latitude'),
            longitude: _.get(value, 'longitude'),
            type: 'states',
          },
        });

        const locationToAdd = {
          name: _.get(stateSelected, '[0].short_name'),
          state: _.get(value, 'state'),
          formatted_address: value.formatted_address,
          latitude: _.get(value, 'latitude'),
          longitude: _.get(value, 'longitude'),
          country: value.country,
          google_location: {
            viewport: _.get(value, 'google_location.viewport') || {},
          },
        };
        remoteQuantStates = update(remoteQuantStates, {
          $push: [locationToAdd],
        });
        remoteQuantBounds = _.filter(remoteQuantBounds, data => {
          return data.state !== _.get(value, 'state');
        });
      } else {
        // Remove state marker and polygon
        const stateFromBounds = _.filter(remoteQuantStates, data => data.state === _.get(value, 'state'));
        if (_.size(stateFromBounds) > 0) {
          markersToSave = await Promise.all(
            _.map(stateFromBounds, item => {
              return handleMarker({
                savedMarkers: markersToSave,
                map: googleMap,
                action: 'delete',
                item: { title: _.get(item, 'formatted_address') },
              });
            }),
          );
        }

        // Remove country marker and polygon
        markersToSave = await handleMarker({
          savedMarkers: markersToSave,
          map: googleMap,
          action: 'delete',
          item: { title: value.country },
        });

        // Add bound marker and circle
        markersToSave = await handleMarker({
          savedMarkers: markersToSave,
          map: googleMap,
          action: 'insert',
          item: {
            title: value.formatted_address,
            name: value.formatted_address,
            latitude: _.get(value, 'latitude'),
            longitude: _.get(value, 'longitude'),
            type: 'bounds',
          },
          colloquial_area: _.includes(_.get(value, 'google_location_types'), 'colloquial_area'),
        });

        const locationToAdd = {
          formatted_address: value.formatted_address,
          state: _.get(value, 'state'),
          latitude: _.get(value, 'latitude'),
          longitude: _.get(value, 'longitude'),
          country: value.country,
          google_location: {
            geometry: {
              latitude_ne: _.get(value, 'google_location.geometry.latitude_ne'),
              longitude_ne: _.get(value, 'google_location.geometry.longitude_ne'),
              latitude_sw: _.get(value, 'google_location.geometry.latitude_sw'),
              longitude_sw: _.get(value, 'google_location.geometry.longitude_sw'),
            },
            viewport: _.get(value, 'google_location.viewport') || {},
          },
        };
        remoteQuantBounds = update(remoteQuantBounds, {
          $push: [locationToAdd],
        });
        remoteQuantStates = _.filter(remoteQuantStates, data => {
          return data.state !== _.get(value, 'state');
        });
      }
    }

    const bookingStateObj = {
      ...bookingState,
      config: {
        ...bookingState.config,
        location: locationInfo,
        criteria: {
          ...bookingState.config.criteria,
          locations: {
            states: remoteQuantStates,
            bounds: remoteQuantBounds,
          },
        },
      },
    };
    setBookingState(bookingStateObj);

    const bookingObj = {
      config: {
        location: locationInfo,
        criteria: {
          locations: {
            states: remoteQuantStates,
            bounds: remoteQuantBounds,
          },
        },
      },
    };
    saveBookingData(bookingObj, bookingStateObj);

    // Update map markers
    setMapMarkers(markersToSave);

    setSelectedLocation({
      formatted_address: value.formatted_address ?? undefined,
      latitude: value?.latitude ?? undefined,
      longitude: value?.longitude ?? undefined,
      locationViewport: [_.get(value, 'google_location.viewport')],
    });
  };

  return (
    <div className="createBookingContent flex flex-col gap-4">
      <h1 id="__pageTitle" className="title">
        Participant location
      </h1>

      {isLoading ? <LoadingOverlay style={{ opacity: 0.8 }} /> : null}

      <div className="mapPreview rounded-md" id="mapPreviewContainer">
        {googleMap && selectedLocation ? (
          <GoogleMaps
            id="locationMap"
            googleMap={googleMap}
            style={{ width: 548, height: 233, borderRadius: '8px' }}
            lat={_.get(selectedLocation, 'latitude')}
            lng={_.get(selectedLocation, 'longitude')}
            locations={{
              states: _.get(bookingState, 'config.criteria.locations.states'),
              bounds: _.get(bookingState, 'config.criteria.locations.bounds'),
            }}
            countryLocation={_.get(bookingState, 'config.location')}
            locationViewport={_.get(selectedLocation, 'locationViewport')}
            bookingID={_.get(booking, '_id')}
          />
        ) : null}
      </div>

      {renderParticipantsLocation()}

      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default deprecatedWithRouter(ParticipantLocations);
