import { Button } from '@askable/ui/components/ui/button';
import { cn } from '@askable/ui/lib/utils';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { ChevronRight, EyeOff } from 'lucide-react';
import { useState, useRef, useEffect, isValidElement, memo } from 'react';

import { useContainer } from './hooks';
import { TaskCardDraggableHeader } from './task-card-draggable-header';

import { CONFIG } from './';

import type { TaskCardDraggableProps } from './';
import type { CSSProperties } from 'react';

interface Props extends Omit<TaskCardDraggableProps, 'isPositionSaved'> {
  position: {
    x: number;
    y: number;
  };
}

export const TaskCardDraggableCard = memo(function TaskCardDraggableCardMemo({
  buttonLabel = 'Go',
  children,
  collapseLabel,
  id,
  isCollapsed = false,
  isDisabled = false,
  isDraggable = true,
  isLoading = false,
  position,
  subtitle = 'Task',
  onProgress,
  onCollapse,
}: Props) {
  const contentRef = useRef<HTMLDivElement>(null);
  const { containerWidth } = useContainer(`card-${id}`, true);

  const [contentHeight, setContentHeight] = useState<number | null>(null);
  const [isFinishedDragging, setIsFinishedDragging] = useState(false);

  // Disable dragging on mobile
  const _isDraggable = isDraggable && containerWidth >= CONFIG.DRAGGABLE_MIN_WIDTH;

  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id,
    disabled: !_isDraggable || isCollapsed,
  });

  const cardStyle: CSSProperties = {
    position: 'absolute',
    height: isCollapsed ? `${CONFIG.COLLAPSED_SIZE.h}px` : contentHeight ? `${contentHeight}px` : 'auto',
    width: isCollapsed ? `${CONFIG.COLLAPSED_SIZE.w}px` : undefined,
    left: isCollapsed ? 0 : `${position.x}px`,
    bottom: isCollapsed ? `${CONFIG.COLLAPSED_Y}px` : `${position.y}px`,
    transform: transform ? CSS.Transform.toString(transform) : undefined,
  };

  // Card's expanded height required to animate it from collapsed state
  useEffect(() => {
    if (contentRef.current && !isCollapsed) {
      setContentHeight(contentRef.current.offsetHeight);
    }
  }, [isCollapsed]);

  // Recalculate height when card content changes in editor
  useEffect(() => {
    if (isValidElement(children)) {
      setContentHeight(null);
      // Delay so content renders before getting new height
      setTimeout(() => {
        if (contentRef.current) {
          setContentHeight(contentRef.current.offsetHeight);
        }
      }, 10);
    }
  }, [isValidElement(children) ? children.props.instructions : undefined]);

  // When not dragging add an duration for the collapsible animation
  useEffect(() => {
    if (isDragging) {
      setIsFinishedDragging(false);
    } else {
      setTimeout(() => setIsFinishedDragging(true), 501);
    }
  }, [isDragging]);

  return (
    <div
      id={id}
      ref={setNodeRef}
      style={cardStyle}
      className={cn('z-20 grid max-w-[380px] origin-center overflow-hidden rounded-xl bg-background shadow', {
        'rounded-l-none focus-within:shadow-lg hover:shadow-lg': isCollapsed,
        'w-[calc(100%_-_16px)]': !isCollapsed,
        'shadow-lg': isDragging,
        'duration-500 ease-anticipation motion-reduce:transition-none': isFinishedDragging,
      })}
    >
      <div
        ref={contentRef}
        className={cn(
          `col-start-1 col-end-2 row-start-1 row-end-2 flex max-w-[380px] flex-col justify-between transition-all delay-200
          motion-reduce:transition-none`,
          {
            'w-[350px] overflow-hidden opacity-0 ': isCollapsed,
            'pt-4': !_isDraggable,
          },
        )}
      >
        {_isDraggable ? (
          <TaskCardDraggableHeader subtitle={subtitle} dragHandleProps={{ ...attributes, ...listeners }} />
        ) : null}

        <div className="flex max-h-[50vh] flex-1 flex-col gap-4 overflow-auto px-4 pb-4">{children}</div>

        <footer className="flex items-center justify-between gap-2 border-t-0.5 border-border px-4 pb-4 pt-4">
          {collapseLabel ? (
            <Button variant="ghost" className="gap-2 text-muted-foreground" onClick={() => onCollapse(true)}>
              <EyeOff className="h-4 w-4" /> {collapseLabel}
            </Button>
          ) : (
            <div />
          )}

          <Button variant="outline" disabled={isDisabled} isLoading={isLoading} onClick={onProgress}>
            {buttonLabel}
          </Button>
        </footer>
      </div>

      <button
        className={cn(
          `pointer-events-none z-10 col-start-1 col-end-2 row-start-1 row-end-2 flex -translate-x-2 flex-col items-center
          justify-center opacity-0 transition-all`,
          {
            [`opacity-200 pointer-events-auto translate-x-0 cursor-pointer delay-300 hover:scale-110 focus:scale-110
            motion-reduce:transition-none`]: isCollapsed,
          },
        )}
        style={{ height: `${CONFIG.COLLAPSED_SIZE.h}px`, width: `${CONFIG.COLLAPSED_SIZE.w}px` }}
        onClick={() => onCollapse(false)}
        title="Expand"
      >
        <ChevronRight className="h-6 w-6" />
      </button>
    </div>
  );
});
