import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const PaintBrushIcon: CreateIconType = createIcon({
  displayName: 'PaintBrushIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: [
    <path
      d="M2.00034 9.55469C1.98495 10.2153 1.85543 11.8736 1.47105 13.4033C1.26401 14.2273 1.85869 15.1445 2.70826 15.1445H8.28766C8.28766 15.1445 10.3466 15.1445 11.7162 12.8588V15.1445H14.0019C14.6331 15.1445 15.1405 14.6331 15.0918 14.0038C14.9911 12.6997 14.7293 10.7932 14.1609 9.70119"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M6.43613 6.28795C6.19504 5.22286 6.00112 4.06764 6.00112 3.14511C6.00112 1.31652 7.52494 0.859375 8.28685 0.859375C9.04876 0.859375 10.5726 1.31652 10.5726 3.14511C10.5726 4.06764 10.3787 5.22286 10.1376 6.28795H12.8583C14.6868 6.28795 15.144 7.81175 15.144 8.57366C15.144 9.20484 14.6323 9.71652 14.0011 9.71652H2.57254C1.94137 9.71652 1.42969 9.20484 1.42969 8.57366C1.42969 7.81175 1.88683 6.28795 3.7154 6.28795H6.43613Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
