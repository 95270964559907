import { AnchorButton, Button } from '@askable/ui/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from '@askable/ui/components/ui/dropdown-menu';
import { Filter, ChevronLeft } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export const ResultsHeader = ({ hasDropdown = false }: { hasDropdown?: boolean }) => {
  const { t } = useTranslation();

  return (
    <header className="results-header flex flex-wrap items-center justify-between gap-2 px-3 py-2 lg:px-5">
      <div className="flex items-center gap-1">
        <AnchorButton variant="ghost" href="#nav" className="lg:!hidden">
          <ChevronLeft className="h-4 w-4" />
        </AnchorButton>
        <h1 className="text-xl font-semibold">{t('sections.studies.results.responses', { count: 2 })}</h1>
      </div>

      {hasDropdown ? (
        <div>
          <DropdownMenu modal={false}>
            <DropdownMenuTrigger asChild>
              <Button variant="outline">
                <Filter className="h-4 w-4" /> {t('sections.studies.results.filter')}
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem>Item 1</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      ) : null}
    </header>
  );
};
