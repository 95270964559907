import { useState, useCallback, useEffect } from 'react';

import { CONFIG } from './';

interface CardPosition {
  x: number;
  y: number;
}

const useContainerWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const container = document.querySelector(CONFIG.CONTAINER);
    if (!container) {
      return;
    }

    let timeoutId: NodeJS.Timeout;
    const observer = new ResizeObserver(entries => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        const newWidth = entries[0].contentRect.width;
        if (newWidth !== width) setWidth(newWidth);
      }, 200);
    });

    observer.observe(container);
    return () => observer.disconnect();
  }, []);

  return width;
};

// Persist card position in localstorage
const useSavedPosition = (cardId: string, isPositionSaved: boolean) => {
  const [position, setPosition] = useState<CardPosition>(() => {
    if (!isPositionSaved) {
      return CONFIG.INITIAL_POSITION;
    }

    try {
      const saved = localStorage.getItem(cardId);
      return saved ? JSON.parse(saved) : CONFIG.INITIAL_POSITION;
    } catch {
      return CONFIG.INITIAL_POSITION;
    }
  });

  const savePosition = useCallback(
    (newPosition: CardPosition) => {
      if (Math.abs(newPosition.x - position.x) > 1 || Math.abs(newPosition.y - position.y) > 1) {
        setPosition(newPosition);
        if (isPositionSaved) {
          localStorage.setItem(cardId, JSON.stringify(newPosition));
        }
      }
    },
    [cardId, isPositionSaved, position],
  );

  return { position, savePosition };
};

// Handle position constraints and edge snapping
export const usePositionConstraints = () => {
  const getCardPosition = useCallback(
    (position: CardPosition, cardRect: DOMRect, containerRect: DOMRect): CardPosition => {
      let { x, y } = position;
      const cardWidth = cardRect.width;
      const cardHeight = cardRect.height;

      // Check horizontal bounds
      const maxX = containerRect.width - cardWidth - CONFIG.EDGE_OFFSET;
      const minX = CONFIG.EDGE_OFFSET;
      x = Math.max(minX, Math.min(maxX, x));

      // Check vertical bounds
      const maxY = containerRect.height - cardHeight - CONFIG.EDGE_OFFSET;
      const minY = CONFIG.EDGE_OFFSET;
      y = Math.max(minY, Math.min(maxY, y));

      // Snap to edges
      if (Math.abs(x - minX) < CONFIG.SNAP_THRESHOLD) {
        x = minX;
      } else if (Math.abs(x - maxX) < CONFIG.SNAP_THRESHOLD) {
        x = maxX;
      }

      if (Math.abs(y - minY) < CONFIG.SNAP_THRESHOLD) {
        y = minY;
      } else if (Math.abs(y - maxY) < CONFIG.SNAP_THRESHOLD) {
        y = maxY;
      }

      return { x, y };
    },
    [],
  );

  return { getCardPosition };
};

// Combine the above hooks
export const useContainer = (cardId: string, isPositionSaved: boolean) => {
  const containerWidth = useContainerWidth();
  const { position, savePosition } = useSavedPosition(cardId, isPositionSaved);
  const { getCardPosition } = usePositionConstraints();

  const getElements = useCallback(() => {
    const container = document.querySelector('.drag-container');
    const card = document.getElementById(cardId);
    if (!container || !card) {
      return null;
    }

    return {
      container,
      card,
      containerRect: container.getBoundingClientRect(),
      cardRect: card.getBoundingClientRect(),
    };
  }, [cardId]);

  // Check and adjust position when container size changes
  useEffect(() => {
    const elements = getElements();
    if (!elements) {
      return;
    }

    const { containerRect, cardRect } = elements;
    const newPosition =
      containerWidth < CONFIG.DRAGGABLE_MIN_WIDTH
        ? { x: CONFIG.EDGE_OFFSET, y: CONFIG.EDGE_OFFSET }
        : getCardPosition(position, cardRect, containerRect);

    // Only update if the position changed and values are different
    if (Math.abs(newPosition.x - position.x) > 1 || Math.abs(newPosition.y - position.y) > 1) {
      savePosition(newPosition);
    }
  }, [containerWidth, getElements, getCardPosition]);

  const saveCardPosition = useCallback(
    (newPosition: CardPosition) => {
      const elements = getElements();
      if (!elements) {
        return;
      }

      const { containerRect, cardRect } = elements;
      const adjustedPosition = getCardPosition(newPosition, cardRect, containerRect);

      if (adjustedPosition.x !== position.x || adjustedPosition.y !== position.y) {
        savePosition(adjustedPosition);
      }
    },
    [position, savePosition, getCardPosition, getElements],
  );

  return {
    containerWidth,
    cardPosition: position,
    saveCardPosition,
  };
};
