import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const CheckIcon: CreateIconType = createIcon({
  displayName: 'CheckIcon',
  defaultProps: {
    ...defaultOptions,
    color: 'green.500',
    viewBox: '0 0 16 16',
  },
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM12.2373 5.58818C12.5622 5.14153 12.4634 4.51611 12.0168 4.19127C11.5701 3.86644 10.9447 3.96519 10.6199 4.41184L6.66428 9.85078L4.62473 8.21914C4.19347 7.87413 3.56418 7.94405 3.21917 8.37531C2.87416 8.80658 2.94408 9.43587 3.37534 9.78088L6.23248 12.0666C6.44581 12.2373 6.71966 12.3132 6.99041 12.2768C7.26116 12.2404 7.50524 12.0948 7.66592 11.8739L12.2373 5.58818Z"
      fill="currentColor"
    />,
  ],
});
