import { graphql } from 'src/graphql';

export const CreateUnmoderatedBooking = graphql(`
  mutation createUnmoderatedBooking($input: CreateUnmoderatedBookingInput!) {
    createUnmoderatedBooking(input: $input) {
      _id
      name
      type
      status
      config {
        unmoderated {
          welcome_block {
            type
            title
            instructions
          }
          thank_you_block {
            type
            title
            instructions
          }
          task_blocks {
            _id
            type
            title
            instructions
            is_recording_enabled

            ... on TaskBlockFigmaPrototype {
              figma_prototype {
                file_id
                goal_screen_id
                start_screen_id
              }
            }

            ... on TaskBlockMultipleChoiceQuestion {
              choices
            }
          }
        }
      }
    }
  }
`);
