import { Button } from '@askable/ui/components/ui/button';
import { Command, CommandGroup, CommandItem, CommandList, CommandEmpty } from '@askable/ui/components/ui/command';
import { Input } from '@askable/ui/components/ui/input';
import { Label } from '@askable/ui/components/ui/label';
import { Popover, PopoverContent, PopoverTrigger } from '@askable/ui/components/ui/popover';
import { useFeatureFlags } from 'feature-flags';
import { useState, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedSearch } from 'shared-utils/hooks';
import { useClient } from 'urql';
import { useOnClickOutside } from 'usehooks-ts';

import { CountrySelect, LoadingOverlay } from 'components/common';
import getLocationFromGooglePlaceId from 'data/queries/location/getLocationFromGooglePlaceId';
import countryCodeData from 'lib/data/phoneCountryCodes.json';
import { utils } from 'lib/utils';

import { LocationAutocompleteQuery } from '../LocationAutocompleteQuery/view';

import { useUserLatLong } from './useUserLatLong';

import type { Location } from 'generated/graphql';

// https://developers.google.com/maps/documentation/places/web-service/supported_types#table3
type AutocompleteType = 'geocode' | 'address' | 'establishment' | '(regions)' | '(cities)';

type Props = {
  initialSearchValue?: string;
  placeholder: string;
  setIsLoading?: (isLoading: boolean) => void;
  onNewPlace: (place: Location) => void;
  canChangeCountry?: boolean;
  type?: AutocompleteType;
  countryCodes?: string[] | null;
  clearValue?: boolean;
  styleWidth?: number;
  readonly?: boolean;
};
export default function LocationAutocomplete(props: Props) {
  const { t } = useTranslation();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [openCountrySelect, setOpenCountrySelect] = useState(false);

  const client = useClient();

  const [debouncedSearch, setDebouncedSearch] = useState(props.initialSearchValue);
  const { searchTerm: search, handleChange: setSearch } = useDebouncedSearch({
    defaultSearchTerm: props.initialSearchValue,
    debounceFunction: setDebouncedSearch,
  });

  useOnClickOutside(inputRef, () => setPopoverOpen(false));
  const [countryCodes, setCountryCodes] = useState<string[]>((props.countryCodes || []).filter(Boolean));
  const countryData = useMemo(() => countryCodeData.find(c => c.region === countryCodes?.[0]), [countryCodes]);

  const { latitude, longitude } = useUserLatLong();
  const { MULTIREGION_COUNTRIES } = useFeatureFlags(['MULTIREGION_COUNTRIES']);

  const getAddressLocation = async (placeId: string) => {
    props.setIsLoading?.(true);

    const result = await client.query(getLocationFromGooglePlaceId, { placeId }).toPromise();
    const location = utils.removeTypenames(result.data?.getLocationFromGooglePlaceId) as Location;
    if (!location) {
      console.warn('No data returned from getLocationFromGooglePlaceId query');
      props.setIsLoading?.(false);
      return;
    }

    props.setIsLoading?.(false);
    props.onNewPlace(location);
    if (props.clearValue) {
      setSearch('');
    }
  };

  const renderForm = () => {
    return (
      <div className="flex flex-col gap-1">
        <Label htmlFor="__locationAutocompleteInput">{t('formFields.location')}</Label>

        <Input
          id="__locationAutocompleteInput"
          placeholder={props.placeholder}
          value={search || ''}
          ref={inputRef}
          onChange={e => {
            setSearch(e.target.value);
            setPopoverOpen(!!(e.target.value && e.target.value.length > 1));

            window.setTimeout(() => {
              if (inputRef.current) {
                inputRef.current.focus();
              }
            }, 0);
          }}
          onFocus={e => {
            setPopoverOpen(!!(e.target.value && e.target.value.length > 1));
          }}
        />
        <Popover open={popoverOpen}>
          <PopoverTrigger />
          <PopoverContent className="p-0" sideOffset={-3} style={{ width: props.styleWidth || '' }}>
            <LocationAutocompleteQuery
              input={debouncedSearch}
              biasLocation={MULTIREGION_COUNTRIES}
              location={{
                countries: MULTIREGION_COUNTRIES && props.canChangeCountry ? undefined : countryCodes,
                latitude,
                longitude,
              }}
              types={props.type}
            >
              {({ data, fetching }) => {
                return (
                  <Command>
                    <CommandList>
                      {fetching && !data?.locationAutocomplete && search.length > 1 ? (
                        <div className="p-4">
                          <LoadingOverlay style={{ height: '34px' }} />
                        </div>
                      ) : null}

                      {!fetching ? (
                        <CommandEmpty className="flex items-center justify-center gap-0 pt-2 text-sm text-muted-foreground">
                          No locations found.{' '}
                          <Button
                            variant="link"
                            className="text-muted-foreground"
                            onClick={() => {
                              setSearch('');
                              if (inputRef?.current) {
                                inputRef.current.focus();
                              }
                            }}
                          >
                            Clear
                          </Button>
                        </CommandEmpty>
                      ) : null}

                      <CommandGroup className={fetching ? 'opacity-50' : undefined}>
                        {data?.locationAutocomplete?.map(location => {
                          if (!location?.placeId) {
                            return null;
                          }
                          return (
                            <CommandItem
                              key={location.placeId}
                              value={location.description!}
                              className="cursor-pointer whitespace-pre p-2 text-sm text-muted-foreground"
                              onSelect={() => {
                                setSearch(location.description!);
                                setPopoverOpen(false);
                                getAddressLocation(location.placeId!);
                              }}
                            >
                              {location.descriptionMatches?.map(({ text, match }: any) => {
                                return match ? <strong key={text}>{text}</strong> : <span key={text}>{text}</span>;
                              })}
                            </CommandItem>
                          );
                        })}
                      </CommandGroup>
                    </CommandList>

                    {!MULTIREGION_COUNTRIES && props.canChangeCountry ? (
                      <CommandGroup>
                        <CommandItem
                          className="hover:bg-background-hover z-[1000] cursor-pointer text-sm text-muted-foreground"
                          onSelect={() => {
                            setOpenCountrySelect(true);
                            setPopoverOpen(false);
                          }}
                        >
                          <div>{countryData?.name ? `Not in ${countryData.name}?` : 'Change country'}</div>
                        </CommandItem>
                      </CommandGroup>
                    ) : null}
                  </Command>
                );
              }}
            </LocationAutocompleteQuery>
          </PopoverContent>
        </Popover>
      </div>
    );
  };

  return (
    <div className="locationAutocompleteContainer">
      {renderForm()}
      {openCountrySelect && (
        <CountrySelect
          onChange={value => setCountryCodes([value.region])}
          openCountrySelect={openCountrySelect}
          closeCountrySelect={() => {
            setOpenCountrySelect(false);
            setPopoverOpen(true);
          }}
          bookingCountry={{ name: countryData?.name!, region: countryData?.region! }}
        />
      )}
    </div>
  );
}
