import { Button } from '@askable/ui/components/ui/button';
import { Check, Figma } from '@askable/ui/components/unmod/icons';
import { PermissionsList } from '@askable/ui/components/unmod/permissions-list';
import { TaskCard, TaskCardHeader } from '@askable/ui/components/unmod/task-card';
import { TaskCardDraggable, TaskCardDraggableContent } from '@askable/ui/components/unmod/task-card-draggable';
import { cn } from '@askable/ui/lib/utils';
import { ArrowRight } from 'lucide-react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useBlockContext } from 'containers/Studies/BuildStudy/BuildStudy';
import { SelectStartScreen } from 'containers/Studies/BuildStudy/components/Fields/FigmaScreensField/SelectStartScreen';
import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { FigmaPreview } from 'containers/Studies/components/FigmaPreview';

import type { ActiveBlock } from '../hooks/useActiveBlockId';
import type { ReactNode } from 'react';

interface BlockPreviewProps {
  children?: ReactNode;
  activeBlock: NonNullable<ActiveBlock>;
  activeBlockId: string;
}

export const BlockPreview = ({ activeBlock, activeBlockId }: BlockPreviewProps) => {
  const { t } = useTranslation();
  const { screenSelection, setScreenSelection, isCardCollapsed, setIsCardCollapsed } = useBlockContext();
  const { study, setActiveSection } = useStudyContext();
  const [isGoalScreen, setIsGoalScreen] = useState(false);

  const isRecordingEnabled =
    (activeBlock.type === 'welcome' &&
      study.config?.unmoderated?.task_blocks?.some(task => task?.is_recording_enabled)) ??
    false;

  useEffect(() => {
    setScreenSelection(null);
    setIsCardCollapsed(false);
  }, [setScreenSelection, setIsCardCollapsed, activeBlockId]);

  const handleSelectedNode = (nodeId: string) => {
    const goalScreenReached =
      'figma_prototype' in activeBlock && nodeId === activeBlock.figma_prototype?.goal_screen_id;
    setIsGoalScreen(goalScreenReached);
    if (goalScreenReached) {
      setIsCardCollapsed(false);
    }
  };

  return (
    <>
      <aside
        className={cn('build-preview', {
          hidden: screenSelection?.mode === 'start',
        })}
        id="preview"
      >
        <div className="flex h-full flex-col overflow-auto bg-secondary p-3 pt-2 transition-transform lg:p-8">
          {'figma_prototype' in activeBlock ? (
            <>
              {!activeBlock.figma_prototype?.file_id ? (
                <div className="drag-container relative h-full w-full @container">
                  <div className="h-full w-full rounded-xl bg-prototype p-6">
                    <div className="flex h-full w-full items-center justify-center">
                      <div className="flex flex-col items-center gap-3 text-center font-semibold opacity-50">
                        <Figma size={48} />
                        {t('sections.studies.previewNoPrototype')}
                      </div>
                    </div>

                    <TaskCardDraggable
                      buttonLabel={t('sections.studies.endTask')}
                      collapseLabel={t('sections.studies.hideInstructions')}
                      id={activeBlockId}
                      isCollapsed={isCardCollapsed}
                      isPositionSaved={false}
                      isDisabled
                      subtitle={t('sections.studies.tasks', { count: 1 })}
                      onCollapse={setIsCardCollapsed}
                    >
                      <TaskCardDraggableContent instructions={activeBlock.instructions} title={activeBlock.title} />
                    </TaskCardDraggable>
                  </div>
                </div>
              ) : null}

              {activeBlock.figma_prototype?.file_id ? (
                <FigmaPreview
                  activeBlockId={activeBlockId}
                  fileId={activeBlock.figma_prototype.file_id}
                  startScreenId={activeBlock.figma_prototype.start_screen_id ?? undefined}
                  embedParams={{ 'hide-ui': 'false' }}
                  submitGoalScreenId={nodeId => {
                    screenSelection?.onSave(nodeId);
                    setActiveSection('editor');
                  }}
                  showGoalScreenSelect={screenSelection?.mode === 'goal'}
                  cancelGoalScreenSelect={() => {
                    setScreenSelection(null);
                    setActiveSection('editor');
                  }}
                  onSelectedNode={handleSelectedNode}
                >
                  <TaskCardDraggable
                    buttonLabel={isGoalScreen ? t('global.continue') : t('sections.studies.endTask')}
                    collapseLabel={!isGoalScreen ? t('sections.studies.hideInstructions') : undefined}
                    id={activeBlockId}
                    isCollapsed={isCardCollapsed}
                    isPositionSaved={false}
                    isDisabled
                    isDraggable={!isGoalScreen}
                    subtitle={t('sections.studies.tasks', { count: 1 })}
                    onCollapse={setIsCardCollapsed}
                  >
                    {isGoalScreen ? (
                      <div className="flex flex-col gap-2 pt-4">
                        <Check bgColor="success" />
                        <TaskCardDraggableContent
                          instructions={t('sections.studies.taskSuccessDescription')}
                          title={t('sections.studies.taskSuccessTitle')}
                        />
                      </div>
                    ) : (
                      <TaskCardDraggableContent instructions={activeBlock.instructions} title={activeBlock.title} />
                    )}
                  </TaskCardDraggable>
                </FigmaPreview>
              ) : null}
            </>
          ) : (
            <TaskCard
              id={activeBlockId}
              footerSlot={
                <>
                  {isRecordingEnabled ? (
                    <div className="px-4 md:px-8">
                      <PermissionsList
                        description={t('sections.studies.permissions.description')}
                        permissions={[
                          { key: 'camera', description: t('sections.studies.permissions.camera') },
                          {
                            key: 'microphone',
                            description: t('sections.studies.permissions.microphone'),
                          },
                        ]}
                      />
                    </div>
                  ) : null}

                  <nav className="flex items-center justify-end gap-2 border-0.5 border-t p-6">
                    {activeBlock.type === 'welcome' ? (
                      <Button variant="primary" disabled>
                        {t('sections.studies.getStarted')} <ArrowRight className="block h-4 w-4" />
                      </Button>
                    ) : null}

                    {activeBlock.type === 'thank_you' ? <Button variant="ghost" disabled /> : null}

                    {activeBlock.type !== 'welcome' && activeBlock.type !== 'thank_you' ? (
                      <Button variant="primary" disabled>
                        {t('sections.studies.endTask')}
                      </Button>
                    ) : null}
                  </nav>
                </>
              }
            >
              <TaskCardHeader
                instructions={activeBlock.instructions}
                subtitle={t('sections.studies.tasks', { count: 1 })}
                title={activeBlock.title}
                type={activeBlock.type}
              />
            </TaskCard>
          )}
        </div>
      </aside>

      {/* TODO: render goal selection here in the same way, to decouple it from the preview screen */}
      {screenSelection?.mode === 'start' && 'figma_prototype' in activeBlock ? (
        <SelectStartScreen
          fileId={activeBlock.figma_prototype?.file_id}
          activeStartScreenId={activeBlock.figma_prototype?.start_screen_id ?? null}
          onChange={nodeId => screenSelection?.onSave(nodeId)}
        />
      ) : null}
    </>
  );
};
