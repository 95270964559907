import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const ChevronRightIcon: CreateIconType = createIcon({
  displayName: 'ChevronRightIcon',

  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: (
    <path
      d="M5.47 12.53a.75.75 0 010-1.06L8.94 8 5.47 4.53a.75.75 0 011.06-1.06l4 4a.75.75 0 010 1.06l-4 4a.75.75 0 01-1.06 0z"
      fill="currentColor"
    />
  ),
});
