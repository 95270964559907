import { Button } from '@askable/ui/components/ui/button';
import { FormControl, FormItem } from '@askable/ui/components/ui/form';
import { MultiSelect } from '@askable/ui/components/ui/multi-select';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@askable/ui/components/ui/select';
import { Trash2 } from 'lucide-react';
import { useState, memo } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormErrorMessage } from 'components/Form/FormErrorMessage';

import type { IndustryListType } from '../data/IndustryList.query';

interface IndustryAndOccupationItemProps {
  index: number;
  items: IndustryListType;
  isDisabled?: boolean;
  onRemove: (id: string) => void;
}

const IndustryAndOccupationItemComponent = ({ index, items, isDisabled, onRemove }: IndustryAndOccupationItemProps) => {
  const { t } = useTranslation();
  const {
    field,
    fieldState: { error },
  } = useController({ name: `industry_and_occupation.${index}` });

  const [occupations, setOccupations] = useState<Array<{ label: string; value: string }>>(
    items[0]?.subcategories?.map(occupation => ({
      label: occupation?.name ?? '',
      value: occupation?._id ?? '',
    })) ?? [],
  );

  const industries = items.map(item => ({
    label: item?.name,
    value: item?._id,
  }));

  return (
    <FormItem>
      <div
        className="flex flex-col gap-2 opacity-100 transition-opacity starting:opacity-0 sm:flex-row"
        data-testid={field.value._id}
      >
        <FormControl>
          <div className="flex-1">
            <label htmlFor={`input_occupations_${index}`} className="sr-only">
              {t('sections.studies.recruit.fields.industryTitle')}
            </label>
            <Select
              value={field.value?.industry ?? industries[0]?.value}
              onValueChange={value => {
                field.onChange({
                  ...field.value,
                  industry: value,
                  occupations: [],
                });

                const industry = items.find(item => item?._id === value);
                setOccupations(
                  industry?.subcategories?.map(occupation => ({
                    label: occupation?.name ?? '',
                    value: occupation?._id ?? '',
                  })) ?? [],
                );
              }}
              disabled={isDisabled}
            >
              <SelectTrigger>
                <SelectValue placeholder={t('sections.studies.recruit.fields.industryPlaceholder')} />
              </SelectTrigger>
              <SelectContent className="z-20">
                {industries.map(option => (
                  <SelectItem key={option.value} value={option.value ?? ''}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </FormControl>

        <FormControl>
          <div className="flex-1">
            <label htmlFor={`input_occupations_${index}`} className="sr-only">
              {t('sections.studies.recruit.fields.occupationsTitle')}
            </label>
            <MultiSelect
              disabled={isDisabled}
              id={`input_occupations_${index}`}
              onChange={value => {
                field.onChange({
                  ...field.value,
                  occupations: value,
                });
              }}
              options={occupations}
              placeholder={
                field.value?.occupations.length === 0
                  ? t('sections.studies.recruit.fields.occupationsPlaceholderEmpty')
                  : `${t('sections.studies.recruit.fields.occupationsPlaceholder')}...`
              }
              values={field.value?.occupations}
            />
          </div>
        </FormControl>

        <Button
          variant="ghost"
          size="icon"
          title={t('global.remove')}
          onClick={() => onRemove(field.value._id)}
          disabled={isDisabled}
        >
          <Trash2 className="h-4 w-4" />
        </Button>
      </div>

      {error ? <FormErrorMessage message={error.message} prefix="sections.studies.recruit.formValidation" /> : null}
    </FormItem>
  );
};

IndustryAndOccupationItemComponent.displayName = 'IndustryAndOccupationItem';

export const IndustryAndOccupationItem = memo(IndustryAndOccupationItemComponent);
