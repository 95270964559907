import { AnchorButton } from '@askable/ui/components/ui/button';
import { Menu } from 'lucide-react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useStudyContext } from '../StudiesContainer';
import { SideNav } from '../components/SideNav';
import { getPageTitle } from '../utils/getPageTitle';

export const Report = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { study } = useStudyContext();

  return (
    <>
      <Helmet>
        <title>
          {getPageTitle(t('sections.studies.tabs.report'), study.name || t('sections.studies.untitledStudy'))}
        </title>
      </Helmet>

      <>
        <SideNav title={t('sections.studies.tabs.report')}>...</SideNav>

        <main className="main overflow-auto lg:p-4">
          <div className="h-full rounded-xl bg-background p-3 lg:p-4">
            <header className="flex items-center gap-2">
              <AnchorButton variant="ghost" size="icon" href={location.hash === '#nav' ? '#main' : '#nav'}>
                <Menu className="h-4 w-4" />
              </AnchorButton>
              <h1 className="text-lg font-semibold">{t('sections.studies.tabs.report')}</h1>
            </header>
          </div>
        </main>
      </>
    </>
  );
};
