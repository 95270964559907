import { FormControl, FormItem, FormLabel } from '@askable/ui/components/ui/form';
import { RadioGroup, RadioGroupItem } from '@askable/ui/components/ui/radio-group';
import { cn } from '@askable/ui/lib/utils';
import { memo } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormErrorMessage } from 'components/Form/FormErrorMessage';
import { FieldLabel } from 'containers/Studies/components/Fields/FieldLabel';

import type { InfoPopoverProps } from 'containers/Studies/components/InfoPopover';

interface Option {
  label: string;
  value: string;
  link?: string;
}

interface RadioGroupFieldProps {
  description?: string;
  infoPopover?: InfoPopoverProps;
  isDisabled?: boolean;
  label: string;
  name: string;
  options: Option[];
  variant?: 'stacked' | 'inline' | 'inline-options';
}

const RadioGroupFieldComponent = ({
  description,
  infoPopover,
  isDisabled,
  label,
  name,
  options,
  variant = 'inline-options',
}: RadioGroupFieldProps) => {
  const { t } = useTranslation();
  const {
    field,
    fieldState: { error },
  } = useController({ name });

  return (
    <FormItem
      className={cn({ 'flex flex-col justify-between gap-2 sm:flex-row sm:items-center': variant === 'inline' })}
    >
      {label !== '' ? (
        <FieldLabel
          name={`${name}_${options[0].value}`}
          label={label}
          description={description}
          infoPopover={infoPopover}
        />
      ) : null}

      <>
        <FormControl>
          <RadioGroup
            {...field}
            aria-describedby={description ? `description_${name}` : undefined}
            className={cn('group flex flex-col flex-wrap gap-3 sm:flex-row', { 'sm:flex-col': variant === 'stacked' })}
            defaultValue={field.value}
            disabled={isDisabled}
            id={`input_${name}`}
            onValueChange={field.onChange}
          >
            {options.map(option => (
              <FormLabel
                key={option.value}
                htmlFor={`input_${name}_${option.value}`}
                className={cn(
                  `flex flex-1 cursor-pointer items-center justify-between gap-2 whitespace-nowrap rounded-md border border-border px-3
                  py-2 hover:border-input focus:border-input active:border-input`,
                  {
                    'border-primary ring-1 ring-primary hover:border-primary focus:border-primary active:border-primary':
                      field.value === option.value,
                    'cursor-not-allowed opacity-50 hover:border-border focus:border-border active:border-border':
                      isDisabled,
                    'w-full': variant === 'stacked',
                  },
                )}
              >
                <div className="flex items-center gap-2">
                  <RadioGroupItem
                    value={String(option.value)}
                    id={`input_${name}_${option.value}`}
                    disabled={isDisabled}
                  />
                  {option.label}
                </div>

                {option.link ? (
                  <a href={option.link} target="_blank" rel="noreferrer">
                    {t('global.preview')}
                  </a>
                ) : null}
              </FormLabel>
            ))}
          </RadioGroup>
        </FormControl>
      </>

      {error ? <FormErrorMessage message={error.message} prefix="sections.studies.recruit.formValidation" /> : null}
    </FormItem>
  );
};

RadioGroupFieldComponent.displayName = 'RadioGroupField';

export const RadioGroupField = memo(RadioGroupFieldComponent);
