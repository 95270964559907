import { Button } from '@askable/ui/components/ui/button';
import { Input } from '@askable/ui/components/ui/input';
import { Label } from '@askable/ui/components/ui/label';
import { Textarea } from '@askable/ui/components/ui/textarea';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import CardContainer from '../components/cardContainer';
import PriceCardContainer from '../components/priceCardContainer';

import './styles/welcomeEndScreenStyles.scss';

function WelcomeEndScreen(props: any) {
  const [booking, setBooking] = useState(_.get(props, 'booking'));
  const [title, setTitle] = useState(
    _.get(booking, 'config.ai_moderated.information.welcome_title') || 'Discovery Research Study',
  );
  const [description, setDescription] = useState(
    _.get(booking, 'config.ai_moderated.information.welcome_body') ||
      'This will be a discovery research interview, and the questions will be open-ended. There are no right or wrong answers, so answer as honestly as you can.',
  );
  const [titleError, setTitleError] = useState<string | boolean>('');
  const [descriptionError, setDescriptionError] = useState<string | boolean>('');
  const TITLE_MAX_LENGTH = 50;
  const titleLength = _.size(title);
  const titleLengthError = titleLength > TITLE_MAX_LENGTH;
  const DESCRIPTION_MAX_LENGTH = 180;
  const descriptionLength = _.size(description);
  const descriptionLengthError = descriptionLength > DESCRIPTION_MAX_LENGTH;

  useEffect(() => {
    props.updateLastStep({
      step: 'AI_MODERATED',
      subStep: `/booking-setup/${props.booking._id}/ai-moderated/welcome-end-screen`,
      stepId: 'ai_moderated_welcome_end_screen',
    });
    props.renderRightContent(rightContent());
    const bookingStateObj = {
      ...booking,
      config: {
        ...booking.config,
        ai_moderated: {
          ...booking.config.ai_moderated,
          information: {
            ...booking.config.ai_moderated?.information,
            welcome_title: title,
            welcome_body: description,
          },
        },
      },
    };

    setBooking(bookingStateObj);
  }, []);

  useEffect(() => {
    props.validateBooking(booking, document.location.pathname, true);
  }, [booking]);

  const isError = _.get(props, 'booking.steps.ai_moderated_welcome_end_screen') === 'error';

  useEffect(() => {
    if (!isError) {
      return;
    }
    if (!title) {
      setTitleError('Required');
    }
    if (!description) {
      setDescriptionError('Required');
    }
  }, [isError, booking]);

  const rightContent = () => {
    const cardTexts1 = [
      {
        cardText: (
          <div className="researchObjectiveRightContentContainer">
            <span className="researchObjectiveParagraph" style={{ fontWeight: 'bold' }}>
              Need help with your welcome screen?
            </span>
            <span className="researchObjectiveParagraph">See a mockup of the welcome screen in our help article.</span>
          </div>
        ),
      },
    ];

    return (
      <>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer booking={booking} team={_.get(props, 'team')} context={props.context} condensedCard />
        <p className="cardContainerTitle" style={{ marginTop: 18 }}>
          Help articles
        </p>
        <CardContainer
          cardId="__descriptionCard1"
          cardClass="slideInAnimationDelay80"
          cardTexts={cardTexts1}
          onLinkClick={() => console.log('gam')}
        />
      </>
    );
  };

  const onChangeValue = (field: any, value: any) => {
    if (field === 'title') {
      setTitle(value);
    } else if (field === 'description') {
      setDescription(value);
    }

    const bookingStateObj = {
      ...booking,
      config: {
        ...booking.config,
        ai_moderated: {
          ...booking.config.ai_moderated,
          information: {
            ...booking.config.ai_moderated?.information,
            welcome_title: field === 'title' ? value : title,
            welcome_body: field === 'description' ? value : description,
          },
        },
      },
    };

    setBooking(bookingStateObj);
  };

  const onClickNext = () => {
    props.validateBooking(booking, document.location.pathname, true);

    const welcomeEndScreenState = {
      config: {
        ai_moderated: {
          ...booking.ai_moderated,
          information: {
            welcome_title: title,
            welcome_body: description,
          },
        },
      },
    };

    const redirectTo = `/booking-setup/${booking._id}/additional-info/closing-date`;
    props.history.push({
      pathname: redirectTo,
      booking: welcomeEndScreenState,
      bookingState: booking,
    });
  };

  return (
    <div className="researchObjectiveContainer">
      <h1 className="researchObjectiveTitle">Customise welcome screen</h1>
      <div className="createBookingContent flex flex-col gap-1" style={{ marginTop: 0 }}>
        <Label htmlFor="welcome-title">Title</Label>
        <Input
          id="welcome-title"
          value={title || ''}
          autoFocus
          required
          onChange={e => {
            if (e.target.value && titleError) {
              setTitleError('');
            }
            onChangeValue('title', e.target.value);
          }}
          variant={titleError ? 'error' : undefined}
        />
        {titleError ? <div className="text-xs text-destructive">{titleError}</div> : null}

        <div className="flex items-center gap-1 text-xs text-muted-foreground">
          <div className={titleLengthError ? 'text-sm font-bold text-destructive' : undefined}>{titleLength}</div>/
          <div>{TITLE_MAX_LENGTH}</div>
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <Label htmlFor="welcome-description">Description</Label>
        <Textarea
          id="welcome-description"
          value={description || ''}
          rows={2}
          maxRows={5}
          autoFocus
          required
          onChange={e => {
            if (e.target.value && descriptionError) {
              setDescriptionError('');
            }
            onChangeValue('description', e.target.value);
          }}
          variant={descriptionError ? 'error' : undefined}
        />
        {descriptionError ? <div className="text-xs text-destructive">{descriptionError}</div> : null}

        <div className="flex items-center gap-1 text-xs text-muted-foreground">
          <div className={descriptionLengthError ? 'text-sm font-bold text-destructive' : undefined}>
            {descriptionLength}
          </div>
          /<div>{DESCRIPTION_MAX_LENGTH}</div>
        </div>
      </div>
      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default deprecatedWithRouter(WelcomeEndScreen);
