import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const HeartOutlineIcon: CreateIconType = createIcon({
  displayName: 'HeartOutlineIcon',
  defaultProps: defaultOptions,
  path: [
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6.99999 3.18302C3.98028 -0.522119 0.791951 2.11054 0.75 4.9485C0.75 9.17342 5.80507 12.6412 6.99999 12.6412C8.19491 12.6412 13.25 9.17342 13.25 4.9485C13.208 2.11054 10.0197 -0.522119 6.99999 3.18302Z"
    />,
  ],
});
