import { FormControl, FormItem } from '@askable/ui/components/ui/form';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@askable/ui/components/ui/select';
import { cn } from '@askable/ui/lib/utils';
import { memo } from 'react';
import { useController } from 'react-hook-form';

import { FormErrorMessage } from 'components/Form/FormErrorMessage';
import { FieldLabel } from 'containers/Studies/components/Fields/FieldLabel';

import type { InfoPopoverProps } from 'containers/Studies/components/InfoPopover';

interface Option {
  label: string;
  value: string;
}

interface SelectFieldProps {
  description?: string;
  infoPopover?: InfoPopoverProps;
  isDisabled?: boolean;
  isFocused?: boolean;
  label: string;
  name: string;
  options: Option[];
  placeholder?: string;
  variant?: 'stacked' | 'inline';
}

const SelectFieldComponent = ({
  description,
  infoPopover,
  isDisabled,
  isFocused,
  label,
  name,
  options,
  placeholder,
  variant,
}: SelectFieldProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name });

  return (
    <FormItem className={cn({ 'w-full justify-between gap-2 md:flex-row md:items-center': variant === 'inline' })}>
      <FieldLabel name={name} label={label} description={description} infoPopover={infoPopover} />

      <FormControl>
        <Select {...field} disabled={isDisabled} onValueChange={field.onChange} value={field.value}>
          <SelectTrigger
            aria-describedby={description ? `description_${name}` : undefined}
            autoFocus={isFocused}
            className={variant === 'inline' ? 'max-w-60' : undefined}
            id={`input_${name}`}
          >
            <SelectValue placeholder={placeholder} />
          </SelectTrigger>
          <SelectContent className="z-20">
            {options.map(option => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </FormControl>

      {error ? <FormErrorMessage message={error.message} prefix="sections.studies.recruit.formValidation" /> : null}
    </FormItem>
  );
};

SelectFieldComponent.displayName = 'SelectField';

export const SelectField = memo(SelectFieldComponent);
