import { IconBlock } from '@askable/ui/components/unmod/icon-block';

import type { ParticipantResponse } from 'containers/Studies/Results/Results';

export const ParticipantResponseItem = ({ id, type, question, answer }: ParticipantResponse) => {
  return (
    <li className="flex items-start gap-3" id={`response-${id}`}>
      <IconBlock type={type} />

      <div className="flex flex-col gap-1">
        <h4 className="font-semibold">{question}</h4>
        <div className="">{answer.value}</div>
      </div>
    </li>
  );
};
