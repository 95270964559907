import { zodResolver } from '@hookform/resolvers/zod';
import { Helmet } from 'react-helmet-async';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { recruitSchema } from 'containers/Studies/data/schemas/recruitSchema';
import { getPageTitle } from 'containers/Studies/utils/getPageTitle';

import { RecruitForm } from './containers/RecruitForm';
import { RecruitSummary } from './containers/RecruitSummary';

export type RecruitFormFields = z.infer<typeof recruitSchema>;

import type { z } from 'zod';

export const Recruit = () => {
  const { t } = useTranslation();
  const { study } = useStudyContext();

  const form = useForm<RecruitFormFields>({
    mode: 'all',
    resolver: zodResolver(recruitSchema),
    defaultValues: {
      participants: 12,
      device: undefined,
      locations: [],
      age_range: [18, 99],
      gender: undefined,
      advanced_filters: false,
      industry_and_occupation: [],
      language_fluency: [],
      education_level: [],
      marital_status: [],
      occupational_status: [],
      business_ownership: [],
      participant_selection: undefined,
      participants_excluded: 'none',
      primary_contact: undefined,
      terms_custom: false,
      terms_custom_id: undefined,
      terms_custom_recipients: [],
      screener_questions: [],
      opportunity_listing_title: '',
      opportunity_listing_description: t('sections.studies.recruit.fields.descriptionPlaceholder'),
    },
  });

  return (
    <>
      <Helmet>
        <title>
          {getPageTitle(t('sections.studies.tabs.recruit'), study.name || t('sections.studies.untitledStudy'))}
        </title>
      </Helmet>

      <FormProvider {...form}>
        <main className="main lg:p-4">
          <RecruitForm />
        </main>
        <RecruitSummary />
      </FormProvider>
    </>
  );
};
