import {
  createMultiStyleConfigHelpers,
  createStylesContext,
  forwardRef,
  HStack,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { useMemo } from 'react';

import type { FlexProps, HTMLChakraProps, ThemingProps } from '@chakra-ui/react';
import type { FC } from 'react';

const helpers: ReturnType<typeof createMultiStyleConfigHelpers<'container' | 'count'>> = createMultiStyleConfigHelpers([
  'container',
  'count',
]);

export const PaginationTheme: ReturnType<typeof helpers.defineMultiStyleConfig> = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      flexDir: 'row',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    count: {
      mr: 4,
    },
  },
  defaultProps: {},
});

export interface PaginationProps extends HTMLChakraProps<'p'>, ThemingProps<'PaginationTheme'> {}

const [PaginationStyleContext] = createStylesContext('Pagination');

type PaginationCountProps = FlexProps & {
  total: number;
  currentPage: number;
  perPageCount: number;
};

export const PaginationCount: FC<PaginationCountProps> = ({ total, currentPage, perPageCount }) => {
  const label = useMemo(() => {
    if (total === 0) {
      return '0 of 0';
    }

    if (currentPage === 1) {
      return `${currentPage} - ${Math.min(perPageCount, total)} of ${total}`;
    }

    return `${currentPage * perPageCount - perPageCount + 1} - ${Math.min(currentPage * perPageCount, total)} of ${total}`;
  }, [currentPage, total]);

  return <div className="text-foreground-subtle text-xs md:text-sm">{label}</div>;
};

export const Pagination: ReturnType<typeof forwardRef<PaginationProps, 'div'>> = forwardRef<PaginationProps, 'div'>(
  (props, ref) => {
    const { size, variant, children, ...rest } = props;
    const styles = useMultiStyleConfig('Pagination', { size, variant });

    return (
      <HStack ref={ref} __css={styles.container} {...rest}>
        <PaginationStyleContext value={styles}>{children}</PaginationStyleContext>
      </HStack>
    );
  },
);
