import { getColorValue } from '@askable/ui/lib/utils';

import type { IconProps } from '.';

export const Star = ({ bgColor = 'foreground', fgColor = 'background', size = 48 }: IconProps) => {
  const bgHsl = getColorValue(bgColor);
  const fgHsl = getColorValue(fgColor);

  return (
    <svg fill="none" viewBox="0 0 64 64" width={size} height={size}>
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="m34.3 3.3 6.6 3h.3c.4.2.7.4.8.8l3.6 17.6 16.7 6.8c.3.1.6.4.6.8s-.2.8-.5 1l-15.9 9-1.6 18a1 1 0 0 1-1.6.7H43l-6.6-2.5-8.9-8.9-9.8 2.4-5.5 1.4a1 1 0 0 1-.5 0 1 1 0 0 1-.6-.2l-6.6-2.7A1 1 0 0 1 4 49l11.6-18.1-6.5-10.6a1 1 0 0 1 0-1 1 1 0 0 1 1-.6L22.7 20 33 3.7a1 1 0 0 1 1.3-.4Z"
        clipRule="evenodd"
      />
      <path fill={fgHsl} d="m34 4.3 3.7 18.1 17.2 7-16.3 9.4-1.7 18.5L23.1 45 5 49.4l7.8-17L3 16.7l18.6 1.8L34 4.3Z" />
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="M34.2 3.4c.4 0 .7.3.8.7l3.6 17.6 16.7 6.8c.3.1.6.4.6.8s-.2.8-.5 1l-15.9 9-1.6 18a1 1 0 0 1-1.7.7L22.8 46 5.1 50.5A1 1 0 0 1 4 49l7.5-16.5-9.3-15.2a1 1 0 0 1 0-1 1 1 0 0 1 1-.6l18 1.8 12-13.8a1 1 0 0 1 1-.3Zm-.8 3.1-11 12.6a1 1 0 0 1-.8.4L5 17.9l8.5 14c.2.3.2.6 0 1l-6.8 15L22.9 44c.3 0 .7 0 1 .2l12.2 11 1.5-16.5c0-.3.2-.6.5-.8l14.5-8.3-15.3-6.3a1 1 0 0 1-.6-.7l-3.3-16Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
