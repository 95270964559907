/* eslint-disable max-lines */
import _ from 'lodash';
import momentTimezone from 'moment-timezone';

import fetchCountryList from 'data/queries/country/fetchCountries';
import fetchUserById from 'data/queries/user/fetchUserById';
import { apolloFetch } from 'lib/http';
import { localStorage } from 'lib/storage';

const getCountryData = async (countryName = null, countryCode = null) => {
  const data = await apolloFetch.fetch(fetchCountryList);
  let countryData;
  if (!countryName) {
    countryData = _.filter(_.get(data, 'data.countryList'), (item: any) => item.country_code === countryCode);
  } else {
    countryData = _.filter(_.get(data, 'data.countryList'), (item: any) => item.name === countryName);
  }
  return countryData;
};

const getUserData = async () => {
  const data = await apolloFetch.fetch(fetchUserById);
  const userData = _.get(data, 'data.userByID');
  return userData;
};

const location = {
  getTimezoneFromCoordinates(latitude: any, longitude: any) {
    return fetch(
      `https://maps.googleapis.com/maps/api/timezone/json?location=${latitude},${longitude}&timestamp=${Math.round(Date.now() / 1000)}&key=AIzaSyBJ_a28Zd0KkRrFm2cnMYduyzUS2linnSs`,
    )
      .then(r => r.json())
      .then(data => data);
  },
  getListOfTimezones() {
    return [
      'Africa/Abidjan',
      'Africa/Accra',
      'Africa/Addis_Ababa',
      'Africa/Algiers',
      'Africa/Asmara',
      'Africa/Bamako',
      'Africa/Bangui',
      'Africa/Banjul',
      'Africa/Bissau',
      'Africa/Blantyre',
      'Africa/Brazzaville',
      'Africa/Bujumbura',
      'Africa/Cairo',
      'Africa/Casablanca',
      'Africa/Ceuta',
      'Africa/Conakry',
      'Africa/Dakar',
      'Africa/Dar_es_Salaam',
      'Africa/Djibouti',
      'Africa/Douala',
      'Africa/El_Aaiun',
      'Africa/Freetown',
      'Africa/Gaborone',
      'Africa/Harare',
      'Africa/Johannesburg',
      'Africa/Juba',
      'Africa/Kampala',
      'Africa/Khartoum',
      'Africa/Kigali',
      'Africa/Kinshasa',
      'Africa/Lagos',
      'Africa/Libreville',
      'Africa/Lome',
      'Africa/Luanda',
      'Africa/Lubumbashi',
      'Africa/Lusaka',
      'Africa/Malabo',
      'Africa/Maputo',
      'Africa/Maseru',
      'Africa/Mbabane',
      'Africa/Mogadishu',
      'Africa/Monrovia',
      'Africa/Nairobi',
      'Africa/Ndjamena',
      'Africa/Niamey',
      'Africa/Nouakchott',
      'Africa/Ouagadougou',
      'Africa/Porto-Novo',
      'Africa/Sao_Tome',
      'Africa/Tripoli',
      'Africa/Tunis',
      'Africa/Windhoek',
      'America/Adak',
      'America/Anchorage',
      'America/Anguilla',
      'America/Antigua',
      'America/Araguaina',
      'America/Argentina/Buenos_Aires',
      'America/Argentina/Catamarca',
      'America/Argentina/Cordoba',
      'America/Argentina/Jujuy',
      'America/Argentina/La_Rioja',
      'America/Argentina/Mendoza',
      'America/Argentina/Rio_Gallegos',
      'America/Argentina/Salta',
      'America/Argentina/San_Juan',
      'America/Argentina/San_Luis',
      'America/Argentina/Tucuman',
      'America/Argentina/Ushuaia',
      'America/Aruba',
      'America/Asuncion',
      'America/Atikokan',
      'America/Bahia',
      'America/Bahia_Banderas',
      'America/Barbados',
      'America/Belem',
      'America/Belize',
      'America/Blanc-Sablon',
      'America/Boa_Vista',
      'America/Bogota',
      'America/Boise',
      'America/Cambridge_Bay',
      'America/Campo_Grande',
      'America/Cancun',
      'America/Caracas',
      'America/Cayenne',
      'America/Cayman',
      'America/Chicago',
      'America/Chihuahua',
      'America/Costa_Rica',
      'America/Creston',
      'America/Cuiaba',
      'America/Curacao',
      'America/Danmarkshavn',
      'America/Dawson',
      'America/Dawson_Creek',
      'America/Denver',
      'America/Detroit',
      'America/Dominica',
      'America/Edmonton',
      'America/Eirunepe',
      'America/El_Salvador',
      'America/Fort_Nelson',
      'America/Fortaleza',
      'America/Glace_Bay',
      'America/Goose_Bay',
      'America/Grand_Turk',
      'America/Grenada',
      'America/Guadeloupe',
      'America/Guatemala',
      'America/Guayaquil',
      'America/Guyana',
      'America/Halifax',
      'America/Havana',
      'America/Hermosillo',
      'America/Indiana/Indianapolis',
      'America/Indiana/Knox',
      'America/Indiana/Marengo',
      'America/Indiana/Petersburg',
      'America/Indiana/Tell_City',
      'America/Indiana/Vevay',
      'America/Indiana/Vincennes',
      'America/Indiana/Winamac',
      'America/Inuvik',
      'America/Iqaluit',
      'America/Jamaica',
      'America/Juneau',
      'America/Kentucky/Louisville',
      'America/Kentucky/Monticello',
      'America/Kralendijk',
      'America/La_Paz',
      'America/Lima',
      'America/Los_Angeles',
      'America/Lower_Princes',
      'America/Maceio',
      'America/Managua',
      'America/Manaus',
      'America/Marigot',
      'America/Martinique',
      'America/Matamoros',
      'America/Mazatlan',
      'America/Menominee',
      'America/Merida',
      'America/Metlakatla',
      'America/Mexico_City',
      'America/Miquelon',
      'America/Moncton',
      'America/Monterrey',
      'America/Montevideo',
      'America/Montserrat',
      'America/Nassau',
      'America/New_York',
      'America/Nipigon',
      'America/Nome',
      'America/Noronha',
      'America/North_Dakota/Beulah',
      'America/North_Dakota/Center',
      'America/North_Dakota/New_Salem',
      'America/Nuuk',
      'America/Ojinaga',
      'America/Panama',
      'America/Pangnirtung',
      'America/Paramaribo',
      'America/Phoenix',
      'America/Port_of_Spain',
      'America/Port-au-Prince',
      'America/Porto_Velho',
      'America/Puerto_Rico',
      'America/Punta_Arenas',
      'America/Rainy_River',
      'America/Rankin_Inlet',
      'America/Recife',
      'America/Regina',
      'America/Resolute',
      'America/Rio_Branco',
      'America/Santarem',
      'America/Santiago',
      'America/Santo_Domingo',
      'America/Sao_Paulo',
      'America/Scoresbysund',
      'America/Sitka',
      'America/St_Barthelemy',
      'America/St_Johns',
      'America/St_Kitts',
      'America/St_Lucia',
      'America/St_Thomas',
      'America/St_Vincent',
      'America/Swift_Current',
      'America/Tegucigalpa',
      'America/Thule',
      'America/Thunder_Bay',
      'America/Tijuana',
      'America/Toronto',
      'America/Tortola',
      'America/Vancouver',
      'America/Whitehorse',
      'America/Winnipeg',
      'America/Yakutat',
      'America/Yellowknife',
      'Antarctica/Casey',
      'Antarctica/Davis',
      'Antarctica/DumontDUrville',
      'Antarctica/Macquarie',
      'Antarctica/Mawson',
      'Antarctica/McMurdo',
      'Antarctica/Palmer',
      'Antarctica/Rothera',
      'Antarctica/Syowa',
      'Antarctica/Troll',
      'Antarctica/Vostok',
      'Arctic/Longyearbyen',
      'Asia/Aden',
      'Asia/Almaty',
      'Asia/Amman',
      'Asia/Anadyr',
      'Asia/Aqtau',
      'Asia/Aqtobe',
      'Asia/Ashgabat',
      'Asia/Atyrau',
      'Asia/Baghdad',
      'Asia/Bahrain',
      'Asia/Baku',
      'Asia/Bangkok',
      'Asia/Barnaul',
      'Asia/Beirut',
      'Asia/Bishkek',
      'Asia/Brunei',
      'Asia/Chita',
      'Asia/Choibalsan',
      'Asia/Colombo',
      'Asia/Damascus',
      'Asia/Dhaka',
      'Asia/Dili',
      'Asia/Dubai',
      'Asia/Dushanbe',
      'Asia/Famagusta',
      'Asia/Gaza',
      'Asia/Hebron',
      'Asia/Ho_Chi_Minh',
      'Asia/Hong_Kong',
      'Asia/Hovd',
      'Asia/Irkutsk',
      'Asia/Istanbul',
      'Asia/Jakarta',
      'Asia/Jayapura',
      'Asia/Jerusalem',
      'Asia/Kabul',
      'Asia/Kamchatka',
      'Asia/Karachi',
      'Asia/Kathmandu',
      'Asia/Khandyga',
      'Asia/Kolkata',
      'Asia/Krasnoyarsk',
      'Asia/Kuala_Lumpur',
      'Asia/Kuching',
      'Asia/Kuwait',
      'Asia/Macau',
      'Asia/Magadan',
      'Asia/Makassar',
      'Asia/Manila',
      'Asia/Muscat',
      'Asia/Nicosia',
      'Asia/Novokuznetsk',
      'Asia/Novosibirsk',
      'Asia/Omsk',
      'Asia/Oral',
      'Asia/Phnom_Penh',
      'Asia/Pontianak',
      'Asia/Pyongyang',
      'Asia/Qatar',
      'Asia/Qostanay',
      'Asia/Qyzylorda',
      'Asia/Riyadh',
      'Asia/Sakhalin',
      'Asia/Samarkand',
      'Asia/Seoul',
      'Asia/Shanghai',
      'Asia/Singapore',
      'Asia/Srednekolymsk',
      'Asia/Taipei',
      'Asia/Tashkent',
      'Asia/Tbilisi',
      'Asia/Tehran',
      'Asia/Thimphu',
      'Asia/Tokyo',
      'Asia/Tomsk',
      'Asia/Ulaanbaatar',
      'Asia/Urumqi',
      'Asia/Ust-Nera',
      'Asia/Vientiane',
      'Asia/Vladivostok',
      'Asia/Yakutsk',
      'Asia/Yangon',
      'Asia/Yekaterinburg',
      'Asia/Yerevan',
      'Atlantic/Azores',
      'Atlantic/Bermuda',
      'Atlantic/Canary',
      'Atlantic/Cape_Verde',
      'Atlantic/Faroe',
      'Atlantic/Madeira',
      'Atlantic/Reykjavik',
      'Atlantic/South_Georgia',
      'Atlantic/St_Helena',
      'Atlantic/Stanley',
      'Australia/Adelaide',
      'Australia/Brisbane',
      'Australia/Broken_Hill',
      'Australia/Darwin',
      'Australia/Eucla',
      'Australia/Hobart',
      'Australia/Lindeman',
      'Australia/Lord_Howe',
      'Australia/Melbourne',
      'Australia/Perth',
      'Australia/Sydney',
      'Etc/GMT',
      'Etc/GMT-0',
      'Etc/GMT-1',
      'Etc/GMT-10',
      'Etc/GMT-11',
      'Etc/GMT-12',
      'Etc/GMT-13',
      'Etc/GMT-14',
      'Etc/GMT-2',
      'Etc/GMT-3',
      'Etc/GMT-4',
      'Etc/GMT-5',
      'Etc/GMT-6',
      'Etc/GMT-7',
      'Etc/GMT-8',
      'Etc/GMT-9',
      'Etc/GMT+0',
      'Etc/GMT+1',
      'Etc/GMT+10',
      'Etc/GMT+11',
      'Etc/GMT+12',
      'Etc/GMT+2',
      'Etc/GMT+3',
      'Etc/GMT+4',
      'Etc/GMT+5',
      'Etc/GMT+6',
      'Etc/GMT+7',
      'Etc/GMT+8',
      'Etc/GMT+9',
      'Etc/GMT0',
      'Etc/UTC',
      'Europe/Amsterdam',
      'Europe/Andorra',
      'Europe/Astrakhan',
      'Europe/Athens',
      'Europe/Belgrade',
      'Europe/Berlin',
      'Europe/Bratislava',
      'Europe/Brussels',
      'Europe/Bucharest',
      'Europe/Budapest',
      'Europe/Busingen',
      'Europe/Chisinau',
      'Europe/Copenhagen',
      'Europe/Dublin',
      'Europe/Gibraltar',
      'Europe/Guernsey',
      'Europe/Helsinki',
      'Europe/Isle_of_Man',
      'Europe/Istanbul',
      'Europe/Jersey',
      'Europe/Kaliningrad',
      'Europe/Kiev',
      'Europe/Kirov',
      'Europe/Lisbon',
      'Europe/Ljubljana',
      'Europe/London',
      'Europe/Luxembourg',
      'Europe/Madrid',
      'Europe/Malta',
      'Europe/Mariehamn',
      'Europe/Minsk',
      'Europe/Monaco',
      'Europe/Moscow',
      'Europe/Nicosia',
      'Europe/Oslo',
      'Europe/Paris',
      'Europe/Podgorica',
      'Europe/Prague',
      'Europe/Riga',
      'Europe/Rome',
      'Europe/Samara',
      'Europe/San_Marino',
      'Europe/Sarajevo',
      'Europe/Saratov',
      'Europe/Simferopol',
      'Europe/Skopje',
      'Europe/Sofia',
      'Europe/Stockholm',
      'Europe/Tallinn',
      'Europe/Tirane',
      'Europe/Ulyanovsk',
      'Europe/Uzhgorod',
      'Europe/Vaduz',
      'Europe/Vatican',
      'Europe/Vienna',
      'Europe/Vilnius',
      'Europe/Volgograd',
      'Europe/Warsaw',
      'Europe/Zagreb',
      'Europe/Zaporozhye',
      'Europe/Zurich',
      'GMT',
      'Indian/Antananarivo',
      'Indian/Chagos',
      'Indian/Christmas',
      'Indian/Cocos',
      'Indian/Comoro',
      'Indian/Kerguelen',
      'Indian/Mahe',
      'Indian/Maldives',
      'Indian/Mauritius',
      'Indian/Mayotte',
      'Indian/Reunion',
      'Pacific/Apia',
      'Pacific/Auckland',
      'Pacific/Bougainville',
      'Pacific/Chatham',
      'Pacific/Chuuk',
      'Pacific/Easter',
      'Pacific/Efate',
      'Pacific/Enderbury',
      'Pacific/Fakaofo',
      'Pacific/Fiji',
      'Pacific/Funafuti',
      'Pacific/Galapagos',
      'Pacific/Gambier',
      'Pacific/Guadalcanal',
      'Pacific/Guam',
      'Pacific/Honolulu',
      'Pacific/Kiritimati',
      'Pacific/Kosrae',
      'Pacific/Kwajalein',
      'Pacific/Majuro',
      'Pacific/Marquesas',
      'Pacific/Midway',
      'Pacific/Nauru',
      'Pacific/Niue',
      'Pacific/Norfolk',
      'Pacific/Noumea',
      'Pacific/Pago_Pago',
      'Pacific/Palau',
      'Pacific/Pitcairn',
      'Pacific/Pohnpei',
      'Pacific/Port_Moresby',
      'Pacific/Rarotonga',
      'Pacific/Saipan',
      'Pacific/Tahiti',
      'Pacific/Tarawa',
      'Pacific/Tongatapu',
      'Pacific/Wake',
      'Pacific/Wallis',
      'UTC',
    ];
  },
  getTimezoneText(timezone?: string | null) {
    if (!timezone) {
      return '';
    }
    const zoneFormatted = momentTimezone().tz(timezone).format('Z');
    return `${timezone} (GMT ${zoneFormatted})`;
  },
  getListOfTimezonesFormatted() {
    return _.map(location.getListOfTimezones(), (item: any) => {
      return {
        label: location.getTimezoneText(item),
        value: item,
      };
    });
  },
  async saveLocationFromUserData() {
    const userData = await getUserData();
    const userLocation = _.get(userData, 'location');

    if (_.get(userLocation, 'country')) {
      const countryData = await getCountryData(null, _.get(userLocation, 'country'));

      localStorage.save('stateByIp', _.get(userLocation, 'region'));
      localStorage.save('countryByIp', _.get(userLocation, 'country'));
      localStorage.save('countryName', _.get(countryData, '0.name') || '');
      localStorage.save('stateName', _.get(userLocation, 'state'));
      localStorage.save('cityByIp', _.get(userLocation, 'city'));
      localStorage.save('latitudeByIp', _.get(userLocation, 'latitude'));
      localStorage.save('longitudeByIp', _.get(userLocation, 'longitude'));
      return userLocation;
    }
    // If no user location default to Brisbane, Australia
    localStorage.save('stateByIp', 'Queensland');
    localStorage.save('countryByIp', 'AU');
    localStorage.save('countryName', 'Australia');
    localStorage.save('stateName', 'QLD');
    localStorage.save('cityByIp', 'Brisbane');
  },
};

export { location };
