import { Avatar, AvatarFallback } from '@askable/ui/components/ui/avatar';
import { AnchorButton, Button } from '@askable/ui/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from '@askable/ui/components/ui/dropdown-menu';
import { Ellipsis } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

interface ParticipantHeaderProps {
  name: string;
}

export const ParticipantHeader = ({ name }: ParticipantHeaderProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const initials =
    name
      .split(' ')
      .map(n => n[0])
      .join('') ?? 'U';

  return (
    <header className="flex flex-wrap items-center justify-between gap-2">
      <div className="flex items-center gap-3">
        <Avatar size="xl">
          <AvatarFallback variant="primary">{initials}</AvatarFallback>
        </Avatar>
        <h2 className="text-xl font-semibold">{name}</h2>
      </div>

      <div className="flex items-center gap-3">
        <AnchorButton variant="outline" href={location.hash === '#details' ? '#main' : '#details'}>
          {t('sections.studies.results.showMoreInfo')}
        </AnchorButton>

        <DropdownMenu modal={false}>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" size="icon">
              <Ellipsis className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem>Item 1</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </header>
  );
};
