import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { LoadingOverlay } from 'components/common';
import ProjectOfferScreen from 'components/researcher/projectOffer/projectOfferScreen';
import updateProjectSubscription from 'data/subscriptions/project/updateProjectSubscription';
import { useFetchProjectByIdQuery } from 'generated/graphql';

import 'components/manageAskablePlus/styles/askablePlusStyles.scss';

function ProjectOffer() {
  const params = useParams();
  useEffect(() => {
    document.title = 'Askable';
    const headerProjectName = document.getElementById('headerBookingName');
    if (headerProjectName) headerProjectName.innerText = '';
  }, []);

  const { loading, data, error, subscribeToMore, refetch } = useFetchProjectByIdQuery({
    variables: {
      id: params?.projectID,
    },
  });

  useEffect(() => {
    if (data?.projectByID?.name) {
      document.title = data?.projectByID?.name;
    }
  }, [data]);

  if (loading) {
    return <LoadingOverlay style={{ opacity: 0.8 }} />;
  }

  if (error) {
    return `Error ${error.message}`;
  }

  const handleSubscribe = () => {
    subscribeToMore({
      document: updateProjectSubscription,
      variables: { id: params?.projectID },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;

        return { ...prev, projectByID: subscriptionData?.data?.projectByID };
      },
    });
  };

  return (
    <ProjectOfferScreen
      project={data?.projectByID}
      refetchProject={refetch}
      subscribeToUpdateProject={handleSubscribe}
    />
  );
}

export default deprecatedWithRouter(ProjectOffer);
