import { toast } from '@askable/ui/components/ui/sonner';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';
import { useMutation, CombinedError } from 'urql';

import { CreateTaskBlockFigmaPrototype } from 'containers/Studies/BuildStudy/data/CreateTaskBlockFigmaPrototype.mutation';
import { UpdateTaskBlockOrder } from 'containers/Studies/BuildStudy/data/UpdateTaskBlockOrder.mutation';
import { useActiveBlockId } from 'containers/Studies/BuildStudy/hooks/useActiveBlockId';
import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { SortableList } from 'containers/Studies/components/SortableList';
import { StudyTaskBlockType } from 'generated/graphql';

import { AddBlock } from '../components/AddBlock';
import { Block } from '../components/Block';

import type { TaskBlock } from 'containers/Studies/BuildStudy/hooks/useActiveBlockId';

export const BlocksSidebar = () => {
  const { t } = useTranslation();
  const { study, setNewBlockId, setActiveSection, setIsSaving, isBuildDisabled } = useStudyContext();
  const { activeBlockId, setActiveBlockId } = useActiveBlockId();

  const [, updateTaskBlockOrder] = useMutation(UpdateTaskBlockOrder);
  const [, createTaskBlockFigmaPrototype] = useMutation(CreateTaskBlockFigmaPrototype);

  const navRef = useRef<HTMLDivElement>(null);

  const [blocks, setBlocks] = useState((study.config?.unmoderated?.task_blocks || []) as TaskBlock[]);
  useEffect(() => {
    setBlocks((study.config?.unmoderated?.task_blocks || []) as TaskBlock[]);
  }, [study.config?.unmoderated?.task_blocks]);

  useEffect(() => {
    return () => setNewBlockId(null);
  }, [setNewBlockId, activeBlockId]);

  const handleAddBlock = useCallback(
    async (type: StudyTaskBlockType) => {
      const newBlocks = await match(type)
        .with(StudyTaskBlockType.FigmaPrototype, async () => {
          const { data, error } = await createTaskBlockFigmaPrototype({
            input: {
              _id: study._id,
              task_block: {
                title: '',
                instructions: '',
                // TODO: check with product/design what the actual logic is
                is_recording_enabled: type === 'figma_prototype',
              },
            },
          });

          if (error || !data?.createTaskBlockFigmaPrototype?._id) {
            toast.error(error?.graphQLErrors[0].message ?? 'Error creating block');
            return;
          }

          return data.createTaskBlockFigmaPrototype.config?.unmoderated?.task_blocks || [];
        })
        .otherwise(() => {
          // Unsupported type
        });

      if (newBlocks?.length) {
        const newBlockId = newBlocks[newBlocks.length - 1]?._id;
        if (newBlockId) {
          setActiveBlockId(newBlockId);
          // Hacky way to not validate the new block straight away
          setTimeout(() => {
            setNewBlockId(newBlockId);
            setActiveSection('editor');
          }, 0);
        }
      }
    },

    [createTaskBlockFigmaPrototype, setActiveBlockId, setNewBlockId, setActiveSection, study._id],
  );

  const updateBlockOrder = useCallback(
    async (ids: string[]) => {
      setIsSaving(true);

      try {
        const { error } = await updateTaskBlockOrder({
          input: { _id: study._id, _task_block_ids: ids },
        });

        if (error) {
          throw error;
        }
      } catch (err) {
        toast.error(err instanceof CombinedError ? err.message : t('sections.errorBoundary.default'));
      } finally {
        setTimeout(() => setIsSaving(false), 200);
      }
    },
    [study._id, updateTaskBlockOrder, t, setIsSaving],
  );

  const handleBlockReorder = (updatedBlocks: TaskBlock[]) => {
    const originalIds = blocks.map(b => b?._id);
    const updatedIds = updatedBlocks.map(b => b?._id);

    // Order unchanged
    if (updatedIds.length === originalIds.length && updatedIds.every((_id, index) => _id === originalIds[index])) {
      console.log('@bail');
      return;
    }

    setBlocks(updatedBlocks);
    updateBlockOrder(updatedIds);
  };

  return (
    <nav className="build-nav overflow-auto @container" id="nav">
      <div className="relative flex h-full min-w-[5rem] flex-col items-center gap-2 bg-background p-4" ref={navRef}>
        <Block data={study.config?.unmoderated?.welcome_block!} isActive={activeBlockId === 'welcome'} />

        {blocks.length > 0 && !isBuildDisabled ? (
          // TODO: see if we can infer `TaskBlock` from the input props here
          <SortableList<TaskBlock>
            onChange={handleBlockReorder}
            items={blocks}
            renderItem={block => (
              <Block
                key={block?._id}
                data={block}
                isMovable
                isActive={activeBlockId ? activeBlockId === block?._id : false}
              />
            )}
          />
        ) : null}

        {blocks.length > 0 && isBuildDisabled
          ? blocks.map(block => (
              <Block
                key={block?._id}
                data={block}
                isMovable
                isActive={activeBlockId ? activeBlockId === block?._id : false}
              />
            ))
          : null}

        {!isBuildDisabled ? <AddBlock isFullBlock={blocks?.length === 0} onSelect={handleAddBlock} /> : null}
        <Block data={study.config?.unmoderated?.thank_you_block!} isActive={activeBlockId === 'thank_you'} />
      </div>
    </nav>
  );
};
