import { FormControl, FormItem } from '@askable/ui/components/ui/form';
import { Input } from '@askable/ui/components/ui/input';
import { Slider } from '@askable/ui/components/ui/slider';
import { useController } from 'react-hook-form';

import { FormErrorMessage } from 'components/Form/FormErrorMessage';
import { FieldLabel } from 'containers/Studies/components/Fields/FieldLabel';

import type { InfoPopoverProps } from 'containers/Studies/components/InfoPopover';

interface SliderFieldProps {
  description?: string;
  infoPopover?: InfoPopoverProps;
  isDisabled?: boolean;
  isFocused?: boolean;
  label: string;
  max: number;
  min: number;
  name: string;
}

export const SliderField = ({
  description,
  infoPopover,
  isDisabled,
  isFocused,
  label,
  max,
  min = 1,
  name,
}: SliderFieldProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name });

  return (
    <FormItem>
      <FieldLabel name={name} label={label} description={description} infoPopover={infoPopover} />

      <div className="flex items-center gap-2">
        <FormControl>
          <Slider
            value={[field.value]}
            max={max}
            min={min}
            onValueChange={value => field.onChange(value[0])}
            disabled={isDisabled}
          />
        </FormControl>
        <FormControl>
          <Input
            {...field}
            id={`input_${name}`}
            aria-describedby={description ? `description_${name}` : undefined}
            autoFocus={isFocused}
            className="text-md w-9 text-center lg:text-sm"
            inputMode="numeric"
            pattern="[0-9]*"
            max={max}
            min={min}
            value={field.value ?? min}
            disabled={isDisabled}
            onChange={e => field.onChange(Number(e.target.value))}
            onBlur={e => {
              const value = Number(e.target.value);
              if (isNaN(value)) {
                return;
              }
              const clampedValue = Math.min(Math.max(value, min), max);
              field.onChange(clampedValue);
            }}
          />
        </FormControl>
      </div>

      {error ? <FormErrorMessage message={error.message} prefix="sections.studies.recruit.formValidation" /> : null}
    </FormItem>
  );
};
