/* eslint-disable max-lines */
import { useQuery } from '@apollo/client';
import { Alert, AlertDescription } from '@askable/ui/components/ui/alert';
import { Button } from '@askable/ui/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@askable/ui/components/ui/dialog';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@askable/ui/components/ui/form';
import { Input } from '@askable/ui/components/ui/input';
import { toast } from '@askable/ui/components/ui/sonner';
import { Textarea } from '@askable/ui/components/ui/textarea';
import { zodResolver } from '@hookform/resolvers/zod';
import _ from 'lodash';
import { TriangleAlert } from 'lucide-react';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'urql';
import { z } from 'zod';

import { LoadingOverlay } from 'components/common';
import { useAppContext } from 'components/common/Askable/Providers/appProvider';
import { useConnectedClient } from 'context/ConnectedClientContext';
import updateTeamMutation from 'data/mutations/teams/updateTeam';
import fetchBookingsByTeam from 'data/queries/booking/fetchBookingsByTeam';
import fetchTeamsByParentTeam from 'data/queries/teams/fetchTeamsByParentTeam';
import { useSettingsTeamQuery } from 'generated/graphql';
import { teamUtils } from 'lib/teams';

import { SectionHeader } from '../../components/SectionHeader';
import { SettingsContainer } from '../../components/SettingsContainer';

import { AllowedDomains } from './containers/AllowedDomains/AllowedDomains';

import type { UpdateTeamMutation, UpdateTeamMutationVariables } from 'generated/graphql';

const formSchema = z.object({
  recruitmentDescription: z.string().optional(),
  recruitmentTitle: z.string().min(1).max(255),
  teamName: z.string().min(1).max(255),
});

export const SettingsTeam = (props: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { details } = useConnectedClient();
  const teamId = details?.team?.id;
  const isAdmin = teamUtils.isAdminByRoleId(details?.teamRole);
  const context = useAppContext();

  const [, updateTeam] = useMutation<UpdateTeamMutation, UpdateTeamMutationVariables>(updateTeamMutation);
  const {
    loading: loadingTeam,
    error,
    data,
    refetch,
  } = useSettingsTeamQuery({
    variables: {
      _id: teamId,
      isAdmin,
    },
    fetchPolicy: 'no-cache',
  });

  const { data: bookingsByTeam } = useQuery(fetchBookingsByTeam, {
    variables: {
      _team_id: teamId,
      status: [1, 3],
      excludeDemoBookings: true,
    },
    fetchPolicy: 'no-cache',
  });

  const { data: teamsByParentTeam } = useQuery(fetchTeamsByParentTeam, {
    variables: {
      _parent_team: teamId,
    },
    fetchPolicy: 'no-cache',
  });

  const [allowedDomains, setAllowedDomains] = useState<string[]>([]);
  const [errorDeactivatingTeam, setErrorDeactivatingTeam] = useState<{ message: string } | null>(null);
  const [saving, setSaving] = useState(false);
  const [openDeactivateTeamDialog, setOpenDeactivateTeamDialog] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    reValidateMode: 'onSubmit',
    defaultValues: {
      teamName: '',
      recruitmentTitle: 'Customer Feedback Program',
      recruitmentDescription: '',
    },
  });

  useEffect(() => {
    if (_.get(props.location, 'state.shouldOpenInviteTeammate')) {
      context.onOpenInviteTeamMemberModal();
    }
  }, []);

  // Redirect if not an admin
  useEffect(() => {
    if (!isAdmin) {
      navigate('/settings/members');
    }
  }, [isAdmin, navigate]);

  useEffect(() => {
    form.setValue('teamName', data?.teamById?.name as string);
    form.setValue('recruitmentTitle', data?.teamById?.settings?.recruitment_page?.title as string);
    form.setValue('recruitmentDescription', data?.teamById?.settings?.recruitment_page?.blurb as string);

    if (data?.teamById?.allowed_domains) {
      setAllowedDomains(data?.teamById?.allowed_domains.map(d => d?.domain).filter(Boolean) as string[]);
    }
  }, [data]);

  const onDeactivateTeam = () => {
    setOpenDeactivateTeamDialog(true);
  };

  const onConfirmDeactivateTeam = async () => {
    if (!teamId) {
      console.warn('No teamId found while trying to deactivate team.');
      return;
    }

    setSaving(true);
    const teamData = { status: 0 };

    try {
      await updateTeam({ _id: teamId, team: teamUtils.normaliseTeamObjectToSave(teamData) });

      setOpenDeactivateTeamDialog(false);
      setTimeout(() => {
        navigate('/logout', { replace: true });
      }, 500);

      toast.success('Team deactivated successfully');
    } catch (err: any) {
      setErrorDeactivatingTeam(err);
    } finally {
      setSaving(false);
    }
  };

  // Add or remove domains from the list of allowed domains
  const updateAllowedDomains = async (domains: string[], isRemoved?: boolean) => {
    if (!teamId) {
      return;
    }

    try {
      const { error: updateTeamError } = await updateTeam({
        _id: teamId,
        team: { allowed_domains: domains },
      });

      if (updateTeamError) {
        throw new Error(updateTeamError.message);
      }

      setAllowedDomains(domains);

      toast.success(isRemoved ? t('sections.settings.team.domainRemoved') : t('sections.settings.team.domainAdded'));
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  const saveTeamData = async (values: z.infer<typeof formSchema>) => {
    if (!teamId) {
      return;
    }

    const teamData = {
      name: values.teamName,
      allowed_domains: allowedDomains,
      settings: {
        recruitment_page: {
          title: values.recruitmentTitle,
          blurb: values.recruitmentDescription,
        },
      },
    };

    setSaving(true);

    try {
      const { error: updateTeamError } = await updateTeam({
        _id: teamId,
        team: teamData,
      });

      if (updateTeamError) {
        throw new Error(updateTeamError.message);
      }

      await refetch();

      toast.success(t('confirmations.updated', { title: t('sections.settings.team.title') }));
    } catch (err: any) {
      toast.error(err.message);
    } finally {
      setSaving(false);
    }
  };

  const renderDeactivateTeam = () => {
    // You can’t deactivate a team with active or remote bookings and with related team
    if (bookingsByTeam?.bookingsByTeam.length > 0 || teamsByParentTeam?.teamsByParentTeam.length > 0) {
      return null;
    }

    return (
      <div className="rounded-md border border-border bg-slate-50 p-4">
        <Button onClick={onDeactivateTeam} variant="destructive">
          {t('sections.settings.team.deactivateTeam')}
        </Button>
      </div>
    );
  };

  return (
    <SettingsContainer title={t('sections.settings.team.title')}>
      {isAdmin ? (
        <>
          {loadingTeam ? <LoadingOverlay style={{ opacity: 0.8 }} /> : null}

          {error ? (
            <Alert variant="destructive">
              <AlertDescription>{error.message}</AlertDescription>
            </Alert>
          ) : null}

          <Form {...form}>
            <form onSubmit={form.handleSubmit(saveTeamData)} className="flex flex-col gap-6">
              <FormField
                control={form.control}
                name="teamName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('sections.settings.team.teamName')}</FormLabel>
                    <FormControl>
                      <Input autoFocus {...field} data-testid="input-team-name" />
                    </FormControl>
                    <FormMessage className="text-xs" variant="error" />
                  </FormItem>
                )}
              />

              <SectionHeader title={t('sections.settings.team.recruitFromOwnAudience')} />

              <FormField
                control={form.control}
                name="recruitmentTitle"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('sections.settings.team.recruitmentPageTitle')}</FormLabel>
                    <FormControl>
                      <Input {...field} data-testid="input-recruitment-title" />
                    </FormControl>
                    <FormMessage className="text-xs" variant="error" />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="recruitmentDescription"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('sections.settings.team.recruitmentPageDescription')}</FormLabel>
                    <FormControl>
                      <Textarea {...field} data-testid="input-recruitment-description" />
                    </FormControl>
                    <FormMessage className="text-xs" variant="error" />
                  </FormItem>
                )}
              />

              <Button type="submit" variant="primary" data-testid="button-save" isLoading={saving}>
                {t('global.save')}
              </Button>
            </form>
          </Form>

          <AllowedDomains
            allowedDomains={allowedDomains}
            userDomain={details?.email?.split('@')[1]}
            onUpdate={updateAllowedDomains}
          />

          {renderDeactivateTeam()}

          {errorDeactivatingTeam ? (
            <Alert variant="destructive">
              <AlertDescription>{errorDeactivatingTeam?.message}</AlertDescription>
            </Alert>
          ) : null}

          {openDeactivateTeamDialog ? (
            <Dialog
              open={openDeactivateTeamDialog}
              onOpenChange={open => {
                if (!open) {
                  setOpenDeactivateTeamDialog(false);
                }
              }}
            >
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>{t('sections.settings.team.deactivateTeamConfirmation')}</DialogTitle>
                </DialogHeader>
                <DialogDescription className="flex gap-2">
                  <TriangleAlert className="h-5 w-5" />
                  {t('sections.settings.team.deactivateTeamDescription')}
                </DialogDescription>
                <DialogFooter>
                  <Button variant="ghost" onClick={() => setOpenDeactivateTeamDialog(false)}>
                    {t('global.cancel')}
                  </Button>
                  <Button variant="primary" onClick={onConfirmDeactivateTeam}>
                    {t('sections.settings.team.deactivateTeam')}
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          ) : null}
        </>
      ) : null}
    </SettingsContainer>
  );
};
