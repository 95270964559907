import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const DragIcon: CreateIconType = createIcon({
  displayName: 'DragIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: [
    <path
      d="M6.89653 2.48275C6.89653 3.39688 6.15548 4.13792 5.24135 4.13792C4.32722 4.13792 3.58618 3.39688 3.58618 2.48275C3.58618 1.56862 4.32722 0.827576 5.24135 0.827576C6.15548 0.827576 6.89653 1.56862 6.89653 2.48275Z"
      fill="currentColor"
    />,
    <path
      d="M6.89653 8.00002C6.89653 8.91415 6.15548 9.65519 5.24135 9.65519C4.32722 9.65519 3.58618 8.91415 3.58618 8.00002C3.58618 7.08589 4.32722 6.34485 5.24135 6.34485C6.15548 6.34485 6.89653 7.08589 6.89653 8.00002Z"
      fill="currentColor"
    />,
    <path
      d="M6.89653 13.5172C6.89653 14.4313 6.15548 15.1724 5.24135 15.1724C4.32722 15.1724 3.58618 14.4313 3.58618 13.5172C3.58618 12.6031 4.32722 11.8621 5.24135 11.8621C6.15548 11.8621 6.89653 12.6031 6.89653 13.5172Z"
      fill="currentColor"
    />,
    <path
      d="M12.4139 2.48275C12.4139 3.39688 11.6728 4.13792 10.7587 4.13792C9.84456 4.13792 9.10352 3.39688 9.10352 2.48275C9.10352 1.56862 9.84456 0.827576 10.7587 0.827576C11.6728 0.827576 12.4139 1.56862 12.4139 2.48275Z"
      fill="currentColor"
    />,
    <path
      d="M12.4139 8.00002C12.4139 8.91415 11.6728 9.65519 10.7587 9.65519C9.84456 9.65519 9.10352 8.91415 9.10352 8.00002C9.10352 7.08589 9.84456 6.34485 10.7587 6.34485C11.6728 6.34485 12.4139 7.08589 12.4139 8.00002Z"
      fill="currentColor"
    />,
    <path
      d="M12.4139 13.5172C12.4139 14.4313 11.6728 15.1724 10.7587 15.1724C9.84456 15.1724 9.10352 14.4313 9.10352 13.5172C9.10352 12.6031 9.84456 11.8621 10.7587 11.8621C11.6728 11.8621 12.4139 12.6031 12.4139 13.5172Z"
      fill="currentColor"
    />,
  ],
});
