/* eslint-disable max-lines */
import { Button } from '@askable/ui/components/ui/button';
import { Label } from '@askable/ui/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@askable/ui/components/ui/select';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { useEffect, useState, Fragment } from 'react';

import PriceCardContainer from 'components/askablePlus/components/priceCardContainer';
import { askablePlusUtils } from 'lib/askablePlus';
import { bookingUtils } from 'lib/booking';
import { utils } from 'lib/utils';

import './styles/sessionDurationStyles.scss';

function SessionDuration(props: any) {
  const askablePlusBrief = _.get(props, 'askablePlusBrief');
  const [askablePlusBriefState, setAskablePlusBriefState] = useState(askablePlusBrief);
  const [sessionDurationValue, setSessionDurationValue] = useState(null);
  const [, setSessionDuration] = useState();
  const arrayOfDaysWeeks = utils.generateNumbersArray(1, 31);
  const [, setFrequencyError] = useState(false);
  const [, setPeriodError] = useState(false);
  const [, setWorkloadError] = useState(false);

  const totalResearchTypesSelected = askablePlusUtils.totalResearchTypesSelected(askablePlusBrief);
  const hasDiscoveryInterviewType = askablePlusUtils.hasDiscoveryInterviewType(askablePlusBrief);
  const hasUsabilityTestingType = askablePlusUtils.hasUsabilityTestingType(askablePlusBrief);
  const hasCompetitiveAnalysisType = askablePlusUtils.hasCompetitiveAnalysisType(askablePlusBrief);
  const hasSurveyType = askablePlusUtils.hasSurveyType(askablePlusBrief);
  const hasLongitudinalStudyType = askablePlusUtils.hasLongitudinalStudyType(askablePlusBrief);

  useEffect(() => {
    if (!_.get(props, 'askablePlusBrief._id')) {
      props.history.push('/askable-plus/create');
    } else if (totalResearchTypesSelected > 1 || hasCompetitiveAnalysisType) {
      props.history.push(`/askable-plus/${askablePlusBrief._id}/discovery-interview/quota`);
    } else {
      props.updateLastStep({
        step: 'Project Setup',
        subStep: `/askable-plus/${askablePlusBrief._id}/project-setup/session-duration`,
        stepId: 'project_setup_session_duration',
      });
    }
  }, []);

  useEffect(() => {
    let sessionValue = _.get(askablePlusBrief, 'askable_plus.research_type.discovery.session.duration');
    if (hasUsabilityTestingType) {
      sessionValue = _.get(askablePlusBrief, 'askable_plus.research_type.usability.session.duration');
    }

    if (hasSurveyType) sessionValue = _.get(askablePlusBrief, 'askable_plus.research_type.survey.session.duration');
    parseSessionDuration(sessionValue);
    props.renderRightContent(rightContent());
  }, [askablePlusBrief, askablePlusBriefState]);

  const rightContent = () => {
    return (
      <Fragment>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          askablePlusBrief={askablePlusBriefState}
          team={_.get(props, 'team')}
          context={props.context}
        />
      </Fragment>
    );
  };

  const parseSessionDuration = (duration: any) => {
    let sessionsDuration = bookingUtils.sessionDurations(2, null);
    if (hasSurveyType) sessionsDuration = bookingUtils.sessionDurations(3, 2);
    const session = utils.getLabelFromArray(sessionsDuration, duration);
    setSessionDuration(session);
    setSessionDurationValue(duration);
  };

  const onChangeValue = (key: any, value: any) => {
    parseSessionDuration(value);
    const askablePlusBriefStateObj = {
      ...askablePlusBriefState,
      askable_plus: {
        ...askablePlusBriefState.askable_plus,
        research_type: {
          ...askablePlusBriefState.askable_plus.research_type,
          [key]: {
            ...askablePlusBriefState.askable_plus.research_type[key],
            session: {
              ...askablePlusBriefState.askable_plus.research_type[key].session,
              duration: value,
              time_limit: key === 'survey' ? 120 : value,
            },
          },
        },
      },
    };
    setAskablePlusBriefState(askablePlusBriefStateObj);
    props.updateAskablePlusBriefState(askablePlusBriefStateObj);
  };

  const onClickNext = () => {
    let redirectTo = `/askable-plus/${askablePlusBrief._id}/audience/participant-criteria`;
    if (hasDiscoveryInterviewType || hasUsabilityTestingType) {
      redirectTo = `/askable-plus/${askablePlusBrief._id}/project-setup/meeting-format`;
    }
    props.history.push({ pathname: redirectTo });
  };

  const onChangeParticipantWorkloadTime = (value: { time: string; measure: string }) => {
    setWorkloadError(false);

    const askablePlusBriefStateObj = {
      ...askablePlusBriefState,
      askable_plus: {
        ...askablePlusBriefState.askable_plus,
        research_type: {
          ...askablePlusBriefState.askable_plus.research_type,
          longitudinal: {
            ...askablePlusBriefState.askable_plus.research_type.longitudinal,
            longitudinal_study: {
              ...askablePlusBriefState.askable_plus.research_type.longitudinal.longitudinal_study,
              participant_workload: {
                ...askablePlusBriefState.askable_plus.research_type.longitudinal.longitudinal_study
                  .participant_workload,
                time: value.time ? parseInt(value.time, 10) : null,
                measure: Number(value.measure) || null,
              },
            },
          },
        },
      },
    };

    setAskablePlusBriefState(askablePlusBriefStateObj);
    props.updateAskablePlusBriefState(askablePlusBriefStateObj);
  };

  const onChangeParticipantWorkloadFrequency = (value: string) => {
    setFrequencyError(false);

    const askablePlusBriefStateObj = {
      ...askablePlusBriefState,
      askable_plus: {
        ...askablePlusBriefState.askable_plus,
        research_type: {
          ...askablePlusBriefState.askable_plus.research_type,
          longitudinal: {
            ...askablePlusBriefState.askable_plus.research_type.longitudinal,
            longitudinal_study: {
              ...askablePlusBriefState.askable_plus.research_type.longitudinal.longitudinal_study,
              participant_workload: {
                ...askablePlusBriefState.askable_plus.research_type.longitudinal.longitudinal_study
                  .participant_workload,
                frequency: Number(value) || null,
              },
            },
          },
        },
      },
    };

    setAskablePlusBriefState(askablePlusBriefStateObj);
    props.updateAskablePlusBriefState(askablePlusBriefStateObj);
  };

  const onChangeBookingPeriod = async ({ value, field }: { value: string; field: string }) => {
    let fieldValue = Number(value);
    if (field === 'frequency') setFrequencyError(false);
    if (field === 'time' && fieldValue) {
      setPeriodError(false);
      fieldValue = parseInt(value, 10);
    }

    const askablePlusBriefStateObj = {
      ...askablePlusBriefState,
      askable_plus: {
        ...askablePlusBriefState.askable_plus,
        research_type: {
          ...askablePlusBriefState.askable_plus.research_type,
          longitudinal: {
            ...askablePlusBriefState.askable_plus.research_type.longitudinal,
            longitudinal_study: {
              ...askablePlusBriefState.askable_plus.research_type.longitudinal.longitudinal_study,
              period: {
                ...askablePlusBriefState.askable_plus.research_type.longitudinal.longitudinal_study.period,
                [field]: fieldValue || null,
              },
            },
          },
        },
      },
    };

    setAskablePlusBriefState(askablePlusBriefStateObj);
    props.updateAskablePlusBriefState(askablePlusBriefStateObj);
  };

  const renderParticipantWorkloadContainer = () => {
    const workloadTime = _.get(
      askablePlusBrief,
      'askable_plus.research_type.longitudinal.longitudinal_study.participant_workload.time',
      '',
    );
    const workloadMeasure = _.get(
      askablePlusBrief,
      'askable_plus.research_type.longitudinal.longitudinal_study.participant_workload.measure',
      '',
    );
    const workloadFrequency = _.get(
      askablePlusBrief,
      'askable_plus.research_type.longitudinal.longitudinal_study.participant_workload.frequency',
    );
    const selectedWorkload = `${workloadTime} ${workloadMeasure}`;

    return (
      <div key="participantWorkloadContainer" className="flex flex-wrap items-center gap-2">
        <Select
          onValueChange={value => {
            return onChangeParticipantWorkloadTime({
              time: value.split(' ')[0],
              measure: value.split(' ')[1],
            });
          }}
          value={selectedWorkload}
        >
          <SelectTrigger id="participantWorkloadInput" className="w-40">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            {bookingUtils.measureTimes().map(value => {
              return (
                <SelectItem key={value.time} value={`${value.time} ${value.measure}`}>
                  {value.label}
                </SelectItem>
              );
            })}
          </SelectContent>
        </Select>

        <span className="text-sm">per</span>

        <Select onValueChange={onChangeParticipantWorkloadFrequency} value={workloadFrequency}>
          <SelectTrigger className="w-40">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            {bookingUtils.frequencyTypes().map(value => {
              return (
                <SelectItem key={value.value} value={value.value as unknown as string}>
                  {value.label}
                </SelectItem>
              );
            })}
          </SelectContent>
        </Select>
      </div>
    );
  };

  const renderParticipantWorkloadError = () => {
    const workloadTime = _.get(
      askablePlusBrief,
      'askable_plus.research_type.longitudinal.longitudinal_study.participant_workload.time',
    );
    if (!workloadTime || workloadTime === 0) {
      return <div className="text-sm text-destructive">This field is required</div>;
    }
    return null;
  };

  const renderBookingPeriodContainer = () => {
    const periodTime = _.get(
      askablePlusBrief,
      'askable_plus.research_type.longitudinal.longitudinal_study.period.time',
    );
    const periodFrequency = _.get(
      askablePlusBrief,
      'askable_plus.research_type.longitudinal.longitudinal_study.period.frequency',
    );
    return (
      <div key="studyDurationContainer" className="flex flex-wrap items-center gap-2">
        <span className="whitespace-nowrap text-sm">To be done over</span>

        <Select onValueChange={(value: any) => onChangeBookingPeriod({ value, field: 'time' })} value={periodTime}>
          <SelectTrigger id="studyDurationInput" className="w-40">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            {arrayOfDaysWeeks.map(value => {
              return (
                <SelectItem key={value} value={value as unknown as string}>
                  {value}
                </SelectItem>
              );
            })}
          </SelectContent>
        </Select>

        <Select
          onValueChange={(value: any) => onChangeBookingPeriod({ value, field: 'frequency' })}
          value={periodFrequency}
        >
          <SelectTrigger className="w-40">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            {bookingUtils.frequencyTypes(true).map(value => {
              return (
                <SelectItem key={value.value} value={value.value as unknown as string}>
                  {value.label}
                </SelectItem>
              );
            })}
          </SelectContent>
        </Select>
      </div>
    );
  };

  const renderBookingPeriodError = () => {
    const periodTime = _.get(
      askablePlusBrief,
      'askable_plus.research_type.longitudinal.longitudinal_study.period.time',
      '',
    );
    if (!periodTime || periodTime === 0) {
      return (
        <div className="periodTimeRequired">
          <span className="smallText">This field is required</span>
        </div>
      );
    }
    return null;
  };

  const renderBookingPeriodFrequencyError = () => {
    const periodFrequency = _.get(
      askablePlusBrief,
      'askable_plus.research_type.longitudinal.longitudinal_study.period.frequency',
    );
    // You cannot select a period frequency lower than the workload frequency
    const workloadFrequency = _.get(
      askablePlusBrief,
      'askable_plus.research_type.longitudinal.longitudinal_study.participant_workload.frequency',
      1,
    );
    if (periodFrequency < workloadFrequency) {
      return <div className="text-sm text-destructive">Period must be greater than workload.</div>;
    }
    return null;
  };

  const renderSessionDuration = () => {
    let sessionValue = _.get(askablePlusBrief, 'askable_plus.research_type.discovery.session.duration');
    if (hasUsabilityTestingType) {
      sessionValue = _.get(askablePlusBrief, 'askable_plus.research_type.usability.session.duration');
    }

    return (
      <Select
        onValueChange={(value: any) => onChangeValue(hasUsabilityTestingType ? 'usability' : 'discovery', value)}
        value={sessionDurationValue || sessionValue}
      >
        <SelectTrigger className="w-40">
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          {bookingUtils.sessionDurations(2, null)?.map(value => {
            return (
              <SelectItem key={value.value} value={value.value as unknown as string}>
                {value.label}
              </SelectItem>
            );
          })}
        </SelectContent>
      </Select>
    );
  };

  const renderSurveyDuration = () => {
    const surveyDurationValue = _.get(askablePlusBrief, 'askable_plus.research_type.survey.session.duration');

    return (
      <Select
        onValueChange={(value: any) => onChangeValue('survey', value)}
        value={sessionDurationValue || surveyDurationValue}
      >
        <SelectTrigger>
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          {bookingUtils.sessionDurations(3, 2)?.map(value => {
            return (
              <SelectItem key={value.value} value={value.value as unknown as string}>
                {value.label}
              </SelectItem>
            );
          })}
        </SelectContent>
      </Select>
    );
  };

  const renderStudyDuration = () => {
    return (
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-1">
          <Label htmlFor="participantWorkloadInput">Participant workload</Label>
          {renderParticipantWorkloadContainer()}
          {renderParticipantWorkloadError()}
        </div>
        <div className="flex flex-col gap-2">
          <Label htmlFor="studyDurationInput">Study period</Label>
          {renderBookingPeriodContainer()}
          {renderBookingPeriodError()}
          {renderBookingPeriodFrequencyError()}
        </div>
      </div>
    );
  };

  return (
    <div className="createAskablePlusBriefContent">
      <h1 className="title">Session duration</h1>

      <div className="flex flex-col gap-8">
        <p className="label">How long should the sessions go for?</p>

        {hasDiscoveryInterviewType || hasUsabilityTestingType ? renderSessionDuration() : null}

        {hasSurveyType ? renderSurveyDuration() : null}

        {hasLongitudinalStudyType ? renderStudyDuration() : null}

        <div>
          <Button variant="primary" size="lg" onClick={onClickNext}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
}

export default deprecatedWithRouter(SessionDuration);
