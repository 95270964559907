import { Volume, Volume1, Volume2 } from 'lucide-react';

import { ControlButton } from './control-button';

interface Props {
  volume: number;
  isDisabled: boolean;
  isMobile: boolean;
  labels: { mute: string; unmute: string; volume: string };
  setVolume: (volume: number) => void;
}

export const ControlVolume = ({ volume, isDisabled, isMobile, labels, setVolume }: Props) => {
  return (
    <div className="group flex items-center gap-2">
      <ControlButton
        isDisabled={isDisabled}
        label={volume === 0 ? labels.unmute : labels.mute}
        onClick={() => setVolume(volume === 0 ? 1 : 0)}
      >
        {volume === 0 ? (
          <Volume className="h-5 w-5" />
        ) : volume < 0.25 ? (
          <Volume1 className="h-5 w-5" />
        ) : (
          <Volume2 className="h-5 w-5" />
        )}
      </ControlButton>

      <label htmlFor="player-volume" className="sr-only">
        {labels.volume}
      </label>
      <input
        id="player-volume"
        type="range"
        min={0}
        max={1}
        step={0.01}
        value={volume}
        style={{
          backgroundImage: `linear-gradient(to right, white ${volume * 100}%, rgba(255,255,255,0.1) ${volume * 100}%)`,
          display: isMobile ? 'none' : undefined,
        }}
        className="hidden h-1 w-20 cursor-pointer appearance-none rounded-full bg-lightest/10 opacity-0 transition-[height,opacity]
          hover:h-1.5 focus:h-1.5 focus:opacity-100 focus:outline-none group-focus-within:opacity-100 group-hover:opacity-100
          sm:block [&::-moz-range-thumb]:h-3 [&::-moz-range-thumb]:w-3 [&::-moz-range-thumb]:rounded-full
          [&::-moz-range-thumb]:border-0 [&::-moz-range-thumb]:bg-lightest [&::-webkit-slider-thumb]:h-3
          [&::-webkit-slider-thumb]:w-3 [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:rounded-full
          [&::-webkit-slider-thumb]:border-0 [&::-webkit-slider-thumb]:bg-lightest [&::-webkit-slider-thumb]:transition-all
          [&:focus::-moz-range-thumb]:scale-125 [&:focus::-webkit-slider-thumb]:scale-125 [&:hover::-moz-range-thumb]:scale-125
          [&:hover::-webkit-slider-thumb]:scale-125"
        onChange={({ target }) => setVolume(Number(target.value))}
      />
    </div>
  );
};
