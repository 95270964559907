/* eslint-disable max-lines */
import { Checkbox } from '@askable/ui/components/ui/checkbox';
import { Input } from '@askable/ui/components/ui/input';
import { Label } from '@askable/ui/components/ui/label';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import { Button, Wrapper, LoadingOverlay, PhoneInput } from 'components/common';
import { useConnectedClient } from 'context/ConnectedClientContext';
import { useFetchTeamByIdQuery, useFetchUserByIdLazyQuery, useUpdateMeMutation } from 'generated/graphql';
import { analytics } from 'lib/analytics';
import { location } from 'lib/location';
import { localStorage } from 'lib/storage';
import { utils } from 'lib/utils';
import { validations, errors } from 'lib/validations';

import bgImage from './assets/company.png';

import type { Teams, User } from 'generated/graphql';

import './styles/ssoCallbackStyles.scss';

function SSOCallback(props: any) {
  const clientDetails = useConnectedClient();

  const [userTeam, setUserTeam] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string>('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [countryCodePhone, setCountryCodePhone] = useState('');
  const [mobilePhone, setMobilePhone] = useState('');
  const [acceptedAggrement, setAcceptedAggrement] = useState(false);
  const [errorTextFirstname, setErrorTextFirstname] = useState<string | null>(null);
  const [errorTextLastname, setErrorTextLastname] = useState<string | null>(null);
  const [errorTextPhoneNumber, setErrorTextPhoneNumber] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const extraArguments = utils.parseQueryParams(_.get(props, 'location.search'));
  const userToken = extraArguments?.get('token');
  const userId = extraArguments?.get('userId');
  const teamId = extraArguments?.get('teamId');

  // Mutations
  const [updateMe] = useUpdateMeMutation();

  // Queries
  const [fetchUser, { data: userByIdResults, loading: userLoading }] = useFetchUserByIdLazyQuery({
    defaultOptions: {
      context: {
        headers: {
          Authorization: userToken,
        },
      },
    },
  });

  const teamByIdResults = useFetchTeamByIdQuery({
    variables: {
      _id: clientDetails.details?.team?.id,
    },
    skip: !clientDetails.details?.team?.id,
  });

  useEffect(() => {
    if (extraArguments) {
      clientDetails.actions.setAccessToken(userToken || '');
      clientDetails.actions.updateClientDetails({
        id: userId,
        team: {
          id: teamId,
        },
      });
      // It should save the access token
      localStorage.save('access_token', userToken);

      fetchUser({
        context: {
          headers: {
            Authorization: userToken,
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    if (userByIdResults?.userByID) {
      setUserEmail(userByIdResults.userByID.email || '');
      updateLoginLocalStorage(userByIdResults.userByID as User);
    }
  }, [userByIdResults]);

  useEffect(() => {
    if (teamByIdResults?.data?.teamById) {
      setUserTeam(teamByIdResults?.data?.teamById?._id!);
      updateTeamLocalStorage(teamByIdResults?.data?.teamById as Teams);
    }
  }, [teamByIdResults.data]);

  const updateLoginLocalStorage = (user: User) => {
    localStorage.save('teamId', user.ConnectedTeam?._id);
    localStorage.save('teamName', user.ConnectedTeam?.name);
    localStorage.save('teamIgnoreTracking', user.ConnectedTeam?.settings?.ignore_tracking || false);
    const clientDetailsToUpdate = {
      id: user?._id,
      email: user.email,
      name: {
        firstname: user.meta?.identity?.firstname,
        lastname: user.meta?.identity?.lastname,
      },
      team: {
        id: user.ConnectedTeam?._id,
        name: user.ConnectedTeam?.name,
      },
      phone: user.contact?.phone?.mobile,
      type: {
        client: user.type?.client,
        researcher: user.type?.researcher,
      },
    };
    clientDetails.actions.updateClientDetails(clientDetailsToUpdate);
    // Update local storage user location
    if (user.location?.name) {
      location.saveLocationFromUserData();
    }

    // Check whether user has all required data
    if (user._id && user.ConnectedTeam?._id && user.meta?.identity?.firstname) {
      analytics.track('signedup', {
        page: window.location.href,
        action: 'Signup with SSO',
        email: userEmail,
      });

      // Redirect the user to the dashboard
      redirectUserToDashboard();
    }
  };

  const updateTeamLocalStorage = (teamData: Teams) => {
    // Save the new team into localstorage
    localStorage.save('teamId', teamData?._id);
    localStorage.save('teamName', teamData?.name);
    localStorage.save('teamIgnoreTracking', teamData?.settings?.ignore_tracking || false);
    const clientDetailsFromLocalStorage = utils.parseJSON(localStorage.get('client_details'));
    const newClientDetails = {
      ...clientDetailsFromLocalStorage,
      team: {
        id: teamData?._id,
        name: teamData?.name,
      },
    };
    localStorage.save('client_details', JSON.stringify(newClientDetails));
    clientDetails.actions.updateClientDetails(newClientDetails);
  };

  const updateUserData = async () => {
    const user = {
      _default_team: teamByIdResults.data?.teamById?._id,
      email: userEmail,
      meta: {
        identity: {
          firstname: firstName,
          lastname: lastName,
        },
      },
      contact: {
        phone: {
          country_code: countryCodePhone,
          mobile: mobilePhone,
        },
      },
    };

    const newClientDetails = {
      ...utils.parseJSON(localStorage.get('client_details')),
      id: userId,
      name: {
        firstname: firstName,
        lastname: lastName,
      },
      team: {
        id: teamByIdResults.data?.teamById?._id,
        name: teamByIdResults.data?.teamById?.name,
      },
      phone: mobilePhone,
    };
    localStorage.save('client_details', JSON.stringify(newClientDetails));
    clientDetails.actions.updateClientDetails(newClientDetails);

    await updateMe({
      variables: {
        user,
      },
    });
  };

  const redirectUserToDashboard = () => {
    // Redirect the user to the dashboard
    setTimeout(() => {
      props.history.push({ pathname: '/', state: { team_id: userTeam } });
    }, 1000);
  };

  const validateFields = () => {
    validations.startValidations();

    // It should only validate the phone field
    if (!validations.validatePhoneNumber(mobilePhone, countryCodePhone)) {
      setErrorTextPhoneNumber(errors.phoneInvalid);
      return false;
    }
    return true;
  };

  const completeRegisterJoinTeam = async (event: any) => {
    if (event) event.preventDefault();

    if (validateFields()) {
      setLoading(true);

      analytics.track('signedup', {
        page: window.location.href,
        action: 'Signup with SSO',
        email: userEmail,
      });
      // Update the user with the rest of the properties
      await updateUserData();

      // Redirect the user to the dashboard
      redirectUserToDashboard();
    }
  };

  const renderFinishUserRegister = () => {
    return (
      <div className="flex flex-col gap-4">
        <h1 className="joinTeamHeader">
          Join the <strong>{teamByIdResults.data?.teamById?.name}</strong> team on Askable
        </h1>

        <div className="flex flex-col gap-1">
          <Label htmlFor="__firstname">First name</Label>
          <Input
            id="__firstname"
            value={firstName}
            onChange={e => {
              setFirstName(e.target.value);
              setErrorTextFirstname(!e.target.value ? errors.nameInvalid : '');
            }}
          />
          {errorTextFirstname ? <div className="text-xs text-destructive">{errorTextFirstname}</div> : null}
        </div>

        <div className="flex flex-col gap-1">
          <Label htmlFor="__firstname">Last name</Label>
          <Input
            id="__lastname"
            value={lastName}
            onChange={e => {
              setLastName(e.target.value);
              setErrorTextLastname(!e.target.value ? errors.nameInvalid : '');
            }}
          />
          {errorTextLastname ? <div className="text-xs text-destructive">{errorTextLastname}</div> : null}
        </div>

        <div className="flex flex-col gap-1">
          <Label htmlFor="__phone">Phone</Label>
          <PhoneInput
            id="__phone"
            countryCode={countryCodePhone || localStorage.get('countryByIp') || ''}
            phoneNumber={mobilePhone || ''}
            onChange={({ phone, country_code }) => {
              setCountryCodePhone(country_code);
              setMobilePhone(phone);
              setErrorTextPhoneNumber('');
            }}
            className="defaultPhoneInput"
            errorTextBottom={errorTextPhoneNumber}
          />
        </div>

        <div className="agreementsContainer">
          <Checkbox
            key="termsCheckbox"
            className="checkboxComponent"
            checked={acceptedAggrement}
            onChange={() => setAcceptedAggrement(!acceptedAggrement)}
          />
          <span onClick={() => setAcceptedAggrement(!acceptedAggrement)}>
            I agree with the{' '}
            <a href="https://www.askable.com/legal?tab=terms" target="_blank" rel="noopener noreferrer">
              Terms and Conditions
            </a>
            *
          </span>
        </div>
        <div className="leftContainerInternalBottom">
          <Button
            label="Join"
            disabled={!(firstName && lastName && acceptedAggrement)}
            onClick={completeRegisterJoinTeam}
          />
        </div>
      </div>
    );
  };

  const renderCallbackScreen = () => {
    if (userLoading || teamByIdResults.loading) {
      return <LoadingOverlay style={{ opacity: 0.8 }} />;
    }

    const userName = userByIdResults?.userByID?.meta?.identity?.firstname;

    if (userByIdResults?.userByID && userToken && userName) {
      return (
        <div>
          <LoadingOverlay style={{ opacity: 0.8 }} />
          <h1>{`Logging on team ${teamByIdResults.data?.teamById?.name}`}</h1>
        </div>
      );
    }

    if (userByIdResults?.userByID && userToken && !userName) {
      return renderFinishUserRegister();
    }
  };

  return (
    <Wrapper
      containerClassName="ssoLoginContainer"
      leftContainerSize={{ maxWidth: 600, paddingBottom: 100 }}
      loading={loading}
      childrenLeft={renderCallbackScreen()}
      bgImage={bgImage}
    />
  );
}

export default deprecatedWithRouter(SSOCallback);
