import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const AlertErrorIcon: CreateIconType = createIcon({
  displayName: 'AlertErrorIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: [
    <circle cx="8" cy="8" r="8" fill="currentColor" />,
    <path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 11v.5M8 4v3" />,
  ],
});
