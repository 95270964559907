import { Button } from '@askable/ui/components/ui/button';
import { Input } from '@askable/ui/components/ui/input';
import { Label } from '@askable/ui/components/ui/label';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { useEffect, useState } from 'react';

function ProjectTitle(props: any) {
  const askablePlusBrief = _.get(props, 'askablePlusBrief');
  const [askablePlusBriefState, setAskablePlusBriefState] = useState(askablePlusBrief);
  const [askablePlusBriefSteps, setAskablePlusBriefSteps] = useState(_.get(props, 'askablePlusBriefSteps'));
  const [projectTitle, setProjectTitle] = useState(_.get(askablePlusBrief, 'name', 'Askable research project'));
  const [projectTitleError, setProjectTitleError] = useState(!_.get(askablePlusBrief, 'name') ? 'Required' : null);

  useEffect(() => {
    if (_.get(props, 'askablePlusBrief._id')) {
      props.updateLastStep({
        step: 'Project Setup',
        subStep: `/askable-plus/${askablePlusBrief._id}/project-setup/project-title`,
        stepId: 'project_setup_project_title',
      });
    }
    props.renderRightContent();
  }, []);

  useEffect(() => {
    setAskablePlusBriefState(_.get(props, 'askablePlusBrief'));
    setProjectTitle(_.get(askablePlusBrief, 'name', 'Askable research project'));
    setProjectTitleError(!_.get(askablePlusBrief, 'name') ? 'Required' : null);
  }, [props.askablePlusBrief]);

  useEffect(() => {
    setAskablePlusBriefSteps(_.get(props, 'askablePlusBriefSteps'));
  }, [props.askablePlusBriefSteps]);

  const onChangeValue = (value: any) => {
    if (_.size(value) === 0) {
      setProjectTitleError('Required');
    } else {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
      setProjectTitleError();
    }
    setProjectTitle(value);

    const askablePlusBriefStateObj = {
      ...askablePlusBriefState,
      name: value,
    };
    setAskablePlusBriefState(askablePlusBriefStateObj);

    if (
      (_.get(askablePlusBriefSteps, 'project_setup_project_title') === 'error' && value) ||
      (_.get(askablePlusBriefSteps, 'project_setup_project_title') === 'seen' && !value)
    ) {
      props.validateAskablePlusBrief(askablePlusBriefStateObj, document.location.pathname, true);
    }

    props.updateAskablePlusBriefState(askablePlusBriefStateObj);
  };

  const onClickNext = () => {
    let redirectTo = '/askable-plus/:projectID/project-setup/project-brief';
    if (_.get(askablePlusBrief, '_id')) {
      redirectTo = `/askable-plus/${askablePlusBrief._id}/project-setup/project-brief`;
    }

    if (!_.get(askablePlusBrief, '_id')) {
      // const projectData = {
      //     ...askablePlusBriefState,
      //     steps: {
      //         project_setup_project_title: 'seen',
      //         project_setup_research_type: 'seen'
      //     }
      // };
      props.createAskablePlusBrief(redirectTo);
    } else {
      props.history.push({ pathname: redirectTo });
    }
  };

  return (
    <div className="createAskablePlusBriefContent">
      <h1 className="title">Give your research project a title</h1>
      <div className="fieldContainer flex flex-col gap-2">
        <Label htmlFor="__projectTitle">This is for internal use only</Label>
        <Input
          id="__projectTitle"
          placeholder="Project title"
          value={projectTitle}
          required
          autoFocus
          onChange={e => {
            onChangeValue(e.target.value);
          }}
          variant={projectTitleError ? 'error' : undefined}
        />
        {projectTitleError ? <div className="text-xs text-destructive">{projectTitleError}</div> : null}
      </div>

      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default deprecatedWithRouter(ProjectTitle);
