/* eslint-disable max-lines */
import uniqueId from 'lodash/uniqueId';
import isEqual from 'react-fast-compare';
import { removeTypeNames } from 'shared-utils';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import { SegmentMethod } from 'generated/graphql';
import { isModeratedBooking } from 'utils/booking-utils';

import { FILTER_CONDITIONS } from '../components/BookingParticipantFilter';
import {
  DEFAULT_SORT,
  getBookingParticipantFilters,
  mapSegmentFiltersToUIFilters,
  mapUIFiltersToSegmentFilters,
  SELECT_CONSTRAINTS,
} from '../utils/booking-participant-utils';

import type { Filter, FilterType, SingleChangeValue, Sort } from '../utils/booking-participant-utils';
import type { OnChangeFn, RowSelectionState, VisibilityState } from '@tanstack/react-table';
import type { Booking, IndustryType, Maybe, Segment, SegmentSortValue, UpdateSegmentMutation } from 'generated/graphql';

const STORAGE_KEYS = {
  SEGMENT_FILTERS: 'segmentFilters',
  PERSISTED_FILTERS: 'persistedFilters',
  PERSISTED_COLUMN_ORDER: 'persistedColumnOrder',
  PERSISTED_COLUMN_VISIBILITY: 'persistedColumnVisibility',
  PERSISTED_SORT: 'persistedSort',
} as const;

export type BookingParticipantBulkPanelName = 'bulk-invite' | 'bulk-message';

type BulkInviteSidePanelState = {
  type: 'bulk';
  panel: BookingParticipantBulkPanelName;
  data: {
    submissionIds: string[];
  };
};

type BulkSidePanel = BulkInviteSidePanelState;

type BasePanel<T> = {
  type: 'single';
  panel: T;
};

export type SegmentFilters = {
  [segmentId: string]: {
    filters: Filter[];
    condition: SingleChangeValue<SegmentMethod>;
  };
};

export type BookingParticipantSinglePanelType =
  | 'message'
  | 'invite'
  | 'report-issue'
  | 'resend-invite'
  | 'cancel'
  | 'reschedule'
  | 'session-joining-links'
  | 'details';

export type BookingDetailsPanelType = 'booking-details';
type ParticipantSinglePanel = BasePanel<BookingParticipantSinglePanelType> & {
  submissionId: string;
};
type BookingSinglePanelType = BasePanel<BookingDetailsPanelType>;

type SingleSidePanelState = BookingSinglePanelType | ParticipantSinglePanel;

const initialiseSegmentFilter = (segment: Segment, filterConfigs: FilterType | null): SegmentFilters[string] => ({
  filters: mapSegmentFiltersToUIFilters(segment.filters ?? [], filterConfigs),
  condition: FILTER_CONDITIONS.find(a => a?.value === segment?.method) ?? FILTER_CONDITIONS[0],
});

const getUpdatedSegmentFilters = (
  segments: Segment[],
  existingFilters: SegmentFilters,
  filterConfigs: FilterType | null,
): SegmentFilters => {
  const updatedFilters = { ...existingFilters };

  // Add or update segment filters
  segments.forEach(segment => {
    if (!updatedFilters[segment._id]) {
      updatedFilters[segment._id] = initialiseSegmentFilter(segment, filterConfigs);
    }
  });

  // Remove filters for segments that no longer exist
  Object.keys(updatedFilters).forEach(id => {
    if (!segments.find(s => s._id === id)) {
      delete updatedFilters[id];
    }
  });

  return updatedFilters;
};

export type BookingParticipantState = {
  filterConfigs: FilterType | null;
  selectedRows: Record<string, boolean>;
  panelState: BulkSidePanel | SingleSidePanelState | null;
  segment: Maybe<Segment>;
  segmentId: Maybe<Segment['_id']>;
  segments: Segment[];
  segmentFilters: SegmentFilters;
  availableSegmentActions: 'save' | 'update' | null;
  /**
   * These are the segmentFilterVersion of Filter.
   * We seperate these out for performance, so we only make requests when this is valid
   */
  segmentInput: Pick<Segment, 'method' | 'filters' | 'tableConfig' | 'sort'>;
  filters: Filter[];
  sort: Sort;
  condition: SingleChangeValue<SegmentMethod>;
  columnVisibility: VisibilityState;
  columnOrder: string[];
  pagination: {
    currentPage: number;
  };
  isBookingModerated: boolean;
  persistedFilters: Filter[];
  persistedSort: Sort;
  persistedColumnVisibility: VisibilityState;
  persistedColumnOrder: string[];
};

function genNewFilter(inc?: number): Filter {
  return {
    id: inc ? (Number(uniqueId()) + inc).toString() : uniqueId(),
    field: {
      value: 'status',
      label: 'Status',
    },
    value: null,
    constraint: SELECT_CONSTRAINTS[0],
    fieldType: 'multi_select',
    config: {
      constraintOptions: SELECT_CONSTRAINTS,
    },
  };
}

function mapTableConfig(tc: Segment['tableConfig']): Pick<BookingParticipantState, 'columnVisibility' | 'columnOrder'> {
  return (tc?.columns ?? []).reduce<Pick<BookingParticipantState, 'columnVisibility' | 'columnOrder'>>(
    (acc, curr) => {
      acc.columnOrder.push(curr?.column!);

      acc.columnVisibility[curr?.column!] = curr?.visible ?? true;
      return acc;
    },
    {
      columnOrder: [],
      columnVisibility: {},
    },
  );
}

function mapConfigToTableConfig(
  cv: Pick<BookingParticipantState, 'columnVisibility' | 'columnOrder'>,
): Segment['tableConfig'] {
  return {
    columns: cv.columnOrder.map(c => {
      return {
        column: c,
        visible: cv.columnVisibility[c] ?? true,
      };
    }),
  };
}

export type BookingParticipantActions = {
  resetSegment: () => void;
  addFilter: (segmentId: string) => void;
  setSelectedRows: OnChangeFn<RowSelectionState>;
  /**
   * Call this after a bulk action has been successfuly performed
   * this reset the selected rows and closes the sidepanel
   */
  bulkActionComplete: () => void;
  removeFilter: (segmentId: string, filterId: string) => void;
  addSegment: (segment: Segment) => void;
  updateColumnOrder: (s: string[]) => void;
  updateColumnVisibility: (visibility: VisibilityState) => void;
  setPanelState: (
    newPanel:
      | BookingParticipantState['panelState']
      | ((prev: BookingParticipantState['panelState']) => BulkInviteSidePanelState | SingleSidePanelState | null),
  ) => void;
  deleteSegment: (segmentId: string) => void;
  updateSegment: (segment: UpdateSegmentMutation['updateSegment']) => void;
  updateSegmentName: (newName: string) => void;
  updateFilter: (segmentId: string, filter: Filter) => void;
  updateCondition: (segmentId: string, newCondition: SingleChangeValue<SegmentMethod>) => void;
  clearFilters: (segmentId: string) => void;
  updateSort: (sort: SegmentSortValue[]) => void;
  setSegments: (segments: Segment[], segmentId: string, booking: Booking, industryList: IndustryType[]) => void;
  setPaginationCurrentPage: (direction: 'forward' | 'back') => void;
  changeSegment: (segmentId: string) => void;

  forceUpdateSegment: () => void;
  clearPersistedActions: (type?: 'filters' | 'tableConfig' | 'sort') => void;

  reset: () => void;
};

export type BookingParticipantStateAndActions = BookingParticipantState & BookingParticipantActions;

const DEFAULT_STATE: BookingParticipantState = {
  segments: [],
  segmentFilters: {},
  filterConfigs: null,
  filters: [],
  columnOrder: [],
  columnVisibility: {},
  selectedRows: {},
  sort: [],
  segmentInput: {
    filters: [],
    method: FILTER_CONDITIONS[0]?.value!,
    sort: [],
    tableConfig: {
      columns: [],
    },
  },
  segment: null,
  segmentId: null,
  pagination: {
    currentPage: 1,
  },
  panelState: null,
  availableSegmentActions: null,
  condition: FILTER_CONDITIONS[0],
  isBookingModerated: false,
  persistedFilters: [],
  persistedColumnOrder: [],
  persistedColumnVisibility: {},
  persistedSort: [],
};

type Args = {
  filters: Filter<string>[];
  originalSegment: Segment;
  sort: Segment['sort'];
  tableConfig: Segment['tableConfig'];
  isBookingModerated: boolean;
};

function updateStatus({ filters, originalSegment, sort, tableConfig, isBookingModerated }: Args) {
  // Once the order of columns is changed it'll always be considered different
  // It already exists this way, it's not caused by this ticket (ASK-2851)
  const defaultSort = isBookingModerated ? DEFAULT_SORT.moderated : DEFAULT_SORT.unmoderated;
  const prevSort = originalSegment._id !== 'all' ? originalSegment?.sort : defaultSort;

  // Only map filters if necessary
  if (isEqual(tableConfig, originalSegment?.tableConfig) && isEqual(sort, prevSort)) {
    const fullFilters = filters.filter(a => (a?.value ?? []).length >= 1);
    const prevFilters =
      originalSegment?._id !== 'all' ? originalSegment?.filters?.filter(a => (a?.values ?? []).length >= 1) : [];

    const mappedFilters = mapUIFiltersToSegmentFilters(fullFilters);
    if (isEqual(mappedFilters, prevFilters)) {
      return null;
    }
  }

  return originalSegment._id === 'all' ? 'save' : 'update';
}

export const useBookingParticipantState = create<BookingParticipantStateAndActions>()(
  persist(
    devtools(set => {
      return {
        ...DEFAULT_STATE,
        setSelectedRows: a => {
          set(
            state => {
              const selectedRows = typeof a === 'function' ? a(state.selectedRows) : a;

              return {
                ...state,
                selectedRows,
              };
            },
            false,
            'rows/set-selected',
          );
        },
        updateColumnOrder: newOrder => {
          set(
            state => {
              const tableConfig = mapConfigToTableConfig({
                columnOrder: newOrder,
                columnVisibility: state.columnVisibility,
              });

              return {
                ...state,
                columnOrder: newOrder,
                segmentInput: {
                  ...state.segmentInput,
                  tableConfig,
                },
                availableSegmentActions: updateStatus({
                  filters: state.filters,
                  originalSegment: state.segment!,
                  sort: state.sort,
                  tableConfig,
                  isBookingModerated: state.isBookingModerated,
                }),
                ...(state.segmentId === 'all' && { persistedColumnOrder: newOrder }),
              };
            },
            false,
            'table-config/column-order',
          );
        },
        updateColumnVisibility: visibility => {
          set(
            state => {
              const columnOrder = state.columnOrder.length >= 1 ? state.columnOrder : Object.keys(visibility);
              const mapped = mapConfigToTableConfig({ columnOrder, columnVisibility: visibility });

              const availableSegmentActions = updateStatus({
                filters: state.filters,
                originalSegment: state.segment!,
                sort: state.sort,
                tableConfig: mapped,
                isBookingModerated: state.isBookingModerated,
              });

              return {
                ...state,
                segment: {
                  ...state.segment!,
                  tableConfig:
                    state.columnOrder.length >= 1
                      ? state.segment?.tableConfig
                      : mapConfigToTableConfig({ columnOrder, columnVisibility: {} }),
                },
                columnOrder,
                columnVisibility: visibility,
                segmentInput: {
                  ...state.segmentInput,
                  tableConfig: mapped,
                },
                availableSegmentActions,
                ...(state.segmentId === 'all' && { persistedColumnVisibility: visibility }),
              };
            },
            false,
            'table-config/column-visibility',
          );
        },
        bulkActionComplete: () => {
          set(
            state => {
              return {
                ...state,
                selectedRows: {},
                panelState: null,
              };
            },
            false,
            'side-panel/bulk-action-complete',
          );
        },
        setPaginationCurrentPage: direction => {
          set(
            state => {
              return {
                ...state,
                selectedRows: {},
                pagination: {
                  ...state.pagination,
                  currentPage:
                    direction === 'forward' ? state.pagination.currentPage + 1 : state.pagination.currentPage - 1,
                },
              };
            },
            false,
            'pagination/direction',
          );
        },
        setPanelState: newPanel => {
          set(
            state => {
              return {
                ...state,
                panelState: typeof newPanel === 'function' ? newPanel(state.panelState) : newPanel,
              };
            },
            false,
            'side-panel/set-panel',
          );
        },
        deleteSegment: segmentId => {
          set(
            state => {
              return {
                ...state,
                segment: state.segments[0],
                segmentId: state.segments[0]._id,
                segments: state.segments.filter(seg => seg._id !== segmentId),
                availableSegmentActions: null,
                segmentInput: DEFAULT_STATE.segmentInput,
              };
            },
            false,
            'segment/delete-segment',
          );
        },
        updateSegmentName: newName => {
          set(
            state => {
              // Not likely to happen just do this to satisfy the types
              if (!state.segment?.name) {
                return state;
              }

              return {
                ...state,
                segment: {
                  ...state.segment,
                  name: newName,
                },
                segments: state.segments.map(a => {
                  if (a._id === state.segment?._id) {
                    return {
                      ...a,
                      name: newName,
                    };
                  }

                  return a;
                }),
              };
            },
            false,
            'segment/update-segment-name',
          );
        },
        updateSegment: updatedSegment => {
          set(
            state => {
              return {
                ...state,
                availableSegmentActions: null,
                segments: state.segments.map(segment => {
                  if (segment._id === updatedSegment?._id) {
                    return {
                      ...state.segment!,
                      method: state.condition?.value!,
                      sort: updatedSegment.sort,
                      tableConfig: updatedSegment.tableConfig,
                      filters: mapUIFiltersToSegmentFilters(state.filters),
                    };
                  }

                  return segment;
                }),
              };
            },
            false,
            'segment/update-segment',
          );
        },
        reset: () => {
          set(DEFAULT_STATE);
        },
        resetSegment: () => {
          set(
            state => {
              const currentSegment = state.segments.find(a => a._id === state.segment?._id);

              const mappedFilters = mapSegmentFiltersToUIFilters(currentSegment?.filters, state.filterConfigs);

              return {
                ...state,
                segment: currentSegment ?? null,
                filters: mappedFilters,
                ...mapTableConfig(currentSegment?.tableConfig),
                segmentInput: {
                  filters: currentSegment?.filters,
                  method: currentSegment?.method,
                  tableConfig: currentSegment?.tableConfig,
                },
                condition: FILTER_CONDITIONS.find(a => a?.value === currentSegment?.method) ?? FILTER_CONDITIONS[0],
                availableSegmentActions: null,
              };
            },
            false,
            'filter/reset',
          );
        },
        addFilter: (segmentId: string) => {
          set(
            state => {
              const currentFilters = state.segmentFilters[segmentId]?.filters || [];
              const newFilter = genNewFilter(
                currentFilters.length ? Number(currentFilters[currentFilters.length - 1].id) : undefined,
              );

              return {
                ...state,
                segmentFilters: {
                  ...state.segmentFilters,
                  [segmentId]: {
                    filters: [...currentFilters, newFilter],
                    condition: state.segmentFilters[segmentId]?.condition || FILTER_CONDITIONS[0],
                  },
                },
              };
            },
            true,
            'filter/add',
          );
        },
        addSegment: segment => {
          set(
            state => {
              return {
                ...state,
                segment,
                segmentId: segment._id,
                segments: [...state.segments, removeTypeNames(segment)],
                availableSegmentActions: null,
              };
            },
            false,
            'filter/add-segment',
          );
        },
        setSegments: (segments, segmentId, booking, industryList) => {
          set(
            state => {
              const isBookingModerated = isModeratedBooking(booking.type);
              const defaultSort = isBookingModerated ? DEFAULT_SORT.moderated : DEFAULT_SORT.unmoderated;
              const filterConfigs = getBookingParticipantFilters(booking, industryList);

              const updatedSegments: Segment[] = [
                {
                  _id: 'all',
                  filters: [],
                  sort: defaultSort,
                  name: 'All participants',
                  method: SegmentMethod.And,
                  tableConfig: mapConfigToTableConfig({
                    columnOrder: state.persistedColumnOrder,
                    columnVisibility: state.persistedColumnVisibility,
                  }),
                },
                ...removeTypeNames(segments),
              ];

              const selectedSegment = updatedSegments.find(a => a._id === segmentId);
              if (!selectedSegment) return state; // Early return if no valid segment

              const segmentFilters = getUpdatedSegmentFilters(updatedSegments, state.segmentFilters, filterConfigs);

              const currentFilters = segmentFilters[segmentId];
              const mappedFilters = currentFilters?.filters ?? [];
              const tableConfig = mapTableConfig(selectedSegment.tableConfig);

              return {
                ...state,
                filterConfigs,
                segmentId,
                segments: updatedSegments,
                segment: selectedSegment,
                segmentFilters,
                filters: mappedFilters,
                condition: currentFilters?.condition ?? FILTER_CONDITIONS[0],
                sort: selectedSegment.sort as SegmentSortValue[],
                ...tableConfig,
                segmentInput: {
                  filters: mapUIFiltersToSegmentFilters(mappedFilters),
                  method: currentFilters?.condition?.value ?? selectedSegment.method ?? state.segmentInput.method,
                  tableConfig: removeTypeNames(selectedSegment.tableConfig ?? {}),
                  sort: selectedSegment.sort as SegmentSortValue[],
                },
                isBookingModerated,
              };
            },
            false,
            'filters/set-segments',
          );
        },
        changeSegment: segmentId => {
          set(
            state => {
              const segment = state.segments.find(seg => seg._id === segmentId)!;

              // Get or initialize the segment's filters
              if (!state.segmentFilters[segmentId]) {
                state.segmentFilters[segmentId] = {
                  filters: mapSegmentFiltersToUIFilters(segment.filters, state.filterConfigs),
                  condition: FILTER_CONDITIONS.find(a => a?.value === segment?.method) ?? FILTER_CONDITIONS[0],
                };
              }

              const currentSegmentFilters = state.segmentFilters[segmentId];

              return {
                ...state,
                segment,
                segmentId,
                filters: currentSegmentFilters.filters,
                condition: currentSegmentFilters.condition,
                segmentInput: {
                  filters: mapUIFiltersToSegmentFilters(currentSegmentFilters.filters),
                  method: currentSegmentFilters.condition?.value ?? state.segmentInput.method,
                  sort: segment.sort as SegmentSortValue[],
                  tableConfig: segment.tableConfig,
                },
                ...mapTableConfig(segment.tableConfig),
                sort: segment.sort as SegmentSortValue[],
                availableSegmentActions: null,
                selectedRows: {},
                pagination: {
                  currentPage: 1,
                },
              };
            },
            false,
            'booking-participant/change-segment',
          );
        },
        updateCondition: (segmentId: string, newCondition: SingleChangeValue<SegmentMethod>) => {
          set(
            state => {
              return {
                ...state,
                segmentFilters: {
                  ...state.segmentFilters,
                  [segmentId]: {
                    ...state.segmentFilters[segmentId],
                    condition: newCondition,
                  },
                },
                availableSegmentActions: segmentId === 'all' ? 'save' : 'update',
                segmentInput: {
                  ...state.segmentInput,
                  method: newCondition?.value,
                },
              };
            },
            false,
            'filters/update-condition',
          );
        },
        updateFilter: (segmentId: string, filter: Filter) => {
          set(
            state => {
              const currentFilters = state.segmentFilters[segmentId]?.filters ?? [];
              const updatedFilters = currentFilters.map(f => (filter.id === f.id ? filter : f));
              const validFilters = updatedFilters.filter(f => !!f.value?.length);

              const segmentFilters = {
                ...state.segmentFilters,
                [segmentId]: {
                  ...state.segmentFilters[segmentId],
                  filters: updatedFilters,
                },
              };

              const availableSegmentActions = updateStatus({
                filters: validFilters,
                originalSegment: state.segment!,
                sort: state.sort,
                tableConfig: mapConfigToTableConfig({
                  columnOrder: state.columnOrder,
                  columnVisibility: state.columnVisibility,
                }),
                isBookingModerated: state.isBookingModerated,
              });

              return {
                ...state,
                segmentFilters,
                filters: validFilters,
                availableSegmentActions,
                pagination: { currentPage: 1 },
                segmentInput: {
                  ...state.segmentInput,
                  filters: mapUIFiltersToSegmentFilters(validFilters),
                },
              };
            },
            false,
            'filters/update-filter',
          );
        },
        getSegmentFilters: (segmentId: string) => {
          return (state: BookingParticipantState) => {
            return (
              state.segmentFilters[segmentId] || {
                filters: [],
                condition: FILTER_CONDITIONS[0],
              }
            );
          };
        },
        forceUpdateSegment: () => {
          set(state => {
            const segmentId = state.segmentId;
            if (segmentId && segmentId !== 'all') {
              return state;
            }

            const existingFilters = state.filters.filter(f => !!f.value?.length);
            const persistedFilters = state.persistedFilters.filter(f => !!f.value?.length);
            const persistedColumnOrder = state.persistedColumnOrder;
            const persistedColumnVisibility = state.persistedColumnVisibility;

            const updatedSegments = state.segments.map(segment => {
              if (segment._id === 'all') {
                segment.filters = mapUIFiltersToSegmentFilters(existingFilters);
                if (persistedFilters.length > existingFilters.length) {
                  segment.filters = mapUIFiltersToSegmentFilters(persistedFilters);
                }
                segment.tableConfig = mapConfigToTableConfig({
                  columnOrder: persistedColumnOrder,
                  columnVisibility: persistedColumnVisibility,
                });
              }

              return segment;
            });

            const [segment] = updatedSegments.filter(s => s._id === segmentId);

            return {
              ...state,
              segments: updatedSegments,
              segmentInput: {
                filters: segment.filters,
                method: segment.method,
                sort: segment.sort,
              },
            };
          });
        },
        removeFilter: (segmentId: string, filterId: string) => {
          set(
            state => {
              const currentFilters = state.segmentFilters[segmentId]?.filters || [];
              const updatedFilters = currentFilters.filter(f => f.id !== filterId);
              const segmentFilters = updatedFilters.filter(f => (f.value?.length ?? 0) >= 1);
              const mappedSegmentFilters = mapUIFiltersToSegmentFilters(segmentFilters);

              return {
                ...state,
                segmentFilters: {
                  ...state.segmentFilters,
                  [segmentId]: {
                    ...state.segmentFilters[segmentId],
                    filters: updatedFilters,
                  },
                },
                availableSegmentActions: updateStatus({
                  filters: updatedFilters,
                  tableConfig: mapConfigToTableConfig({
                    columnOrder: state.columnOrder,
                    columnVisibility: state.columnVisibility,
                  }),
                  sort: state.sort,
                  originalSegment: state.segment!,
                  isBookingModerated: state.isBookingModerated,
                }),
                segmentInput: {
                  ...state.segmentInput,
                  filters: mappedSegmentFilters,
                },
                ...(segmentId === 'all' && { persistedFilters: updatedFilters }),
              };
            },
            false,
            'filters/remove',
          );
        },
        clearFilters: (segmentId: string) => {
          set(
            state => {
              return {
                ...state,
                segmentFilters: {
                  ...state.segmentFilters,
                  [segmentId]: {
                    ...state.segmentFilters[segmentId],
                    filters: [],
                  },
                },
                availableSegmentActions: updateStatus({
                  filters: [],
                  tableConfig: mapConfigToTableConfig({
                    columnOrder: state.columnOrder,
                    columnVisibility: state.columnVisibility,
                  }),
                  sort: state.sort,
                  originalSegment: state.segment!,
                  isBookingModerated: state.isBookingModerated,
                }),
                segmentInput: {
                  ...state.segmentInput,
                  filters: [],
                },
                ...(segmentId === 'all' && { persistedFilters: [] }),
              };
            },
            false,
            'filters/clear',
          );
        },
        updateSort: sort => {
          set(
            state => {
              return {
                ...state,
                sort,
                availableSegmentActions: updateStatus({
                  filters: state.filters,
                  tableConfig: mapConfigToTableConfig({
                    columnOrder: state.columnOrder,
                    columnVisibility: state.columnVisibility,
                  }),
                  sort,
                  originalSegment: state.segment!,
                  isBookingModerated: state.isBookingModerated,
                }),
                segmentInput: {
                  ...state.segmentInput,
                  sort,
                },
                ...(state.segmentId === 'all' && { persistedSort: sort }),
              };
            },
            false,
            'sort/update',
          );
        },
        clearPersistedActions: type => {
          set(state => {
            const clearFilters = type === 'filters';
            const clearSort = type === 'sort';
            const clearTableConfig = type === 'tableConfig';
            const clearAll = type === undefined;

            const defaultTableConfig = {
              columns: [],
            };

            const defaultSort = state.isBookingModerated ? DEFAULT_SORT.moderated : DEFAULT_SORT.unmoderated;

            const segments = state.segments.slice(1);
            const updatedSegments: Segment[] = [
              {
                _id: 'all',
                filters: clearFilters || clearAll ? [] : mapUIFiltersToSegmentFilters(state.filters),
                sort: clearSort || clearAll ? defaultSort : state.sort,
                name: 'All participants',
                method: SegmentMethod.And,
                tableConfig:
                  clearTableConfig || clearAll
                    ? defaultTableConfig
                    : mapConfigToTableConfig({
                        columnOrder: state.columnOrder,
                        columnVisibility: state.columnVisibility,
                      }),
              },
              ...removeTypeNames(segments),
            ];

            return {
              ...state,
              persistedFilters: clearFilters || clearAll ? DEFAULT_STATE.persistedFilters : state.persistedFilters,
              persistedColumnOrder:
                clearTableConfig || clearAll ? DEFAULT_STATE.persistedColumnOrder : state.persistedColumnOrder,
              persistedColumnVisibility:
                clearTableConfig || clearAll
                  ? DEFAULT_STATE.persistedColumnVisibility
                  : state.persistedColumnVisibility,
              persistedSort: clearSort || clearAll ? defaultSort : state.persistedSort,
              segments: updatedSegments,
            };
          });
        },
      };
    }),
    {
      name: 'booking-participant-state',
      storage: createJSONStorage(() => sessionStorage),
      partialize: state => ({
        [STORAGE_KEYS.SEGMENT_FILTERS]: state.segmentFilters,
        [STORAGE_KEYS.PERSISTED_FILTERS]: state.persistedFilters,
        [STORAGE_KEYS.PERSISTED_COLUMN_ORDER]: state.persistedColumnOrder,
        [STORAGE_KEYS.PERSISTED_COLUMN_VISIBILITY]: state.persistedColumnVisibility,
        [STORAGE_KEYS.PERSISTED_SORT]: state.persistedSort,
      }),
      migrate: (persistedState: any) => {
        // If the user has old state with filters but no segmentFilters, then migrate them
        if ('persistedFilters' in persistedState && !('segmentFilters' in persistedState)) {
          return {
            ...persistedState,
            [STORAGE_KEYS.SEGMENT_FILTERS]: {
              all: {
                filters: persistedState.persistedFilters,
                condition: FILTER_CONDITIONS[0],
              },
            },
          };
        }
        return persistedState;
      },
    },
  ),
);

/**
 * Selectors
 * We create selector function outside of the components for a performance boost
 */
export const bookingParticipantSelectors = {
  availableSegmentActions: (state: BookingParticipantStateAndActions) => state.availableSegmentActions,
  filters: (state: BookingParticipantStateAndActions) => state.filters,
  condition: (state: BookingParticipantStateAndActions) => state.condition,
  sort: (state: BookingParticipantStateAndActions) => state.sort,
  segment: (state: BookingParticipantStateAndActions) => state.segment,
  segmentId: (state: BookingParticipantStateAndActions) => state.segment?._id,
  allSegments: (state: BookingParticipantStateAndActions) => state.segments,
  filterCount: (state: BookingParticipantStateAndActions) => {
    return state.segmentInput.filters?.length ?? 0;
  },
  sidePanelState: (state: BookingParticipantStateAndActions) => state.panelState,
  setPanelState: (state: BookingParticipantStateAndActions) => state.setPanelState,
  segmentInput: (state: BookingParticipantStateAndActions) => {
    const { filters, method, tableConfig, sort } = state.segmentInput;
    return { filters, method, tableConfig, sort };
  },
  paginationCurrentPage: (state: BookingParticipantStateAndActions) => {
    return [state.pagination.currentPage, state.setPaginationCurrentPage] as const;
  },
  bulkSidePanelState: (state: BookingParticipantStateAndActions) => {
    if (state.panelState?.type === 'single') {
      return null;
    }

    return state.panelState;
  },
  singleSidePanelState: (state: BookingParticipantStateAndActions) => {
    // This just adds type safety
    if (state.panelState?.type === 'bulk') {
      return null;
    }

    return state.panelState;
  },
  selectedRowState: (state: BookingParticipantStateAndActions) => {
    return [state.selectedRows, state.setSelectedRows] as const;
  },
  completeBulkAction: (state: BookingParticipantStateAndActions) => state.bulkActionComplete,
  updateFilter: (state: BookingParticipantStateAndActions) => state.updateFilter,
  updateSort: (state: BookingParticipantStateAndActions) => state.updateSort,
  updateColumnOrder: (state: BookingParticipantStateAndActions) => state.updateColumnOrder,
  updateColumnVisibility: (state: BookingParticipantStateAndActions) => state.updateColumnVisibility,
  setSegments: (state: BookingParticipantStateAndActions) => state.setSegments,
  columnConfig: (state: BookingParticipantStateAndActions) => ({
    order: [...state.columnOrder],
    visibility: { ...state.columnVisibility },
  }),
  persistedFilters: (state: BookingParticipantStateAndActions) => state.persistedFilters,
  persistedColumnOrder: (state: BookingParticipantStateAndActions) => state.persistedColumnOrder,
  persistedColumnVisibility: (state: BookingParticipantStateAndActions) => state.persistedColumnVisibility,
  persistedSort: (state: BookingParticipantStateAndActions) => state.persistedSort,
  currentSegmentFilters: (state: BookingParticipantStateAndActions) => {
    const segmentId = state.segment?._id;
    const filters = segmentId ? state.segmentFilters[segmentId] : null;
    if (!filters) {
      return null;
    }

    return {
      filters: filters.filters,
      condition: filters.condition,
    };
  },
  allSegmentFilters: (state: BookingParticipantStateAndActions) => state.segmentFilters,
};
