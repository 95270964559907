import { Button } from '@askable/ui/components/ui/button';
import { Table, TableBody, TableHead, TableHeader, TableRow } from '@askable/ui/components/ui/table';
import { useTranslation } from 'react-i18next';

import { Section } from 'containers/Studies/Results/components/Section';

import { ResponseTableRow } from './ResponseTableRow';

import type { StudyTaskBlockResultPartial } from '../../data/studyTaskBlockResults.query';

interface IndividualResponsesProps {
  results: StudyTaskBlockResultPartial[];
  totalCount: number;
  loadMore?: () => void;
}

export const IndividualResponses = ({ results, totalCount, loadMore }: IndividualResponsesProps) => {
  const { t } = useTranslation();

  return (
    <Section
      description={t('sections.studies.results.individualResponsesDescription')}
      id="individual-responses"
      title={t('sections.studies.results.individualResponsesTitle')}
    >
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>{t('sections.studies.results.participants', { count: 1 })}</TableHead>
            <TableHead>{t('sections.studies.results.outcome')}</TableHead>
            <TableHead className="text-end">{t('sections.studies.results.duration')}</TableHead>
            <TableHead>{t('sections.studies.results.completionDate')}</TableHead>
          </TableRow>
        </TableHeader>

        <TableBody>
          {results.map(result => (
            <ResponseTableRow key={result._id} result={result} />
          ))}
        </TableBody>
      </Table>

      {loadMore && results.length < totalCount ? (
        <Button variant="ghost" className="w-full" onClick={loadMore}>
          {t('global.plusMore', { count: 10 })}
        </Button>
      ) : null}
    </Section>
  );
};
