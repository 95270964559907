import { Cog, Explore, Target } from '@askable/ui/components/unmod/icons';
import { useTranslation } from 'react-i18next';

import { useStudyContext } from 'containers/Studies/StudiesContainer';

import { SectionAudience } from '../components/SectionAudience';
import { SectionContainer } from '../components/SectionContainer';
import { SectionOpportunityListing } from '../components/SectionOpportunityListing';
import { SectionParticipants } from '../components/SectionParticipants';
import { SectionScreenerQuestions } from '../components/SectionScreenerQuestions';
import { SectionSettings } from '../components/SectionSettings';

export const RecruitForm = () => {
  const { t } = useTranslation();
  const { study } = useStudyContext();

  // TODO: disable when study is not in draft anymore (status != 0)
  const isDisabled = study.status === 7; // 7 = Archived

  return (
    <div className="flex flex-col gap-12">
      <SectionContainer id="section-participants">
        <SectionParticipants isDisabled={isDisabled} />
      </SectionContainer>

      <SectionContainer
        id="section-audience"
        title={t('sections.studies.recruit.audience')}
        icon={<Target size={28} />}
      >
        <SectionAudience isDisabled={isDisabled} />
      </SectionContainer>

      <SectionContainer id="section-screener-questions">
        <SectionScreenerQuestions isDisabled={isDisabled} />
      </SectionContainer>

      <SectionContainer id="section-settings" title={t('sections.studies.recruit.settings')} icon={<Cog size={28} />}>
        <SectionSettings isDisabled={isDisabled} />
      </SectionContainer>

      <SectionContainer
        id="section-opportunity-listing"
        title={t('sections.studies.recruit.opportunityListing')}
        icon={<Explore size={28} />}
      >
        <SectionOpportunityListing isDisabled={isDisabled} />
      </SectionContainer>
    </div>
  );
};
