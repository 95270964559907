import { Slider, Rail, Handles, Tracks } from 'react-compound-slider';

import { SliderRail, Handle, Track } from './components';

function SliderComponent(props: any) {
  const sliderStyle = {
    position: 'relative',
    width: '100%',
    touchAction: 'none',
  };
  const domain = [props.min, props.max];
  const defaultValues = [props.startValue];

  return (
    <Slider
      mode={1}
      step={1}
      domain={domain}
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ position: string; width: string; touchActi... Remove this comment to see the full error message
      rootStyle={sliderStyle}
      onUpdate={value => props.onUpdateValue(value)}
      values={defaultValues}
    >
      <Rail>
        {({ getRailProps }) => {
          return <SliderRail getRailProps={getRailProps} railCustomStyles={props.railCustomStyles} />;
        }}
      </Rail>
      <Handles>
        {({ handles, getHandleProps }) => (
          <div className="slider-handles">
            {handles.map(handle => {
              return <Handle key={handle.id} handle={handle} domain={domain} getHandleProps={getHandleProps} />;
            })}
          </div>
        )}
      </Handles>
      <Tracks right={false}>
        {({ tracks, getTrackProps }) => (
          <div className="slider-tracks">
            {tracks.map(({ id, source, target }) => (
              <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
            ))}
          </div>
        )}
      </Tracks>
    </Slider>
  );
}

export default SliderComponent;
