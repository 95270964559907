import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const InProgressIcon: CreateIconType = createIcon({
  displayName: 'InProgressIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
    color: 'green.500',
  },
  path: (
    <path
      d="M0.0153765 8.5001C-0.00162309 8.22448 0.223858 8.00001 0.5 8.00001H8.05371V0.503482C8.05371 0.226055 8.28021 5.9989e-05 8.55699 0.0190941C12.7154 0.305076 16 3.76896 16 8.00001C16 12.4183 12.4183 16 8 16C3.74968 16 0.273519 12.6854 0.0153765 8.5001Z"
      fill="currentColor"
    />
  ),
});
