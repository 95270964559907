import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const LightingIcon: CreateIconType = createIcon({
  displayName: 'LightingIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 24 24',
  },
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.846 2.562a.337.337 0 00-.259.182c-1.342 2.654-1.901 6.364-2.055 9.308a.523.523 0 00.15.388.532.532 0 00.39.161h3.471a1.125 1.125 0 011.075 1.457c-.758 2.46-1.258 4.3-1.468 6.385 5.103-4.058 8.625-8.408 10.182-11.095l.007-.013a.257.257 0 00-.176-.384l-.026-.005c-.966-.196-2.027-.307-3.428-.358a1.125 1.125 0 01-.992-1.57L15.362 3.2a.506.506 0 00-.002-.438.355.355 0 00-.286-.193 28.847 28.847 0 00-3.746-.24h-.007c-1.495-.008-2.99.07-4.475.233zM11.33.08a40.865 40.865 0 00-4.738.247h-.008a2.587 2.587 0 00-2.004 1.4C3.023 4.806 2.443 8.9 2.284 11.941v.01a2.773 2.773 0 00.786 2.058 2.782 2.782 0 002.027.841H7.03c-.784 2.698-1.243 4.923-1.243 7.943a1.125 1.125 0 001.784.911c6.355-4.596 10.785-9.913 12.704-13.224a2.507 2.507 0 00-1.703-3.743 19.196 19.196 0 00-2.154-.302l1.009-2.342c.681-1.577-.194-3.509-2.058-3.755a31.073 31.073 0 00-4.038-.26z"
      fill="currentColor"
    />,
  ],
});
