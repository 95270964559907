import { AnchorButton } from '@askable/ui/components/ui/button';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { ExternalLink, User, Users } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { useConnectedClient } from 'context/ConnectedClientContext';
import { teamUtils } from 'lib/teams';

type NavItem = {
  label: string;
  icon: JSX.Element;
  links: {
    href: string;
    label: string;
  }[];
};

export const Sidebar = () => {
  const { details } = useConnectedClient();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const isAdmin = teamUtils.isAdminByRoleId(details?.teamRole);
  const kinde = useKindeAuth();

  const manageSSO = kinde?.getPermission?.('manage:sso') ?? { isGranted: isAdmin };

  const navItems: NavItem[] = [
    {
      icon: <User className="h-4 w-4" />,
      label: t('sections.settings.account.title'),
      links: [
        {
          href: '/settings/account',
          label: t('sections.settings.profile.title'),
        },
        {
          href: '/settings/notifications',
          label: t('sections.settings.notifications.title'),
        },
      ],
    },
  ];

  // Hide team settings for researchers
  if (!details?.type?.researcher) {
    const links = [
      {
        href: '/settings/members',
        label: t('sections.settings.team.members'),
      },
      {
        href: '/settings/billing',
        label: t('sections.settings.billing.title'),
      },
      {
        href: '/settings/usage',
        label: t('sections.settings.usage.title'),
      },
      {
        href: '/settings/invoices',
        label: t('sections.settings.invoices.title'),
      },
      {
        href: '/settings/resources',
        label: t('sections.settings.resources.title'),
      },
      ...(manageSSO.isGranted
        ? [
            {
              href: '/settings/sso',
              label: t('sections.settings.team.enterpriseSso.title'),
            },
          ]
        : []),
      ...(isAdmin
        ? [
            {
              href: '/settings/research',
              label: t('sections.settings.team.research.title'),
            },
          ]
        : []),
    ];
    // Only show general team settings for admins
    if (isAdmin) {
      links.splice(0, 0, {
        href: '/settings/team',
        label: t('sections.settings.team.general'),
      });
    }

    navItems.push({
      icon: <Users className="h-4 w-4" />,
      label: t('sections.settings.team.title'),
      links,
    });
  }

  return (
    <aside
      className="flex w-full max-w-[250px] flex-col justify-between gap-4 p-2 text-sm md:sticky md:top-0 md:border-r md:border-border
        md:p-4"
    >
      <nav>
        <ul className="flex flex-col gap-2 whitespace-nowrap font-semibold">
          {navItems.map(section => (
            <li key={section.label}>
              <div className="flex gap-2 p-2 text-xs uppercase tracking-wide text-muted-foreground">
                {section.icon}
                {section.label}
              </div>

              {section.links ? (
                <ul className="flex gap-1 overflow-auto md:flex-col md:pl-5">
                  {section.links.map(link => (
                    <li key={link.label}>
                      <AnchorButton width="full" variant={link.href === pathname ? 'default' : 'ghost'} asChild>
                        <Link to={link.href ?? ''}>{link.label}</Link>
                      </AnchorButton>
                    </li>
                  ))}
                </ul>
              ) : null}
            </li>
          ))}
        </ul>
      </nav>

      <nav>
        <ul className="flex whitespace-nowrap md:flex-col">
          <li>
            <AnchorButton
              className="justify-between"
              href="https://www.askable.com/legal?tab=terms"
              rel="noopener noreferrer"
              target="_blank"
              variant="ghost"
              width="full"
            >
              {t('sections.settings.legal')} <ExternalLink className="h-4 w-4" />
            </AnchorButton>
          </li>
          <li className="px-3 py-1 text-sm text-muted-foreground">{`Version ${import.meta.env.VITE_APP_VERSION}`}</li>
        </ul>
      </nav>
    </aside>
  );
};
