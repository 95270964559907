import { getColorValue } from '@askable/ui/lib/utils';

import type { IconProps } from '.';

export const Palmtree = ({ bgColor = 'foreground', size = 48 }: IconProps) => {
  const bgHsl = getColorValue(bgColor);

  return (
    <svg fill="none" viewBox="0 0 64 64" width={size} height={size}>
      <path
        fill={bgHsl}
        d="M51 56.5c.9-.6 1.3-1.8 1.1-3-.1-1-.6-1.8-1-2.4a19.3 19.3 0 0 0-6.9-6.2 22 22 0 0 0-8.5-22 1 1 0 0 0-1.4.3 1 1 0 0 0-.1 1l.3.4A20 20 0 0 1 42 45.8c-1 .7-4.3 3-7.6.9l.5-3.1 1.5.2c2.1 0 4.4-.9 5.2-1.5.5-.3.6-1 .2-1.4a1 1 0 0 0-1.3-.2c-1 .6-4.1 1.5-5.4.8v-2.7h.2c2 0 4.8-1.6 5.6-2.5.4-.4.4-1 0-1.4a1 1 0 0 0-1.4 0c-.9.9-3.5 2-4.4 1.8 0-1.4-.3-2.8-.5-4.3 1 0 2.3-.6 3.2-1.2.5-.3.6-1 .3-1.4a1 1 0 0 0-1.3-.3c-1.3.8-2.4 1-2.7.9-.5-1.8-1.1-3.5-2-5.1a1 1 0 0 0-1.4-.4 1 1 0 0 0-.4 1.4c2.8 4.7 3 10.9 2.8 15.2-1.6-.3-3.2 0-4.6.2-5 1-10 3.2-14.3 6.2a13 13 0 0 0-4.6 4.7c-.9 1.8-.8 3.2-.5 4 .2 1 .8 1.7 1.5 2a5 5 0 0 0 4.1 0l1.9-.8 1.3-.6c.7-.3 1.8-.6 2.6-.3s1.4 1 2 1.9c.6.7 1.2 1.5 2 2a6 6 0 0 0 3.5 1h1c1.6-.2 3-.5 4.2-1 7.2-3.2 10.6-3 12.9-2.9 1.8.2 3.2.2 5-1.4Z"
      />
      <path
        fill={bgHsl}
        d="M12.8 23.9h.2l5.8-1.2-2.2 2.1a12 12 0 0 0-3 7.5c0 1.9.4 3.4 1.4 4.4.2.1.4.3.7.3.4 0 .7-.2.8-.5a26 26 0 0 1 5.8-5.6 9 9 0 0 0-.5 2.5c-.1 2.7 1.1 5 3.6 7.1.3.3.6.4.9.4l.6-.2.4-.5 2-4.7 1.8-4.4c.3-.5 0-1.1-.4-1.4a1 1 0 0 0-1.3.4l-2 4.7-1.4 3.6a6.5 6.5 0 0 1-2.2-4.8c0-2.7 1.8-5 2.3-5.4 1.4-.8 2.5-1.4 3-1.5.6-.1 1-.6.8-1.2a1 1 0 0 0-1.1-.8 36 36 0 0 0-13 9.5c-.2-.6-.2-1.3-.2-1.8 0-2.3 1-4.5 2.5-6.2 1.6-1.8 3.7-3.8 6.8-4 2.3 0 3.1.6 3.1.6.4.4 1 .3 1.4-.1.4-.5.3-1.1-.1-1.4-.2-.2-1.3-1-4.4-1.2-1.4 0-3.1.1-5.4.5l-5.4 1c.3-1 1-2.1 1.8-3 1-1.1 2.6-2 4.4-2.6h.1c2.4-.8 5.8-1.9 10.3 4.6l.1.1.2.2h.1l.2.1h.6l.1-.1.2-.2.1-.1.1-.2v-.1l.5-2a9.8 9.8 0 0 1 3.3-5.7c1.7-1.5 3.9-2.3 6.1-2.5 1.2 0 2.2 0 3 .4.6.3 1.2.7 1.5 1.2l-.5.4c-3.5 2.4-9.4 6.4-11.6 8.5a1 1 0 0 0 .7 1.7c.3 0 .5 0 .7-.2l2-1.7c6-.9 10.2.9 12 5.2.5 1 .3 2.3 0 3.1a45.8 45.8 0 0 0-9-3.2 1 1 0 0 0-1.2.8c-.1.5.2 1 .8 1.2a42.3 42.3 0 0 1 10 3.6c.3 0 .7-.2.9-.5 0-.2 1.5-3.2.4-5.8-1.1-2.6-4-6.6-11-6.6l6.4-4.5 1.3-.8c.4-.3.5-.8.4-1.2a5.3 5.3 0 0 0-3-3 8.4 8.4 0 0 0-3.9-.6c-2.6.2-5 1.1-7 2.7.3-2 .4-4.7.4-8a1 1 0 0 0-.3-.7 1 1 0 0 0-.9-.2c-3.4.8-6.4 3.3-7.6 6.6-.6 1.5-.6 3.6-.2 5.6-2.8-1.3-5.2-.5-6.7 0h-.2c-2 .7-3.9 1.7-5.2 3.1a9.1 9.1 0 0 0-2.7 5.6c0 .4.1.7.4.9l.6.2Zm16.3-8.1c-.9-2.3-1.1-5-.5-6.6a8.5 8.5 0 0 1 5-5c-.1 4.2-.3 7.2-.7 9.1-1 1.4-1.6 2.9-1.9 4.4l-1.9-2Z"
      />
    </svg>
  );
};
