import { getColorValue } from '@askable/ui/lib/utils';

import type { IconProps } from '.';

export const Heart = ({ bgColor = 'foreground', fgColor = 'background', size = 48 }: IconProps) => {
  const bgHsl = getColorValue(bgColor);
  const fgHsl = getColorValue(fgColor);

  return (
    <svg fill="none" viewBox="0 0 64 64" width={size} height={size}>
      <path
        fill={bgHsl}
        d="M50.3 41.8c3.9-2.7 10-7.7 10.5-13.3.4-3.7-.9-7-3-10.6a14 14 0 0 0-9-6.3c-4.3-1-7.4 0-11.4 3-3.3-4.7-6-7.7-10.3-8.8a25 25 0 0 0-14.3 2.8c-3.2 2-6.6 5.7-7 9.3-.4 5.7 20 33.5 21 39.1l5.6-2.8 17.9-12.4Z"
      />
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="M19.4 5.1a21 21 0 0 1 7.8-.3C31.6 6 34.5 9 37.6 13.1c3.7-2.5 7-3.6 11.3-2.5 3.7 1 7.4 2.9 9.7 6.8 2.2 3.7 3.6 7.4 3.2 11.3-.3 3.1-2 6-4.2 8.3a41 41 0 0 1-6.7 5.7l-18 12.4-5.6 2.8a1 1 0 0 1-1 0 2 2 0 0 1-.5-.9c0-.5-.4-1.4-1-2.7l-2.6-4.5c-1.5-2.7-3.4-5.7-5.4-8.9L15 38a230.5 230.5 0 0 1-9.3-16.7 8.7 8.7 0 0 1-.8-3.6c.2-2 1.2-4 2.6-5.7a17 17 0 0 1 4.7-4.2c1.5-1 4.3-2 7.2-2.6Zm.6 2a19.2 19.2 0 0 0-6.6 2.4c-1.5 1-3.1 2.3-4.4 3.8a9.4 9.4 0 0 0-2.2 4.8c0 .5.2 1.3.7 2.6l2.1 4.5c1.9 3.5 4.4 7.6 7 11.8l1.8 3a448.5 448.5 0 0 1 8 13.6l1 2 4.4-2.3 17.8-12.4c2-1.3 4.5-3.2 6.5-5.4a13 13 0 0 0 3.8-7.3c.3-3.3-.8-6.4-2.9-9.9-1.9-3.2-5-4.9-8.3-5.7-3.9-1-6.8 0-10.6 2.8-.5.3-1 .1-1.4-.4-3.3-4.5-5.8-7.2-9.7-8.2a19 19 0 0 0-7 .4Z"
        clipRule="evenodd"
      />
      <path
        fill={fgHsl}
        d="M42.7 45.2c4-2.7 10.1-7.7 10.6-13.3.3-3.7-1-7-3-10.6a14 14 0 0 0-9-6.3c-4.3-1-7.5 0-11.4 3-3.3-4.7-6-7.7-10.3-8.8a12 12 0 0 0-9.8 2.2 12 12 0 0 0-5 8.6c-.6 5.7 3 11 6.3 15.4L26.4 57l16.3-11.7Z"
      />
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="M9.2 10.6a13 13 0 0 1 10.6-2.3c4.4 1 7.3 4 10.3 8.2 1.7-1.1 3.4-2 5-2.4 2-.6 4.1-.6 6.4 0a15 15 0 0 1 9.6 6.7c2.2 3.6 3.5 7.2 3.2 11.1-.3 3.2-2.1 6-4.3 8.4a41.1 41.1 0 0 1-6.7 5.7L27 57.7a1 1 0 0 1-1.4-.2L10.3 36c-3.3-4.4-7-10-6.5-16a13 13 0 0 1 5.4-9.3Zm10.1-.4a11 11 0 0 0-9 2 11 11 0 0 0-4.6 7.9c-.4 5.2 2.9 10.2 6.2 14.7l14.8 20.7 15.4-11c2-1.4 4.4-3.3 6.5-5.5a13 13 0 0 0 3.7-7.2c.3-3.3-.8-6.5-2.9-10-2-3.3-5-5-8.4-5.8a10 10 0 0 0-5.3 0 17 17 0 0 0-5.2 2.7 1 1 0 0 1-1.4-.2c-3.3-4.6-6-7.3-9.8-8.3Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
