import { LoadingOverlay } from 'components/common';

import './styles/WrapperStyles.scss';

export default function Wrapper(props: any) {
  const {
    id,
    containerClassName,
    internalClassName,
    leftContainerClassName = '',
    rightContainerClassName = '',
    childrenLeft,
    childrenRight,
    leftContainerSize,
    loading,
  } = props;

  return (
    <div id={`${id && id}`} className={`wrapperContainer ${containerClassName}`}>
      {loading && <LoadingOverlay style={{ opacity: 0.8, width: leftContainerSize.maxWidth }} />}
      <div className={`wrapperContainerLeft ${leftContainerClassName}`} style={leftContainerSize}>
        <div className={`wrapperInternalContainer ${internalClassName}`}>{childrenLeft}</div>
      </div>
      <div
        className={`wrapperContainerRight ${rightContainerClassName}`}
        style={{ backgroundImage: `url(${props.bgImage})` }}
      >
        {childrenRight}
      </div>
    </div>
  );
}
