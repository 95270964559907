import classnames from 'classnames';
import { CircleCheck } from 'lucide-react';
import { Button, Flex, Box } from 'ui';

import type React from 'react';
import type { FC } from 'react';
import type { ButtonProps, LinkProps } from 'ui';

import './card.scss';

type Props = {
  label?: string | React.ReactElement;
  rightButton?: ButtonProps & LinkProps & { label?: string };
  className?: string;
  onClickCard?: () => any;
  [key: string]: any;
};

const HorizontalNarrowCard: FC<Props> = ({
  label,
  rightButton,
  className: defaultClassName = '',
  selected = false,
  disabled = false,
  onClickCard,
  ...props
}) => {
  const classNames = classnames(
    'cardComponent horizontal narrow',
    {
      selected: !!selected,
      disabled: !!disabled,
    },
    defaultClassName,
  );

  const icon = (() => {
    if (!props.icon) return null;
    if (selected) {
      return props.selectedIcon || <CircleCheck className="h-4 w-4" />;
    }
    return props.icon;
  })();

  const renderRightButton = () => {
    if (!rightButton) return null;

    const defaultButtonProps = {
      variant: 'ghost',
      colorScheme: 'blue',
      size: 'sm',
      my: '-2',
      mr: '-1',
    };

    const { label: buttonLabel, onClick, as: buttonAs, ...componentButtonProps } = rightButton;

    const buttonProps: any = {
      ...defaultButtonProps,
      ...componentButtonProps,
    };

    buttonProps.onClick = (event: any) => {
      if (onClick) {
        onClick(event);
      }
      if (event.stopPropagation) {
        event.stopPropagation();
      }
    };
    return (
      <Button as={buttonAs ?? (buttonProps.href ? 'a' : undefined)} {...buttonProps}>
        {buttonLabel}
      </Button>
    );
  };

  return (
    <Flex
      direction="row"
      alignItems="center"
      className={classNames}
      role="button"
      onClick={() => {
        if (disabled) return;
        if (onClickCard) {
          onClickCard();
        }
      }}
      py="0"
      px="4"
      mb="4"
      {...props}
    >
      {icon && (
        <Box fontSize="md" className="narrowCardIcon" mr="3">
          {icon}
        </Box>
      )}
      {label && (
        <Box className="narrowCardLabel" fontSize="md" py="4" lineHeight="short" flexGrow="1" _notLast={{ mr: '2' }}>
          {label}
        </Box>
      )}
      {rightButton && (
        <Box ml="auto" pb="0.5">
          {renderRightButton()}
        </Box>
      )}
    </Flex>
  );
};

export default HorizontalNarrowCard;
