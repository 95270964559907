import _ from 'lodash';
import momentTimezone from 'moment-timezone';

import type { BookingConfig, Maybe, SessionsType } from 'generated/graphql';

const bookingSubmissionUtils = {
  getAnswersForDynamicQuestions(submissionData: any, questionData: any) {
    const answers = submissionData.filter((item: any) => item._question_id === questionData._id);

    return answers.map((item: any) => {
      if (_.get(item, 'value')) {
        return { label: item.value };
      }
      if (_.get(item, '_answer_id')) {
        // Finds the label of the question
        const options = questionData.options.find((value: any) => value._id === item._answer_id);
        return _.pickBy(options, (item2: any, key: any) => key === 'label' || key === 'screen_in');
      }
      return '';
    });
  },
};
const isSessionCompleted = (session: Maybe<SessionsType>) => {
  return session?.status === 1 && session.cancel === 0;
};
const isSessionConfirmed = (session?: Maybe<SessionsType>) => {
  return session?.status === 1 && session.cancel === 0;
};

const isSessionInProgress = (session: Maybe<SessionsType>, bookingConfig?: Maybe<Pick<BookingConfig, 'timezone'>>) => {
  return (
    (session?.status === 5 && session.cancel === 0) ||
    (session?.status === 1 &&
      session.cancel === 0 &&
      momentTimezone(session.session?.start)
        .tz(bookingConfig?.timezone || '')
        .isBefore() &&
      momentTimezone(session.session?.end)
        .tz(bookingConfig?.timezone || '')
        .isAfter())
  );
};

const findConfirmedSession = (sessions: Maybe<Maybe<SessionsType>[]> | undefined) => {
  return sessions?.find(session => isSessionConfirmed(session));
};

export { bookingSubmissionUtils, isSessionCompleted, isSessionConfirmed, findConfirmedSession, isSessionInProgress };
