import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const HeadingTwoIcon: CreateIconType = createIcon({
  displayName: 'HeadingTwoIcon',
  defaultProps: {
    ...defaultOptions,
  },
  path: [
    <path
      id="Vector 4"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.2926 12.4988H10.5942V11.8855C10.5942 11.4567 10.8322 11.0685 11.2005 10.8966L12.702 10.1959C13.0608 10.0285 13.2926 9.65044 13.2926 9.23274C13.2926 8.6521 12.8513 8.1814 12.307 8.1814H11.6061C11.1655 8.1814 10.7907 8.48175 10.6518 8.90096"
    />,
    <path
      id="Vector 1"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M0.70752 11.9961V1.50122M7.7041 1.50122V11.9961M0.70752 6.31137H7.7041"
    />,
  ],
});
