import classnames from 'classnames';

import type { ReactNode } from 'react';

import './card.scss';

export type CardProps = {
  id?: string;
  onClickCard?: (selected: any) => void;
  image?: string;
  imageAlt?: string;
  title?: ReactNode;
  content?: { label: ReactNode }[];
  labelClass?: string;
  subtitle?: ReactNode;
  vertical?: boolean;
  small?: boolean;
  selected?: boolean;
  disabled?: boolean;
  className?: string;
};
const Card = (props: CardProps) => {
  const className = classnames(
    'cardComponent',
    {
      vertical: props.vertical,
      horizontal: !props.vertical,
      small: props.small,
      selected: props.selected,
      disabled: props.disabled,
    },
    props.className,
  );

  return (
    <div id={props.id} className={className} onClick={() => props.onClickCard && props.onClickCard(props.selected)}>
      {props.image && <img className="illustration" src={props.image} alt={props.imageAlt ?? 'Type illustration'} />}
      {(props.title || props.content) && (
        <div className="content">
          <h2>{props.title}</h2>
          {props.subtitle && props.subtitle}
          {props.content?.map((item: any) => (
            <p key={item.label} className={`${props.labelClass ? props.labelClass : ''}`}>
              {item.label}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default Card;
