import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  PARTICIPANT_SELECTION_OPTIONS,
  PARTICIPANTS_EXCLUDED_OPTIONS,
} from 'containers/Studies/data/schemas/recruitSchema';

import { CustomTerms } from './CustomTerms';
import { RadioGroupField } from './Fields/RadioGroupField';
import { SelectField } from './Fields/SelectField';
import { SwitchField } from './Fields/SwitchField';
import { PrimaryContact } from './PrimaryContact';

export const SectionSettings = ({ isDisabled }: { isDisabled?: boolean }) => {
  const { t } = useTranslation();
  const { watch } = useFormContext();

  const showCustomTerms = watch('terms_custom');

  const participantSelectionOptions = PARTICIPANT_SELECTION_OPTIONS.map(option => ({
    label: t(`sections.studies.recruit.options.${option}`),
    value: option,
  }));

  const participantsExcludedOptions = PARTICIPANTS_EXCLUDED_OPTIONS.map(option => ({
    label: t(`sections.studies.recruit.options.${option}`),
    value: option,
  }));

  return (
    <>
      <RadioGroupField
        name="participant_selection"
        label={t('sections.studies.recruit.fields.participantSelectionTitle')}
        options={participantSelectionOptions}
        infoPopover={{
          title: t('sections.studies.recruit.fields.participantSelectionCalloutTitle'),
          description: t('sections.studies.recruit.fields.participantSelectionCalloutDescription'),
        }}
        variant="inline"
        isDisabled={isDisabled}
      />

      <hr className="my-1 border-t-0.5 border-secondary p-0" />

      <SelectField
        name="participants_excluded"
        label={t('sections.studies.recruit.fields.participantsExcludedTitle')}
        description={t('sections.studies.recruit.fields.participantsExcludedDescription')}
        options={participantsExcludedOptions}
        variant="inline"
        isDisabled={isDisabled}
      />

      <hr className="my-1 border-t-0.5 border-secondary p-0" />

      <PrimaryContact isDisabled={isDisabled} />

      <hr className="my-1 border-t-0.5 border-secondary p-0" />

      <SwitchField
        name="terms_custom"
        label={t('sections.studies.recruit.fields.customTermsTitle')}
        description={t('sections.studies.recruit.fields.customTermsDescription')}
        size="lg"
        isDisabled={isDisabled}
      />

      {showCustomTerms ? <CustomTerms /> : null}
    </>
  );
};
