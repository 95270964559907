import { Container } from 'ui';

import type { FC, PropsWithChildren } from 'react';
import type { ContainerProps } from 'ui';

export const AskablePlusProjectContainer: FC<PropsWithChildren<ContainerProps>> = ({ children, ...props }) => {
  return (
    <Container maxW="full" w="full" p={[6, 6, 10]} {...props}>
      {children}
    </Container>
  );
};
