import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

import type config from '../tailwind.config.js';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

const MOBILE_BREAKPOINT = 640;

// Convert Tailwind color to HSL value
type Colors = typeof config.theme extends { colors: infer C } ? C : never;
type ColorKeys<T> = T extends object
  ? keyof T extends string
    ? keyof T | `${keyof T}-${ColorKeys<T[keyof T]>}`
    : never
  : never;

export type ColorValue = ColorKeys<Colors>;

export const getColorValue = (color: string): string => {
  if (color.includes('-')) {
    const [base, variant] = color.split('-');
    return `hsl(var(--${base}-${variant}))`;
  }

  return `hsl(var(--${color}))`;
};

// Check if the item should be a mobile view based on width
// Shared between Clients and Sessions
export const isMobileByWidth = (width?: number | null): boolean => {
  return width ? width <= MOBILE_BREAKPOINT : false;
};

// Format seconds to 00:00 eg. 350 -> 05:50
// If format is 'labels', return 5m 50s
export const secondsToTime = (value: number, format?: 'default' | 'labels'): string => {
  const seconds = Math.round(value);
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const secondsString = remainingSeconds.toString().padStart(2, '0');

  if (format === 'labels') {
    return minutes > 0 ? `${minutes}m ${secondsString}s` : `${secondsString}s`;
  }

  return `${minutes}:${secondsString}`;
};
