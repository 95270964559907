import { graphql } from 'src/graphql';

import type { ResultOf } from 'gql.tada';

export const EsignatureTemplate = graphql(`
  query EsignatureTemplate($id: ID!) {
    getBookingESignatureTemplates(_booking_id: $id) {
      _id
      global
      active
      _team_id
      _organisation_id
      provider {
        name
        reference
      }
      name
      description
      recipients
      created
      updated
    }
  }
`);

export type EsignatureTemplateType = NonNullable<
  NonNullable<ResultOf<typeof EsignatureTemplate>>['getBookingESignatureTemplates']
>;
export type EsignatureTemplateItemType = NonNullable<EsignatureTemplateType[number]>;
