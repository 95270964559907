import _ from 'lodash';
import { useState } from 'react';

import { Dialog, ButtonDefault } from 'components/common';
import countryCodeData from 'lib/data/phoneCountryCodes.json';
import { update } from 'lib/utils';
import './styles/CountrySelectStyles.scss';

type Props = {
  bookingCountry: {
    name: string;
    region: string;
  };
  openCountrySelect: boolean;
  closeCountrySelect: () => void;
  onChange: (country: (typeof countryCodeData)[number]) => void;
};
function CountrySelect(props: Props) {
  const [openSelectList, setOpenSelectList] = useState(false);
  const [searchValue, setSearchValue] = useState(props.bookingCountry.name);
  const [searchRegion, setSearchRegion] = useState(props.bookingCountry.region);
  const [countryList, setCountryList] = useState(countryCodeData);

  const handleSelectList = () => {
    setOpenSelectList(!openSelectList);
  };

  const updateCountryList = (search: string) => {
    setSearchValue(search);
    let newCountryList: typeof countryList = [];
    if (search && search !== '') {
      newCountryList = update(countryList, {
        $set: _.filter(countryCodeData, country => country.name.toLowerCase().includes(search.toLowerCase())),
      });
    } else {
      newCountryList = update(countryList, {
        $set: newCountryList,
      });
    }

    setCountryList(newCountryList);
    setSearchRegion(_.get(newCountryList, '[0].region', ''));
    props.onChange(newCountryList[0]);
  };

  return (
    <Dialog
      open={props.openCountrySelect}
      modal={false}
      onRequestClose={props.closeCountrySelect}
      styles={{ width: '300px' }}
    >
      <div className="modalChangeCountry">
        <p>Change Country</p>
        <div
          id="__countrySelectContainer"
          className={`countrySelectContainer ${openSelectList ? 'active' : ''}`}
          onClick={handleSelectList}
        >
          <div className="inputArrow">
            {searchRegion && (
              <img
                className="countryFlagImg"
                src={`https://askable-misc-public.s3-ap-southeast-2.amazonaws.com/country_flags/${searchRegion.toLowerCase()}.svg`}
                alt={`Flag for ${searchRegion}`}
                height="15"
                width="20"
              />
            )}
            <input
              className="searchInput"
              placeholder="Select Country"
              value={searchValue}
              onChange={item => updateCountryList(item.target.value)}
              onClick={() => {
                setSearchValue('');
                setSearchRegion('');
              }}
            />
            <div className="arrowDown">
              <i className={`arrow ${openSelectList ? 'up' : 'down'}`} />
            </div>
          </div>
          <div className={`countrySelectOptions ${openSelectList ? 'visible' : 'hidden'}`}>
            {_.map(countryList, (country: any) => {
              return (
                <div
                  id={country.region}
                  className="countrySelectItems"
                  key={country.region}
                  onClick={() => {
                    setSearchValue(country.name);
                    setSearchRegion(country.region);
                    props.onChange(country);
                  }}
                >
                  <img
                    className="countryFlagImg"
                    src={`https://askable-misc-public.s3-ap-southeast-2.amazonaws.com/country_flags/${country.region.toLowerCase()}.svg`}
                    alt={`Flag for ${country.region}`}
                    height="15"
                    width="20"
                  />
                  {`${country.name}`}
                </div>
              );
            })}
          </div>
        </div>
        <div className="buttonSaveCountry">
          <ButtonDefault className="buttonSave" label="Save" onClick={props.closeCountrySelect} />
        </div>
      </div>
    </Dialog>
  );
}

export default CountrySelect;
