import jwt_decode from 'jwt-decode';

import type { Maybe, User, UserRoles } from 'generated/graphql';

type DecodedToken = {
  roles: UserRoles[];
  user_id: string;
  scopes?: User['type'];
  _default_team_id?: string;
};

export function decodeToken(t?: Maybe<string>): Maybe<DecodedToken> {
  if (!t) {
    return null;
  }

  try {
    return jwt_decode<DecodedToken>(t);
  } catch {
    console.error('Error decoding token');

    return null;
  }
}
