import { AnchorButton } from '@askable/ui/components/ui/button';
import { Eye } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { useActiveBlockId } from 'containers/Studies/BuildStudy/hooks/useActiveBlockId';
import { useStudyContext } from 'containers/Studies/StudiesContainer';

import type { ComponentPropsWithoutRef } from 'react';

interface PreviewLinkProps extends ComponentPropsWithoutRef<'a'> {
  asChild?: boolean;
}

export const PreviewLinkButton = () => {
  const { t } = useTranslation();
  const { study } = useStudyContext();
  const { activeBlockId } = useActiveBlockId();

  if (!activeBlockId) {
    return null;
  }

  return (
    <AnchorButton
      className="hidden lg:flex"
      href={`${window.location.origin}/studies/${study._id}/preview?task_id=${activeBlockId}`}
      rel="noopener noreferrer"
      target="_blank"
      variant="outline"
    >
      {t('sections.studies.preview')}
    </AnchorButton>
  );
};

export const PreviewLink = ({ ...props }: PreviewLinkProps) => {
  const { t } = useTranslation();
  const { study } = useStudyContext();
  const { activeBlockId } = useActiveBlockId();

  if (!activeBlockId) {
    return null;
  }

  return (
    <a
      href={`${window.location.origin}/studies/${study._id}/preview?task_id=${activeBlockId}`}
      target="_blank"
      rel="noreferrer"
      {...props}
    >
      <Eye className="h-4 w-4" /> {t('sections.studies.preview')}
    </a>
  );
};
