import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const ContactSupportIcon: CreateIconType = createIcon({
  displayName: 'ContactSupportIcon',
  defaultProps: defaultOptions,
  path: [
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6.13044 11.4362C5.47012 11.4329 4.81753 11.3046 4.20646 11.0593L1.44472 11.5215C1.07294 11.5837 0.768643 11.2278 0.887861 10.8702L1.58811 8.76982C1.17866 8.04811 0.943489 7.23789 0.903983 6.40466C0.858726 5.45011 1.07195 4.50111 1.52095 3.65872C1.96994 2.81633 2.6379 2.1121 3.45366 1.62105C4.26943 1.12999 5.20246 0.870492 6.15338 0.870194C6.94454 0.869725 7.72561 1.04872 8.43835 1.39384C9.1511 1.73896 9.77714 2.2413 10.2698 2.86341C10.7625 3.48553 11.1091 4.21137 11.2837 4.98683C11.3366 5.22162 11.3733 5.45913 11.3939 5.69768"
    />,
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M11.2535 5.60161C10.6417 5.23331 9.94191 5.03869 9.22872 5.03847C8.63534 5.03812 8.04955 5.17236 7.51499 5.4312C6.98043 5.69004 6.5109 6.0668 6.14139 6.53338C5.77189 6.99997 5.51195 7.54435 5.38095 8.12595C5.24994 8.70755 5.25125 9.31136 5.38478 9.89238C5.51831 10.4734 5.78061 11.0166 6.15213 11.4816C6.52366 11.9466 6.99482 12.3212 7.5305 12.5777C8.06617 12.8342 8.65255 12.9659 9.24592 12.963C9.74116 12.9605 10.2306 12.8643 10.6889 12.6803L12.7602 13.0269C13.039 13.0736 13.2673 12.8067 13.1779 12.5385L12.6527 10.9632C12.9598 10.4219 13.1361 9.81424 13.1658 9.18931C13.1997 8.47341 13.0398 7.76165 12.703 7.12986C12.3663 6.49807 11.8653 5.9699 11.2535 5.60161Z"
    />,
  ],
});
