import { Archive, CircleDashed, CirclePause, Circle, CircleEllipsis } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import type { Booking } from 'generated/graphql';

const intToEnum = [
  'draft',
  'active',
  'pending_review',
  'in_review',
  'rejected',
  'completed',
  'pending_payment',
  'archived',
] as const;

const statusTypes = {
  draft: { icon: CircleDashed, tone: 'text-muted-foreground' },
  in_review: { icon: CircleEllipsis, tone: 'text-warning' },
  pending_review: { icon: CircleEllipsis, tone: 'text-warning' },
  pending_payment: { icon: CirclePause, tone: 'text-warning' },
  active: { icon: Circle, tone: 'text-success' },
  paused: { icon: CirclePause, tone: 'text-warning' },
  archived: { icon: Archive, tone: 'text-muted-foreground' },
  completed: { icon: Archive, tone: 'text-muted-foreground' },
  rejected: { icon: CirclePause, tone: 'text-error' },
} as const;

type Props = {
  study: Booking;
};

export const StudyStatusIndicator = ({ study }: Props) => {
  const { t } = useTranslation();

  const status = study.status;
  if (!status) {
    return null;
  }

  const statusEnum = intToEnum[status];
  const tone = statusTypes[statusEnum]?.tone;
  const IconComponent = statusTypes[statusEnum]?.icon;

  return (
    <div className="flex items-center gap-2 text-sm text-muted-foreground">
      {IconComponent && <IconComponent className={`h-3.5 w-3.5 ${tone}`} />}
      {t(`sections.studies.status.${statusEnum}`)}
    </div>
  );
};
