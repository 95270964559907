import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import {
  FormControl,
  Input,
  Button,
  FormErrorMessage,
  Heading,
  Container,
  VStack,
  Alert,
  AlertDescription,
  Link,
  Text,
} from 'ui';
import { useMutation } from 'urql';

import { withAppContext } from 'components/HOCS';
import { LoadingOverlay } from 'components/common';
import { useConnectedClient } from 'context/ConnectedClientContext';
import fetchTeamsBySsoDomain from 'data/queries/teams/fetchTeamsBySsoDomain';
import fetchUserByEmail from 'data/queries/user/fetchUserByEmail';
import { apolloFetch } from 'lib/http';
import { localStorage } from 'lib/storage';
import { utils } from 'lib/utils';
import { validations } from 'lib/validations';

import { SSOLoginMutation } from './data/SSOLogin.mutation';

import type { FC, ReactNode } from 'react';

import './styles/ssoLoginStyles.scss';

type Props = {
  history: any;
  location: any;
};

type SSOLoginForm = {
  email: string;
};

const SSOLogin: FC<Props> = ({ history }) => {
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState<ReactNode | string>('');
  const [loading, setLoading] = useState(false);
  const [showTeamSsoInfo, setShowTeamSsoInfo] = useState(false);
  const [, loginUser] = useMutation(SSOLoginMutation);
  const clientContext = useConnectedClient();

  const { register, setValue, formState, handleSubmit } = useForm<SSOLoginForm>({
    defaultValues: {
      email: '',
    },
  });

  // Effect on mount
  useEffect(() => {
    const extraArguments = location?.search ? utils.parseQueryParams(location.search) : null;

    const emailQuery = extraArguments && extraArguments.get('email');

    if (emailQuery !== null) {
      setShowTeamSsoInfo(true);
      setValue('email', emailQuery);
      handleEmailChange(emailQuery);
    }
  }, []);

  const onSubmit = async (formValues: SSOLoginForm) => {
    setLoading(true);
    const ssoTeams = await findTeamBySsoDomain(formValues.email);
    setShowTeamSsoInfo(false);

    // Check if exists teams with SSO for the email domain
    if (!ssoTeams || ssoTeams.length === 0) {
      setLoading(false);
      setErrorMessage(
        <Text>
          Your team doesn&apos;t have SSO enabled. <br />
          Please click{' '}
          <Link size="md" href="/login">
            here
          </Link>{' '}
          to log in with email and password.
        </Text>,
      );
    } else {
      const userData = await apolloFetch.fetch(fetchUserByEmail, { email: formValues.email });

      // If user already exists or exists only one SSO team for the email domain
      if (_.get(userData, 'data.userByEmail') || ssoTeams.length === 1) {
        // If user already exists and exists more than one team with SSO for the email domain
        // we need to redirect to the user default team
        let defaultTeam = ssoTeams;
        if (_.get(userData, 'data.userByEmail')) {
          const filterTeam = _.filter(
            ssoTeams,
            (team: any) => team._id === _.get(userData, 'data.userByEmail._default_team'),
          );
          if (filterTeam.length > 0) defaultTeam = filterTeam;
        }

        // Login with SSO
        try {
          const loginUserResult = await loginUser({
            email: formValues.email,
            enterpriseLogin: JSON.stringify(defaultTeam),
          });
          if (loginUserResult.error) {
            throw loginUserResult.error.graphQLErrors[0].message;
          }
          const loginData = loginUserResult.data?.login;
          if (loginData?.sso_error) {
            setErrorMessage(loginData?.sso_error);
            setLoading(false);
          } else if (loginData?.sso_url) {
            window.location.replace(loginData?.sso_url);
          } else {
            localStorage.save('access_token', loginData?.access_token);
            clientContext.actions.setAccessToken(loginData?.access_token!);
            setLoading(false);
            history.push({ pathname: '/' }, { team_id: loginData?._default_team });
          }
        } catch (error) {
          setErrorMessage(_.get(error, 'graphQLErrors[0].message'));
          setLoading(false);
        }
      } else {
        setLoading(false);
        history.push({
          pathname: '/join-new-team',
          search: `email=${formValues.email}&ssoLogin=${true}`,
        });
      }
    }
  };

  const handleEmailChange = (value: string) => {
    setValue('email', value);
  };

  const findTeamBySsoDomain = async (value: string) => {
    // Verify if email is an enterprise email
    validations.startValidations();
    if (validations.validateEmail(value)) {
      const userDomain = value.substring(value.lastIndexOf('@'));
      if (!utils.isGenericEmail(userDomain)) {
        const teamsBySsoDomain = await apolloFetch.fetch(fetchTeamsBySsoDomain, { email: value });
        if (_.get(teamsBySsoDomain, 'data.teamsBySsoDomain').length > 0) {
          return _.get(teamsBySsoDomain, 'data.teamsBySsoDomain');
        }
      }
    }
    validations.stopValidations();
    return null;
  };

  return (
    <div id="__loginContainer" className="enterpriseLoginContainer">
      {loading && <LoadingOverlay style={{ opacity: 0.8 }} />}
      <Container maxW="sm" backgroundColor="white" borderRadius="md" px="6" py="10" boxShadow="md">
        <Heading fontWeight="light">SSO Login</Heading>
        {showTeamSsoInfo && (
          <Alert status="warning" my="4">
            <AlertDescription>Your team uses SSO, please login with SSO instead</AlertDescription>
          </Alert>
        )}

        {errorMessage && (
          <Alert status="error" my="4">
            <AlertDescription>{errorMessage}</AlertDescription>
          </Alert>
        )}
        <VStack as="form" onSubmit={handleSubmit(onSubmit)} alignItems="flex-end" mt="6" spacing="6">
          <FormControl isInvalid={!!formState.errors.email}>
            <Input
              size="lg"
              id="email"
              {...register('email', { required: true })}
              placeholder="Email"
              type="email"
              autoFocus
            />
            {!!formState.errors.email && <FormErrorMessage>Invalid email</FormErrorMessage>}
          </FormControl>
          <Button w="full" size="lg" id="__btnLogin" type="submit">
            Log in with SSO
          </Button>
        </VStack>
      </Container>

      <div className="flex items-center justify-center gap-2 py-6">
        <img alt="Money Symbol" src="/icons/moneySymbol.svg" />
        <Link size="lg" href="https://use.askable.com/join?utm_source=clients_app" isExternal color="gray.500">
          I want to earn money testing
        </Link>
      </div>
    </div>
  );
};

export default deprecatedWithRouter(withAppContext(SSOLogin));
