import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { useCallback } from 'react';

import { useContainer } from './hooks';
import { TaskCardDraggableCard } from './task-card-draggable-card';

import type { DragEndEvent } from '@dnd-kit/core';
import type { ReactNode } from 'react';

/**
 * Task Card Draggable
 * - Requires a parent container with the class 'drag-container'
 * - Stores the position of the draggable card in localstorage
 *
 * Usage:
 *
 * <TaskCardDraggable
 *   buttonLabel="End task"
 *   collapseLabel="Hide instructions"
 *   id={id}
 *   isCollapsed={isCollapsed}
 *   onCollapse={setIsCollapsed}
 * >
 *   <>
 *    <TaskCardDraggableContent title="Some task" instructions="Content here" />
 *    other content...
 *   </>
 * </TaskCardDraggable>
 */

export interface TaskCardDraggableProps {
  buttonLabel: string;
  children: ReactNode;
  collapseLabel?: string;
  id: string;
  isCollapsed?: boolean;
  isDisabled?: boolean;
  isDraggable?: boolean;
  isLoading?: boolean;
  isPositionSaved?: boolean; // Save position in localstorage. Default: true
  subtitle: string;
  onCollapse: (isCollapsed: boolean) => void;
  onProgress?: () => void; // Next button clicked
}

export const CONFIG = {
  CONTAINER: '.drag-container',
  COLLAPSED_SIZE: { h: 60, w: 36 }, // Size of the collapsed card
  COLLAPSED_Y: 84, // Distance from the bottom of the container when collapsed
  DRAGGABLE_MIN_WIDTH: 480,
  EDGE_OFFSET: 8,
  INITIAL_POSITION: { x: 8, y: 8 },
  SNAP_THRESHOLD: 24, // Snap to edge when within this distance
};

export const TaskCardDraggable = ({ isPositionSaved = true, ...props }: TaskCardDraggableProps) => {
  const cardId = `card-${props.id}`;
  const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 8 } }));
  const { cardPosition, saveCardPosition } = useContainer(cardId, isPositionSaved);

  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { delta } = event;
      const newPosition = {
        x: Number((cardPosition.x + delta.x).toFixed(0)),
        y: Number((cardPosition.y - delta.y).toFixed(0)),
      };

      saveCardPosition(newPosition);
    },
    [cardPosition, saveCardPosition],
  );

  return (
    <DndContext sensors={sensors} modifiers={[restrictToParentElement]} onDragEnd={onDragEnd}>
      <TaskCardDraggableCard {...props} id={cardId} position={cardPosition} />
    </DndContext>
  );
};

export { TaskCardDraggableContent } from './task-card-draggable-content';
