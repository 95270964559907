import { useApolloClient } from '@apollo/client';
import { withApollo } from '@apollo/client/react/hoc';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import { withAppContext } from 'components/HOCS';
import { LoadingOverlay } from 'components/common';
import { useIsKinde } from 'containers/Auth/AuthWrapper';
import { useConnectedClient } from 'context/ConnectedClientContext';
import { analytics } from 'lib/analytics';
import { localStorage } from 'lib/storage';

function Logout() {
  const apolloClient = useApolloClient();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { logout, isLoading } = useKindeAuth();
  const isKinde = useIsKinde();

  const connectedClient = useConnectedClient();

  const intercom = useIntercom();

  useEffect(() => {
    const f = async () => {
      connectedClient.actions.clear();
      localStorage.reset();
      apolloClient.resetStore();
      analytics.reset();
      intercom.shutdown();
      intercom.boot();

      if (isKinde) {
        await logout?.();
      } else {
        const redirectArgs = params.get('redirect') || '';
        const redirectUrl = `/${redirectArgs}`.replace(/,/g, '&');
        navigate({ pathname: redirectUrl }, { replace: true });
      }
    };

    if (isLoading && isKinde) {
      return;
    }

    f();
  }, [isLoading, isKinde]);

  return <LoadingOverlay style={{ opacity: 0.8 }} />;
}

export default withApollo(deprecatedWithRouter(withAppContext(Logout)));
