import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const AlertInfoIcon: CreateIconType = createIcon({
  displayName: 'AlertInfoIcon',
  defaultProps: defaultOptions,
  path: [
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.11463 11.3428C4.35913 13.9785 9.64108 13.9785 11.8856 11.3428C13.9529 8.9151 13.796 4.16332 11.3078 2.0849C9.079 0.223245 4.92121 0.223245 2.69245 2.0849C0.204203 4.16332 0.0472987 8.9151 2.11463 11.3428Z"
    />,
    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M5.74609 6.27261H7.24256V9.73504" />,
    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M5.76074 9.73505H8.739" />,
    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M7.25708 3.71982V4.0426" />,
  ],
});
