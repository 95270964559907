import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const ShrinkIcon: CreateIconType = createIcon({
  displayName: 'ShrinkIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: [
    <path
      d="M1 6L5.44444 6C5.59179 6 5.73309 5.94147 5.83728 5.83728C5.94147 5.73309 6 5.59179 6 5.44444L6 1"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M10 1L10 5.44444C10 5.59179 10.0585 5.73309 10.1627 5.83728C10.2669 5.94147 10.4082 6 10.5556 6L15 6"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M15 10L10.5556 10C10.4082 10 10.2669 10.0585 10.1627 10.1627C10.0585 10.2669 10 10.4082 10 10.5556L10 15"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M6 15L6 10.5556C6 10.4082 5.94147 10.2669 5.83728 10.1627C5.7331 10.0585 5.59179 10 5.44444 10L1 10"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
