import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import { Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './styles.scss';

class Wysiwyg extends Component {
  constructor(props: any) {
    super(props);

    this.state = {
      editorState: '',
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'Readonly<... Remove this comment to see the full error message
      fieldText: this.props.value,
      focused: false,
    };

    this.onBriefSummaryChange = this.onBriefSummaryChange.bind(this);
    this.onBlurComponent = this.onBlurComponent.bind(this);
  }

  UNSAFE_componentWillMount() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'Readonly<... Remove this comment to see the full error message
    if (this.props.value) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'Readonly<... Remove this comment to see the full error message
      this.updateComponentState(this.props.value);
    }
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (newProps.value) {
      this.updateComponentState(newProps.value);
    }
  }

  onBriefSummaryChange(editorState: any) {
    this.setState({
      editorState,
    });
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToMarkdown(rawContentState);
    this.setState({ fieldText: markup });
  }

  onBlurComponent(event: any, editorState: any) {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToMarkdown(rawContentState);
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onBlurComponent' does not exist on type ... Remove this comment to see the full error message
    if (this.props.onBlurComponent) this.props.onBlurComponent(markup);
    this.setState({ focused: false });
  }

  updateComponentState(content: any) {
    const rawObject = markdownToDraft(content);
    const contentState = convertFromRaw(rawObject);
    this.setState({
      editorState: EditorState.createWithContent(contentState),
      fieldText: content,
    });
  }

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'className' does not exist on type 'Reado... Remove this comment to see the full error message
    const { className, placeholder, editorClassName } = this.props;

    return (
      <>
        <Editor
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'editorState' does not exist on type 'Rea... Remove this comment to see the full error message
          editorState={this.state.editorState}
          toolbar={{
            options: ['inline', 'link', 'emoji'],
            inline: {
              options: ['bold', 'italic'],
            },
            link: {
              options: ['link'],
              link: {
                className: 'linkContainer',
              },
            },
          }}
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'focused' does not exist on type 'Readonl... Remove this comment to see the full error message
          wrapperClassName={`wysiwygContainer ${className} ${this.state.focused ? 'focused' : ''} ${
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'required' does not exist on type 'Readon... Remove this comment to see the full error message
            this.props.required && !this.state.fieldText ? 'fieldError' : ''
          }`}
          toolbarClassName="toolbarClassName"
          editorClassName={`editorClassName ${editorClassName}`}
          onEditorStateChange={this.onBriefSummaryChange}
          // @ts-expect-error FIXME: Target signature provides too few arguments. Expected 2 or more, but got 1.
          onBlur={this.onBlurComponent}
          placeholder={placeholder}
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'readOnly' does not exist on type 'Readon... Remove this comment to see the full error message
          readOnly={this.props.readOnly || false}
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'wrapperId' does not exist on type 'Reado... Remove this comment to see the full error message
          wrapperId={this.props.wrapperId || ''}
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'wrapperId' does not exist on type 'Reado... Remove this comment to see the full error message
          webDriverTestID={this.props.wrapperId || ''}
          onFocus={() => {
            this.setState({ focused: true });
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectOnFocus' does not exist on type 'R... Remove this comment to see the full error message
            if (this.props.selectOnFocus) {
              const range = document.createRange();
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'wrapperId' does not exist on type 'Reado... Remove this comment to see the full error message
              const target = document.getElementById(`rdw-wrapper-${this.props.wrapperId || ''}`);
              // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
              const elements = target.querySelectorAll('div div div div div div div span [data-text=true]');
              if (elements) {
                if (elements.length === 1) {
                  range.selectNodeContents(elements[0]);
                } else {
                  range.setStart(elements[0], 0);
                  range.setEnd(elements[elements.length - 1], 1);
                }
                const selection = window.getSelection();
                // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
                selection.removeAllRanges();
                // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
                selection.addRange(range);
              }
            }
          }}
        />
        {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'required' does not exist on type 'Readon... Remove this comment to see the full error message */}
        {this.props.required && !this.state.fieldText && <p className="wysiwygTextRequiredError">Required</p>}
      </>
    );
  }
}

export default Wysiwyg;
