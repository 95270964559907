import { useMutation } from '@apollo/client';
import { Button } from '@askable/ui/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@askable/ui/components/ui/dropdown-menu';
import { Popover, PopoverContent, PopoverTrigger } from '@askable/ui/components/ui/popover';
import { Textarea } from '@askable/ui/components/ui/textarea';
import _ from 'lodash';
import { ChevronDown } from 'lucide-react';
import momentTimezone from 'moment-timezone';
import { useState, useEffect } from 'react';

import { MiniCalendar, LoadingOverlay } from 'components/common';
import { useConnectedClient } from 'context/ConnectedClientContext';
import rescheduleSessionLiveBookingMutation from 'data/mutations/booking/rescheduleSessionLiveBooking';
import { utils } from 'lib/utils';

import './sessionToReschedulePopover.scss';

type HoursMinutesDay = { value: string; hour: number; minutes: number };

function SessionToReschedulePopover(props: any) {
  const [openMiniCalendarPopover, setOpenMiniCalendarPopover] = useState(false);
  const [dateToAdd, setDateToAdd] = useState(momentTimezone(props?.sessionToReschedule?.start).tz(props.timezone));
  const [isLoading, setIsLoading] = useState(false);
  const [txtToSend, setTxtToSend] = useState('');
  const [newTimeSelected, setNewTimeSelected] = useState(
    _.get(props, 'sessionToReschedule._id') === 'pendingEventToReschedule',
  );
  const { details: clientDetails } = useConnectedClient();

  const currentSession = _.get(props, 'sessionSelected');
  const userName = !props.isFocusGroup ? _.get(props, 'sessionSelected.username') : 'there';
  const hoursMinutesDay = utils.getHoursMinutesDay();
  const userTimezone = _.get(currentSession, 'userTimezone') || props.timezone;

  const [rescheduleSessionLiveBooking] = useMutation(rescheduleSessionLiveBookingMutation, {
    client: props.client,
  });

  useEffect(() => {
    const sessionStart = momentTimezone(_.get(currentSession, 'start')).tz(userTimezone);
    const newSessionStart = momentTimezone(dateToAdd).tz(userTimezone);
    // If client is trying to reschedule a completed participant from the past, it shouldn't have any auto-created message
    if (props.sessionToReschedule.event_status !== 'completed') {
      setTxtToSend(
        `Hi ${userName}, the session you were confirmed for at ${sessionStart.format('h:mm a')} on the ${sessionStart.format(
          'Do of MMMM',
        )} has been moved.\n\nThe new time will be ${newSessionStart.format('h:mm a')} on the ${newSessionStart.format('Do of MMMM')}.\n\n- ${_.get(
          clientDetails,
          'name.firstname',
        )}`,
      );
    }
  }, [dateToAdd]);

  if (!props.sessionToReschedule) {
    return null;
  }

  const onRescheduleSession = async (message: any, shouldMessage: any) => {
    setIsLoading(true);
    const sessionToReschedule = {
      start: dateToAdd.valueOf(),
      end: momentTimezone(dateToAdd).add(props.sessionDuration, 'minutes').valueOf(),
    };
    await rescheduleSessionLiveBooking({
      variables: {
        booking_id: props.booking_id,
        session_id: _.get(currentSession, '_id'),
        newSession: sessionToReschedule,
        message,
        shouldMessage,
      },
    });

    props.onClose();
    setIsLoading(false);
  };

  const handleScrollToSelectedTime = (isOpen: boolean) => {
    if (!isOpen) {
      return;
    }
    const timeSelected = momentTimezone(dateToAdd).tz(props.timezone).format('h:mm a');
    setTimeout(() => {
      const element = document.getElementById(`time-${timeSelected}`);
      if (element) element.scrollIntoView();
    }, 150);
  };

  const handleSelectTime = (item: HoursMinutesDay) => {
    const m = momentTimezone(dateToAdd).tz(props.timezone);
    const newSelectedTime = m.hour(item.hour).minutes(item.minutes).valueOf();
    setDateToAdd(momentTimezone(newSelectedTime).tz(props.timezone));
    setNewTimeSelected(true);
  };

  const renderConfirmedContainer = () => {
    return (
      <>
        <div className="flex items-center gap-2">
          <Popover open={openMiniCalendarPopover} onOpenChange={setOpenMiniCalendarPopover}>
            <PopoverTrigger
              id="sessionToRescheduleComponent_dayContainer"
              className="flex h-[2.1rem] items-center gap-2 rounded-md border border-input px-2 py-1 aria-[expanded=true]:ring-1
                aria-[expanded=true]:ring-ring"
              onClick={() => setOpenMiniCalendarPopover(true)}
            >
              {momentTimezone(dateToAdd).tz(props.timezone).format('D MMM, YYYY')}
              <ChevronDown className="h-4 w-4 opacity-50" />
            </PopoverTrigger>
            <PopoverContent className="z-[4001]" align="start">
              <MiniCalendar
                timezone={props.timezone}
                month={momentTimezone(dateToAdd).tz(props.timezone).month()}
                year={momentTimezone(dateToAdd).tz(props.timezone).year()}
                selectedDay={dateToAdd}
                onClickDay={(day: any) => {
                  const selectedDay = momentTimezone(day).date();
                  const selectedMonth = momentTimezone(day).month();
                  const selectedYear = momentTimezone(day).year();
                  const newDaySelected = momentTimezone(dateToAdd)
                    .date(selectedDay)
                    .month(selectedMonth)
                    .year(selectedYear);
                  setDateToAdd(momentTimezone(newDaySelected.valueOf()).tz(props.timezone));
                  setOpenMiniCalendarPopover(false);
                }}
              />
            </PopoverContent>
          </Popover>

          <DropdownMenu onOpenChange={handleScrollToSelectedTime}>
            <DropdownMenuTrigger
              id="sessionToRescheduleComponent_hourContainer"
              className="flex h-[2.1rem] items-center gap-2 rounded-md border border-input px-2 py-1 aria-[expanded=true]:ring-1
                aria-[expanded=true]:ring-ring"
            >
              {momentTimezone(dateToAdd).tz(props.timezone).format('h:mm a')}
              <ChevronDown className="h-4 w-4 opacity-50" />
            </DropdownMenuTrigger>
            <DropdownMenuContent className="z-[4001]">
              {_.map(hoursMinutesDay, item => {
                const isSelected = item.value === momentTimezone(dateToAdd).tz(props.timezone).format('h:mm a');
                return (
                  <DropdownMenuItem
                    asChild
                    id={`time-${item.value}`}
                    key={`time-${item.value}`}
                    className="w-full cursor-pointer px-2 py-1 text-sm hover:bg-gray-100"
                    onClick={() => handleSelectTime(item)}
                  >
                    <span className={isSelected ? 'selected' : ''}>{item.value}</span>
                  </DropdownMenuItem>
                );
              })}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        {!props.isFocusGroup && (
          <div className="textContainerComponent">
            <Textarea
              id="txtToSend"
              value={newTimeSelected ? txtToSend : ''}
              onChange={(e: any) => setTxtToSend(e.target.value)}
              rows={8}
              maxRows={8}
              disabled={!newTimeSelected}
              autoFocus
            />
          </div>
        )}
        <div>
          <Button
            // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
            onClick={() => onRescheduleSession(txtToSend)}
            variant="primary"
            className="sendButton ml-auto"
            disabled={_.size(txtToSend) === 0 && !props.isFocusGroup}
          >
            Send
          </Button>
        </div>
      </>
    );
  };

  const renderInvitedContainer = () => {
    return (
      <>
        <p>Would you like to send sms updates to the invited participants?</p>
        <div className="bottomContainer flex gap-2">
          <Button onClick={() => onRescheduleSession('', false)}>Don't Send</Button>
          <Button onClick={() => onRescheduleSession('', true)} variant="primary" className="sendButton">
            Send
          </Button>
        </div>
      </>
    );
  };

  return (
    <div className="sessionToRescheduleComponent flex flex-col gap-2">
      {isLoading && <LoadingOverlay style={{ opacity: 0.8 }} />}
      <h3>Reschedule session</h3>
      {props.sessionToReschedule.event_status === 'invited' ? renderInvitedContainer() : renderConfirmedContainer()}
    </div>
  );
}

export default SessionToReschedulePopover;
