import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const ExternalLinkIcon: CreateIconType = createIcon({
  displayName: 'ExternalLinkIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: [
    <path
      d="M2 14L13.2443 2.75568"
      stroke="currentColor"
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M13.7332 11.0667C14.0304 8.07476 14.0793 6.33433 13.8799 3.84787C13.844 3.40179 13.6505 2.98304 13.3342 2.66651C13.0178 2.34998 12.5992 2.15631 12.1531 2.12015C9.66471 1.92069 7.92526 1.96957 4.93329 2.26682"
      stroke="currentColor"
      strokeWidth="1.14286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
