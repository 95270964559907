/* eslint-disable max-lines */
import _ from 'lodash';
import moment from 'moment';

import { bookingUtils } from 'lib/booking';
import { BOOKING_CONFIG_PARTICIPANT_AGREEMENT_TYPE } from 'lib/constants';
import { utils } from 'lib/utils';

import type { Booking } from 'generated/graphql';

const stepperMenuUtils = {
  projectSubSteps(booking: any, bookingSteps: any) {
    const subSteps = [];
    subSteps.push({
      index: 5,
      label: 'Study title',
      id: 'project_setup_project_title',
      link: `/booking-setup/${_.get(booking, '_id', ':bookingID')}/project-setup/project-title`,
      completed: !booking || stepperMenuUtils.isStepCompleted(bookingSteps, 'project_setup_project_title'),
      error: stepperMenuUtils.stepHasErrors(bookingSteps, 'project_setup_project_title'),
      validateStep: stepperMenuUtils.validateProjectTitle(booking),
    });
    subSteps.push({
      index: 10,
      label: 'Research method',
      id: 'project_setup_research_type',
      link: `/booking-setup/${_.get(booking, '_id', ':bookingID')}/project-setup/research-type`,
      completed: stepperMenuUtils.isStepCompleted(bookingSteps, 'project_setup_research_type'),
      error: stepperMenuUtils.stepHasErrors(bookingSteps, 'project_setup_research_type'),
      validateStep: stepperMenuUtils.validateResearchType(booking),
    });

    if (!bookingUtils.isInPerson(booking) && !bookingUtils.isAiModeration(booking)) {
      subSteps.push({
        index: 15,
        label: 'Device',
        id: 'project_setup_devices',
        link: `/booking-setup/${_.get(booking, '_id', ':bookingID')}/project-setup/devices`,
        completed: stepperMenuUtils.isStepCompleted(bookingSteps, 'project_setup_devices'),
        error: stepperMenuUtils.stepHasErrors(bookingSteps, 'project_setup_devices'),
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
        validateStep: stepperMenuUtils.validateDevice(booking),
      });
    }

    if (
      !_.get(booking, '_id') ||
      bookingUtils.isRemote(booking) ||
      (bookingUtils.isOnlineTask(booking) && !bookingUtils.isAiModeration(booking))
    ) {
      subSteps.push({
        index: 20,
        label: 'Tool',
        id: 'project_setup_tool',
        link: `/booking-setup/${_.get(booking, '_id', ':bookingID')}/project-setup/tool`,
        completed: stepperMenuUtils.isStepCompleted(bookingSteps, 'project_setup_tool'),
        error: stepperMenuUtils.stepHasErrors(bookingSteps, 'project_setup_tool'),
        validateStep: stepperMenuUtils.validateTool(booking),
      });
    }
    subSteps.push({
      index: 25,
      label: 'Quota',
      id: 'project_setup_quota',
      link: `/booking-setup/${_.get(booking, '_id', ':bookingID')}/project-setup/quota`,
      completed: stepperMenuUtils.isStepCompleted(bookingSteps, 'project_setup_quota'),
      error: stepperMenuUtils.stepHasErrors(bookingSteps, 'project_setup_quota'),
      validateStep: stepperMenuUtils.validateQuota(booking),
    });
    if (!_.get(booking, '_id') || bookingUtils.isRemote(booking) || bookingUtils.isInPerson(booking)) {
      subSteps.push({
        index: 30,
        label: 'Session format',
        id: 'project_setup_session_format',
        link: `/booking-setup/${_.get(booking, '_id', ':bookingID')}/project-setup/session-format`,
        completed: stepperMenuUtils.isStepCompleted(bookingSteps, 'project_setup_session_format'),
        error: stepperMenuUtils.stepHasErrors(bookingSteps, 'project_setup_session_format'),
        validateStep: stepperMenuUtils.validateSessionFormat(booking),
      });
      subSteps.push({
        index: 35,
        label: 'Session duration',
        id: 'project_setup_session_duration',
        link: `/booking-setup/${_.get(booking, '_id', ':bookingID')}/project-setup/session-duration`,
        completed: stepperMenuUtils.isStepCompleted(bookingSteps, 'project_setup_session_duration'),
        error: stepperMenuUtils.stepHasErrors(bookingSteps, 'project_setup_session_duration'),
        validateStep: stepperMenuUtils.validateSessionDuration(booking),
      });
    }
    if (bookingUtils.isOnlineTask(booking) && !bookingUtils.isAiModeration(booking)) {
      subSteps.push({
        index: 40,
        label: 'Task Duration',
        id: 'project_setup_task_duration',
        link: `/booking-setup/${_.get(booking, '_id', ':bookingID')}/project-setup/task-duration`,
        completed: stepperMenuUtils.isStepCompleted(bookingSteps, 'project_setup_task_duration'),
        error: stepperMenuUtils.stepHasErrors(bookingSteps, 'project_setup_task_duration'),
        validateStep: stepperMenuUtils.validateTaskDuration(booking),
      });
    }
    if (bookingUtils.isLongitudinal(booking)) {
      subSteps.push({
        index: 45,
        label: 'Study duration',
        id: 'project_setup_study_duration',
        link: `/booking-setup/${_.get(booking, '_id', ':bookingID')}/project-setup/study-duration`,
        completed: stepperMenuUtils.isStepCompleted(bookingSteps, 'project_setup_study_duration'),
        error: stepperMenuUtils.stepHasErrors(bookingSteps, 'project_setup_study_duration'),
        validateStep: stepperMenuUtils.validateStudyDuration(booking),
      });
    }
    if (!(bookingUtils.isFocusGroup(booking) && (bookingUtils.isRemote(booking) || bookingUtils.isInPerson(booking)))) {
      subSteps.push({
        index: 50,
        label: 'Participant selection',
        id: 'project_setup_participant_selection',
        link: `/booking-setup/${_.get(booking, '_id', ':bookingID')}/project-setup/participant-selection`,
        completed: stepperMenuUtils.isStepCompleted(bookingSteps, 'project_setup_participant_selection'),
        error: stepperMenuUtils.stepHasErrors(bookingSteps, 'project_setup_participant_selection'),
        validateStep: stepperMenuUtils.validateParticipantSelection(booking),
      });
    }
    return subSteps;
  },
  audienceSubSteps(booking: Booking, bookingSteps: Booking['steps'], isMultiRegion: boolean) {
    const subSteps = [];
    subSteps.push({
      index: 5,
      label: 'Panel',
      id: 'audience_panel',
      link: `/booking-setup/${_.get(booking, '_id', ':bookingID')}/audience/panel`,
      completed: stepperMenuUtils.isStepCompleted(bookingSteps, 'audience_panel'),
      error: stepperMenuUtils.stepHasErrors(bookingSteps, 'audience_panel'),
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
      validateStep: stepperMenuUtils.validatePanel(booking),
    });
    if (!bookingUtils.isInPerson(booking)) {
      if (isMultiRegion) {
        subSteps.push({
          index: 10,
          label: 'Participant location',
          id: 'audience_participant_locations',
          link: `/booking-setup/${_.get(booking, '_id', ':bookingID')}/audience/participant-locations`,
          completed: stepperMenuUtils.isStepCompleted(bookingSteps, 'audience_participant_locations'),
          error: stepperMenuUtils.stepHasErrors(bookingSteps, 'audience_participant_locations'),
          validateStep: stepperMenuUtils.validateParticipantLocationsMultiRegion(booking),
        });
      } else {
        subSteps.push({
          index: 10,
          label: 'Participant location',
          id: 'audience_participant_locations',
          link: `/booking-setup/${_.get(booking, '_id', ':bookingID')}/audience/participant-locations`,
          completed: stepperMenuUtils.isStepCompleted(bookingSteps, 'audience_participant_locations'),
          error: stepperMenuUtils.stepHasErrors(bookingSteps, 'audience_participant_locations'),
          validateStep: stepperMenuUtils.validateParticipantLocations(booking),
        });
      }
    }
    subSteps.push({
      index: 15,
      label: 'Demographic filters',
      id: 'audience_demographic_filters',
      link: `/booking-setup/${_.get(booking, '_id', ':bookingID')}/audience/demographic-filters`,
      completed: stepperMenuUtils.isStepCompleted(bookingSteps, 'audience_demographic_filters'),
      error: stepperMenuUtils.stepHasErrors(bookingSteps, 'audience_demographic_filters'),
      validateStep: stepperMenuUtils.validateDemographicFilters(),
    });
    subSteps.push({
      index: 20,
      label: 'Incentives',
      id: 'audience_incentives',
      link: `/booking-setup/${_.get(booking, '_id', ':bookingID')}/audience/incentives`,
      completed: stepperMenuUtils.isStepCompleted(bookingSteps, 'audience_incentives'),
      error: stepperMenuUtils.stepHasErrors(bookingSteps, 'audience_incentives'),
      validateStep: stepperMenuUtils.validateIncentives(),
    });
    subSteps.push({
      index: 35,
      label: 'Custom screener',
      id: 'audience_custom_screener',
      link: `/booking-setup/${_.get(booking, '_id', ':bookingID')}/audience/custom-screener`,
      completed: stepperMenuUtils.isStepCompleted(bookingSteps, 'audience_custom_screener'),
      error: stepperMenuUtils.stepHasErrors(bookingSteps, 'audience_custom_screener'),
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
      validateStep: stepperMenuUtils.validateCustomScreening(booking),
    });

    return subSteps;
  },
  aiModeratedSubSteps(booking: any, bookingSteps: any) {
    const subSteps = [];
    subSteps.push({
      index: 5,
      label: 'Research objective',
      id: 'ai_moderated_research_objective',
      link: `/booking-setup/${_.get(booking, '_id', ':bookingID')}/ai-moderated/research-objective`,
      completed: stepperMenuUtils.isStepCompleted(bookingSteps, 'ai_moderated_research_objective'),
      error: stepperMenuUtils.stepHasErrors(bookingSteps, 'ai_moderated_research_objective'),
      validateStep: stepperMenuUtils.validateResearchObjective(booking),
    });
    subSteps.push({
      index: 15,
      label: 'Welcome screen',
      id: 'ai_moderated_welcome_end_screen',
      link: `/booking-setup/${_.get(booking, '_id', ':bookingID')}/ai-moderated/welcome-end-screen`,
      completed: stepperMenuUtils.isStepCompleted(bookingSteps, 'ai_moderated_welcome_end_screen'),
      error: stepperMenuUtils.stepHasErrors(bookingSteps, 'ai_moderated_welcome_end_screen'),
      validateStep: stepperMenuUtils.validateWelcomeEndScreen(booking),
    });

    return subSteps;
  },
  additionalInfoSubSteps(booking: any, bookingSteps: any) {
    const subSteps = [];
    if (bookingUtils.isRemote(booking)) {
      subSteps.push({
        index: 5,
        label: 'Your timezone',
        id: 'additional_info_your_timezone',
        link: `/booking-setup/${_.get(booking, '_id', ':bookingID')}/additional-info/your-timezone`,
        completed: stepperMenuUtils.isStepCompleted(bookingSteps, 'additional_info_your_timezone'),
        error: stepperMenuUtils.stepHasErrors(bookingSteps, 'additional_info_your_timezone'),
        validateStep: stepperMenuUtils.validateYourTimeZone(booking),
      });
    }
    if (bookingUtils.isInPerson(booking)) {
      subSteps.push({
        index: 10,
        label: 'Session location',
        id: 'additional_info_session_location',
        link: `/booking-setup/${_.get(booking, '_id', ':bookingID')}/additional-info/session-location`,
        completed: stepperMenuUtils.isStepCompleted(bookingSteps, 'additional_info_session_location'),
        error: stepperMenuUtils.stepHasErrors(bookingSteps, 'additional_info_session_location'),
        validateStep: stepperMenuUtils.validateSessionLocation(booking),
      });
    }
    if (!bookingUtils.isRemote(booking) && !bookingUtils.isInPerson(booking)) {
      subSteps.push({
        index: 15,
        label: 'Closing date',
        id: 'additional_info_closing_date',
        link: `/booking-setup/${_.get(booking, '_id', ':bookingID')}/additional-info/closing-date`,
        completed: stepperMenuUtils.isStepCompleted(bookingSteps, 'additional_info_closing_date'),
        error: stepperMenuUtils.stepHasErrors(bookingSteps, 'additional_info_closing_date'),
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
        validateStep: stepperMenuUtils.validateClosingDate(booking),
      });
    }
    subSteps.push({
      index: 25,
      label: 'Description',
      id: 'additional_info_description',
      link: `/booking-setup/${_.get(booking, '_id', ':bookingID')}/additional-info/description`,
      completed:
        stepperMenuUtils.isStepCompleted(bookingSteps, 'additional_info_description') &&
        stepperMenuUtils.isStepCompleted(bookingSteps, 'additional_info_short_description'),
      error:
        stepperMenuUtils.stepHasErrors(bookingSteps, 'additional_info_description') ||
        stepperMenuUtils.stepHasErrors(bookingSteps, 'additional_info_short_description'),
      validateStep: stepperMenuUtils.validateDescription(booking),
    });
    if (!bookingUtils.isOnlineTask(booking) && !bookingUtils.isAiModeration(booking)) {
      subSteps.push({
        index: 30,
        label: 'Additional instructions',
        id: 'additional_info_additional_instructions',
        link: `/booking-setup/${_.get(booking, '_id', ':bookingID')}/additional-info/additional-instructions`,
        completed: stepperMenuUtils.isStepCompleted(bookingSteps, 'additional_info_additional_instructions'),
        error: stepperMenuUtils.stepHasErrors(bookingSteps, 'additional_info_additional_instructions'),
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
        validateStep: stepperMenuUtils.validateAdditionalInstructions(booking),
      });
    }
    subSteps.push({
      index: 35,
      label: 'Contacts',
      id: 'additional_info_contacts',
      link: `/booking-setup/${_.get(booking, '_id', ':bookingID')}/additional-info/contacts`,
      completed: stepperMenuUtils.isStepCompleted(bookingSteps, 'additional_info_contacts'),
      error: stepperMenuUtils.stepHasErrors(bookingSteps, 'additional_info_contacts'),
      validateStep: stepperMenuUtils.validateContacts(booking),
    });
    subSteps.push({
      index: 40,
      label: 'Legal & privacy',
      id: 'additional_info_legal_privacy',
      link: `/booking-setup/${_.get(booking, '_id', ':bookingID')}/additional-info/legal-privacy`,
      completed: stepperMenuUtils.isStepCompleted(bookingSteps, 'additional_info_legal_privacy'),
      error: stepperMenuUtils.stepHasErrors(bookingSteps, 'additional_info_legal_privacy'),
      validateStep: stepperMenuUtils.validateLegalPrivacy(booking),
    });
    return subSteps;
  },
  isStepCompleted(bookingSteps: any, step: any) {
    if (!bookingSteps || bookingSteps === null) return false;
    return _.get(bookingSteps, `[${step}]`) === 'seen' || _.get(bookingSteps, `[${step}]`) === 'error';
  },
  stepHasErrors(bookingSteps: any, step: any) {
    return (_.get(bookingSteps, `[${step}]`) && _.get(bookingSteps, `[${step}]`) === 'error') || false;
  },
  allStepsSeen(bookingSteps: any, stepsGroup: any) {
    const stepsNotViewed = _.filter(stepsGroup, (item: any) => !_.get(bookingSteps, `[${item.id}]`));
    return !(_.size(stepsNotViewed) > 0);
  },
  validateConfirmBooking(
    booking: any,
    validatedSteps: any,
    projectSetupSteps: any,
    audienceSteps: any,
    additionalInfoSteps: any,
    aiModeratedSteps: any,
  ) {
    const allProjectSetupStepsSeen = stepperMenuUtils.allStepsSeen(validatedSteps, projectSetupSteps);
    const allAudienceStepsSeen = stepperMenuUtils.allStepsSeen(validatedSteps, audienceSteps);
    const allAdditionalInfoStepsSeen = stepperMenuUtils.allStepsSeen(validatedSteps, additionalInfoSteps);
    const allAIModeratedStepsSeen = stepperMenuUtils.allStepsSeen(validatedSteps, aiModeratedSteps);

    // Validates all seen Project Setup steps
    let projectSetupStepError = false;
    _.map(projectSetupSteps, (item: any) => {
      const stepError = item.validateStep;
      if (_.get(validatedSteps, `[${item.id}]`)) {
        validatedSteps[`${item.id}`] = stepError ? 'error' : 'seen';
      } else if (stepError) {
        validatedSteps[`${item.id}`] = 'error';
      }
      if (stepError) projectSetupStepError = true;
    });

    if (allProjectSetupStepsSeen) {
      validatedSteps.project_setup = projectSetupStepError ? 'error' : 'seen';
    } else {
      validatedSteps.project_setup = projectSetupStepError ? 'error' : null;
    }

    // Validates all seen Audience steps
    let audienceStepError = false;
    _.map(audienceSteps, (item: any) => {
      const stepError = item.validateStep;
      if (_.get(validatedSteps, `[${item.id}]`)) {
        validatedSteps[`${item.id}`] = stepError ? 'error' : 'seen';
      } else if (stepError) {
        validatedSteps[`${item.id}`] = 'error';
      }
      if (stepError) audienceStepError = true;
    });

    if (allAudienceStepsSeen) {
      validatedSteps.audience = audienceStepError ? 'error' : 'seen';
    } else {
      validatedSteps.audience = audienceStepError ? 'error' : null;
    }

    // Validates all AI Moderated steps
    let aiModeratedStepError = false;
    _.map(aiModeratedSteps, (item: any) => {
      const stepError = item.validateStep;
      const researchObjectiveStepError = stepperMenuUtils.validateResearchObjective(booking);
      const welcomeEndScreenStepError = stepperMenuUtils.validateWelcomeEndScreen(booking);

      if (_.get(validatedSteps, `[${item.id}]`)) {
        if (item.id === 'ai_moderated_research_objective') {
          validatedSteps.ai_moderated_research_objective = researchObjectiveStepError ? 'error' : 'seen';
          validatedSteps.ai_moderated = researchObjectiveStepError ? 'error' : 'seen';
        }
        if (item.id === 'ai_moderated_welcome_end_screen') {
          validatedSteps.ai_moderated_welcome_end_screen = welcomeEndScreenStepError ? 'error' : 'seen';
          validatedSteps.ai_moderated = researchObjectiveStepError ? 'error' : 'seen';
        } else {
          validatedSteps[`${item.id}`] = stepError ? 'error' : 'seen';
        }
      } else if (stepError) {
        validatedSteps[`${item.id}`] = 'error';
      }
      if (stepError || researchObjectiveStepError || welcomeEndScreenStepError) aiModeratedStepError = true;
    });

    if (allAIModeratedStepsSeen) {
      validatedSteps.ai_moderated = aiModeratedStepError ? 'error' : 'seen';
    } else {
      validatedSteps.ai_moderated = aiModeratedStepError ? 'error' : null;
    }

    // Validates all seen Additional Info steps
    let additionalInfoStepError = false;
    _.map(additionalInfoSteps, (item: any) => {
      const stepError = item.validateStep;
      const shortDescriptionError = stepperMenuUtils.validateShortDescription(booking);
      const fullDescriptionError = stepperMenuUtils.validateFullDescription(booking);
      if (_.get(validatedSteps, `[${item.id}]`)) {
        if (item.id === 'additional_info_description') {
          validatedSteps.additional_info_short_description = shortDescriptionError ? 'error' : 'seen';
          validatedSteps.additional_info_description = fullDescriptionError ? 'error' : 'seen';
        } else {
          validatedSteps[`${item.id}`] = stepError ? 'error' : 'seen';
        }
      } else if (item.id === 'additional_info_description') {
        if (shortDescriptionError) validatedSteps.additional_info_short_description = 'error';
        if (fullDescriptionError) validatedSteps.additional_info_description = 'error';
      } else if (stepError) {
        validatedSteps[`${item.id}`] = 'error';
      }
      if (stepError || shortDescriptionError || fullDescriptionError) additionalInfoStepError = true;
    });

    if (allAdditionalInfoStepsSeen) {
      validatedSteps.additional_info = additionalInfoStepError ? 'error' : 'seen';
    } else {
      validatedSteps.additional_info = additionalInfoStepError ? 'error' : null;
    }

    // If booking is Remote or In Person validate session times
    if (bookingUtils.isRemote(booking) || bookingUtils.isInPerson(booking)) {
      const stepError = stepperMenuUtils.validateSessionTimes(booking);
      if (_.get(validatedSteps, 'session_times_calendar')) {
        validatedSteps.session_times_backup = stepperMenuUtils.validateBackupSessionTimes(booking) ? 'warning' : 'seen';
        validatedSteps.session_times_calendar = stepError ? 'error' : 'seen';
        validatedSteps.session_times = stepError ? 'error' : 'seen';
      } else if (!_.get(validatedSteps, 'session_times_calendar')) {
        if (stepError) {
          validatedSteps.session_times_calendar = 'error';
          validatedSteps.session_times = 'error';
        }
        if (stepperMenuUtils.validateBackupSessionTimes(booking)) validatedSteps.session_times_backup = 'warning';
      }
    }

    // If booking is Online Task or Survey validate link to task / survey
    if (bookingUtils.isOnlineTask(booking)) {
      const stepError = stepperMenuUtils.validateLinkToTask(booking);
      if (bookingUtils.isSurvey(booking)) {
        if (_.get(validatedSteps, 'link_to_task_survey')) {
          validatedSteps.link_to_task_survey = stepError ? 'error' : 'seen';
          validatedSteps.link_to_task = stepError ? 'error' : 'seen';
        } else if (stepError) {
          validatedSteps.link_to_task_survey = 'error';
          validatedSteps.link_to_task = 'error';
        }
      } else if (!bookingUtils.isSurvey(booking)) {
        if (_.get(validatedSteps, 'link_to_task_online_task')) {
          validatedSteps.link_to_task_online_task = stepError ? 'error' : 'seen';
          validatedSteps.link_to_task = stepError ? 'error' : 'seen';
        } else if (stepError) {
          validatedSteps.link_to_task_online_task = 'error';
          validatedSteps.link_to_task = 'error';
        }
      }
    }

    return validatedSteps;
  },
  validateBookingSteps(
    booking: any,
    bookingSteps: any,
    page: any,
    validateCurrentLink = false,
    isMultiRegion: boolean,
  ) {
    if (!page) return null;
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    const projectSetupSteps = stepperMenuUtils.projectSubSteps(booking);
    const audienceSteps = stepperMenuUtils.audienceSubSteps(booking, bookingSteps, isMultiRegion);
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    const additionalInfoSteps = stepperMenuUtils.additionalInfoSubSteps(booking);
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    const aiModeratedSteps = stepperMenuUtils.aiModeratedSubSteps(booking);

    let validatedSteps = _.clone(bookingSteps || {});

    // Test if you are navigating inside Project Setup steps
    const projectSetupStepSelected = _.find(projectSetupSteps, (item: any) => item.link.includes(page));
    if (projectSetupStepSelected) {
      if (validateCurrentLink) {
        // Validates selected step
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'number | { i... Remove this comment to see the full error message
        validatedSteps[projectSetupStepSelected.id] = projectSetupStepSelected.validateStep ? 'error' : 'seen';
        validatedSteps = stepperMenuUtils.validateProjectSetup(booking, validatedSteps, projectSetupStepSelected);
        return validatedSteps;
      }
      // Validates Project Setup steps
      validatedSteps = stepperMenuUtils.validateProjectSetup(booking, validatedSteps, projectSetupStepSelected);
    }

    // // Test if you are navigating inside Audience steps
    const audienceStepSelected = _.find(audienceSteps, (item: any) => item.link.includes(page));
    if (audienceStepSelected) {
      if (validateCurrentLink) {
        // Validates selected step
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'number | { i... Remove this comment to see the full error message
        validatedSteps[audienceStepSelected.id] = audienceStepSelected.validateStep ? 'error' : 'seen';
        validatedSteps = stepperMenuUtils.validateAudience(
          booking,
          validatedSteps,
          audienceStepSelected,
          isMultiRegion,
        );
        return validatedSteps;
      }
      // Validates Project Setup steps
      validatedSteps = stepperMenuUtils.validateProjectSetup(booking, validatedSteps, projectSetupSteps);
      // Validates Audience steps
      validatedSteps = stepperMenuUtils.validateAudience(booking, validatedSteps, audienceStepSelected, isMultiRegion);
    }

    // Test if you are navigating inside Link to Task / Link to Survey step
    if (page.includes('/link-to-task/online-task') || page.includes('/link-to-task/survey')) {
      if (validateCurrentLink) {
        // Validates selected step
        const stepError = stepperMenuUtils.validateLinkToTask(booking);
        if (bookingUtils.isSurvey(booking)) {
          if (_.get(bookingSteps, 'link_to_task_survey')) {
            validatedSteps.link_to_task_survey = stepError ? 'error' : 'seen';
            validatedSteps.link_to_task = stepError ? 'error' : 'seen';
          }
        } else if (!bookingUtils.isSurvey(booking)) {
          if (_.get(bookingSteps, 'link_to_task_online_task')) {
            validatedSteps.link_to_task_online_task = stepError ? 'error' : 'seen';
            validatedSteps.link_to_task = stepError ? 'error' : 'seen';
          }
        }
        return validatedSteps;
      }
      // Validates Project Setup steps
      validatedSteps = stepperMenuUtils.validateProjectSetup(booking, validatedSteps, projectSetupSteps);
      // Validates Audience steps
      validatedSteps = stepperMenuUtils.validateAudience(booking, validatedSteps, audienceSteps, isMultiRegion);
    }

    // Test if you are navigating inside AI Moderated steps
    const aiModeratedStepSelected = _.find(aiModeratedSteps, (item: any) => item.link.includes(page));
    if (aiModeratedStepSelected) {
      if (validateCurrentLink) {
        // Validates selected step
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'number | { i... Remove this comment to see the full error message
        if (aiModeratedStepSelected.id === 'ai_moderated_research_objective') {
          if (!validatedSteps.ai_moderated_research_objective) {
            validatedSteps.ai_moderated_research_objective = 'seen';
          } else {
            const researchObjectiveError = stepperMenuUtils.validateResearchObjective(booking);
            validatedSteps.ai_moderated_research_objective = researchObjectiveError ? 'error' : 'seen';
          }
        }
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'number | { i... Remove this comment to see the full error message
        else if (aiModeratedStepSelected.id === 'ai_moderated_welcome_end_screen') {
          const welcomeEndScreenStepError = stepperMenuUtils.validateWelcomeEndScreen(booking);
          validatedSteps.ai_moderated_welcome_end_screen = welcomeEndScreenStepError ? 'error' : 'seen';
        } else {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'number | { i... Remove this comment to see the full error message
          validatedSteps[aiModeratedStepSelected.id] = aiModeratedStepSelected.validateStep ? 'error' : 'seen';
        }
        validatedSteps = stepperMenuUtils.validateAiModerated(booking, validatedSteps, aiModeratedStepSelected);
        return validatedSteps;
      }
      // Validates Project Setup steps
      validatedSteps = stepperMenuUtils.validateProjectSetup(booking, validatedSteps, projectSetupSteps);
      // Validates Audience steps
      validatedSteps = stepperMenuUtils.validateAudience(booking, validatedSteps, audienceSteps, isMultiRegion);

      // If booking is Online Task or Survey validate link to task / survey
      if (bookingUtils.isOnlineTask(booking)) {
        const stepError = stepperMenuUtils.validateLinkToTask(booking);
        if (bookingUtils.isSurvey(booking)) {
          if (_.get(bookingSteps, 'link_to_task_survey')) {
            validatedSteps.link_to_task_survey = stepError ? 'error' : 'seen';
            validatedSteps.link_to_task = stepError ? 'error' : 'seen';
          }
        } else if (!bookingUtils.isSurvey(booking)) {
          if (_.get(bookingSteps, 'link_to_task_online_task')) {
            validatedSteps.link_to_task_online_task = stepError ? 'error' : 'seen';
            validatedSteps.link_to_task = stepError ? 'error' : 'seen';
          }
        }
      }

      // Validates AI Moderated steps
      validatedSteps = stepperMenuUtils.validateAdditionalInfo(booking, validatedSteps, aiModeratedStepSelected);
    }

    // Test if you are navigating inside Additional Info steps
    const additionalInfoStepSelected = _.find(additionalInfoSteps, (item: any) => item.link.includes(page));
    if (additionalInfoStepSelected) {
      if (validateCurrentLink) {
        // Validates selected step
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'number | { i... Remove this comment to see the full error message
        if (additionalInfoStepSelected.id === 'additional_info_description') {
          const shortDescriptionError = stepperMenuUtils.validateShortDescription(booking);
          const fullDescriptionError = stepperMenuUtils.validateFullDescription(booking);
          validatedSteps.additional_info_short_description = shortDescriptionError ? 'error' : 'seen';
          validatedSteps.additional_info_description = fullDescriptionError ? 'error' : 'seen';
        } else {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'number | { i... Remove this comment to see the full error message
          validatedSteps[additionalInfoStepSelected.id] = additionalInfoStepSelected.validateStep ? 'error' : 'seen';
        }
        validatedSteps = stepperMenuUtils.validateAdditionalInfo(booking, validatedSteps, additionalInfoStepSelected);
        return validatedSteps;
      }
      // Validates Project Setup steps
      validatedSteps = stepperMenuUtils.validateProjectSetup(booking, validatedSteps, projectSetupSteps);
      // Validates Audience steps
      validatedSteps = stepperMenuUtils.validateAudience(booking, validatedSteps, audienceSteps, isMultiRegion);

      // If booking is Online Task or Survey validate link to task / survey
      if (bookingUtils.isOnlineTask(booking)) {
        const stepError = stepperMenuUtils.validateLinkToTask(booking);
        if (bookingUtils.isSurvey(booking)) {
          if (_.get(bookingSteps, 'link_to_task_survey')) {
            validatedSteps.link_to_task_survey = stepError ? 'error' : 'seen';
            validatedSteps.link_to_task = stepError ? 'error' : 'seen';
          }
        } else if (!bookingUtils.isSurvey(booking)) {
          if (_.get(bookingSteps, 'link_to_task_online_task')) {
            validatedSteps.link_to_task_online_task = stepError ? 'error' : 'seen';
            validatedSteps.link_to_task = stepError ? 'error' : 'seen';
          }
        }
      }

      // Validates Additional Info steps
      validatedSteps = stepperMenuUtils.validateAdditionalInfo(booking, validatedSteps, additionalInfoStepSelected);
    }

    // Test if you are navigating inside Session Times step
    if (page.includes('/session-times/calendar')) {
      if (validateCurrentLink) {
        // Validates selected step
        const stepError = stepperMenuUtils.validateSessionTimes(booking);
        if (_.get(bookingSteps, 'session_times_calendar')) {
          validatedSteps.session_times_backup = stepperMenuUtils.validateBackupSessionTimes(booking)
            ? 'warning'
            : 'seen';
          validatedSteps.session_times_calendar = stepError ? 'error' : 'seen';
          validatedSteps.session_times = stepError ? 'error' : 'seen';
        } else if (!_.get(bookingSteps, 'session_times_calendar')) {
          if (stepError) {
            validatedSteps.session_times_calendar = 'error';
            validatedSteps.session_times = 'error';
          }
          if (stepperMenuUtils.validateBackupSessionTimes(booking)) validatedSteps.session_times_backup = 'warning';
        }
        return validatedSteps;
      }
      // Validates Project Setup steps
      validatedSteps = stepperMenuUtils.validateProjectSetup(booking, validatedSteps, projectSetupSteps);
      // Validates Audience steps
      validatedSteps = stepperMenuUtils.validateAudience(booking, validatedSteps, audienceSteps, isMultiRegion);
      // Validates Additional Info steps
      validatedSteps = stepperMenuUtils.validateAdditionalInfo(booking, validatedSteps, additionalInfoSteps);
    }

    // Test if you are navigating inside Review & Submit step
    if (page.includes('/review-submit/confirm-booking')) {
      validatedSteps = stepperMenuUtils.validateConfirmBooking(
        booking,
        validatedSteps,
        projectSetupSteps,
        audienceSteps,
        additionalInfoSteps,
        aiModeratedSteps,
      );
    }

    return validatedSteps;
  },
  validateProjectSetup(booking: any, validatedSteps: any, projectSetupStepSelected: any) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    const projectSetupSteps = stepperMenuUtils.projectSubSteps(booking);
    const allStepsSeen = stepperMenuUtils.allStepsSeen(validatedSteps, projectSetupSteps);
    let stepsValidated = validatedSteps;
    if (allStepsSeen) {
      // Validates all pages
      let projectSetupValidate = false;
      _.map(projectSetupSteps, (item: any) => {
        stepsValidated[`${item.id}`] = item.validateStep ? 'error' : 'seen';
        if (item.validateStep) projectSetupValidate = true;
      });
      stepsValidated.project_setup = projectSetupValidate ? 'error' : 'seen';
    } else {
      // Validates only the pages that have been viewed
      stepsValidated = stepperMenuUtils.validateSeenProjectSetup(
        booking,
        stepsValidated,
        projectSetupStepSelected,
        projectSetupSteps,
      );
    }
    return stepsValidated;
  },
  validateSeenProjectSetup(booking: any, validatedSteps: any, projectSetupStepSelected: any, projectSetupSteps: any) {
    const projectSetupPreviousSteps = _.filter(
      projectSetupSteps,
      (item: any) => item.index < projectSetupStepSelected.index,
    );
    _.map(projectSetupPreviousSteps, (item: any) => {
      if (_.get(validatedSteps, `[${item.id}]`)) {
        validatedSteps[`${item.id}`] = item.validateStep ? 'error' : 'seen';
      }
    });
    if (_.get(validatedSteps, 'project_setup') === 'error') {
      let projectSetupValidate = false;
      _.map(projectSetupSteps, (item: any) => {
        if (item.validateStep) projectSetupValidate = true;
      });
      validatedSteps.project_setup = projectSetupValidate ? 'error' : null;
    }
    return validatedSteps;
  },
  validateAudience(booking: any, validatedSteps: any, audienceStepSelected: any, isMultiRegion: boolean) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    const audienceSteps = stepperMenuUtils.audienceSubSteps(booking, isMultiRegion);
    const allStepsSeen = stepperMenuUtils.allStepsSeen(validatedSteps, audienceSteps);
    let stepsValidated = validatedSteps;
    if (allStepsSeen) {
      // Validates all pages
      let audienceValidate = false;
      _.map(audienceSteps, (item: any) => {
        stepsValidated[`${item.id}`] = item.validateStep ? 'error' : 'seen';
        if (item.validateStep) audienceValidate = true;
      });
      stepsValidated.audience = audienceValidate ? 'error' : 'seen';
    } else {
      // Validates only the pages that have been viewed
      stepsValidated = stepperMenuUtils.validateSeenAudience(
        booking,
        stepsValidated,
        audienceStepSelected,
        audienceSteps,
      );
    }

    return stepsValidated;
  },
  validateSeenAudience(booking: any, validatedSteps: any, audienceStepSelected: any, audienceSteps: any) {
    const audiencePreviousSteps = _.filter(audienceSteps, (item: any) => item.index < audienceStepSelected.index);
    _.map(audiencePreviousSteps, (item: any) => {
      if (_.get(validatedSteps, `[${item.id}]`)) {
        validatedSteps[`${item.id}`] = item.validateStep ? 'error' : 'seen';
      }
    });
    if (_.get(validatedSteps, 'audience') === 'error') {
      let audienceValidate = false;
      _.map(audienceSteps, (item: any) => {
        if (item.validateStep) audienceValidate = true;
      });
      validatedSteps.audience = audienceValidate ? 'error' : null;
    }
    return validatedSteps;
  },
  validateLinkToTask(booking: any) {
    if (_.size(_.get(booking, 'config.online_task.links')) === 0) {
      return true;
    }
    if (_.size(_.filter(_.get(booking, 'config.online_task.links'), (onlineTask: any) => !onlineTask.checked)) > 0) {
      return true;
    }
    return false;
  },
  validateAiModerated(booking: any, validatedSteps: any, aiModeratedStepSelected: any) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    const aiModeratedSteps = stepperMenuUtils.aiModeratedSubSteps(booking);
    const allStepsSeen = stepperMenuUtils.allStepsSeen(validatedSteps, aiModeratedSteps);
    let stepsValidated = validatedSteps;
    if (allStepsSeen) {
      const researchObjectiveError = stepperMenuUtils.validateResearchObjective(booking);
      const welcomeEndScreenError = stepperMenuUtils.validateWelcomeEndScreen(booking);
      let aiModeratedValidate = false;
      _.map(aiModeratedSteps, (item: any) => {
        if (item.id === 'ai_moderated_research_objective') {
          stepsValidated.ai_moderated_research_objective = researchObjectiveError ? 'error' : 'seen';
          stepsValidated.ai_moderated = researchObjectiveError ? 'error' : 'seen';
          if (researchObjectiveError) aiModeratedValidate = true;
        }
        if (item.id === 'ai_moderated_welcome_end_screen') {
          stepsValidated.ai_moderated_welcome_end_screen = welcomeEndScreenError ? 'error' : 'seen';
          stepsValidated.ai_moderated = welcomeEndScreenError ? 'error' : 'seen';
          if (welcomeEndScreenError) aiModeratedValidate = true;
        } else {
          stepsValidated[`${item.id}`] = item.validateStep ? 'error' : 'seen';
          if (item.validateStep) aiModeratedValidate = true;
        }
      });
      stepsValidated.ai_moderated = aiModeratedValidate ? 'error' : 'seen';
    } else {
      // Validates only the pages that have been viewed
      stepsValidated = stepperMenuUtils.validateSeenAiModerated(
        booking,
        stepsValidated,
        aiModeratedStepSelected,
        aiModeratedSteps,
      );
    }
    return stepsValidated;
  },
  validateAdditionalInfo(booking: any, validatedSteps: any, additionalInfoStepSelected: any) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    const additionalInfoSteps = stepperMenuUtils.additionalInfoSubSteps(booking);
    const allStepsSeen = stepperMenuUtils.allStepsSeen(validatedSteps, additionalInfoSteps);
    let stepsValidated = validatedSteps;
    if (allStepsSeen) {
      const shortDescriptionError = stepperMenuUtils.validateShortDescription(booking);
      const fullDescriptionError = stepperMenuUtils.validateFullDescription(booking);
      let additionalInfoValidate = false;
      _.map(additionalInfoSteps, (item: any) => {
        if (item.id === 'additional_info_description') {
          stepsValidated.additional_info_short_description = shortDescriptionError ? 'error' : 'seen';
          stepsValidated.additional_info_description = fullDescriptionError ? 'error' : 'seen';
          if (shortDescriptionError || fullDescriptionError) additionalInfoValidate = true;
        } else {
          stepsValidated[`${item.id}`] = item.validateStep ? 'error' : 'seen';
          if (item.validateStep) additionalInfoValidate = true;
        }
      });
      stepsValidated.additional_info = additionalInfoValidate ? 'error' : 'seen';
    } else {
      // Validates only the pages that have been viewed
      stepsValidated = stepperMenuUtils.validateSeenAdditionalInfo(
        booking,
        stepsValidated,
        additionalInfoStepSelected,
        additionalInfoSteps,
      );
    }

    return stepsValidated;
  },
  validateSeenAiModerated(booking: any, validatedSteps: any, aiModeratedStepSelected: any, aiModeratedSteps: any) {
    const aiModeratedPreviousSteps = _.filter(
      aiModeratedSteps,
      (item: any) => item.index < aiModeratedStepSelected.index,
    );
    _.map(aiModeratedPreviousSteps, (item: any) => {
      if (_.get(validatedSteps, `[${item.id}]`)) {
        if (item.id === 'ai_moderated_research_objective') {
          validatedSteps.ai_moderated_research_objective = stepperMenuUtils.validateResearchObjective(booking)
            ? 'error'
            : 'seen';
        }
        if (item.id === 'ai_moderated_welcome_end_screen') {
          validatedSteps.ai_moderated_welcome_end_screen = stepperMenuUtils.validateWelcomeEndScreen(booking)
            ? 'error'
            : 'seen';
        } else {
          validatedSteps[`${item.id}`] = item.validateStep ? 'error' : 'seen';
        }
      }
    });
    if (_.get(validatedSteps, 'ai_moderated') === 'error') {
      const researchObjectiveError = stepperMenuUtils.validateResearchObjective(booking);
      const welcomeEndScreenError = stepperMenuUtils.validateWelcomeEndScreen(booking);
      let aiModeratedValidate = false;
      _.map(aiModeratedSteps, (item: any) => {
        if (item.id === 'ai_moderated_research_objective') {
          if (researchObjectiveError) aiModeratedValidate = true;
        }
        if (item.id === 'ai_moderated_welcome_end_screen') {
          if (welcomeEndScreenError) aiModeratedValidate = true;
        } else if (item.validateStep) {
          aiModeratedValidate = true;
        }
      });
      validatedSteps.ai_moderated = aiModeratedValidate ? 'error' : null;
    }

    return validatedSteps;
  },
  validateSeenAdditionalInfo(
    booking: any,
    validatedSteps: any,
    additionalInfoStepSelected: any,
    additionalInfoSteps: any,
  ) {
    const additionalInfoPreviousSteps = _.filter(
      additionalInfoSteps,
      (item: any) => item.index < additionalInfoStepSelected.index,
    );
    _.map(additionalInfoPreviousSteps, (item: any) => {
      if (_.get(validatedSteps, `[${item.id}]`)) {
        if (item.id === 'additional_info_description') {
          validatedSteps.additional_info_short_description = stepperMenuUtils.validateShortDescription(booking)
            ? 'error'
            : 'seen';
          validatedSteps.additional_info_description = stepperMenuUtils.validateFullDescription(booking)
            ? 'error'
            : 'seen';
        } else {
          validatedSteps[`${item.id}`] = item.validateStep ? 'error' : 'seen';
        }
      }
    });
    if (_.get(validatedSteps, 'additional_info') === 'error') {
      const shortDescriptionError = stepperMenuUtils.validateShortDescription(booking);
      const fullDescriptionError = stepperMenuUtils.validateFullDescription(booking);
      let additionalInfoValidate = false;
      _.map(additionalInfoSteps, (item: any) => {
        if (item.id === 'additional_info_description') {
          if (shortDescriptionError || fullDescriptionError) additionalInfoValidate = true;
        } else if (item.validateStep) {
          additionalInfoValidate = true;
        }
      });
      validatedSteps.additional_info = additionalInfoValidate ? 'error' : null;
    }

    return validatedSteps;
  },
  validateProjectTitle(booking: any) {
    if (!_.get(booking, 'name')) return true;
    return false;
  },
  validateResearchType(booking: any) {
    if (!_.get(booking, 'type')) return true;
    return false;
  },
  validateTool(booking: any) {
    if (bookingUtils.isOnlineTask(booking) && !_.get(booking, 'config.online_task')) return true;
    if (bookingUtils.isRemote(booking) && !_.get(booking, 'config.remote')) return true;

    return false;
  },
  validateQuota(booking: any) {
    // @ts-expect-error ts-migrate(2365) FIXME: Operator '>=' cannot be applied to types 'boolean'... Remove this comment to see the full error message
    if (!_.get(booking, 'total_participants') >= 1) return true;
    return false;
  },
  validateSessionFormat(booking: any) {
    if (!_.has(booking, 'config.session.type')) return true;
    return false;
  },
  validateSessionDuration(booking: any) {
    // @ts-expect-error ts-migrate(2365) FIXME: Operator '>=' cannot be applied to types 'boolean'... Remove this comment to see the full error message
    if (!_.get(booking, 'config.session.duration') >= 1) return true;
    return false;
  },
  validateTaskDuration(booking: any) {
    // @ts-expect-error ts-migrate(2365) FIXME: Operator '>=' cannot be applied to types 'boolean'... Remove this comment to see the full error message
    if (!_.get(booking, 'config.session.duration') >= 1) return true;
    return false;
  },
  validateStudyDuration(booking: any) {
    // @ts-expect-error ts-migrate(2365) FIXME: Operator '>=' cannot be applied to types 'boolean'... Remove this comment to see the full error message
    if (!_.get(booking, 'config.longitudinal_study.participant_workload.time') >= 1) return true;
    // @ts-expect-error ts-migrate(2365) FIXME: Operator '>=' cannot be applied to types 'boolean'... Remove this comment to see the full error message
    if (!_.get(booking, 'config.longitudinal_study.period.time') >= 1) return true;
    if (
      _.get(booking, 'config.longitudinal_study.period.frequency') <
      _.get(booking, 'config.longitudinal_study.participant_workload.frequency')
    ) {
      return true;
    }

    return false;
  },
  validateParticipantSelection(booking: any) {
    if (!_.has(booking, 'config.options.review_submission')) return true;
    return false;
  },
  validatePanel() {
    return false;
  },
  validateIncentives() {
    return false;
  },
  validateParticipantLocations(booking?: Booking | null) {
    if (bookingUtils.isInPerson(booking)) {
      return false;
    }

    if (booking?.config?.location?.country) {
      return false;
    }

    const locations = booking?.config?.criteria?.locations;
    if (locations?.bounds?.length || locations?.states?.length || locations?.countries?.length) {
      return false;
    }

    return true;
  },
  validateParticipantLocationsMultiRegion(booking?: Booking | null) {
    if (bookingUtils.isInPerson(booking)) {
      return false;
    }

    const locations = booking?.config?.criteria?.locations;
    if (locations?.bounds?.length || locations?.states?.length || locations?.countries?.length) {
      return false;
    }

    return true;
  },
  validateSessionLocation(booking: any) {
    if (bookingUtils.isInPerson(booking)) {
      if (
        !_.get(booking, 'config.location.country') ||
        !_.get(booking, 'config.location.city') ||
        !_.get(booking, 'config.location.name') ||
        !_.get(booking, 'config.location.state') ||
        !_.get(booking, 'config.location.street1')
      ) {
        return true;
      }
    }
    return false;
  },
  validateDemographicFilters() {
    return false;
  },
  validateCustomScreening() {
    return false;
  },
  validateYourTimeZone(booking: any) {
    if (!_.get(booking, 'config.timezone')) return true;
    return false;
  },
  validateResearchObjective(booking: any) {
    const objective = _.get(booking, 'config.ai_moderated.prompt.overview');
    const context = _.get(booking, 'config.ai_moderated.prompt.context');
    if (!objective) return true;
    if (objective.length > 300 || context.length > 300) return true;
    return false;
  },
  validateWelcomeEndScreen(booking: any) {
    const title = _.get(booking, 'config.ai_moderated.information.welcome_title');
    const description = _.get(booking, 'config.ai_moderated.information.welcome_body');
    if (!title || title.length > 50) return true;
    if (!description || description.length > 180) return true;
    return false;
  },
  validateShortDescription(booking: any) {
    if (!_.get(booking, 'config.information.short_description')) return true;
    if (_.get(booking, 'config.information.short_description').length > 240) {
      return true;
    }
    return false;
  },
  validateFullDescription(booking: any) {
    if (!_.get(booking, 'config.information.brief_summary')) {
      return true;
    }
    return false;
  },
  validateDescription(booking: any) {
    if (
      !_.get(booking, 'config.information.brief_summary') ||
      !_.get(booking, 'config.information.short_description')
    ) {
      return true;
    }

    if (_.get(booking, 'config.information.short_description').length > 240) {
      return true;
    }
    return false;
  },
  validateAdditionalInstructions() {
    return false;
  },
  validateContacts(booking: Booking) {
    // It should only validate the contacts if booking is in person
    if (
      booking?.config?.contact?.UserOrganiser &&
      !booking?.config?.contact?.UserOrganiser?.contact?.phone?.mobile &&
      bookingUtils.isInPerson(booking)
    ) {
      return true;
    }
    return false;
  },
  validateLegalPrivacy(booking: any) {
    if (!_.has(booking, 'config.participant_agreement.type')) return false;
    if (booking.config.participant_agreement.type === BOOKING_CONFIG_PARTICIPANT_AGREEMENT_TYPE.ASKABLE_TERMS) {
      return false;
    }

    return (
      !booking.config.participant_agreement._template_id ||
      _.isEmpty(booking.config.participant_agreement.recipients || [])
    );
  },
  validateRemoteModeratedOrFaceToFaceDate(booking: any) {
    if (bookingUtils.isRemote(booking) || bookingUtils.isInPerson(booking)) {
      if (_.size(_.get(booking, 'session')) === 0) return false;
      if (_.get(booking, 'session[0].start') === _.get(booking, 'session[0].end')) return false;
    }
    return true;
  },
  validateSlotsNeeded(booking: any) {
    if (booking.config.session.type === 1 && (bookingUtils.isRemote(booking) || bookingUtils.isInPerson(booking))) {
      if (_.size(_.get(booking, 'session')) < _.get(booking, 'total_participants')) {
        return false;
      }
      return true;
    }
    if (_.size(_.get(booking, 'session')) === 0) {
      return false;
    }
    return true;
  },
  validateSessionStartDate(booking: any) {
    // Method to validate whether or not the session start date is valid
    //  It should not test if the booking is already live
    if (bookingUtils.isActive(booking)) return true;

    const orderedArray = utils.orderArrayByDate(_.get(booking, 'session'), 'start');
    if (orderedArray.length === 0) return false;
    const minDate = moment(utils.addDaysToTimestamp(moment(moment().format('DD/MM/YYYY'), 'DD/MM/YYYY').valueOf(), 1));
    return moment(orderedArray[0].start).isSameOrAfter(minDate);
  },
  validateClosingDate() {
    // Longitudinal study has the same logic as the online task
    // if (bookingUtils.isOnlineTask(booking) || bookingUtils.isLongitudinal(booking)) {
    //     if (booking.session.length > 1) return true;
    //     if (booking.session.length === 1 && booking.session[0].start !== booking.session[0].end) return true;
    //     if (!stepperMenuUtils.validateSessionStartDate(booking)) return true;
    // }
    return false;
  },
  validateSessionTimes(booking: any) {
    const doesHaveSlotsNeeded = stepperMenuUtils.validateSlotsNeeded(booking);
    const doesHaveAValidStartDate = stepperMenuUtils.validateSessionStartDate(booking);
    const doesHaveAValidRemoteModeratedOrFaceToFaceDate =
      stepperMenuUtils.validateRemoteModeratedOrFaceToFaceDate(booking);

    return !(doesHaveSlotsNeeded && doesHaveAValidStartDate && doesHaveAValidRemoteModeratedOrFaceToFaceDate);
  },
  validateBackupSessionTimes(booking: any) {
    const extraBackupSessions = bookingUtils.isFocusGroup(booking)
      ? 0
      : Math.floor(_.get(booking, 'total_participants') / 2) || 0;
    if (_.get(booking, 'total_participants') + extraBackupSessions > _.size(_.get(booking, 'session'))) return true;
    return false;
  },
  validateDevice() {
    return false;
  },
};

export { stepperMenuUtils };
