import { z } from 'zod';

export const AGE_LIMIT = { min: 18, max: 99 };
export const PARTICIPANTS_LIMIT = { min: 1, max: 50 };
export const DEVICE_OPTIONS = ['any_device', 'mobile', 'desktop'] as const;
export const GENDER_OPTIONS = ['any_gender', 'male', 'female', 'non_binary'] as const;
export const PARTICIPANT_SELECTION_OPTIONS = ['manual', 'automated'] as const;
export const PARTICIPANTS_EXCLUDED_OPTIONS = ['none', 'last_3_months', 'last_6_months', 'last_12_months'] as const;
export const LANGUAGE_FLUENCY_OPTIONS = ['native', 'fluent', 'conversational', 'beginner'] as const;
export const EDUCATION_LEVEL_OPTIONS = [
  'high_school',
  'some_university',
  'undergraduate',
  'postgraduate',
  'apprenticeship',
] as const;
export const MARITAL_STATUS_OPTIONS = ['single', 'married', 'divorced', 'widowed'] as const;
export const OCCUPATIONAL_STATUS_OPTIONS = [
  'full_time',
  'part_time',
  'student',
  'home_duties',
  'retired',
  'unemployed',
] as const;
export const BUSINESS_OWNERSHIP_OPTIONS = ['employee', 'sole_trader', 'business_owner'] as const;

const validateRequiredFields = (
  data: any,
  ctx: z.RefinementCtx,
  fields: readonly string[],
  message: string = 'required',
) => {
  fields.forEach(field => {
    const value = data[field as keyof typeof data];
    if (!value || !Array.isArray(value) || value.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message,
        path: [field],
      });
    }
  });
};

const industryAndOccupationItemSchema = z.object({
  _id: z.string(),
  industry: z.string(),
  occupations: z.array(z.string()),
});

const screenerQuestionAnswerSchema = z.object({
  _id: z.string(),
  value: z.string(),
  isQualified: z.boolean(),
});

const screenerQuestionSchema = z
  .object({
    _id: z.string(),
    question: z.string(),
    description: z.string().nullable().optional(),
    type: z.enum(['short_answer', 'multiple_choice']),
    isMultipleSelection: z.boolean().optional(),
    answers: z.array(screenerQuestionAnswerSchema).optional(),
  })
  .refine(
    data => {
      /* Skip validation if type is not multiple_choice or isMultipleSelection is true */
      if (data.type !== 'multiple_choice' || data.isMultipleSelection || !data.answers) {
        return true;
      }

      const qualifiedCount = data.answers.filter(answer => answer.isQualified).length;

      // When isMultipleSelection is false, only one answer can be qualified
      return qualifiedCount <= 1;
    },
    {
      message: 'screenerQuestions.multipleChoiceQualified',
      path: ['options'],
    },
  );

const baseRecruitSchema = z
  .object({
    participants: z
      .number()
      .min(PARTICIPANTS_LIMIT.min, { message: 'participants.min' })
      .max(PARTICIPANTS_LIMIT.max, { message: 'participants.max' }),
    device: z.enum(DEVICE_OPTIONS, {
      invalid_type_error: 'required',
    }),
    locations: z.array(z.string()).nonempty({ message: 'requiredOne' }),
    age_range: z.tuple([
      z.number().min(AGE_LIMIT.min, { message: 'ageRange.min' }),
      z.number().max(AGE_LIMIT.max, { message: 'ageRange.max' }),
    ]),
    gender: z.enum(GENDER_OPTIONS, { invalid_type_error: 'required' }),
    advanced_filters: z.boolean().optional(),
    industry_and_occupation: z.array(industryAndOccupationItemSchema).optional(),
    language_fluency: z.array(z.enum(LANGUAGE_FLUENCY_OPTIONS)).optional(),
    education_level: z.array(z.enum(EDUCATION_LEVEL_OPTIONS)).optional(),
    marital_status: z.array(z.enum(MARITAL_STATUS_OPTIONS)).optional(),
    occupational_status: z.array(z.enum(OCCUPATIONAL_STATUS_OPTIONS)).optional(),
    business_ownership: z.array(z.enum(BUSINESS_OWNERSHIP_OPTIONS)).optional(),
    participant_selection: z.enum(PARTICIPANT_SELECTION_OPTIONS, { required_error: 'requiredOne' }),
    participants_excluded: z.enum(PARTICIPANTS_EXCLUDED_OPTIONS, {
      required_error: 'requiredOne',
    }),
    primary_contact: z.string().min(1, { message: 'required' }),
    terms_custom: z.boolean().optional(),
    terms_custom_id: z.string({ invalid_type_error: 'required' }).nonempty({ message: 'required' }).optional(),
    terms_custom_recipients: z.array(z.string()).nonempty({ message: 'required' }).optional(),
    screener_questions: z.array(screenerQuestionSchema).nonempty({ message: 'requiredOne' }).optional(),
    opportunity_listing_title: z.string().min(2, { message: 'required' }).max(240, { message: 'max' }),
    opportunity_listing_description: z.string().min(2, { message: 'required' }),
  })
  .superRefine((data, ctx) => {
    /* Conditional validation: make the following fields required if advanced_filters is true */
    // if (data.advanced_filters === true) {
    //   const requiredAdvancedFields = [
    //     'industry_and_occupation',
    //     'language_fluency',
    //     'education_level',
    //     'marital_status',
    //     'occupational_status',
    //     'business_ownership',
    //   ] as const;

    //   validateRequiredFields(data, ctx, requiredAdvancedFields, 'requiredOne');
    // }

    /* Conditional validation: make the following fields required if terms_custom is true */
    if (data.terms_custom === true) {
      const requiredTermsFields = ['terms_custom_id', 'terms_custom_recipients'] as const;
      validateRequiredFields(data, ctx, requiredTermsFields);
    }
  });

export const recruitSchema = baseRecruitSchema;
