/* eslint-disable max-lines */
import { Button } from '@askable/ui/components/ui/button';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { X } from 'lucide-react';
import { useEffect, useState } from 'react';

import { Card } from 'components/common';
import CardContainer from 'components/createBooking/components/cardContainer';
import PriceCardContainer from 'components/createBooking/components/priceCardContainer';
import { bookingUtils } from 'lib/booking';
import { utils } from 'lib/utils';

import './styles/incentivesStyles.scss';

function Incentives(props: any) {
  const booking = _.get(props, 'booking');
  const allFilters = bookingUtils.demographicFilterOptions();
  const [showIncentivesTip, setShowIncentivesTip] = useState(false);
  const [bookingState, setBookingState] = useState(booking);
  const [bookingData, setBookingData] = useState(null);
  const [incentivesType, setIncentivesType] = useState(_.get(booking, 'config.participant_category'));
  const [hasPremiumFilters, setHasPremiumFilters] = useState(false);
  // const [incentivesAverage, setIncentivesAverage] = useState([]);

  // const [incentiveAmount, setIncentiveAmount] = useState(_.get(booking, 'config.incentive.value'));
  // const [incentiveValues, setIncentiveValues] = useState([]);
  // const [incentiveLabel, setIncentiveLabel] = useState();
  // const [currencyCode, setCurrencyCode] = useState();

  useEffect(() => {
    props.updateLastStep({
      step: 'Audience',
      subStep: `/booking-setup/${props.booking._id}/audience/incentives`,
      stepId: 'audience_incentives',
    });
    setTimeout(() => setShowIncentivesTip(true), 350);
  }, []);

  useEffect(() => {
    setBookingState(props.booking);
    setIncentivesType(_.get(props.booking, 'config.participant_category'));

    // Load all saved filters
    const allFiltersSaved = _.filter(allFilters, item => {
      if (
        _.has(props.booking, `config.criteria[${item.id}]`) &&
        _.get(props.booking, `config.criteria[${item.id}]`) !== null &&
        _.get(item, 'type') === 'professionals'
      ) {
        return item;
      }
    });
    setHasPremiumFilters(_.size(allFiltersSaved) > 0);
  }, [props.booking]);

  useEffect(() => {
    props.renderRightContent(rightContent());
    props.renderRightAppPreview(null);
    // getCountryCurrencyData();
    // }, [incentivesAverage, bookingState, incentivesType]);
  }, [bookingState, incentivesType]);

  // useEffect(() => {
  //     getIncentivesAverage();
  // }, [incentivesType]);

  // const getIncentivesAverage = async () => {
  //     const { data } = await apolloFetch.fetch(
  //         fetchIncentivesAverage,
  //         {
  //             booking_type: _.get(booking, 'type'),
  //             booking_subtype: _.get(booking, 'config.online_task.type') || null,
  //             session_duration: _.get(booking, 'config.session.duration'),
  //             incentive_type: incentivesType
  //         }
  //     );
  //     if (data && data.incentivesAverage) {
  //         setIncentivesAverage(data.incentivesAverage);
  //     }
  // };
  // const getCountryCurrencyData = async () => {
  // const { data } = await apolloFetch.fetch(fetchCountryList);
  // const countryInfo = _.filter(data.countryList, item => item.country_code === _.get(booking, 'config.location.country'));
  // let currency_code = _.get(taxesForCountry, 'country_code');
  // if (countryInfo.length > 0) {
  //     currency_code = _.get(countryInfo, '[0].currency_code');
  // }
  // setCurrencyCode(currency_code);
  // const incentivesValue = _.map(bookingUtils.incentiveValues(), (item) => {
  //     if (!item.value) return item;
  //     return (
  //         {
  //             value: item.value,
  //             label: `${_.get(booking, 'config.incentive.currency')}${item.label} ${currency_code}`
  //         }
  //     );
  // });
  // setIncentiveValues(incentivesValue);
  // if (bookingUtils.isBYO(booking)) {
  //     let incentiveAmountType = _.get(bookingState, 'config.incentive.value');
  //     if (_.get(bookingState, 'config.incentive.provided_by_client')) {
  //         incentiveAmountType = null;
  //         setIncentiveAmount(null);
  //     }
  //     const incentiveValue = _.filter(bookingUtils.incentiveValues(), item => item.value === incentiveAmountType);
  //     if (_.size(incentiveValue) === 0) {
  //         setIncentiveLabel(`${_.get(booking, 'config.incentive.currency')}${_.get(bookingState, 'config.incentive.value')}`);
  //     } else {
  //         const incentiveValueLabel = _.filter(incentivesValue, item => item.value === incentiveAmountType);
  //         setIncentiveLabel(_.get(incentiveValueLabel, '[0].label', '').replace(` ${currency_code}`, ''));
  //     }
  // }
  // };

  // const parseSessionDuration = () => {
  //     const session = utils.getLabelFromArray(
  //         bookingUtils.sessionDurations(_.get(bookingState, 'type'), _.get(bookingState, 'config.online_task.type')),
  //         _.get(bookingState, 'config.session.duration')
  //     );
  //     const parsedDuration = (typeof session === 'string') ? session.slice(0, -1) : `${session} mins`;
  //     return parsedDuration;
  // };

  const rightContent = () => {
    // const bookingTypeSettings = bookingUtils.getBookingTypeSettings(bookingState);
    const cardTexts1 = [
      {
        cardText:
          'Premium incentives allow you to reach more specific and sometimes harder to reach demographics by offering them a higher incentive.',
      },
    ];
    // const cardTexts2 = [
    //     {
    //         cardText: `For a ${parseSessionDuration()} ${_.get(bookingTypeSettings, 'label')} session`,
    //         indicators: _.map(incentivesAverage, (item) => {
    //             return (
    //                 {
    //                     label: `$${item.incentive}`, value: item.total, displayValue: `${item.total}%`, maxValue: 100
    //                 }
    //             );
    //         })
    //     },
    //     { cardText: '* These numbers only indicative and the incentive for your study may vary', additionalText: true }
    // ];
    const cardTexts3 = [
      {
        cardText:
          "Once you submit your study, it gets reviewed by our team and then it will go live. As soon as it goes live, you'll get an email with some info including the incentive.",
      },
      {
        cardText: <span>You'll also be able to see it on the study dashboard.</span>,
        additionalText: true,
      },
    ];
    // const cardTexts4 = [{ cardText: <span>Yes you can, simply select the &quot;None&quot; option from the dropdown.</span> }];
    // const cardTexts5 = [{ cardText: <span>Yes you can, simply select the &quot;I&apos;ll handle incentives&quot; option from the menu and then use our handy messaging system to arrange your own incentives to your participants.</span> }];

    return (
      <>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          booking={bookingState}
          bookingSteps={props.bookingSteps}
          team={_.get(props, 'team')}
          context={props.context}
        />
        <p className="cardContainerTitle additionalTitle">Faqs</p>
        <CardContainer
          cardId="__incentivesCard1"
          cardClass="slideInAnimationDelay80"
          cardTitle="What are the benefits of premium incentives?"
          cardTexts={cardTexts1}
          onLinkClick={() => {
            window.open('https://help.askable.com/en/articles/4275559-participant-incentives', '_blank');
          }}
        />
        {/* {!bookingUtils.isBYO(booking) && */}
        <>
          {/* {_.size(incentivesAverage) > 0 &&
                            <CardContainer
                                additionalCard
                                cardId="__incentivesCard2"
                                cardClass="slideInAnimationDelay160"
                                cardTitle={`Typical amount for ${incentivesType === 2 ? 'Premium' : 'Standard'} incentives`}
                                cardTexts={cardTexts2}
                                onLinkClick={() => {
                                    // Track Event Analytics
                                    segment.trackEvent(
                                        `faq_typical_amount_for_${incentivesType === 2 ? 'premium' : 'standard'}_incentives_clicked`,
                                        {
                                            page: window.location.href,
                                            action: `Faq Typical amount for ${incentivesType === 2 ? 'Premium' : 'Standard'} incentives clicked`,
                                            bookingId: _.get(props, 'booking._id'),
                                            bookingName: _.get(props, 'booking.name')
                                        }
                                    );
                                    window.open('https://help.askable.com/en/articles/4275559-participant-incentives#h_c11da2e41f', '_blank');
                                }}
                            />
                        } */}
          <CardContainer
            additionalCard
            cardId="__incentivesCard3"
            cardClass="slideInAnimationDelay240"
            cardTitle="When will I find out what the incentive is?"
            cardTexts={cardTexts3}
            onLinkClick={() => {
              window.open('https://help.askable.com/en/articles/4275559-participant-incentives#h_082492fc0e', '_blank');
            }}
          />
        </>
        {/* } */}
        {/* {bookingUtils.isBYO(booking) &&
                    <Fragment>
                        <CardContainer
                            additionalCard
                            cardId="__incentivesCard4"
                            cardClass="slideInAnimationDelay320"
                            cardTitle="Can I have no incentive?"
                            cardTexts={cardTexts4}
                        />
                        <CardContainer
                            additionalCard
                            cardId="__incentivesCard5"
                            cardClass="slideInAnimationDelay400"
                            cardTitle="Can I organise my own incentives for the participants?"
                            cardTexts={cardTexts5}
                        />
                    </Fragment>
                } */}
      </>
    );
  };

  // const handleAnimation = () => {
  //     const element1 = document.getElementById('__incentivesCard1');
  //     element1.classList.remove('slideInAnimationDelay80');
  //     setTimeout(() => {
  //         element1.classList.add('slideInAnimationDelay80');
  //     }, 1);

  //     // if (!bookingUtils.isBYO(booking)) {
  //         const element2 = document.getElementById('__incentivesCard2');
  //         const element3 = document.getElementById('__incentivesCard3');
  //         if (_.size(incentivesAverage) > 0) element2.classList.remove('slideInAnimationDelay160');
  //         element3.classList.remove('slideInAnimationDelay240');
  //         setTimeout(() => {
  //             if (_.size(incentivesAverage) > 0) element2.classList.add('slideInAnimationDelay160');
  //             element3.classList.add('slideInAnimationDelay240');
  //         }, 1);
  //     // }

  //     // if (bookingUtils.isBYO(booking)) {
  //     //     const element4 = document.getElementById('__incentivesCard4');
  //     //     const element5 = document.getElementById('__incentivesCard5');
  //     //     element4.classList.remove('slideInAnimationDelay320');
  //     //     element5.classList.remove('slideInAnimationDelay400');
  //     //     setTimeout(() => {
  //     //         element4.classList.add('slideInAnimationDelay320');
  //     //         element5.classList.add('slideInAnimationDelay400');
  //     //     }, 1);
  //     // }
  // };

  const onClickCard = (type: any) => {
    const bookingStateObj = {
      ...bookingState,
      config: {
        ...booking.config,
        participant_category: type,
        criteria: {
          ...utils.removeTypenames(booking.config.criteria),
          meta_work_employment_type: type === 1 ? null : _.get(booking, 'config.criteria.meta_work_employment_type'),
          meta_work_industry: type === 1 ? null : _.get(booking, 'config.criteria.meta_work_industry'),
        },
      },
    };
    setBookingState(bookingStateObj);

    if (type !== _.get(booking, 'config.participant_category')) {
      const bookingObj = {
        config: {
          participant_category: type,
        },
      };
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ config: { participant_category... Remove this comment to see the full error message
      setBookingData(bookingObj);
      props.history.replace({ booking: bookingObj, bookingState: bookingStateObj });
    } else {
      setBookingData(null);
      props.history.replace({ booking: null, bookingState: null });
    }

    // handleAnimation();
    setTimeout(() => setIncentivesType(type), 2);
  };

  // const onChangeValue = (value) => {
  //     let providedByClient = false;
  //     let newIncentive = value;
  //     if (value === null) {
  //         providedByClient = true;
  //         newIncentive = 0;
  //     } else if (value && value >= 0) {
  //         newIncentive = parseInt(value, 10);
  //     }

  //     const bookingObj = {
  //         config: {
  //             incentive: {
  //                 value: newIncentive,
  //                 provided_by_client: providedByClient
  //             }
  //         }
  //     };
  //     setIncentiveAmount(newIncentive);
  //     setBookingData(bookingObj);
  //     setBookingState({
  //         ...booking,
  //         config: {
  //             ...booking.config,
  //             incentive: {
  //                 ...booking.config.incentive,
  //                 value: newIncentive,
  //                 provided_by_client: providedByClient
  //             }
  //         }
  //     });

  //     if (newIncentive !== _.get(booking, 'config.incentive.value') ||
  //         providedByClient !== _.get(booking, 'config.incentive.provided_by_client')) {
  //         props.history.replace({ booking: bookingObj });
  //     } else {
  //         props.history.replace({ booking: null, bookingState: null });
  //     }
  // };

  const onClickNext = () => {
    const redirectTo = `/booking-setup/${booking._id}/audience/custom-screener`;
    props.history.push({ pathname: redirectTo, booking: bookingData, bookingState });
  };

  const premiumIncentiveSubtitle = () => {
    let incentivesMultiplier = 1.5;
    // 5-10 mins survey - 2.5x
    // 15-30 min survey - 2x
    if (bookingUtils.isSurvey(booking)) {
      if (_.get(booking, 'config.session.duration') === 5 || _.get(booking, 'config.session.duration') === 10) {
        incentivesMultiplier = 2.5;
      } else {
        incentivesMultiplier = 2;
      }
    }

    return (
      <div className="premiumIncentiveContainer">
        <img className="premiumIncentiveIcon" src="/booking/icons/flashCircleIcon.svg" alt="flashCircle" />
        <span className="premiumIncentiveSubtitle">{`${incentivesMultiplier}x standard incentives`}</span>
      </div>
    );
  };

  // if (!bookingUtils.isBYO(booking)) {
  return (
    <div className="createBookingContent">
      <h1 id="__pageTitle" className="title">
        Choose your incentive level
      </h1>
      <p className="label">The incentives are automatically selected based on your chosen demographic filters.</p>
      <div className="incentivesTypes">
        <Card
          id="__standardIncentiveCard"
          title="Standard incentives"
          className="incentivesCard"
          content={[
            { label: 'Best for lower income earners,' },
            { label: 'people with plenty of free time,' },
            { label: 'easy to reach etc' },
          ]}
          selected={incentivesType === 1}
          image="/booking/illustrations/oneCoin.svg"
          onClickCard={() => onClickCard(1)}
        />
        <Card
          id="__premiumIncentiveCard"
          title="Premium incentives"
          subtitle={premiumIncentiveSubtitle()}
          className="incentivesCard"
          content={[
            { label: 'Best for higher income earners,' },
            { label: 'busy professionals, hard to' },
            { label: 'reach people etc' },
          ]}
          selected={incentivesType === 2}
          image="/booking/illustrations/manyCoins.svg"
          onClickCard={() => onClickCard(2)}
        />
      </div>
      {incentivesType === 1 && hasPremiumFilters && (
        <div className="additionalInfoContainer flipInAnimation mtop30">
          <img className="headsUpIcon" src="/icons/warningIcon.svg" alt="Type illustration" />
          <div className="additionalInfoContent">
            <p className="additionalInfoTitle">Heads up!</p>
            <p className="additionalInfoText">
              We have removed your premium demographic filters to match the standard incentives. You can select the
              premium incentives again to reapply your premium demographic filters.
            </p>
          </div>
        </div>
      )}
      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
      {showIncentivesTip && (
        <div className="additionalInfoContainer flipAnimation">
          <img className="additionalInfoImage" src="/booking/illustrations/money.svg" alt="illustration" />
          <div className="additionalInfoContent">
            <p className="additionalInfoTitle">Why can&apos;t I set an exact amount?</p>
            <p className="additionalInfoText">
              Askable's smart algorithms and experienced team do the heavy lifting when it comes to setting the
              incentive at the perfect amount for your study, so you can focus on the important stuff: doing the
              research!
            </p>
          </div>
          <X
            className="closeIcon"
            onClick={() => {
              setShowIncentivesTip(false);
            }}
            style={{ width: 20, height: 20 }}
          />
        </div>
      )}
    </div>
  );
}

export default deprecatedWithRouter(Incentives);
