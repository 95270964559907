import { GripVertical } from 'lucide-react';

interface Props {
  dragHandleProps: Record<string, unknown>;
  subtitle: string;
}

export const TaskCardDraggableHeader = ({ subtitle, dragHandleProps }: Props) => (
  <div
    className="group flex !cursor-grab touch-none items-center justify-between gap-3 p-4 active:!cursor-grabbing"
    title="Drag card"
    {...dragHandleProps}
  >
    <div className="w-fit rounded-lg bg-background-subtle px-3 py-2 text-xs font-medium leading-none">{subtitle}</div>
    <GripVertical className="h-4 w-4 text-muted-foreground group-hover:text-foreground group-active:text-foreground" />
  </div>
);
