import { useParams } from 'react-router-dom';
import { useQuery } from 'urql';

import { EmptyState } from 'containers/Studies/Results/components/EmptyState';
import { TaskHeader } from 'containers/Studies/Results/components/Task/TaskHeader';
import { useStudyContext } from 'containers/Studies/StudiesContainer';

import { IndividualResponses } from '../components/Task/IndividualResponses';
import { StudyTaskBlockResultsQuery } from '../data/studyTaskBlockResults.query';

export const TaskContent = () => {
  const params = useParams();
  const { study } = useStudyContext();
  const taskBlock = study.config?.unmoderated?.task_blocks?.find(block => block?._id === params.taskId);

  const [{ data }] = useQuery({
    query: StudyTaskBlockResultsQuery,
    variables: {
      filter: {
        _study_id: { eq: study._id },
        _block_id: { eq: params.taskId },
      },
    },
    pause: !params.taskId,
  });

  return (
    <div
      id={`task-block-${taskBlock?._id}`}
      className="flex h-full flex-col gap-10 rounded-xl bg-background p-3 lg:p-8"
    >
      {taskBlock && data ? (
        <>
          <TaskHeader title={taskBlock.title} type={taskBlock.type} responses={data.studyBlockResults.nodes.length} />
          {data.studyBlockResults.totalCount ? (
            <IndividualResponses
              results={data.studyBlockResults.nodes}
              totalCount={data.studyBlockResults.totalCount}
            />
          ) : (
            <EmptyState />
          )}
        </>
      ) : (
        <EmptyState />
      )}
    </div>
  );
};
