import { FormControl, FormItem } from '@askable/ui/components/ui/form';
import { MultiSelect } from '@askable/ui/components/ui/multi-select';
import { memo } from 'react';
import { useController } from 'react-hook-form';

import { FormErrorMessage } from 'components/Form/FormErrorMessage';
import { FieldLabel } from 'containers/Studies/components/Fields/FieldLabel';

import type { InfoPopoverProps } from 'containers/Studies/components/InfoPopover';

interface Option {
  label: string;
  value: string;
}

interface MultiSelectFieldProps {
  description?: string;
  infoPopover?: InfoPopoverProps;
  isDisabled?: boolean;
  label: string;
  name: string;
  options: Option[];
  placeholder?: string;
}

const MultiSelectFieldComponent = ({
  description,
  infoPopover,
  isDisabled,
  label,
  options,
  placeholder,
  name,
}: MultiSelectFieldProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name });

  return (
    <FormItem>
      <FieldLabel name={name} label={label} description={description} infoPopover={infoPopover} />

      <FormControl>
        <MultiSelect
          aria-describedby={description ? `description_${name}` : undefined}
          disabled={isDisabled}
          id={`input_${name}`}
          onChange={value => field.onChange(value)}
          options={options}
          placeholder={placeholder}
          values={field.value}
        />
      </FormControl>

      {error ? <FormErrorMessage message={error.message} prefix="sections.studies.recruit.formValidation" /> : null}
    </FormItem>
  );
};

MultiSelectFieldComponent.displayName = 'MultiSelectField';

export const MultiSelectField = memo(MultiSelectFieldComponent);
