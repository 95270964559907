import { Button } from '@askable/ui/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@askable/ui/components/ui/dropdown-menu';
import { cn } from '@askable/ui/lib/utils';
import { ArrowDownIcon, ArrowUpIcon, ChevronsUpDown } from 'lucide-react';

import type React from 'react';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  sortConfig?: {
    /**
     * If the table is empty, disable sorting.
     */
    disableSortByPageCount?: number;
    isSorted: false | 'desc' | 'asc';
    onAscClick: () => void;
    onDescClick: () => void;
  };
}

export function TableColumnHeader({ sortConfig, title, className }: Props) {
  if (!sortConfig) {
    return <div className={cn(className, 'font-medium text-foreground')}>{title}</div>;
  }

  const Icon = (() => {
    const sorted = sortConfig.isSorted;
    if (sorted === 'desc') {
      return ArrowDownIcon;
    }
    if (sorted === 'asc') {
      return ArrowUpIcon;
    }
    return ChevronsUpDown;
  })();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          disabled={sortConfig.disableSortByPageCount === 0}
          variant="ghost"
          className={cn('-ml-3 h-8 data-[state=open]:bg-accent', className)}
        >
          <span className="font-medium">{title}</span>
          <Icon size={16} className="ml-2" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start">
        <DropdownMenuItem onClick={sortConfig.onAscClick}>
          <ArrowUpIcon className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
          Asc
        </DropdownMenuItem>
        <DropdownMenuItem onClick={sortConfig.onDescClick}>
          <ArrowDownIcon className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
          Desc
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
