/* eslint-disable max-lines */
import { graphql } from '@apollo/client/react/hoc';
import { Button } from '@askable/ui/components/ui/button';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { useState, useEffect, Fragment } from 'react';

import { BoxMessage, LoadingOverlay } from 'components/common';
import CardContainer from 'components/createBooking/components/cardContainer';
import PriceCardContainer from 'components/createBooking/components/priceCardContainer';
import createOnlineTaskLinkMutation from 'data/mutations/booking/createOnlineTaskLink';
import deleteOnlineTaskLinkMutation from 'data/mutations/booking/deleteOnlineTaskLink';
import updateOnlineTaskLinkMutation from 'data/mutations/booking/updateOnlineTaskLink';
import { bookingUtils } from 'lib/booking';
import { update } from 'lib/utils';

import OnlineVariant from './onlineLinkVariant';

import './styles/linkToTaskStyles.scss';

function LinkToTask(props: any) {
  const booking = _.get(props, 'booking');
  const [bookingState, setBookingState] = useState(booking);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [onlineTasks, setOnlineTasks] = useState([]);
  const unverifiedTasks = _.filter(onlineTasks, (onlineTask: any) => onlineTask.checked !== true);

  useEffect(() => {
    if (!bookingUtils.isOnlineTask(_.get(props, 'booking'))) {
      props.history.push({
        pathname: `/booking-setup/${_.get(props, 'booking._id')}/session-times/calendar`,
      });
    }
    props.updateLastStep({
      step: 'Link to Task',
      subStep: `/booking-setup/${props.booking._id}/link-to-task/${bookingUtils.isSurvey(_.get(props, 'booking')) ? 'survey' : 'online-task'}`,
      stepId: `link_to_task_${bookingUtils.isSurvey(_.get(props, 'booking')) ? 'survey' : 'online_task'}`,
    });
  }, []);

  useEffect(() => {
    if (_.get(props, 'booking.config.online_task')) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ _id: any; name: any; url: any;... Remove this comment to see the full error message
      setOnlineTasks(normaliseLinks(_.get(booking, 'config.online_task.links')));
    }
    setBookingState(_.get(props, 'booking'));
  }, [props.booking]);

  useEffect(() => {
    props.renderRightContent(rightContent());
    props.renderRightAppPreview(null);
  }, [bookingState]);

  const rightContent = () => {
    const cardTexts1 = [
      {
        cardText: 'Since your study is set to automated mode, you must put the final link in now.',
      },
      {
        cardText: 'If you want to be able to update your link later, please change your study to hand picking mode.',
        additionalText: true,
      },
    ];
    const cardTexts2 = [
      {
        cardText:
          'Yes, since your study is set to cherry picking mode, you can update the link later. Just make sure you remember to do that before you start inviting applicants to complete your task.',
      },
    ];
    const cardTexts3 = [
      { cardText: 'Link variations are useful in a many different situations' },
      {
        cardText:
          'For example if you have two slightly different studies that you want to send to the same demographic, you can set up a link variation instead of having to create two separate studies.',
        additionalText: true,
      },
      {
        cardText: (
          <span>
            When you&apos;re selecting applicants to complete the task, you&apos;ll be able to pick which link variation
            you want to send them.
          </span>
        ),
        additionalText: true,
      },
    ];
    return (
      <Fragment>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          booking={bookingState}
          bookingSteps={props.bookingSteps}
          team={_.get(props, 'team')}
          context={props.context}
          condensedCard
        />
        <p className="cardContainerTitle additionalTitle">Faqs</p>
        {!_.get(bookingState, 'config.options.review_submission') && (
          <CardContainer
            cardId="__linkToTaskCard1"
            cardClass="slideInAnimationDelay80"
            cardTitle="Can I update the link later?"
            cardTexts={cardTexts1}
            onLinkClick={() => {
              props.history.push({
                pathname: `/booking-setup/${_.get(props, 'booking._id')}/project-setup/participant-selection`,
              });
            }}
            linkText="Go to study setup"
          />
        )}
        {_.get(bookingState, 'config.options.review_submission') && (
          <CardContainer
            cardId="__linkToTaskCard1"
            cardClass="slideInAnimationDelay80"
            cardTitle="Can I update the link later?"
            cardTexts={cardTexts2}
            onLinkClick={() => {
              window.open(
                'https://help.askable.com/en/articles/4293059-how-to-add-your-link-to-an-online-task-or-survey#h_7f84abc57b',
                '_blank',
              );
            }}
          />
        )}
        {_.get(bookingState, 'config.options.review_submission') &&
          _.size(_.get(bookingState, 'config.online_task.links')) > 0 && (
            <CardContainer
              cardId="__linkToTaskCard2"
              cardClass="slideInAnimationDelay160"
              additionalCard
              cardTitle="How do link variations work?"
              cardTexts={cardTexts3}
              onLinkClick={() => {
                window.open(
                  'https://help.askable.com/en/articles/4293059-how-to-add-your-link-to-an-online-task-or-survey',
                  '_blank',
                );
              }}
            />
          )}
      </Fragment>
    );
  };

  const normaliseLinks = (links: any) => {
    // Method to normalise links
    return _.map(links, (link: any) => {
      return {
        _id: link._id,
        name: link.name,
        url: link.url,
        checked: link.checked,
      };
    });
  };

  const onOnlineTaskUpdated = (onlineTask: any) => {
    setLoading(true);
    let newArrayOnlineTasks = [];
    if (_.size(onlineTasks) === 0) {
      newArrayOnlineTasks = update(onlineTasks, {
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ url: any; checked: any; name: any; }[]' is... Remove this comment to see the full error message
        $push: [
          {
            url: onlineTask.url,
            checked: onlineTask.checked,
            name: onlineTask.name,
          },
        ],
      });
    } else {
      let internalIndex = _.findIndex(onlineTasks, (online: any) => online._id === _.get(onlineTask, '_id'));
      if (internalIndex < 0) internalIndex = onlineTask.index;
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ [x: number]: { url: { $set: an... Remove this comment to see the full error message
      newArrayOnlineTasks = update(onlineTasks, {
        [internalIndex]: {
          url: { $set: onlineTask.url },
          checked: { $set: onlineTask.checked },
          name: { $set: onlineTask.name },
        },
      });
    }
    if (_.get(onlineTask, '_id')) {
      onUpdateOnlineTask(onlineTask);
    } else {
      onCreateNewOnlineTaskLink({
        name: onlineTask.name,
        url: onlineTask.url,
        checked: onlineTask.checked,
      });
    }
    setOnlineTasks(newArrayOnlineTasks);
  };

  const onRemoveOnlineTask = (link_id: any) => {
    setLoading(true);
    const internalIndex = _.findIndex(onlineTasks, (onlineTask: any) => onlineTask._id === link_id);
    const newArrayOnlineTasks = update(onlineTasks, {
      $splice: [[internalIndex, 1]],
    });
    setOnlineTasks(newArrayOnlineTasks);

    if (link_id) {
      onDeleteOnlineTask(link_id);
    } else {
      setLoading(false);
    }
  };

  const onClickNext = () => {
    const redirectTo = `/booking-setup/${booking._id}/additional-info/closing-date`;
    props.history.push({ pathname: redirectTo, booking: null, bookingState: null });
  };

  const onCreateNewOnlineTaskLink = async ({ name, url, checked }: any) => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '""' is not assignable to paramet... Remove this comment to see the full error message
    setError('');
    try {
      await props.createOnlineTaskLink(_.get(booking, '_id'), { name, url, checked }).then(({ data }: any) => {
        if (_.get(data, 'createOnlineTaskLink')) {
          const bookingData = {
            ...bookingState,
            config: {
              ...bookingState.config,
              online_task: {
                ...bookingState.config.online_task,
                links: _.get(data, 'createOnlineTaskLink.config.online_task.links'),
              },
            },
          };
          // setBooking(bookingData);
          props.updateBookingState(bookingData);

          let urlLink = `/booking-setup/${bookingState._id}/link-to-task/online-task`;
          if (bookingUtils.isSurvey(bookingState)) {
            urlLink = `/booking-setup/${bookingState._id}/link-to-task/survey`;
          }
          props.validateBooking(bookingData, urlLink, true);

          setLoading(false);
        }
      });
    } catch (err: any) {
      setError(err);
      setLoading(false);
    }
  };

  const onUpdateOnlineTask = async (onlineTask: any) => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '""' is not assignable to paramet... Remove this comment to see the full error message
    setError('');
    try {
      await props
        .updateOnlineTaskLink(
          _.get(bookingState, '_id'),
          _.get(onlineTask, '_id'),
          _.omit(onlineTask, ['_id', 'index']),
        )
        .then(({ data }: any) => {
          if (_.get(data, 'updateOnlineTaskLink')) {
            const bookingData = {
              ...bookingState,
              config: {
                ...bookingState.config,
                online_task: {
                  ...bookingState.config.online_task,
                  links: _.get(data, 'updateOnlineTaskLink.config.online_task.links'),
                },
              },
            };
            // setBooking(bookingData);
            props.updateBookingState(bookingData);

            let urlLink = `/booking-setup/${bookingState._id}/link-to-task/online-task`;
            if (bookingUtils.isSurvey(bookingState)) {
              urlLink = `/booking-setup/${bookingState._id}/link-to-task/survey`;
            }
            props.validateBooking(bookingData, urlLink, true);

            setLoading(false);
          }
        });
    } catch (err: any) {
      setError(err);
      setLoading(false);
    }
  };

  const onDeleteOnlineTask = (link_id: any) => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '""' is not assignable to paramet... Remove this comment to see the full error message
    setError('');
    try {
      props.deleteOnlineTaskLink(_.get(bookingState, '_id'), link_id).then(({ data }: any) => {
        if (_.get(data, 'deleteOnlineTaskLink')) {
          const bookingData = {
            ...bookingState,
            config: {
              ...bookingState.config,
              online_task: {
                ...bookingState.config.online_task,
                links: _.get(data, 'deleteOnlineTaskLink.config.online_task.links'),
              },
            },
          };
          // setBooking(bookingData);
          props.updateBookingState(bookingData);

          let urlLink = `/booking-setup/${bookingState._id}/link-to-task/online-task`;
          if (bookingUtils.isSurvey(bookingState)) {
            urlLink = `/booking-setup/${bookingState._id}/link-to-task/survey`;
          }
          props.validateBooking(bookingData, urlLink, true);

          setLoading(false);
        }
      });
    } catch (err: any) {
      setError(err);
      setLoading(false);
    }
  };

  const renderOnlineTasks = () => {
    let filteredOnlineTasks = _.filter(onlineTasks, (onlineTask: any, index: any) => index !== 0);
    if (bookingUtils.isBookingAutomated(booking)) {
      filteredOnlineTasks = _.slice(filteredOnlineTasks, 0, 0);
    }
    return _.map(filteredOnlineTasks, (onlineTask: any, index: any) => {
      return renderOnlineTaskUI({ onlineTask, index: index + 1 });
    });
  };

  const renderOnlineTaskUI = ({ onlineTask, index }: any) => {
    return (
      <OnlineVariant
        key={_.get(onlineTask, '_id') || `onlineTask-${index}`}
        index={index}
        onlineTask={onlineTask}
        onOnlineTaskUpdated={onOnlineTaskUpdated}
        onRemoveVariant={onRemoveOnlineTask}
        isLinkEmpty={!_.has(onlineTask, 'url') || _.get(onlineTask, 'url') === ''}
        isTaskUnchecked={!_.get(onlineTask, 'checked')}
        {...props}
      />
    );
  };

  return (
    <div className="createBookingContent">
      {loading && <LoadingOverlay style={{ opacity: 0.8 }} />}
      <h1 id="__pageTitle" className="title">
        Link to your online task
      </h1>
      <div className="linkToOnlineTaskContainer">
        {error && (
          <BoxMessage type="error" className="mbottom20" error>
            <p className="error">{error}</p>
          </BoxMessage>
        )}
        {renderOnlineTaskUI({ onlineTask: onlineTasks[0], index: 0 })}
        {renderOnlineTasks()}
      </div>
      {_.get(booking, 'config.options.review_submission') &&
        _.size(onlineTasks) > 0 &&
        _.size(unverifiedTasks) === 0 && (
          <div className="additionalVariationContainer">
            <p className="additionalVariantHint">
              Since you've selected hand picking mode for your study, you can also add variations.
            </p>
            <div
              id="buttonAddVariation"
              className="largeAddButton additionalVariant"
              onClick={() => {
                setOnlineTasks(
                  update(onlineTasks, {
                    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ index: number; }[]' is not assignable to t... Remove this comment to see the full error message
                    $push: [{ index: onlineTasks.length }],
                  }),
                );
              }}
            >
              <img className="addFiltersIcon" src="/icons/blackPlusIcon.svg" alt="addVariationIcon" />
              <div className="addFiltersContent">
                <p className="addFiltersTitle">Add a link variation</p>
              </div>
            </div>
          </div>
        )}
      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
}

const createOnlineTaskLinkContainer = graphql(createOnlineTaskLinkMutation, {
  props: ({ mutate }) => ({
    createOnlineTaskLink: (booking_id: any, link: any) =>
      mutate?.({
        variables: { booking_id, link },
      }),
  }),
});

const updateOnlineTaskLinkContainer = graphql(updateOnlineTaskLinkMutation, {
  props: ({ mutate }) => ({
    updateOnlineTaskLink: (booking_id: any, link_id: any, link: any) =>
      mutate?.({
        variables: { booking_id, link_id, link },
      }),
  }),
});

const deleteOnlineTaskLinkContainer = graphql(deleteOnlineTaskLinkMutation, {
  props: ({ mutate }) => ({
    deleteOnlineTaskLink: (booking_id: any, link_id: any) =>
      mutate?.({
        variables: { booking_id, link_id },
      }),
  }),
});

export default deprecatedWithRouter(
  _.flowRight(createOnlineTaskLinkContainer, updateOnlineTaskLinkContainer, deleteOnlineTaskLinkContainer)(LinkToTask),
);
