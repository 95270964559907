import { graphql } from 'src/graphql';

import type { ResultOf } from 'gql.tada';

export const UsersByTeamId = graphql(`
  query FetchUsersByTeamId($id: ID) {
    teamById(_id: $id) {
      users {
        _id
        role
        User {
          _id
          email
          meta {
            identity {
              firstname
              lastname
            }
          }
          contact {
            phone {
              country_code
              mobile
            }
          }
        }
      }
    }
  }
`);

export type UsersByTeamIdType = NonNullable<NonNullable<ResultOf<typeof UsersByTeamId>>['teamById']>;
export type User = NonNullable<
  NonNullable<NonNullable<NonNullable<ResultOf<typeof UsersByTeamId>>['teamById']>['users']>[number]
>;
