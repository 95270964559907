import { graphql } from 'src/graphql';

import type { ResultOf } from 'src/graphql';

export const StudyTaskBlockResultQuery = graphql(`
  query studyBlockResult($id: ObjectID!) {
    studyBlockResult(_id: $id) {
      _id
      status
      _user_id
      task_ended
      task_started
      task_loaded
      video {
        code
        _id
        _room_id
        playback_url
      }
    }
  }
`);

export type StudyTaskBlockResultPartial = NonNullable<
  NonNullable<ResultOf<typeof StudyTaskBlockResultQuery>['studyBlockResult']>
>;
