/* eslint-disable max-lines */
import { graphql } from '@apollo/client/react/hoc';
import { toast } from '@askable/ui/components/ui/sonner';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { X } from 'lucide-react';
import momentTimezone from 'moment-timezone';
import { useEffect, useState } from 'react';

import { Button, Calendar, LoadingOverlay } from 'components/common';
import createSessionMutation from 'data/mutations/booking/createSession';
import deleteSessionMutation from 'data/mutations/booking/deleteSession';
import updateSessionMutation from 'data/mutations/booking/updateSession';
import { bookingUtils } from 'lib/booking';
import { update, utils } from 'lib/utils';

import './styles/sessionTimesCalendarStyles.scss';

function SessionTimesCalendar(props: any) {
  const booking = _.get(props, 'booking');

  // State
  const [bookingState, setBookingState] = useState(booking);
  const [bookingSteps, setBookingSteps] = useState(_.get(props, 'bookingSteps'));
  const [timezone, setTimezone] = useState(_.get(booking, 'config.timezone') || momentTimezone.tz.guess());
  const [sessions, setSessions] = useState(_.get(booking, 'session'));
  const [firstSession, setFirstSession] = useState(_.minBy(sessions, 'start'));
  const [showDontForget, setShowDontForget] = useState(true);
  const [showHeadsUpTimes, setShowHeadsUpTimes] = useState(true);

  // Normal variables
  let extraBackupSessions = bookingUtils.isFocusGroup(booking)
    ? 0
    : Math.floor(_.get(bookingState, 'total_participants') / 2) || 0;
  let missingSessions = bookingUtils.isFocusGroup(booking) ? 1 : _.get(bookingState, 'total_participants');
  if (sessions !== undefined) {
    missingSessions -= sessions.length;
    if (!bookingUtils.isFocusGroup(booking)) {
      extraBackupSessions = _.get(bookingState, 'total_participants') + extraBackupSessions - sessions.length;
    }
  }

  useEffect(() => {
    if (
      (_.get(bookingSteps, 'session_times') &&
        _.get(bookingSteps, 'session_times_calendar') === 'error' &&
        missingSessions <= 0) ||
      (_.get(bookingSteps, 'session_times') &&
        _.get(bookingSteps, 'session_times_calendar') === 'seen' &&
        missingSessions > 0)
    ) {
      props.validateBooking(bookingState, document.location.pathname, true);
    }
  }, [missingSessions]);

  useEffect(() => {
    props.updateLastStep({
      step: 'Session Times',
      subStep: `/booking-setup/${booking._id}/session-times/calendar`,
      stepId: 'session_times_calendar',
    });
    if (bookingUtils.isSurvey(booking)) {
      props.history.push({ pathname: `/booking-setup/${booking._id}/link-to-task/survey` });
    } else if (bookingUtils.isOnlineTask(booking)) {
      props.history.push({ pathname: `/booking-setup/${booking._id}/link-to-task/online-task` });
    }
  }, []);

  useEffect(() => {
    setBookingState(_.get(props, 'booking'));
  }, [props.booking]);

  useEffect(() => {
    setBookingSteps(_.get(props, 'bookingSteps'));
  }, [props.bookingSteps]);

  useEffect(() => {
    props.renderRightContent(null);
    props.renderRightAppPreview(null);
    setTimezone(_.get(bookingState, 'config.timezone') || momentTimezone.tz.guess());
    setSessions(_.get(bookingState, 'session'));
    setFirstSession(_.minBy(_.get(bookingState, 'session'), 'start'));
  }, [bookingState]);

  const onSaveSessionTimes = () => {
    const redirectTo = `/booking-setup/${booking._id}/review-submit/confirm-booking`;
    props.history.push({ pathname: redirectTo, booking: null, bookingState: null });
  };

  const renderControlsComponent = () => {
    return (
      <div className="controlsComponent">
        <div className="topContainer">
          <div className="timezoneContainer">
            <div className="image">
              <img alt="World" src="/icons/earthIcon.svg" />
            </div>
            <div className="contentTimezone">
              <p className="font--bold">Time Zone</p>
              <p className="timezone">{utils.getCurrentTimezone()}</p>
              <a
                onClick={() => {
                  if (bookingUtils.isRemote(booking)) {
                    props.history.push({
                      pathname: `/booking-setup/${booking._id}/additional-info/your-timezone`,
                    });
                  } else {
                    props.history.push({
                      pathname: `/booking-setup/${booking._id}/additional-info/session-location`,
                    });
                  }
                }}
              >
                {bookingUtils.isRemote(booking) ? 'Change' : 'Change location'}
              </a>
            </div>
          </div>
          {showHeadsUpTimes && (
            <div className="headsUpTimesContainer">
              <div className="headsUpTimesHeader">
                <h3>Heads up!</h3>
                <X
                  className="closeIcon"
                  onClick={() => {
                    setShowHeadsUpTimes(false);
                  }}
                  style={{ width: 16, height: 16 }}
                />
              </div>
              <p>Participants will see times in their own time zone</p>
            </div>
          )}
          {showDontForget && (
            <div className="dontForgetContainer">
              <div className="headerDontForget">
                <h3>Don&apos;t Forget!</h3>
                <X
                  className="closeIcon"
                  onClick={() => {
                    setShowDontForget(false);
                  }}
                  style={{ width: 16, height: 16 }}
                />
              </div>
              <p>
                <span role="img" aria-label="coffee">
                  ☕️
                </span>{' '}
                Leave a break between sessions
              </p>
              <p>
                <span role="img" aria-label="coffee">
                  🍔
                </span>{' '}
                Leave some time to eat lunch
              </p>
            </div>
          )}
        </div>
        <div className="controlsBottomContainer">
          {missingSessions <= 0 && extraBackupSessions > 0 && (
            <div className={`proTipsContainer ${extraBackupSessions > 0 ? 'firstFlip' : 'lastFlip'}`}>
              <div className="headerProTips">
                <h3>Pro tip!</h3>
                <img alt="Light Bulb Icon" src="/icons/lightBulb.svg" />
              </div>
              <p id="__backupSessionTip">{`Add at least ${extraBackupSessions} extra backup ${extraBackupSessions === 1 ? 'session' : 'sessions'}`}</p>
            </div>
          )}
          {missingSessions > 0 && (
            <div className="missingSessions">
              <p id="__missingSessionTip">{`Please add at least ${missingSessions} more ${missingSessions === 1 ? 'session' : 'sessions'}`}</p>
            </div>
          )}
          <Button label="Next" onClick={onSaveSessionTimes} />
        </div>
      </div>
    );
  };

  return (
    <div className="stepSessionTimesContainer">
      {_.size(bookingState) === 0 && <LoadingOverlay style={{ opacity: 0.8 }} />}
      <Calendar
        client={props.client}
        booking={bookingState}
        weekFirstSession={momentTimezone(_.get(firstSession, 'start')).tz(timezone).week()}
        withMiniCalendar
        controlsComponent={renderControlsComponent()}
        onAddSession={(sessionToAdd: any) => {
          props
            .createSession(booking._id, sessionToAdd)
            .then(({ data }: any) => {
              const bookingStateObj = {
                ...bookingState,
                session: _.get(data, 'createSession.session'),
                steps: {
                  ...bookingSteps,
                  session_times_calendar: _.get(bookingSteps, 'session_times_calendar')
                    ? _.get(bookingSteps, 'session_times_calendar')
                    : 'seen',
                },
              };
              setBookingState(bookingStateObj);
              props.updateBookingState(bookingStateObj);
              props.updateBookingStepsState({
                ...bookingSteps,
                session_times_calendar: _.get(bookingSteps, 'session_times_calendar')
                  ? _.get(bookingSteps, 'session_times_calendar')
                  : 'seen',
              });
            })
            .catch((e: any) => {
              toast.error(_.get(e, 'graphQLErrors[0].message') ? e.graphQLErrors[0].message : 'Error saving study');
            });
        }}
        onDeleteSession={(sessionToDelete: any) => {
          if (sessionToDelete) {
            props
              .deleteSession(booking._id, sessionToDelete)
              .then(({ data }: any) => {
                const bookingStateObj = {
                  ...bookingState,
                  session: _.get(data, 'deleteSession.session'),
                  steps: {
                    ...bookingSteps,
                    session_times_calendar: _.get(bookingSteps, 'session_times_calendar')
                      ? _.get(bookingSteps, 'session_times_calendar')
                      : 'seen',
                  },
                };
                setBookingState(bookingStateObj);
                props.updateBookingState(bookingStateObj);
                props.updateBookingStepsState({
                  ...bookingSteps,
                  session_times_calendar: _.get(bookingSteps, 'session_times_calendar')
                    ? _.get(bookingSteps, 'session_times_calendar')
                    : 'seen',
                });
              })
              .catch((e: any) => {
                toast.error(_.get(e, 'graphQLErrors[0].message') ? e.graphQLErrors[0].message : 'Error saving study');
              });
          }
        }}
        onRescheduleSession={(sessionToReschedule: any, newSessionTimes: any) => {
          if (sessionToReschedule) {
            const sessionChangedIndex = _.findIndex(
              bookingState.session,
              (item: any) => item._id === sessionToReschedule,
            );
            const bookingStateObj = {
              ...bookingState,
              session: update(bookingState.session, {
                $auto: {
                  [sessionChangedIndex]: {
                    $auto: {
                      $set: {
                        start: newSessionTimes.start,
                        end: newSessionTimes.end,
                      },
                    },
                  },
                },
              }),
              steps: {
                ...bookingSteps,
                session_times_calendar: _.get(bookingSteps, 'session_times_calendar')
                  ? _.get(bookingSteps, 'session_times_calendar')
                  : 'seen',
              },
            };
            setBookingState(bookingStateObj);

            props
              .updateSession(booking._id, sessionToReschedule, newSessionTimes)
              .then(({ data }: any) => {
                const updatedBookingStateObj = {
                  ...bookingStateObj,
                  session: _.get(data, 'updateSession.session'),
                };
                setBookingState(updatedBookingStateObj);
                props.updateBookingState(updatedBookingStateObj);
                props.updateBookingStepsState({
                  ...bookingSteps,
                  session_times_calendar: _.get(bookingSteps, 'session_times_calendar')
                    ? _.get(bookingSteps, 'session_times_calendar')
                    : 'seen',
                });
              })
              .catch((e: any) => {
                toast.error(_.get(e, 'graphQLErrors[0].message') ? e.graphQLErrors[0].message : 'Error saving study');
              });
          }
        }}
        onAssignSessionToResearcher={(sessionToAssign: any, researcherId: any) => {
          if (sessionToAssign) {
            const sessionChangedIndex = _.findIndex(bookingState.session, (item: any) => item._id === sessionToAssign);
            const bookingStateObj = {
              ...bookingState,
              session: update(bookingState.session, {
                $auto: {
                  [sessionChangedIndex]: {
                    $auto: {
                      $set: {
                        _researcher_user_user: researcherId,
                      },
                    },
                  },
                },
              }),
              steps: {
                ...bookingSteps,
                session_times_calendar: _.get(bookingSteps, 'session_times_calendar')
                  ? _.get(bookingSteps, 'session_times_calendar')
                  : 'seen',
              },
            };
            setBookingState(bookingStateObj);

            props
              .updateSession(booking._id, sessionToAssign, { _researcher_user_id: researcherId })
              .then(({ data }: any) => {
                const updatedBookingStateObj = {
                  ...bookingStateObj,
                  session: _.get(data, 'updateSession.session'),
                };
                setBookingState(updatedBookingStateObj);
                props.updateBookingState(updatedBookingStateObj);
                props.updateBookingStepsState({
                  ...bookingSteps,
                  session_times_calendar: _.get(bookingSteps, 'session_times_calendar')
                    ? _.get(bookingSteps, 'session_times_calendar')
                    : 'seen',
                });
              })
              .catch((e: any) => {
                toast.error(_.get(e, 'graphQLErrors[0].message') ? e.graphQLErrors[0].message : 'Error saving study');
              });
          }
        }}
      />
    </div>
  );
}

const deleteSessionContainer = graphql(deleteSessionMutation, {
  props: ({ mutate }) => ({
    deleteSession: (booking_id: any, session_id: any) =>
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      mutate({
        variables: { booking_id, session_id },
      }),
  }),
});

const updateSessionContainer = graphql(updateSessionMutation, {
  props: ({ mutate }) => ({
    updateSession: (booking_id: any, session_id: any, session: any) =>
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      mutate({
        variables: { booking_id, session_id, session },
      }),
  }),
});

const createSessionContainer = graphql(createSessionMutation, {
  props: ({ mutate }) => ({
    createSession: (booking_id: any, session: any) =>
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      mutate({
        variables: { booking_id, session },
      }),
  }),
});

export default deprecatedWithRouter(
  _.flowRight(deleteSessionContainer, updateSessionContainer, createSessionContainer)(SessionTimesCalendar),
);
