import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'urql';

import { useConnectedClient } from 'context/ConnectedClientContext';

import { UsersByTeamId } from '../data/UsersByTeamId.query';

import { SelectField } from './Fields/SelectField';

import type { RecruitFormFields } from '../Recruit';
import type { User } from '../data/UsersByTeamId.query';

interface Option {
  label: string;
  value: string;
}

const formatUserName = (user: User): string => {
  const firstName = user.User?.meta?.identity?.firstname ?? '';
  const lastName = user.User?.meta?.identity?.lastname ?? '';
  return `${firstName} ${lastName}`.trim() || 'Unknown User';
};

const createUserOption = (user: User | null): Option => {
  if (!user) {
    return { label: 'Unknown User', value: '' };
  }

  return {
    label: formatUserName(user),
    value: user._id ?? '',
  };
};

export const PrimaryContact = ({ isDisabled }: { isDisabled?: boolean }) => {
  const { t } = useTranslation();
  const { details: clientDetails } = useConnectedClient();
  const { control, setValue } = useFormContext<RecruitFormFields>();

  const currentValue = useWatch({
    control,
    name: 'primary_contact',
  });

  const teamId = clientDetails?.ConnectedTeam?._id;

  const [{ data, fetching, error }] = useQuery({
    query: UsersByTeamId,
    pause: !teamId,
    variables: { id: teamId },
  });

  useEffect(() => {
    if (!data?.teamById?.users || fetching || currentValue) {
      return;
    }

    const users = data.teamById.users;
    const options = users.map(createUserOption);

    // Set current user as default value
    if (!options.some(option => option.value === currentValue)) {
      const defaultValue = options.find(option => option.value === clientDetails?.id)?.value ?? options[0]?.value;

      if (defaultValue) {
        setValue('primary_contact', defaultValue, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    }
  }, [data, fetching, clientDetails?.id, currentValue, setValue]);

  if (error) {
    return (
      <div className="flex items-center justify-between gap-2 text-sm" role="alert">
        <div className="font-medium">{t('sections.studies.recruit.fields.primaryContactTitle')}</div>
        <div className="text-sm text-destructive" role="alert">
          {t('sections.studies.recruit.fields.primaryContactError')}
        </div>
      </div>
    );
  }

  const users = data?.teamById?.users ?? [];
  const options = users.map(createUserOption);

  return (
    <SelectField
      name="primary_contact"
      label={t('sections.studies.recruit.fields.primaryContactTitle')}
      description={t('sections.studies.recruit.fields.primaryContactDescription')}
      options={options}
      variant="inline"
      isDisabled={isDisabled || fetching}
    />
  );
};
