import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@askable/ui/components/ui/alert-dialog';
import { Button } from '@askable/ui/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@askable/ui/components/ui/dropdown-menu';
import { toast } from '@askable/ui/components/ui/sonner';
import { Archive, CirclePlay, Copy, Drum, Ellipsis, ExternalLink } from 'lucide-react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';
import { useMutation } from 'urql';

import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { PreviewLink } from 'containers/Studies/components/PreviewLink';
import { DuplicateBooking } from 'containers/Studies/data/DuplicateBooking.mutation';
import { UpdateBookingRecruit } from 'containers/Studies/data/UpdateBookingRecruit.mutation';
import { UpdateUnmoderatedBooking } from 'containers/Studies/data/UpdateUnmoderatedBooking.mutation';
import { getUnmodUrl } from 'utils/url-utils';

import type { Booking } from 'generated/graphql';

type StudyActionsProps = {
  study: Booking;
  disableSubmit: boolean;
};

export const StudyActions = ({ study, disableSubmit }: StudyActionsProps) => {
  const { t } = useTranslation();
  const { isSaving, setIsSaving, isBuildDisabled } = useStudyContext();

  const [, updateBooking] = useMutation(UpdateUnmoderatedBooking);
  const [, duplicateBooking] = useMutation(DuplicateBooking);
  const [, updateBookingRecruit] = useMutation(UpdateBookingRecruit);

  const handleDuplicate = useCallback(async () => {
    try {
      const { data, error } = await duplicateBooking({ booking_id: study._id });
      if (error || !data) {
        throw new Error(error?.message || t('sections.errorBoundary.default'));
      }

      window.location.href = `/studies/${data.duplicateBooking?._id}/build?block_id=welcome#editor`;
    } catch (err: any) {
      toast.error(err?.message || t('sections.errorBoundary.default'));
    }
  }, []);

  // THIS IS ALL TEMPORARY FOR DEMOS / TESTING, EVENTUALLY THE BOOKING STATUS WILL BE DRIVEN
  // BY ACTUAL WORKFLOWS
  const handleUpdateStatus = useCallback(
    async (status: number) => {
      try {
        setIsSaving(true);
        const { error } = await updateBooking({ input: { _id: study._id, status } });
        if (error) {
          throw new Error(error.graphQLErrors?.[0]?.message || t('sections.errorBoundary.default'));
        }
      } catch (e: any) {
        toast.error(e.message);
      } finally {
        setIsSaving(false);
      }
    },
    [setIsSaving, study._id, updateBooking],
  );

  const [isOpenConfirmArchive, setIsOpenConfirmArchive] = useState(false);

  const handleRecruit = useCallback(
    async (review_submission: boolean) => {
      try {
        setIsSaving(true);
        const { error } = await updateBookingRecruit({ input: { _id: study._id, review_submission } });
        if (error) {
          throw new Error(error.graphQLErrors?.[0]?.message);
        }
      } catch (e: any) {
        toast.error(e.message);
      } finally {
        setIsSaving(false);
      }
    },
    [setIsSaving, study._id, updateBookingRecruit],
  );

  return (
    <>
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger asChild>
          <Button size="icon" variant="ghost">
            <Ellipsis className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="z-[2101]" align="end">
          <DropdownMenuItem className="lg:!hidden" asChild>
            <PreviewLink />
          </DropdownMenuItem>

          {/* THIS IS ALL TEMPORARY FOR DEMOS / TESTING, EVENTUALLY THE BOOKING STATUS WILL BE DRIVEN BY ACTUAL WORKFLOWS */}
          {/* TODO: PULL STUDY ACTIONS OUT INTO SEPARATE COMPONENT */}
          {match(study.status as any)
            .with(
              0, // Draft
              () => (
                <>
                  <DropdownMenuItem
                    onClick={() => handleUpdateStatus(3)}
                    disabled={isSaving || disableSubmit || isBuildDisabled}
                  >
                    <CirclePlay className="h-4 w-4" /> {t('sections.studies.actions.submit')}
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={() => handleUpdateStatus(7)} disabled={isSaving}>
                    <Archive className="h-4 w-4" /> {t('sections.studies.actions.archive')}
                  </DropdownMenuItem>
                </>
              ),
            )
            .with(
              1, // Active
              () => (
                <>
                  <DropdownMenuItem onClick={() => handleUpdateStatus(5)} disabled={isSaving}>
                    <CirclePlay className="h-4 w-4" /> {t('sections.studies.actions.complete')}
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={() => handleUpdateStatus(0)} disabled={isSaving}>
                    <Archive className="h-4 w-4" /> {t('sections.studies.actions.redraft')}
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={() => setIsOpenConfirmArchive(true)} disabled={isSaving}>
                    <Archive className="h-4 w-4" /> {t('sections.studies.actions.archive')}
                  </DropdownMenuItem>
                </>
              ),
            )
            .with(
              3, // In review
              () => (
                <>
                  <DropdownMenuItem onClick={() => handleUpdateStatus(1)} disabled={isSaving}>
                    <CirclePlay className="h-4 w-4" /> {t('sections.studies.actions.approve')}
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={() => handleUpdateStatus(0)} disabled={isSaving}>
                    <Archive className="h-4 w-4" /> {t('sections.studies.actions.redraft')}
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={() => handleUpdateStatus(7)} disabled={isSaving}>
                    <Archive className="h-4 w-4" /> {t('sections.studies.actions.archive')}
                  </DropdownMenuItem>
                </>
              ),
            )
            .with(
              5, // Completed
              () => (
                <DropdownMenuItem onClick={() => handleUpdateStatus(1)} disabled={isSaving}>
                  <CirclePlay className="h-4 w-4" /> {t('sections.studies.actions.reopen')}
                </DropdownMenuItem>
              ),
            )
            .with(
              7, // Archived
              () => (
                <DropdownMenuItem onClick={() => handleUpdateStatus(1)} disabled={isSaving}>
                  <CirclePlay className="h-4 w-4" /> {t('sections.studies.actions.reopen')}
                </DropdownMenuItem>
              ),
            )
            .otherwise(() => null)}

          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={() => handleRecruit(true)} disabled={isSaving}>
            <Drum className="h-4 w-4" /> Recruit (Hand-picked)
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => handleRecruit(false)} disabled={isSaving}>
            <Drum className="h-4 w-4" /> Recruit (Automated)
          </DropdownMenuItem>

          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={handleDuplicate} disabled={isSaving}>
            <Copy className="h-4 w-4" /> {t('sections.studies.actions.duplicate')}
          </DropdownMenuItem>

          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={() => window.open(`${getUnmodUrl(study._id)}?demo=true`, '_blank')}>
            <ExternalLink className="h-4 w-4" /> {t('sections.studies.actions.test')}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <AlertDialog open={isOpenConfirmArchive}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{t('sections.studies.actions.archiveConfirmTitle')}</AlertDialogTitle>
            <AlertDialogDescription>{t('sections.studies.actions.archiveConfirmDescription')}</AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => setIsOpenConfirmArchive(false)} className="w-full sm:w-fit">
              {t('global.cancel')}
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={async () => {
                await handleUpdateStatus(7);
                setIsOpenConfirmArchive(false);
              }}
              className="w-full sm:w-fit"
            >
              {t('sections.studies.actions.archiveConfirmCta')}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
