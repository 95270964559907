import { graphql } from 'src/graphql';

export const UpdateBookingRecruit = graphql(`
  mutation UpdateBookingRecruit($input: UpdateBookingRecruitInput!) {
    updateBookingRecruit(input: $input) {
      _id
      name
      type
      status
      config {
        contact {
          _organiser_user_id
        }
      }
      session {
        _id
        start
        end
        _researcher_user_id
      }
    }
  }
`);
