import { Avatar, AvatarImage, AvatarFallback } from '@askable/ui/components/ui/avatar';

import type { AvatarProps } from '@askable/ui/components/ui/avatar';

type Props = {
  firstName: string;
  lastName: string;
  pictureLink: string;
  size?: AvatarProps['size'];
};

const getInitials = (firstName: string, lastName: string): string => `${firstName.charAt(0)}${lastName.charAt(0)}`;

export const FacilitatorAvatar = ({ firstName, lastName, pictureLink, size = 'sm' }: Props) => {
  return (
    <Avatar size={size} className="border border-solid border-background bg-primary [&:not(:first-child)]:-ml-1">
      <AvatarImage src={pictureLink} alt={firstName} />
      <AvatarFallback aria-label={firstName}>{getInitials(firstName, lastName)}</AvatarFallback>
    </Avatar>
  );
};
