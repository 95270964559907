import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';

import type { CreateIconType } from './icon-types';

export const UpdateIcon: CreateIconType = createIcon({
  displayName: 'UpdateIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: [
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.3.53a3.563 3.563 0 0 0-1.44.366 3.32 3.32 0 0 0-.888.635c-.068.066-5.492 5.601-7 7.144-.14.143-.195.227-.233.355-.037.127-1.308 4.874-1.321 4.938-.07.332.003.7.2.995.11.166.257.31.425.416.285.181.64.249.96.183.075-.015 4.902-1.255 5.022-1.29a.737.737 0 0 0 .319-.182l3.6-3.411c1.956-1.851 3.595-3.405 3.644-3.453.206-.203.37-.406.513-.634.422-.675.6-1.488.508-2.323a4.115 4.115 0 0 0-.671-1.827 4.378 4.378 0 0 0-1.085-1.127 4.14 4.14 0 0 0-1.959-.764A3.957 3.957 0 0 0 11.3.529Zm.018 1.497a2.09 2.09 0 0 0-.841.233c-.141.075-.264.162-.39.275-.063.058-6.372 6.495-6.368 6.498l.12.068c.494.276.966.596 1.358.921.226.187.547.495.724.695.394.445.736.926 1.077 1.515.025.044.046.074.05.071.016-.01 6.503-6.156 6.555-6.21.131-.139.23-.278.315-.448a2.08 2.08 0 0 0 .208-.72c.012-.105.01-.376-.003-.49a2.69 2.69 0 0 0-.61-1.405 4.264 4.264 0 0 0-.37-.374c-.503-.415-1.1-.638-1.693-.632-.058 0-.117.002-.132.003Zm-8.381 8.318c-.044.159-.99 3.688-.99 3.688.004.004 3.787-.975 3.787-.98 0-.003-.032-.06-.071-.128-.413-.705-.842-1.243-1.327-1.664a7.642 7.642 0 0 0-1.34-.914l-.052-.03-.007.027Z"
      clipRule="evenodd"
    />,
  ],
});
