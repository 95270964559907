import { authExchange } from '@urql/exchange-auth';
import { cacheExchange } from '@urql/exchange-graphcache';
import { createClient as createWSClient } from 'graphql-ws';
import { createClient, dedupExchange, errorExchange, fetchExchange, subscriptionExchange } from 'urql';

import { globalVariables } from 'lib/globalVariables';
import { graphqlErrorHandler } from 'lib/graphqlErrorHandler';

import schema from '../../gql-schema.json';

import { AUTH_CONFIG } from './auth';

import type { IntrospectionData } from '@urql/exchange-graphcache/dist/types/ast';
import type { RateParticipantMutationVariables } from 'generated/graphql';
import type { SubscribePayload } from 'graphql-ws';

const WS_URI = globalVariables.getEnvironmentVariables().subscriptionURI;

function createSubscriptionClient() {
  const wsClient = createWSClient({
    url: WS_URI,
  });

  return subscriptionExchange({
    forwardSubscription(operation) {
      return {
        subscribe: sink => {
          const dispose = wsClient.subscribe(operation as SubscribePayload, sink);
          return {
            unsubscribe: dispose,
          };
        },
      };
    },
  });
}

export const urqlClient = createClient({
  exchanges: [
    dedupExchange,
    // @ts-expect-error FIXME - typeof _opaque doesn't seem to match?
    authExchange(async utils => {
      let token = await AUTH_CONFIG.getToken();

      return {
        didAuthError: value => {
          return value.graphQLErrors.some(e => {
            return e.extensions.code === 401 || e.message === 'invalid token' || e.message === 'jwt expired';
          });
        },
        willAuthError: () => {
          return !token;
        },
        refreshAuth: async () => {
          token = await AUTH_CONFIG.getToken();
        },
        addAuthToOperation: operation => {
          if (!token) return operation;

          return utils.appendHeaders(operation, {
            Authorization: `${token}`,
          });
        },
      };
    }),
    cacheExchange({
      keys: {
        BookingConfig: () => null,
        BookingStartEndSessions: () => null,
        OnlineTaskBookingConfig: () => null,
        BookingSteps: () => null,
        UserMetaIdentityType: () => null,
        UserMetaType: () => null,
        BookingRating: () => null,
        BookingSubmissionRating: () => null,
        CreditsPriceType: () => null,
        InvoiceTransactionsType: () => null,
        GoogleViewportType: () => null,
        // HACK (ASK-9003): disable cache keys for the following fields, because they are the same for draft and live configs
        // meaning draft config data gets overriden by live config data :|
        StudyWelcomeBlock: () => null,
        StudyThankYouBlock: () => null,
        StudyFigmaPrototypeTaskBlock: () => null,
      },
      schema: schema as IntrospectionData,
      updates: {
        Mutation: {},
      },
      optimistic: {
        submissionRateParticipant: args => {
          const input = args?.input as RateParticipantMutationVariables['input'];
          return {
            __typename: 'Submission',
            _id: input?._submission_id,
            rating: {
              __typename: 'BookingSubmissionRating',
              overall: input?.overall,
            },
          };
        },
      },
    }),
    errorExchange({
      onError: error => {
        graphqlErrorHandler({
          graphqlErrors: error.graphQLErrors,
          networkError: error.networkError,
        });
      },
    }),
    fetchExchange,
    createSubscriptionClient(),
  ],
  url: globalVariables.getEnvironmentVariables().graphqlURI,
  fetchOptions: () => {
    return {
      headers: {
        'x-graphql-client-name': 'clients',
        'x-graphql-client-version': import.meta.env.VITE_APP_VERSION ?? 'N/A',
      },
    };
  },
});
