import { getColorValue } from '@askable/ui/lib/utils';

import type { IconProps } from '.';

export const Cog = ({ bgColor = 'foreground', fgColor = 'background', size = 48 }: IconProps) => {
  const bgHsl = getColorValue(bgColor);
  const fgHsl = getColorValue(fgColor);

  return (
    <svg fill="none" viewBox="0 0 64 64" width={size} height={size}>
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="m27.5 4.8-.1-1.6c-1.6.1-4.1 2.6-5.1 3.8a5.7 5.7 0 0 0-1.3 4.2v.5a5.7 5.7 0 0 1-2.4 5.3l-1.2.8a6 6 0 0 1-5.8.6l-.8-3.4c-2 .8-3.3 1.6-4.3 2.5C5.2 18.7 4 19.6 3.4 21l-.6 1.2a5.8 5.8 0 0 0 3 7.6l.4.2a5.9 5.9 0 0 1 3.5 4.8l.1 1.4a5.7 5.7 0 0 1-2.4 5.3l-.4.3a5.7 5.7 0 0 0-1.4 8l.8 1a6 6 0 0 0 8.2 1.4l.4-.3a6 6 0 0 1 5.8-.5l1.4.6a5.9 5.9 0 0 1 3.4 4.7v.5a6 6 0 0 0 6.4 5.2l1.4-.1c1.5-.2 3-1.1 4.3-2.2 0 0 2-2 3.3-3.7l-2.4-1a5.7 5.7 0 0 1 2.4-5.2l1.2-.9a6 6 0 0 1 5.8-.5l.5.2a6 6 0 0 0 4.5.1c1.4-.5 3-2 4.4-3.6l1.2-2.2c.4-.8 0-1.1-.6-1.5-.4-.3-.8-.6-1-1.2a5.8 5.8 0 0 0-3.1-3.3l-.5-.2a5.9 5.9 0 0 1-3.4-4.7l-.2-1.5a5.7 5.7 0 0 1 2.4-5.2l.4-.3a5.7 5.7 0 0 0 1.4-8l-.8-1.1A6 6 0 0 0 45 15l-.4.2a6 6 0 0 1-5.8.6l-1.4-.6a5.9 5.9 0 0 1-3.4-4.7V10a6 6 0 0 0-6.4-5.2Zm-2.3 25.7c0 4.5 3.8 8.2 8.4 8.2 4.6 0 8.3-3.7 8.3-8.2 0-4.5-3.7-8.2-8.3-8.2a8.2 8.2 0 0 0-8.4 8.2Z"
        clipRule="evenodd"
      />
      <path fill={bgHsl} d="m27 25.6 1.2-.1c4.6 0 8.3 3.7 8.3 8.2a8 8 0 0 1-1.6 4.9c4-.6 7-4 7-8a8.4 8.4 0 0 0-15-5Z" />
      <path
        fill={fgHsl}
        fillRule="evenodd"
        d="m31.3 3.1-1.3.2c-1.4.1-2.8.8-3.7 2a5.4 5.4 0 0 0-1.2 4v.4a5.4 5.4 0 0 1-2.3 5l-1 .7a5.6 5.6 0 0 1-5.6.6l-.4-.2a5.6 5.6 0 0 0-4.3-.2 5.5 5.5 0 0 0-3 2.9l-.6 1a5.4 5.4 0 0 0-.1 4.2 5.5 5.5 0 0 0 2.9 3l.4.3a5.5 5.5 0 0 1 3.3 4.4l.1 1.4a5.4 5.4 0 0 1-2.3 5l-.4.2a5.4 5.4 0 0 0-1.2 7.6l.7 1A5.6 5.6 0 0 0 19 48l.4-.3a5.6 5.6 0 0 1 5.5-.5l1.3.6a5.5 5.5 0 0 1 3.2 4.4v.5c.2 1.4 1 2.8 2 3.7 1.2.9 2.7 1.3 4.1 1.2l1.2-.1a6 6 0 0 0 3.8-2c1-1 1.4-2.5 1.2-4V51a5.4 5.4 0 0 1 2.2-5l1.2-.7a5.6 5.6 0 0 1 5.5-.5l.4.2c1.4.6 2.9.6 4.3.1a5.5 5.5 0 0 0 3-2.8l.5-1.1c.7-1.3.7-2.8.2-4.2a5.5 5.5 0 0 0-3-3l-.4-.2a5.5 5.5 0 0 1-3.2-4.5l-.1-1.4a5.4 5.4 0 0 1 2.3-5l.3-.2a5.4 5.4 0 0 0 1.3-7.6l-.7-1a5.6 5.6 0 0 0-7.7-1.3l-.4.3a5.6 5.6 0 0 1-5.5.5l-1.3-.5a5.5 5.5 0 0 1-3.2-4.5V8a5.4 5.4 0 0 0-2-3.6A5.6 5.6 0 0 0 31.2 3Zm-6 27.4c0 4.5 3.7 8.2 8.3 8.2 4.6 0 8.3-3.7 8.3-8.2 0-4.5-3.7-8.2-8.3-8.2a8.2 8.2 0 0 0-8.4 8.2Z"
        clipRule="evenodd"
      />
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="M36 3.6A7.7 7.7 0 0 0 31.2 2l-1.3.2c-1.7.1-3.3 1-4.4 2.3a6.4 6.4 0 0 0-1.4 4.7v.5a4.4 4.4 0 0 1-1.9 4l-1 .8a4.6 4.6 0 0 1-4.6.4l-.4-.1a6.7 6.7 0 0 0-5-.2c-1.7.6-3 1.8-3.7 3.4L7 19a6.3 6.3 0 0 0-.2 5 6.5 6.5 0 0 0 3.5 3.6l.4.2a4.5 4.5 0 0 1 2.7 3.6l.1 1.4a4.4 4.4 0 0 1-1.9 4l-.3.3a6.4 6.4 0 0 0-1.6 9l.8 1a6.6 6.6 0 0 0 9.1 1.5l.4-.2a4.6 4.6 0 0 1 4.5-.5l1.3.6a4.5 4.5 0 0 1 2.6 3.6v.5c.2 1.7 1 3.3 2.4 4.4a7 7 0 0 0 4.8 1.4l1.2-.1c1.8-.2 3.4-1 4.5-2.3 1-1.4 1.6-3 1.4-4.8V51a4.4 4.4 0 0 1 1.8-4v-.1l1.2-.8a4.6 4.6 0 0 1 4.5-.4l.4.2c1.6.7 3.4.7 5 .1s3-1.8 3.7-3.3l.5-1.1c.7-1.6.8-3.3.1-5a6.5 6.5 0 0 0-3.4-3.5l-.4-.2a4.5 4.5 0 0 1-2.7-3.7l-.1-1.4a4.4 4.4 0 0 1 2-4l.3-.3a6.4 6.4 0 0 0 1.4-9l-.7-1a6.6 6.6 0 0 0-9.1-1.5l-.4.3a4.6 4.6 0 0 1-4.5.4l-1.3-.5a4.5 4.5 0 0 1-2.6-3.7V8c-.2-1.7-1-3.2-2.4-4.3Zm-4.6.5c1.2 0 2.3.3 3.3 1 .9.8 1.5 1.9 1.6 3v.5a6.4 6.4 0 0 0 3.9 5.3l1.2.5a6.7 6.7 0 0 0 6.6-.6l.3-.2a4.6 4.6 0 0 1 6.4 1l.7 1a4.4 4.4 0 0 1-1.1 6.2l-.4.3a6.5 6.5 0 0 0-2.6 5.8l.1 1.4a6.4 6.4 0 0 0 3.8 5.3l.4.2c1.2.5 2 1.4 2.4 2.5.5 1 .4 2.3 0 3.4l-.6 1c-.5 1.1-1.4 2-2.5 2.4-1.1.4-2.4.3-3.5-.2l-.4-.1a6.7 6.7 0 0 0-6.5.6l-1.2.8a6.5 6.5 0 0 0-2.7 5.8l.1.5c.1 1.2-.2 2.4-1 3.3-.8.9-1.9 1.5-3 1.6l-1.3.1c-1.2.1-2.4-.2-3.3-1a4 4 0 0 1-1.6-3V52a6.4 6.4 0 0 0-3.9-5.2l-1.2-.6a6.7 6.7 0 0 0-6.6.6l-.3.3a4.6 4.6 0 0 1-6.4-1l-.7-1a4.4 4.4 0 0 1 1-6.2l.4-.3a6.5 6.5 0 0 0 2.7-5.9l-.1-1.3a6.4 6.4 0 0 0-3.8-5.3l-.5-.2c-1-.5-2-1.4-2.3-2.5a4.5 4.5 0 0 1 0-3.4l.6-1.1c.5-1 1.4-2 2.5-2.3a4.6 4.6 0 0 1 3.5.1l.4.2a6.7 6.7 0 0 0 6.5-.6l1.1-.8a6.5 6.5 0 0 0 2.7-5.9v-.5A4.2 4.2 0 0 1 30 4.4l1.3-.2Z"
        clipRule="evenodd"
      />
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="M33.6 23.3c4 0 7.3 3.3 7.3 7.2 0 4-3.3 7.2-7.3 7.2s-7.3-3.2-7.3-7.2 3.2-7.2 7.3-7.2Zm9.3 7.2c0-5-4.2-9.2-9.3-9.2a9.3 9.3 0 0 0-9.4 9.2 9.3 9.3 0 0 0 18.7 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
