/* eslint-disable max-lines */
import { Button } from '@askable/ui/components/ui/button';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import { useFeatureFlags } from 'feature-flags';
import _ from 'lodash';
import { MapPin } from 'lucide-react';
import { useEffect, useState } from 'react';

import PriceCardContainer from 'components/askablePlus/components/priceCardContainer';
import { LoadingOverlay, GoogleMaps } from 'components/common';
import LocationAutocomplete from 'components/common/LocationAutocomplete/view';
import getLocationByAddress from 'data/queries/location/getLocationByAddress';
import { useFetchCountryListQuery } from 'generated/graphql';
import { bookingUtils } from 'lib/booking';
import countryCodeData from 'lib/data/phoneCountryCodes.json';
import { apolloFetch } from 'lib/http';
import { update, utils } from 'lib/utils';

import type { Location } from 'generated/graphql';

import './styles/participantLocationStyles.scss';

function Quota(props: any) {
  const countryList = useFetchCountryListQuery();
  const countryCurrencyList = countryList.data?.countryList;

  const askablePlusBrief = _.get(props, 'askablePlusBrief');
  const [askablePlusBriefState, setAskablePlusBriefState] = useState(askablePlusBrief);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [countryLocation, setCountryLocation] = useState<Location | null>(null);
  const [googleMap, setGoogleMap] = useState<google.maps.Map | null>(null);
  const [mapMarkers, setMapMarkers] = useState([]);

  const { MULTIREGION_COUNTRIES } = useFeatureFlags(['MULTIREGION_COUNTRIES']);

  useEffect(() => {
    if (!_.get(props, 'askablePlusBrief._id')) {
      props.history.push('/askable-plus/create');
    } else {
      props.updateLastStep({
        step: 'Audience',
        subStep: `/askable-plus/${askablePlusBrief._id}/audience/participant-locations`,
        stepId: 'audience_participant_locations',
      });
      props.renderRightContent(rightContent());
    }
    createGoogleMap();
  }, []);

  useEffect(() => {
    if (!selectedLocation) {
      updateSelectedLocation(askablePlusBriefState);
    }
  }, [askablePlusBriefState]);

  const rightContent = () => {
    return (
      <>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          askablePlusBrief={askablePlusBriefState}
          team={_.get(props, 'team')}
          context={props.context}
        />
      </>
    );
  };

  const createGoogleMap = async () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'google' does not exist on type 'Window &... Remove this comment to see the full error message
    const map = await new window.google.maps.Map(document.getElementById('mapPreviewContainer'), {
      ...props.options,
      controlSize: 24,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      gestureHandling: 'cooperative',
    });
    setGoogleMap(map);
    loadSavedMarkers(map);
  };

  const loadSavedMarkers = async (map: any) => {
    const states = _.map(
      _.get(askablePlusBriefState, 'askable_plus.audience.booking_config.criteria.locations.states') || [],
      (item: any) => ({
        ...item,
        type: 'states',
      }),
    );
    const bounds = _.map(
      _.get(askablePlusBriefState, 'askable_plus.audience.booking_config.criteria.locations.bounds') || [],
      (item: any) => ({
        ...item,
        type: 'bounds',
      }),
    );
    const remoteQuantStates = utils.removeTypenames(states || []);
    const remoteQuantBounds = utils.removeTypenames(bounds || []);
    const savedLocations = remoteQuantStates.concat(remoteQuantBounds);

    if (savedLocations.length > 0) {
      const markers = await Promise.all(
        _.map(savedLocations, item => {
          return handleMarker({
            savedMarkers: [],
            map,
            action: 'firstLoad',
            item: {
              title: item.formatted_address,
              name: _.get(item, 'name') || item.formatted_address,
              latitude: item.latitude,
              longitude: item.longitude,
              type: item.type,
            },
          });
        }),
      );
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
      setMapMarkers(markers);
    } else if (_.get(askablePlusBriefState, 'askable_plus.audience.booking_config.location.country')) {
      const countryLocationData = await getCountryData(
        _.get(askablePlusBriefState, 'askable_plus.audience.booking_config.location.country'),
      );
      const marker = await handleMarker({
        savedMarkers: [],
        map,
        action: 'firstLoad',
        item: {
          title: _.get(askablePlusBriefState, 'askable_plus.audience.booking_config.location.country'),
          name: _.get(askablePlusBriefState, 'askable_plus.audience.booking_config.location.country'),
          latitude: countryLocationData.latitude,
          longitude: countryLocationData.longitude,
          type: 'country',
        },
      });
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
      setMapMarkers([marker]);
    }
  };

  const handleMarker = async ({ savedMarkers, map, action, item, colloquial_area = false }: any) => {
    const markerIcon = { url: '/booking/icons/mapMarkerIcon.svg' };
    let markersToSave = _.clone(savedMarkers);

    if (action === 'insert' || action === 'firstLoad') {
      const marker = new window.google.maps.Marker({
        title: item.title,
        position: { lat: item.latitude, lng: item.longitude },
        animation: window.google.maps.Animation.DROP,
        icon: markerIcon,
        map,
      });

      let markerCircle = null;
      let mapPolygons = null;
      if (colloquial_area) {
        markerCircle = new window.google.maps.Circle({
          strokeColor: '#56A1EB',
          strokeWeight: 1,
          fillColor: '#066CD2',
          map: googleMap,
          center: { lat: item.latitude, lng: item.longitude },
          radius: 20000,
        });
      } else if (item.type !== 'bounds') {
        mapPolygons = await loadMapPolygon(map, item.name);
      }

      const newMarker = {
        title: item.title,
        marker,
        markerCircle,
        mapPolygons,
      };

      if (action === 'firstLoad') return newMarker;

      markersToSave = update(markersToSave, {
        $push: [newMarker],
      });
    } else if (action === 'delete') {
      const markerData = _.filter(markersToSave, (mark: any) => mark.title === item.title);
      await Promise.all(
        _.map(markerData, (marker: any, index: any) => {
          if (marker) {
            if (_.get(marker, 'marker')) {
              marker.marker.setMap(null);
            }
            if (_.get(marker, 'markerCircle')) {
              marker.markerCircle.setMap(null);
            }
            if (_.get(marker, 'mapPolygons')) {
              _.map(_.get(marker, 'mapPolygons'), (polygon: any) => polygon.setMap(null));
            }
          }
          if (index) {
            markersToSave = update(markersToSave, {
              $splice: [[index, 1]],
            });
          }
        }),
      );
    }

    return markersToSave;
  };

  const removeAllMarkers = async (savedMarkers: any) => {
    await Promise.all(
      _.map(savedMarkers, (markerData: any) => {
        if (_.get(markerData, 'marker')) {
          markerData.marker.setMap(null);
        }
        if (_.get(markerData, 'markerCircle')) {
          markerData.markerCircle.setMap(null);
        }
        if (_.get(markerData, 'mapPolygons')) {
          _.map(_.get(markerData, 'mapPolygons'), (polygon: any) => polygon.setMap(null));
        }
      }),
    );
    return [];
  };

  const loadMapPolygon = async (map: any, locationName: any) => {
    const locationPolygons = await bookingUtils.getLocationPolygons(locationName);
    if (locationPolygons) {
      const polygonData = _.map(locationPolygons, (item: any) => {
        const polygon = new window.google.maps.Polygon({
          paths: item,
        });
        polygon.setOptions({ fillColor: '#56A1EB', strokeColor: '#066CD2', strokeWeight: 1 });
        polygon.setMap(map);
        return polygon;
      });

      return polygonData;
    }
    return null;
  };

  const onLocationClick = (value: any) => {
    // Updates location on the map view
    setSelectedLocation({
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ formatted_address: any; latitu... Remove this comment to see the full error message
      formatted_address: value.formatted_address,
      latitude: value.latitude,
      longitude: value.longitude,
      locationViewport: [_.get(value, 'google_location.viewport')],
    });
  };

  const getCountryData = async (country: any) => {
    const countryData = _.find(countryCodeData, { region: country });
    const result = await apolloFetch.fetch(getLocationByAddress, {
      address: _.get(countryData, 'name'),
      country: _.get(countryData, 'region'),
      types: '(regions)',
    });
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    const countryLocationData = result.data.getLocationByAddress;
    return countryLocationData;
  };

  const getCountryLocation = async (country: any, saveCountryMarker: any) => {
    const countryLocationData = await getCountryData(country);
    setCountryLocation(countryLocationData);
    setSelectedLocation({
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ formatted_address: any; latitu... Remove this comment to see the full error message
      formatted_address: countryLocationData.formatted_address,
      latitude: countryLocationData.latitude,
      longitude: countryLocationData.longitude,
      locationViewport: [
        _.get(countryLocationData, 'google_location.viewport') ||
          _.get(askablePlusBriefState, 'askable_plus.audience.booking_config.location.google_location.viewport'),
      ],
    });

    const askablePlusBriefStateObj = {
      ...askablePlusBriefState,
      askable_plus: {
        ...askablePlusBriefState.askable_plus,
        audience: {
          ...askablePlusBriefState.askable_plus.audience,
          booking_config: {
            ...askablePlusBriefState.askable_plus.audience.booking_config,
            location: {
              ...askablePlusBriefState.askable_plus.audience.booking_config.location,
              google_location: {
                viewport:
                  _.get(countryLocationData, 'google_location.viewport') ||
                  _.get(
                    askablePlusBriefState,
                    'askable_plus.audience.booking_config.location.google_location.viewport',
                  ),
              },
            },
            criteria: {
              ...askablePlusBriefState.askable_plus.audience.booking_config.criteria,
              locations: {
                states: [],
                bounds: [],
                countries: MULTIREGION_COUNTRIES ? [] : undefined,
              },
            },
          },
        },
      },
    };
    setAskablePlusBriefState(askablePlusBriefStateObj);
    props.updateAskablePlusBriefState(askablePlusBriefStateObj);

    if (saveCountryMarker) {
      const markersToSave = await handleMarker({
        savedMarkers: [],
        map: googleMap,
        action: 'insert',
        item: {
          title: country,
          name: country,
          latitude: _.get(countryLocationData, 'latitude'),
          longitude: _.get(countryLocationData, 'longitude'),
          type: 'country',
        },
      });
      setMapMarkers(markersToSave);
    }
  };

  const updateSelectedLocation = (askablePlusBriefStateObj: any, saveCountryMarker = false) => {
    // Update selected locations on screen
    const remoteQuantStates = utils.removeTypenames(
      _.get(askablePlusBriefStateObj, 'askable_plus.audience.booking_config.criteria.locations.states') || [],
    );
    const remoteQuantBounds = utils.removeTypenames(
      _.get(askablePlusBriefStateObj, 'askable_plus.audience.booking_config.criteria.locations.bounds') || [],
    );
    const locationsViewport = getLocationsViewport(remoteQuantStates, remoteQuantBounds);
    if (remoteQuantStates.length > 0 || remoteQuantBounds.length > 0) {
      if (remoteQuantStates.length > 0) {
        setSelectedLocation({
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ formatted_address: any; latitu... Remove this comment to see the full error message
          formatted_address: remoteQuantStates[0].formatted_address,
          latitude: remoteQuantStates[0].latitude,
          longitude: remoteQuantStates[0].longitude,
          locationViewport: locationsViewport,
        });
      } else {
        setSelectedLocation({
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ formatted_address: any; latitu... Remove this comment to see the full error message
          formatted_address: remoteQuantBounds[0].formatted_address,
          latitude: remoteQuantBounds[0].latitude,
          longitude: remoteQuantBounds[0].longitude,
          locationViewport: locationsViewport,
        });
      }
    } else if (_.get(askablePlusBriefStateObj, 'askable_plus.audience.booking_config.location.country')) {
      getCountryLocation(
        _.get(askablePlusBriefStateObj, 'askable_plus.audience.booking_config.location.country'),
        saveCountryMarker,
      );
    }
  };

  const getLocationsViewport = (states: any, bounds: any) => {
    const viewportStates = _.map(states, (state: any) => {
      return _.get(state, 'google_location.viewport');
    });
    const viewportBounds = _.map(bounds, (bound: any) => {
      return _.get(bound, 'google_location.viewport');
    });
    return viewportStates.concat(viewportBounds);
  };

  const onLocationRemove = async (index: any, value: any) => {
    let remoteQuantStates = utils.removeTypenames(
      _.get(askablePlusBriefState, 'askable_plus.audience.booking_config.criteria.locations.states') || [],
    );
    let remoteQuantBounds = utils.removeTypenames(
      _.get(askablePlusBriefState, 'askable_plus.audience.booking_config.criteria.locations.bounds') || [],
    );

    // Deleting a state
    if (!_.has(value, 'google_location.geometry')) {
      remoteQuantStates = update(remoteQuantStates, {
        $splice: [[index, 1]],
      });
    }

    // Deleting bounds
    if (_.has(value, 'google_location.geometry')) {
      remoteQuantBounds = update(remoteQuantBounds, {
        $splice: [[index, 1]],
      });
    }

    const askablePlusBriefLocation = utils.removeTypenames(
      askablePlusBriefState.askable_plus.audience.booking_config.location,
    );
    askablePlusBriefLocation.google_location = {};

    const askablePlusBriefStateObj = {
      ...askablePlusBriefState,
      askable_plus: {
        ...askablePlusBriefState.askable_plus,
        audience: {
          ...askablePlusBriefState.askable_plus.audience,
          booking_config: {
            ...askablePlusBriefState.askable_plus.audience.booking_config,
            location: askablePlusBriefLocation,
            criteria: {
              ...askablePlusBriefState.askable_plus.audience.booking_config.criteria,
              locations: {
                states: remoteQuantStates,
                bounds: remoteQuantBounds,
              },
            },
          },
        },
      },
    };
    setAskablePlusBriefState(askablePlusBriefStateObj);
    props.updateAskablePlusBriefState(askablePlusBriefStateObj);

    const markersToSave = await handleMarker({
      savedMarkers: mapMarkers,
      map: googleMap,
      action: 'delete',
      item: { title: value.formatted_address },
    });

    setMapMarkers(markersToSave);

    const saveCountryBounds = _.size(remoteQuantStates) === 0 && _.size(remoteQuantBounds) === 0;
    updateSelectedLocation(askablePlusBriefStateObj, saveCountryBounds);
  };

  const renderRemoteLocation = (data: any, index: any) => {
    const totalLocations =
      _.size(_.get(askablePlusBriefState, 'askable_plus.audience.booking_config.criteria.locations.states')) +
      _.size(_.get(askablePlusBriefState, 'askable_plus.audience.booking_config.criteria.locations.bounds'));
    return (
      <div
        className={`locationsAreaItems ${totalLocations <= 1 ? 'oneLocation' : ''}`}
        onClick={event => {
          if (totalLocations > 1) {
            event.preventDefault();
            event.stopPropagation();
            onLocationClick(data);
          }
        }}
        key={index}
      >
        <MapPin className="mx-2 h-4 w-4" />
        <p className="locationName">{_.get(data, 'formatted_address')}</p>
        {totalLocations >= 1 && (
          <a
            className="locationRemove"
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              onLocationRemove(index, data);
            }}
          >
            Delete
          </a>
        )}
      </div>
    );
  };

  const renderParticipantsLocation = () => {
    const hasStates =
      _.size(_.get(askablePlusBriefState, 'askable_plus.audience.booking_config.criteria.locations.states')) > 0;
    const hasBounds =
      _.size(_.get(askablePlusBriefState, 'askable_plus.audience.booking_config.criteria.locations.bounds')) > 0;

    return (
      <>
        <div className="locationsContainer">
          <div className="locationsArea">
            {_.get(askablePlusBriefState, 'askable_plus.audience.booking_config.location.country') &&
              !hasStates &&
              !hasBounds &&
              renderRemoteLocation(countryLocation, 0)}
            {hasStates &&
              _.map(
                _.get(askablePlusBriefState, 'askable_plus.audience.booking_config.criteria.locations.states'),
                (data: any, index: any) => {
                  return renderRemoteLocation(data, index);
                },
              )}
            {hasBounds &&
              _.map(
                _.get(askablePlusBriefState, 'askable_plus.audience.booking_config.criteria.locations.bounds'),
                (data: any, index: any) => {
                  return renderRemoteLocation(data, index);
                },
              )}
          </div>
        </div>

        <LocationAutocomplete
          placeholder="Type to add more participant locations"
          onNewPlace={onLocationAdded}
          type="(regions)"
          countryCodes={[_.get(askablePlusBriefState, 'askable_plus.audience.booking_config.location.country')]}
          styleWidth={548}
          clearValue
          setIsLoading={setIsLoading}
          canChangeCountry
        />
      </>
    );
  };

  const onLocationAdded = async (value: Location) => {
    let markersToSave = _.clone(mapMarkers);
    let remoteQuantStates = utils.removeTypenames(
      _.get(askablePlusBriefState, 'askable_plus.audience.booking_config.criteria.locations.states') || [],
    );
    let remoteQuantBounds = utils.removeTypenames(
      _.get(askablePlusBriefState, 'askable_plus.audience.booking_config.criteria.locations.bounds') || [],
    );
    const locationInfo = {
      country: value.country,
      name: '',
      street1: '',
      city: '',
      postal_code: '',
      state: '',
      latitude: null,
      longitude: null,
      region: '',
      google_location:
        value.country !== _.get(askablePlusBriefState, 'askable_plus.audience.booking_config.location.country')
          ? {}
          : _.get(askablePlusBriefState, 'askable_plus.audience.booking_config.location.google_location'),
    };

    // Tests if location has already been added
    const locationAdded = _.filter([...remoteQuantStates, ...remoteQuantBounds], (data: any) => {
      return data.formatted_address === value.formatted_address;
    });
    if (locationAdded.length > 0) return null;

    // If select an entire country or change country location
    if (
      _.get(value, 'google_location_types')?.includes('country') ||
      _.get(value, 'country') !== _.get(askablePlusBriefState, 'askable_plus.audience.booking_config.location.country')
    ) {
      // Clears list of selected locations
      remoteQuantStates = [];
      remoteQuantBounds = [];
      locationInfo.google_location = { viewport: _.get(value, 'google_location.viewport') || {} };
      setCountryLocation(value);

      // Remove all markers
      markersToSave = await removeAllMarkers(markersToSave);

      // Add country marker and polygon
      if (_.get(value, 'google_location_types')?.includes('country')) {
        markersToSave = await handleMarker({
          savedMarkers: markersToSave,
          map: googleMap,
          action: 'insert',
          item: {
            title: value.country,
            name: value.country,
            latitude: _.get(value, 'latitude'),
            longitude: _.get(value, 'longitude'),
            type: 'country',
          },
        });
      }
    }

    // If you didnt select an entire country
    if (!_.get(value, 'google_location_types')?.includes('country')) {
      // If was selected a state
      if (_.includes(_.get(value, 'google_location_types'), 'administrative_area_level_1')) {
        const stateSelected = value.google_location_types?.includes('administrative_area_level_1');

        // Remove state bounds markers and circles
        const boundsOnState = _.filter(remoteQuantBounds, (data: any) => data.state === _.get(value, 'state'));
        if (_.size(boundsOnState) > 0) {
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'any[]' is not assignable to type 'never[]'.
          markersToSave = await Promise.all(
            _.map(boundsOnState, (item: any) => {
              return handleMarker({
                savedMarkers: markersToSave,
                map: googleMap,
                action: 'delete',
                item: { title: _.get(item, 'formatted_address') },
              });
            }),
          );
        }

        // Remove country marker and polygon
        markersToSave = await handleMarker({
          savedMarkers: markersToSave,
          map: googleMap,
          action: 'delete',
          item: { title: value.country },
        });

        // Add state marker and polygon
        markersToSave = await handleMarker({
          savedMarkers: markersToSave,
          map: googleMap,
          action: 'insert',
          item: {
            title: value.formatted_address,
            name: _.get(stateSelected, '[0].short_name'),
            latitude: _.get(value, 'latitude'),
            longitude: _.get(value, 'longitude'),
            type: 'states',
          },
        });

        const locationToAdd = {
          name: _.get(stateSelected, '[0].short_name'),
          state: _.get(value, 'state'),
          formatted_address: value.formatted_address,
          latitude: _.get(value, 'latitude'),
          longitude: _.get(value, 'longitude'),
          google_location: {
            viewport: _.get(value, 'google_location.viewport') || {},
          },
        };
        remoteQuantStates = update(remoteQuantStates, {
          $push: [locationToAdd],
        });
        remoteQuantBounds = _.filter(remoteQuantBounds, (data: any) => {
          return data.state !== _.get(value, 'state');
        });
      } else {
        // Remove state marker and polygon
        const stateFromBounds = _.filter(remoteQuantStates, data => data.state === _.get(value, 'state'));
        if (_.size(stateFromBounds) > 0) {
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'any[]' is not assignable to type 'never[]'.
          markersToSave = await Promise.all(
            _.map(stateFromBounds, item => {
              return handleMarker({
                savedMarkers: markersToSave,
                map: googleMap,
                action: 'delete',
                item: { title: _.get(item, 'formatted_address') },
              });
            }),
          );
        }

        // Remove country marker and polygon
        markersToSave = await handleMarker({
          savedMarkers: markersToSave,
          map: googleMap,
          action: 'delete',
          item: { title: value.country },
        });

        // Add bound marker and circle
        markersToSave = await handleMarker({
          savedMarkers: markersToSave,
          map: googleMap,
          action: 'insert',
          item: {
            title: value.formatted_address,
            name: value.formatted_address,
            latitude: _.get(value, 'latitude'),
            longitude: _.get(value, 'longitude'),
            type: 'bounds',
          },
          colloquial_area: _.includes(_.get(value, 'google_location_types'), 'colloquial_area'),
        });

        const locationToAdd = {
          formatted_address: value.formatted_address,
          state: _.get(value, 'state'),
          latitude: _.get(value, 'latitude'),
          longitude: _.get(value, 'longitude'),
          google_location: {
            geometry: {
              latitude_ne: _.get(value, 'google_location.geometry.latitude_ne'),
              longitude_ne: _.get(value, 'google_location.geometry.longitude_ne'),
              latitude_sw: _.get(value, 'google_location.geometry.latitude_sw'),
              longitude_sw: _.get(value, 'google_location.geometry.longitude_sw'),
            },
            viewport: _.get(value, 'google_location.viewport') || {},
          },
        };
        remoteQuantBounds = update(remoteQuantBounds, {
          $push: [locationToAdd],
        });
        remoteQuantStates = _.filter(remoteQuantStates, (data: any) => {
          return data.state !== _.get(value, 'state');
        });
      }
    }

    const countryInfo = countryCurrencyList?.find(item => item?.country_code === value.country);

    const askablePlusBriefStateObj = {
      ...askablePlusBriefState,
      askable_plus: {
        ...askablePlusBriefState.askable_plus,
        audience: {
          ...askablePlusBriefState.askable_plus.audience,
          booking_config: {
            ...askablePlusBriefState.askable_plus.audience.booking_config,
            location: locationInfo,
            criteria: {
              ...askablePlusBriefState.askable_plus.audience.booking_config.criteria,
              locations: {
                states: remoteQuantStates,
                bounds: remoteQuantBounds,
              },
            },
            incentive: {
              ...askablePlusBriefState.askable_plus.audience.booking_config.incentive,
              currency_symbol: countryInfo?.currency_symbol || '$',
              currency_code: countryInfo?.currency_code || 'USD',
            },
          },
        },
      },
    };
    setAskablePlusBriefState(askablePlusBriefStateObj);
    props.updateAskablePlusBriefState(askablePlusBriefStateObj);

    // Update map markers
    setMapMarkers(markersToSave);

    setSelectedLocation({
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ formatted_address: any; latitu... Remove this comment to see the full error message
      formatted_address: value.formatted_address,
      latitude: value.latitude,
      longitude: value.longitude,
      locationViewport: [_.get(value, 'google_location.viewport')],
    });
  };

  const onClickNext = () => {
    const redirectTo = `/askable-plus/${askablePlusBrief._id}/additional-info/moderation-guide`;
    props.history.push({ pathname: redirectTo });
  };

  return (
    <div className="createAskablePlusBriefContent flex flex-col gap-4">
      <h1 className="title">Participant location</h1>

      {isLoading ? <LoadingOverlay style={{ opacity: 0.8 }} /> : null}

      <div className="mapPreview rounded-md" id="mapPreviewContainer">
        {googleMap && selectedLocation ? (
          <GoogleMaps
            id="locationMap"
            googleMap={googleMap}
            style={{ width: 548, height: 233, borderRadius: '8px' }}
            lat={_.get(selectedLocation, 'latitude')}
            lng={_.get(selectedLocation, 'longitude')}
            locations={{
              states: _.get(askablePlusBriefState, 'askable_plus.audience.booking_config.criteria.locations.states'),
              bounds: _.get(askablePlusBriefState, 'askable_plus.audience.booking_config.criteria.locations.bounds'),
            }}
            countryLocation={_.get(askablePlusBriefState, 'askable_plus.audience.booking_config.location')}
            locationViewport={_.get(selectedLocation, 'locationViewport')}
          />
        ) : null}
      </div>

      {renderParticipantsLocation()}

      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default deprecatedWithRouter(Quota);
