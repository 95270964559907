/* eslint-disable max-lines */
import type { ResultsProps, ParticipantProps } from './Results';

const participants: ParticipantProps[] = [
  {
    id: '1',
    completed: 1733293371000,
    duration: 235,
    misclickRate: 0.3,
    name: 'Donald Bradman',
    outcome: 'success',
    screensViewed: 7,
    status: 'active',
    thumbnail: 'https://picsum.photos/id/80/72/128?grayscale',
    details: {
      age: 27,
      location: 'Brisbane, QLD, AU',
      gender: 'Male',
    },
    recording: {
      url: 'https://cdn-dev.askable.com/recordings/a7k-yhyr-oew/JgY9jZ7HWOLUFAFagyDT.mp4',
      duration: 300,
      screens: [
        {
          id: '1',
          title: 'Screen 1',
          image: 'https://picsum.photos/id/180/1280/2000?grayscale',
          thumbnail: 'https://picsum.photos/id/180/64/64?grayscale',
          height: 2000,
          width: 1280,
          duration: 20,
          misclickRate: 0.2,
          clicks: [
            { x: 200, y: 100 },
            { x: 210, y: 110 },
            { x: 210, y: 130 },
            { x: 500, y: 600 },
            { x: 550, y: 650 },
            { x: 780, y: 200 },
            { x: 1100, y: 600 },
            { x: 720, y: 1900 },
          ],
        },
        {
          id: '2',
          title: 'Screen 2',
          image: 'https://picsum.photos/id/43/768/2000?grayscale',
          thumbnail: 'https://picsum.photos/id/43/64/64?grayscale',
          height: 2000,
          width: 768,
          duration: 45,
          misclickRate: 0.15,
          clicks: [
            { x: 300, y: 100 },
            { x: 410, y: 210 },
            { x: 410, y: 130 },
            { x: 500, y: 600 },
          ],
        },
      ],
    },
    responses: [
      {
        id: '1',
        type: 'multiple_choice_question',
        question: 'How often do you watch cricket matches?',
        answer: {
          id: '1',
          value: 'Every match I can',
        },
      },
      {
        id: '2',
        type: 'multiple_choice_question',
        question: 'Which format of cricket do you enjoy watching the most?',
        answer: {
          id: '2',
          value: 'I enjoy all formats equally',
        },
      },
      {
        id: '3',
        type: 'opinion_scale',
        question: 'On a scale of 1-5, how well do you know the cricket rules?',
        answer: {
          id: '1',
          value: '2',
        },
      },
      {
        id: '4',
        type: 'figma_prototype',
        question:
          'Imagine you need to find a cricket ground in your area to book a net session. Click through the following prototype to find a ground',
        answer: {
          id: '1',
          value: 'Test',
        },
      },
    ],
    studyDetails: {
      completionDate: 1733293371000,
      deviceType: '',
      duration: 300,
      os: 'iOS 18',
    },
    screenerQuestions: [
      {
        id: '1',
        eligible: true,
        question: 'How often do you watch cricket matches?',
        answer: 'Every match I can',
      },
      {
        id: '2',
        eligible: false,
        question: 'Which cricket tournament do you look forward to the most?',
        answer: 'Every match I can',
      },
    ],
  },
  {
    completed: 1731263371000,
    duration: 154,
    id: '2',
    misclickRate: 0.3,
    name: 'Ian Botham',
    outcome: 'incomplete',
    screensViewed: 3,
    status: 'active',
    thumbnail: 'https://picsum.photos/id/155/72/128?grayscale',
    details: {
      age: 35,
      location: 'Cairns, QLD, AU',
      gender: 'Male',
    },
    recording: {
      url: 'https://cdn-dev.askable.com/recordings/a7k-yhyr-oew/JgY9jZ7HWOLUFAFagyDT.mp4',
      duration: 300,
      screens: [
        {
          id: '1',
          title: 'Screen 1',
          image: 'https://picsum.photos/id/180/1280/2000?grayscale',
          thumbnail: 'https://picsum.photos/id/180/64/64?grayscale',
          height: 2000,
          width: 1280,
          duration: 20,
          misclickRate: 0.2,
          clicks: [
            { x: 900, y: 300 },
            { x: 410, y: 110 },
            { x: 410, y: 110 },
            { x: 420, y: 130 },
            { x: 500, y: 600 },
          ],
        },
      ],
    },
    responses: [],
    screenerQuestions: [],
    studyDetails: {
      completionDate: 1733293371000,
      deviceType: 'Mobile',
      duration: 300,
      os: 'iOS 18',
    },
  },
  {
    completed: 1731263371000,
    duration: 78,
    id: '3',
    misclickRate: 0.25,
    name: 'Sara Swimmer',
    outcome: 'success',
    screensViewed: 4,
    status: 'active',
    thumbnail: 'https://picsum.photos/id/144/72/128?grayscale',
    details: {
      age: 21,
      location: 'Sydney, NSW, AU',
      gender: 'Female',
    },
    screenerQuestions: [],
    studyDetails: {
      completionDate: 1733293371000,
      deviceType: 'Mobile',
      duration: 300,
      os: 'iOS 18',
    },
    recording: {
      url: 'https://cdn-dev.askable.com/recordings/a7k-yhyr-oew/JgY9jZ7HWOLUFAFagyDT.mp4',
      duration: 300,
      screens: [
        {
          id: '1',
          title: 'Screen 1',
          image: 'https://picsum.photos/id/180/1280/2000?grayscale',
          thumbnail: 'https://picsum.photos/id/180/64/64?grayscale',
          height: 2000,
          width: 1280,
          duration: 20,
          misclickRate: 0.2,
          clicks: [
            { x: 600, y: 100 },
            { x: 410, y: 110 },
            { x: 420, y: 130 },
            { x: 500, y: 600 },
          ],
        },
      ],
    },
    responses: [],
  },
];

const tasks: ResultsProps['tasks'] = [
  {
    id: '1',
    title: 'Test task 1',
    type: 'figma_prototype',
    responses: 12,
    stats: [
      { type: 'successful', value: 0.75, subtitle: '9 responses' },
      { type: 'incomplete', value: 0.25 },
      { type: 'misclickRate', value: 0.2 },
      { type: 'averageDuration', value: 25.3 },
    ],
    sections: [
      {
        id: '1',
        title: 'Path 1',
        type: 'success',
        responses: 6,
        misclickRate: 0.1,
        duration: 154,
        screens: [
          {
            id: '1',
            title: 'Mobile Screen 1',
            image: 'https://picsum.photos/id/237/400/700?grayscale',
            thumbnail: 'https://picsum.photos/id/237/64/64?grayscale',
            width: 400,
            height: 700,
            responses: 3,
            duration: 12,
            misclickRate: 0.2,
            clicks: [],
          },
          {
            id: '2',
            title: 'Mobile Screen 2',
            image: 'https://picsum.photos/id/290/400/700?grayscale',
            thumbnail: 'https://picsum.photos/id/290/64/64?grayscale',
            width: 400,
            height: 700,
            responses: 6,
            duration: 73,
            misclickRate: 0.2,
            clicks: [],
          },
          {
            id: '3',
            title: 'Mobile Screen 3',
            image: 'https://picsum.photos/id/180/400/700?grayscale',
            thumbnail: 'https://picsum.photos/id/180/64/64?grayscale',
            width: 400,
            height: 700,
            responses: 6,
            duration: 23,
            misclickRate: 0.2,
            clicks: [],
          },
          {
            id: '5',
            title: 'Desktop Screen 5',
            image: 'https://picsum.photos/id/29/800/400?grayscale',
            thumbnail: 'https://picsum.photos/id/29/64/64?grayscale',
            width: 800,
            height: 400,
            responses: 5,
            duration: 16,
            misclickRate: 0.2,
            clicks: [],
          },
          {
            id: '4',
            title: 'Mobile Screen 4',
            image: 'https://picsum.photos/id/33/400/700?grayscale',
            thumbnail: 'https://picsum.photos/id/33/64/64?grayscale',
            width: 400,
            height: 700,
            responses: 6,
            duration: 9,
            misclickRate: 0.2,
            clicks: [],
          },
          {
            id: '6',
            title: 'Desktop Screen 6',
            image: 'https://picsum.photos/id/42/800/1700?grayscale',
            thumbnail: 'https://picsum.photos/id/42/64/64?grayscale',
            width: 800,
            height: 1700,
            responses: 3,
            duration: 54,
            misclickRate: 0.2,
            clicks: [],
          },
          {
            id: '7',
            title: 'Desktop Screen 7',
            image: 'https://picsum.photos/id/40/800/1700?grayscale',
            thumbnail: 'https://picsum.photos/id/40/64/64?grayscale',
            width: 800,
            height: 1700,
            responses: 3,
            duration: 54,
            misclickRate: 0.2,
            clicks: [],
          },
        ],
      },
      {
        id: '2',
        title: 'Path 2',
        type: 'incomplete',
        responses: 3,
        misclickRate: 0.2,
        duration: 40,
        screens: [],
      },
    ],
  },
  {
    id: '2',
    title: 'Test task 2',
    type: 'multiple_choice_question',
    responses: 6,
    stats: [],
    sections: [
      {
        id: '3',
        title: 'Path 4',
        type: 'incomplete',
        responses: 4,
        misclickRate: 0.54,
        duration: 130,
        screens: [
          {
            id: '1',
            title: 'Mobile Screen 1',
            image: 'https://picsum.photos/id/237/400/700?grayscale',
            thumbnail: 'https://picsum.photos/id/237/64/64?grayscale',
            width: 400,
            height: 700,
            responses: 6,
            duration: 23,
            clicks: [],
          },
        ],
      },
    ],
  },
];

export const dummyResults: ResultsProps = {
  id: '123',
  name: 'Test study',
  status: 'active',
  completed: 1733293371000,
  created: 1731263371000,
  participants,
  tasks,
};
