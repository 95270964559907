import { cn } from '@askable/ui/lib/utils';
import { Briefcase, Building2, CalendarDays, Gem, Globe, GraduationCap, MapPin, Store, Users } from 'lucide-react';
import { Fragment } from 'react';

import type { LucideIcon } from 'lucide-react';

export type summaryItemType =
  | 'age_range'
  | 'business_ownership'
  | 'education_level'
  | 'gender'
  | 'industry_and_occupation'
  | 'language_fluency'
  | 'locations'
  | 'marital_status'
  | 'occupational_status';

interface SummaryItemProps {
  label: string;
  options: string[] | number[];
  type: summaryItemType;
  variant?: 'inline' | 'stacked' | 'dash-between';
}

const summaryItemIconByType: Record<summaryItemType, LucideIcon> = {
  age_range: CalendarDays,
  business_ownership: Store,
  education_level: GraduationCap,
  gender: Users,
  industry_and_occupation: Building2,
  language_fluency: Globe,
  locations: MapPin,
  marital_status: Gem,
  occupational_status: Briefcase,
};

export const SummaryItem = ({ label, type, options, variant = 'inline' }: SummaryItemProps) => {
  const Icon = summaryItemIconByType[type];

  return (
    <li className="flex gap-2">
      <Icon className="h-4 w-4 text-foreground-subtle" />
      <div className={cn('flex flex-col gap-1 truncate')}>
        <div className="text-xs text-foreground-subtle">{label}</div>

        {options.length > 0 ? (
          <>
            {variant === 'dash-between' ? `${options[0]} - ${options[1]}` : null}

            {variant === 'inline' ? (
              <div className="w-full whitespace-normal leading-normal">{options.join(', ')}</div>
            ) : null}

            {variant === 'stacked' ? (
              <ul className="flex flex-col">
                {options.map(option => (
                  <Fragment key={option}>
                    <li className="truncate" title={String(option)}>
                      {option}
                    </li>
                  </Fragment>
                ))}
              </ul>
            ) : null}
          </>
        ) : (
          <>-</>
        )}
      </div>
    </li>
  );
};
