import { Button } from '@askable/ui/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@askable/ui/components/ui/form';
import { Input } from '@askable/ui/components/ui/input';
import { toast } from '@askable/ui/components/ui/sonner';
import { Switch } from '@askable/ui/components/ui/switch';
import { Textarea } from '@askable/ui/components/ui/textarea';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'urql';
import { z } from 'zod';

import { KindeSsoStrategy } from 'generated/graphql';
import { validDomain } from 'lib/form/validators';

import { UpdateSSOConnection } from '../data/UpdateSSOConnection.mutation';

const customSamlForm = z.object({
  clientId: z.string(),
  azureAdDomain: z.string().url(),
  clientSecret: z.string(),
  useCommonEndpoint: z.boolean().default(false),
  isEnabled: z.boolean().default(false),
  homeRealmDomains: validDomain,
});

interface Props {
  teamId: string;
  connectionId: string;
  strategy: KindeSsoStrategy;
  defaultValues?: z.infer<typeof customSamlForm>;
  handleSaveComplete: () => void;
}

export const MicrosoftSSO = ({ strategy, handleSaveComplete, defaultValues, teamId, connectionId }: Props) => {
  const { t } = useTranslation();

  const [{ fetching }, updateConnection] = useMutation(UpdateSSOConnection);

  const form = useForm<z.infer<typeof customSamlForm>>({
    resolver: zodResolver(customSamlForm),
    defaultValues: {
      ...defaultValues,
      useCommonEndpoint: defaultValues?.useCommonEndpoint ?? false,
    },
  });

  const onSubmit = async (values: z.infer<typeof customSamlForm>) => {
    const { error } = await updateConnection({
      connectionId,
      isEnabled: values.isEnabled,
      teamId,
      options: {
        home_realm_domains: values.homeRealmDomains.split(',').map(a => a.trim()),
        azure_ad: {
          entra_id_domain: values.azureAdDomain,
          client_id: values.clientId,
          client_secret: values.clientSecret,
          is_use_common_endpoint: values.useCommonEndpoint,
        },
      },
    });

    if (error) {
      toast.error(error.message);
      return;
    }

    handleSaveComplete();
  };

  const callbackUrl = `${import.meta.env.VITE_KINDE_DOMAIN}/login/${strategy === KindeSsoStrategy.Oauth2AzureAd ? '' : 'wsfed/'}callback`;

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="azureAdDomain"
          render={({ field }) => (
            <FormItem className="space-y-2">
              <FormLabel>{t('sections.settings.team.enterpriseSso.microsoft.azureAdDomain')}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="clientId"
          render={({ field }) => (
            <FormItem className="space-y-2">
              <FormLabel>{t('sections.settings.team.enterpriseSso.microsoft.clientID')}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="clientSecret"
          render={({ field }) => (
            <FormItem className="space-y-2">
              <FormLabel>{t('sections.settings.team.enterpriseSso.microsoft.clientSecret')}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="homeRealmDomains"
          render={({ field }) => (
            <FormItem className="space-y-2">
              <FormLabel>{t('sections.settings.team.enterpriseSso.domains.title')}</FormLabel>
              <FormControl>
                <Textarea {...field} />
              </FormControl>
              <FormDescription>{t('sections.settings.team.enterpriseSso.domains.description')}</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="useCommonEndpoint"
          render={({ field: { value, ...fields } }) => (
            <FormItem className="space-y-2">
              <div className="flex items-center justify-between">
                <FormLabel>{t('sections.settings.team.enterpriseSso.microsoft.useCommonEndpointTitle')}</FormLabel>
                <FormControl>
                  <Switch {...fields} checked={value} onCheckedChange={fields.onChange} />
                </FormControl>
              </div>
              <FormDescription>
                {t('sections.settings.team.enterpriseSso.microsoft.useCommonEndpointDescription')}
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormItem className="space-y-2">
          <FormLabel>{t('sections.settings.team.enterpriseSso.microsoft.callbackUrlTitle')}</FormLabel>
          <FormControl>
            <Input disabled readOnly value={callbackUrl} />
          </FormControl>
          <FormDescription>
            {t('sections.settings.team.enterpriseSso.microsoft.callbackUrlDescription', {
              strategy: strategy === KindeSsoStrategy.WsfedAzureAd ? '(WS-Fed)' : '(OAuth 2.0)',
            })}
          </FormDescription>
          <FormMessage />
        </FormItem>

        <FormField
          control={form.control}
          name="isEnabled"
          render={({ field: { value, ...fields } }) => {
            return (
              <FormItem className="space-y-2">
                <div className="flex items-center justify-between">
                  <FormLabel>{t('global.enabled')}</FormLabel>
                  <FormControl>
                    <Switch {...fields} checked={value} onCheckedChange={fields.onChange} />
                  </FormControl>
                </div>
                <FormDescription>{t('sections.settings.team.enterpriseSso.enabledDescription')}</FormDescription>
                <FormMessage />
              </FormItem>
            );
          }}
        />

        <Button isLoading={fetching} className="ml-auto" variant="primary" size="xl" type="submit">
          {t('global.save')}
        </Button>
      </form>
    </Form>
  );
};
