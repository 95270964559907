import { graphql } from 'src/graphql';

import type { ResultOf } from 'src/graphql';

export const StudyTaskBlockResultsQuery = graphql(`
  query studyBlockResults($first: Int, $filter: StudyBlockResultsFilterInput) {
    studyBlockResults(first: $first, filter: $filter) {
      totalCount
      nodes {
        _id
        status
        _user_id
        task_ended
        task_started
        task_loaded
      }
    }
  }
`);

export type StudyTaskBlockResultPartial = NonNullable<
  NonNullable<ResultOf<typeof StudyTaskBlockResultsQuery>['studyBlockResults']['nodes']>[number]
>;
