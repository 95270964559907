import { cn } from '@askable/ui/lib/utils';
import { cva, type VariantProps } from 'class-variance-authority';
import { Loader2 } from 'lucide-react';
import { forwardRef, cloneElement, isValidElement } from 'react';

// import { Slot } from '@radix-ui/react-slot';
import { link } from '../styles/shared-styles';

import type { AnchorHTMLAttributes, ButtonHTMLAttributes, PropsWithChildren, ReactElement } from 'react';

const buttonVariants = cva(
  'flex relative items-center justify-center gap-1 shrink-0 whitespace-nowrap rounded-sm text-sm font-medium ring-offset-background transition focus-visible:border-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 aria-[expanded=true]:bg-secondary',
  {
    variants: {
      variant: {
        link,
        outline:
          'text-secondary-foreground border-input border-0.5 bg-background hover:bg-accent focus:bg-accent active:bg-accent',
        default:
          'bg-secondary text-secondary-foreground hover:bg-secondary/80 focus:bg-secondary/80 active:bg-secondary',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90 focus:bg-destructive/90 active:bg-destructive',
        ghost: 'bg-transparent text-foreground hover:bg-accent active:bg-accent focus:bg-accent',
        primary:
          'bg-primary text-primary-foreground hover:bg-primary/90 focus:bg-primary/90 active:bg-primary aria-[expanded=true]:bg-primary',
        secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80 active:bg-secondary',
        info: 'bg-info-foreground text-info hover:bg-info/10 focus:bg-info/10 active:bg-info/20',
      },
      size: {
        sm: 'h-6 px-2 py-1 text-xs',
        default: 'h-8 px-3',
        lg: 'h-10 px-4 py-2 rounded-md text-md',
        xl: 'h-12 px-4 py-2 rounded-md text-md',
        icon: 'h-8 w-8 p-0 rounded-md aspect-square',
        icon_rounded_full: 'h-8 w-8 p-0 rounded-full aspect-square',
        icon_lg: 'h-10 w-10 p-0 rounded-md text-md aspect-square',
        icon_lg_rounded_full: 'h-10 w-10 p-0 rounded-full text-md aspect-square',
        icon_xl: 'h-12 w-12 p-0 rounded-md text-md aspect-square',
        icon_xl_rounded_full: 'h-12 w-12 p-0 rounded-full text-md aspect-square',
        icon_sm: 'h-6 w-6 p-0 rounded-md aspect-square',
        icon_sm_rounded_full: 'h-6 w-6 p-0 rounded-full aspect-square',
      },
      width: {
        fit: 'w-fit',
        full: 'justify-start w-full',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
      width: 'fit',
    },
  },
);

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  isLoading?: boolean;
}

const LoadingState = ({ isLoading = false, children }: PropsWithChildren<{ isLoading?: boolean }>) => {
  if (!isLoading) {
    return children;
  }
  return (
    <>
      <span className="opacity-0">{children}</span>
      <Loader2 className="absolute h-4 w-4 animate-spin" />
    </>
  );
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, disabled, variant, isLoading = false, size, width, type = 'button', children, ...props }, ref) => {
    const isDisabled = isLoading || disabled;
    return (
      <button
        className={cn(buttonVariants({ variant, size, width, className }), {
          disabled: isDisabled,
        })}
        ref={ref}
        {...props}
        disabled={isDisabled}
        type={type}
      >
        <LoadingState isLoading={isLoading}>{children}</LoadingState>
      </button>
    );
  },
);
Button.displayName = 'Button';

export interface AnchorButtonProps
  extends AnchorHTMLAttributes<HTMLAnchorElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
}

const AnchorButton = forwardRef<HTMLAnchorElement, AnchorButtonProps>(
  ({ className, disabled, variant, asChild = false, isLoading = false, size, width, children, ...props }, ref) => {
    const isDisabled = isLoading || disabled;
    // const Comp = asChild ? Slot : 'a';
    // return (
    //   <Comp
    //     className={cn(buttonVariants({ variant, size, width, className }), {
    //       'pointer-events-none opacity-50': isDisabled,
    //     })}
    //     ref={ref}
    //     {...props}
    //   >
    //     <LoadingState isLoading={isLoading}>{children}</LoadingState>
    //   </Comp>
    // );

    const classes = cn(buttonVariants({ variant, size, width, className }), {
      'pointer-events-none opacity-50': isDisabled,
    });

    const renderChildElement = () => {
      if (isValidElement(children)) {
        return cloneElement(children as ReactElement, { className: classes });
      }
      return children;
    };

    if (asChild) {
      return <>{renderChildElement()}</>;
    }

    return (
      <a className={classes} ref={ref} {...props}>
        <LoadingState isLoading={isLoading}>{children}</LoadingState>
      </a>
    );
  },
);

AnchorButton.displayName = 'AnchorButton';

export { Button, AnchorButton, buttonVariants };
export type ButtonVariants = VariantProps<typeof buttonVariants>;
